import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  
  constructor(private http: HttpClient) { }

  getCountry(): Observable<any> {
    return this.http.get(URLS.getCountry)
  }

  getState(data:any): Observable<any> {
    let getStateList = URLS.getState;
    let getStateLists = getStateList.replace('accState', data.trim())
    return this.http.get(getStateLists)
  }

  createCustomer(formData: any) {
    let addCustomer = URLS.createCustomer;
    return this.http.post(addCustomer,formData);
  }
  postContactUs(formData: any) {
    let postContactUs = URLS.postContactUs;
    return this.http.post(postContactUs,formData);
  }
  
}
