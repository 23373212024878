import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../services/services.service';

@Component({
  selector: 'app-add-credit-card',
  templateUrl: './add-credit-card.component.html'
})
export class AddCreditCardComponent implements OnInit {
  accountData: any;
  partyId: any;
  editMode: boolean;
  creditCardForm: FormGroup;
  cardTypes: any[];
  cardNumber: any;
  cardType: any;
  firstNameOnCard: any;
  lastNameOnCard: any;
  show!: boolean;
  prefixArray = [
    {
      label: 'Mr.',
      value: 'Mr.'
    },
    {
      label: 'Mrs.',
      value: 'Mrs.'
    },
    {
      label: 'Ms.',
      value: 'Ms.'
    },
    {
      label: 'Dr.',
      value: 'Dr.'
    }];
  suffixArray = [
    {
      label: 'Jr.',
      value: 'Jr.'
    },
    {
      label: 'Sr.',
      value: 'Sr.'
    },
    {
      label: 'I',
      value: 'I'
    },
    {
      label: 'II',
      value: 'II'
    },
    {
      label: 'III',
      value: 'III'
    },
    {
      label: 'IV',
      value: 'IV'
    },
    {
      label: 'V',
      value: 'V'
    },
  ];
  expirationMonth = [
    {
      label: '1 (Jan)',
      value: '1'
    },
    {
      label: '2 (Feb)',
      value: '2'
    },
    {
      label: '3 (Mar)',
      value: '3'
    },
    {
      label: '4 (Apr)',
      value: '4'
    },
    {
      label: '5 (May)',
      value: '5'
    },
    {
      label: '6 (Jun)',
      value: '6'
    },
    {
      label: '7 (Jul)',
      value: '7'
    },
    {
      label: '8 (Aug)',
      value: '8'
    },
    {
      label: '9 (Sep)',
      value: '9'
    },
    {
      label: '10 (Oct)',
      value: '10'
    },
    {
      label: '11 (Nov)',
      value: '11'
    },
    {
      label: '12 (Dec)',
      value: '12'
    }];
  expirationYear = [


    {
      label: '2020',
      value: '2020'
    },
    {
      label: '2021',
      value: '2021'
    },
    {
      label: '2022',
      value: '2022'
    },
    {
      label: '2023',
      value: '2023'
    },

    {
      label: '2024',
      value: '2024'
    },
    {
      label: '2025',
      value: '2025'
    },
    {
      label: '2026',
      value: '2026'
    },
    {
      label: '2027',
      value: '2027'
    },
    {
      label: '2028',
      value: '2028'
    },
    {
      label: '2029',
      value: '2029'
    },
    {
      label: '2030',
      value: '2030'
    }];
  contactMechId: string;
  paymentMethodId: string;
  creditCardValue: any;
  constructor(
    private _CRMAccountService: ServicesService,
    readonly _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    private _ToastrService: ToastrService
  ) {
    this.partyId = localStorage.getItem('partyId');
    this.editMode = false;
    this.creditCardForm = this._FormBuilder.group({
      cardNumber: ['', [Validators.required]],
      cardType: ['', [Validators.required]],
      companyNameOnCard: [''],
      contactMechId: [''],
      description: [''],
      expMonth: ['', [Validators.required]],
      expYear: ['', [Validators.required]],
      firstNameOnCard: ['', [Validators.required]],
      lastNameOnCard: ['', [Validators.required]],
      middleNameOnCard: ['', [Validators.required]],
      suffixOnCard: [''],
      titleOnCard: ['']
    });
    this.cardTypes = [];
    this.contactMechId = '';
    this.paymentMethodId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show = false;
    this.partyId = localStorage.getItem('partyId');
    this.paymentMethodId = '';
    this._ActivatedRoute.queryParams.subscribe(params => {
      if (params["cardNumber"] && params["cardNumber"] && params["firstNameOnCard"] && params["lastNameOnCard"] && params["paymentMethodId"]) {
        this.cardNumber = params["cardNumber"];
        this.cardType = params["cardType"];
        this.firstNameOnCard = params["firstNameOnCard"];
        this.lastNameOnCard = params["lastNameOnCard"];
        this.paymentMethodId=params["paymentMethodId"];
        this.show = true;
      }
    })
    if (this.paymentMethodId) {
      this.spinner.show();
      this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'CREDIT_CARD')
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.creditCardValue = data.data;
            this.contactMechId = data.data.creditCardData.currentPostalAddress.contactMechId;
            this.creditCardForm.patchValue({
              ...data.data.creditCardData, ...{
                description: data.data.paymentMethodData.description
              }
            });
            this.editMode = true;
          }
        });
    }
    this.getCardTypes();
    this.getAccountDetails();
  }
  getAccountDetails(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.accountData = data.data;
        }
      });

  }
  getCardTypes(): void {
    this.spinner.show();
    this._CRMAccountService.getCreditCardTypes()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.cardTypes = data.data.map((value: { enumCode: any; enumId: any; }) => {
            return {
              label: value.enumCode,
              value: value.enumId
            };
          });
        }
      });

  }

  getAddress(event: any, contactMechId: string): void {
    this.contactMechId = (event.target.checked) ? contactMechId : '';
  }
  submit(): void {
    this.spinner.show();
    
      this._CRMAccountService.postCreditCard(this.partyId, {
        ...this.creditCardForm.value, ...{
          contactMechId: this.contactMechId
        }
      }).subscribe((res: any) => {
          if (res.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.cancelSubmit();
          }
          }, (err) => {
          
            //this.spinner.hide();
            for (const value of err.error.errors) {
              this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
            }
        });
    
  }
  update(): void {
    this.spinner.show();

      this._CRMAccountService.updateCreditCard(this.partyId, this.paymentMethodId, {
        ...this.creditCardValue.creditCardData, ...this.creditCardForm.value, ...{
          contactMechId: this.contactMechId,
          suffixOnCard: 'I'
        }
      })
      .subscribe((res: any) => {
        if (res.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.cancelSubmit();
        }
        }, (err) => {
         
          //this.spinner.hide();
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
          }
      });
    
  }

  goToQuickCheckOut() {
    this._Router.navigate(['/profile/payment-method'], { queryParams: { partyId: this.partyId } });
  }

  cancelSubmit(): void {
    this.creditCardForm.reset();
    this._Router.navigate(['/profile/payment-method'], { queryParams: { partyId: this.partyId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}