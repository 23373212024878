<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card mt-2" *ngIf="show">
                <div class="card-title">
                    <div class="float-start">
                        <h6 class="text-left mt-3 mx-3"><h6>Messages</h6></h6>
                    </div>
                    <div class="float-end mt-3 me-4">
                        <span class="span-link" (click)="viewRecieved()">View Sent

                        </span>
                    </div>
                </div>
                <div class="p-2 pt-0">
                    <div class="form-group px-2 table-scroll" >
                        <p-table [value]="messageListFirstList" [paginator]="true" [rows]="rows"
                            scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100]"
                            [totalRecords]="total" [scrollable]="true" [style]="{'width':'100%','min-width':'600px'}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Subject</th>
                                    <th>Sent Date</th>
                                    <th>Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product>
                                <tr>
                                    <td>{{product.dataNode.partyIdFrom}}</td>
                                    <td>{{product.dataNode.partyIdTo}}</td>
                                    <td>{{product.dataNode.subject}}</td>
                                    <td>{{product.dataNode.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                    <td> <span class="span-link"  
                                        (click)="summaryPage(product.dataNode.communicationEventId)">Read</span></td>
                                   
                                </tr>
                            </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td>
                      <span class="text-center">
                          <h6 class="ml-2">No Record Found
                          </h6>
                      </span>
                  </td>
              </tr>
          </ng-template>
          </p-table>
                        
                    </div>
                   
                </div>
            </div>
            <div class="card mt-2" *ngIf="!show" >
                <div class="card-title">
                    <div class="float-start">
                        <h6 class="text-left mt-3 mx-3"><h6>Messages</h6></h6>
                    </div>
                    <div class="float-end mt-3 me-4">
                        <span class="span-link" (click)="viewRead()">View Recieved Only

                        </span>
                    </div>
                </div>
                <div class="p-2 pt-0">
                  
                    <div class="form-group px-2 table-scroll" >
                        <p-table [value]="messageListSecondList" [paginator]="true" [rows]="rows"
                            scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100]"
                            [totalRecords]="total" [scrollable]="true" [style]="{'width':'100%','min-width':'600px'}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Subject</th>
                                    <th>Sent Date</th>
                                    <th>Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product>
                                <tr>
                                    <td>{{product.dataNode.partyIdFrom}}</td>
                                    <td>{{product.dataNode.partyIdTo}}</td>
                                    <td>{{product.dataNode.subject}}</td>
                                    <td>{{product.dataNode.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                    <td> <span class="span-link" 
                                        (click)="summaryPage(product.dataNode.communicationEventId)">
                                        Read</span> <br> <span class="span-link" (click)="sendMailPage(product.dataNode.communicationEventId)">Reply</span></td>
                                   
                                </tr>
                            </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td>
                      <span class="text-center">
                          <h6 class="ml-2">No Record Found
                          </h6>
                      </span>
                  </td>
              </tr>
          </ng-template>
          </p-table>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
