<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 px-4">
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title mb-0">
                    <div class="float-start">
                        <h6 class="text-left my-3 mx-4">
                            Request <span *ngIf="this.custRequestId"> {{this.custRequestId}}</span> Informtion</h6>
                    </div>

                </div>
                <div class="card-body ps-4">

                    <div class="">
                        <div class="d-flex row border-bottom py-2 ">
                            <div class="col-lg-2">
                                <label for="exampletext">
                                    <h6 class="me-4">Type:</h6>
                                </label>
                            </div>
                            <div class="col-lg-10" *ngIf="custRequestIdList">
                                <p>{{this.custRequestIdList.custRequestTypeId}} 
                                   
                                </p>
                            </div>
                        </div>

                        <div class="d-flex row border-bottom py-2 pt-4">
                            <div class="col-lg-2">
                                <label for="exampletext">
                                    <h6 class="me-4">Status:</h6>
                                </label>
                            </div>
                            <div class="col-lg-10" *ngIf="custRequestIdList">
                                <p>{{this.custRequestIdList.statusId}} 
                                </p>
                            </div>
                        </div>
                        <div class="d-flex row border-bottom  py-2 pt-4">
                            <div class="col-lg-2">
                                <label for="exampletext">
                                    <h6 class="me-4">Party Id:</h6>
                                </label>
                            </div>
                            <div class="col-lg-10" >
                                <p>{{this.partyId}} 
                                </p>
                            </div>
                        </div>
                        <div class="d-flex row border-bottom  py-2 pt-4">
                            <div class="col-lg-2">
                                <label for="exampletext">
                                    <h6 class="me-4">Name:</h6>
                                </label>
                            </div>
                            <div class="col-lg-10" *ngIf="custRequestIdList">
                                <p>{{this.custRequestIdList.custRequestName}} 
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex row border-bottom  py-2 pt-4">
                                <div class="col-lg-2">
                                    <label for="exampletext">
                                        <h6 class="me-4">Description:</h6>
                                    </label>
                                </div>
                                <div class="col-lg-10" *ngIf="custRequestIdList">
                                    <p>{{this.custRequestIdList.description}} 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex row border-bottom  py-2 pt-4">
                                <div class="col-lg-2">
                                    <label for="exampletext">
                                        <h6 class="me-4">Currency:</h6>
                                    </label>
                                </div>
                                <div class="col-lg-10" *ngIf="custRequestIdList">
                                    <p>{{this.custRequestIdList.currencyUomId}} 
                                    </p>
                                </div>
                            </div>
                        </div> <div>
                            <div class="d-flex row border-bottom  py-2 pt-4">
                                <div class="col-lg-2">
                                    <label for="exampletext">
                                        <h6 class="me-4">Internal Comment:</h6>
                                    </label>
                                </div>
                                <div class="col-lg-10" *ngIf="custRequestIdList">
                                    <p>{{this.custRequestIdList.internalComment}} 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex row  py-2 pt-4">
                                <div class="col-lg-2">
                                    <label for="exampletext">
                                        <h6 class="me-4">Reason</h6>
                                    </label>
                                </div>
                                <div class="col-lg-10" *ngIf="custRequestIdList">
                                    <p>{{this.custRequestIdList.reason}} 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <div class="float-start">
                        <h6 class="text-left my-3 mx-4"><strong>Request History</strong></h6>
                    </div>
                </div>
                <div class="p-2">
                  <div class="form-group p-2 table-scroll">
                    <p-table [value]="custRequestIdTable" [paginator]="true" [rows]="rows"
                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100]"
                        [totalRecords]="total" [scrollable]="true" [style]="{'width':'100%','min-width':'600px'}">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Item</th>
                                <th>Product </th>
                                <th>Quantity</th>
                                <th>Amount </th>
                                <th>Maximum Amount</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-product>
                            <tr>
                              <td>{{product.custRequestItemSeqId}}</td>
                              <td>{{product.productId}}</td>
                              <td>{{product.quantity}}</td>
                              <td>{{product.description}}</td>
                              <td></td> 
                              <td>{{product.maximumAmount }}</td> 
                            </tr>
                        </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td>
                      <span class="text-center">
                          <h6 class="ml-2">No Record Found
                          </h6>
                      </span>
                  </td>
              </tr>
          </ng-template>
          </p-table>
                    
                </div>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title mb-0">
                    <div class="float-start">
                        <h6 class="text-left my-3 mx-4">
                            Date </h6>
                    </div>

                </div>
                <div class="card-body ps-4">

                    <div class="">
                        <div>
                            <div class="d-flex row  border-bottom  py-2 pt-4">
                                <div class="col-lg-2">
                                    <label for="exampletext">
                                        <h6 class="me-4">Request Date</h6>
                                    </label>
                                </div>
                                <div class="col-lg-10" *ngIf="custRequestIdList">
                                    <p>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex row border-bottom py-2 pt-4 ">
                            <div class="col-lg-2">
                                <label for="exampletext">
                                    <h6 class="me-4">Created Date:</h6>
                                </label>
                            </div>
                            <div class="col-lg-10" *ngIf="custRequestIdList">
                                <p>{{this.custRequestIdList.createdStamp | date:"yyyy-MM-dd hh:mm:ss"}} 
                                   
                                </p>
                            </div>
                        </div>

                       
                        
                        <div>
                            <div class="d-flex row  py-2 pt-4">
                                <div class="col-lg-2">
                                    <label for="exampletext">
                                        <h6 class="me-4">Last Modified Date</h6>
                                    </label>
                                </div>
                                <div class="col-lg-10" *ngIf="custRequestIdList">
                                    <p>{{this.custRequestIdList.lastUpdatedStamp | date:"yyyy-MM-dd hh:mm:ss"}} 
                                    </p>
                                </div>
                            </div>
                        </div>
                       
                                         
                    </div>
                </div>
            
               
            </div>
        </div>

    </div>
</div>