<div class="col-lg-12">
    <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
            <p class="text-left my-3 mx-2">Catalog</p>
        </div>
        <div class="p-2">
            <form [formGroup]="CatalogForm">
                <div class="form-group p-1">
                  <label class="mb-2" for="exampleInputEmail1">Choose Catalog</label>
                  <p-dropdown [options]="catalogArray" placeholder="Select Catalog" formControlName="catalogId"
                    (onChange)="getCategoryByCatalog($event)">
                  </p-dropdown>
                </div>
              </form>
        </div>
    </div>
    <div class="card mx-auto mt-2" style="width:100%">
        <div class="card-title">
            <p class="text-left my-3 mx-2">Search Catalog</p>
        </div>
        <div class="p-2">
            <form [formGroup]="findForm">
                <div class="form-group p-1">
                    <label class="mb-2" for="exampleInputEmail1">Search Catalog</label>
                    <input type="email" class="form-control mb-2" formControlName="findCatalog" id="username" aria-describedby="emailHelp"
                        placeholder="">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="OR" formControlName="radioAll" name="searchCatalogueRadio"
                            id="searchCatalogueRadio" checked>
                        <label class="form-check-label" for="searchCatalogueRadio">Any</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" value="AND" formControlName="radioAll" type="radio" name="searchCatalogueRadio"
                            id="searchCatalogueRadio">
                        <label class="form-check-label" for="searchCatalogueRadio">All</label>
                    </div>
                </div>
                <div class="form-group p-1">
                    <input class="btn btn-outline-secondary" type="submit" value="Find" (click)="goToFindCatalog()">
                </div>
                <div class="form-group p-1">
                    <input class="btn btn-outline-secondary" type="submit" value="Advacned Search"
                        (click)="goToAdvancedSearch()">
                </div>
            </form>
        </div>
    </div>
    <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
            <p class="text-left my-3 mx-2">Categories</p>
        </div>
        <div class="p-2">
            <form>
                <div class="form-group p-1">
                    <p-tree [value]="category" selectionMode="single"></p-tree>
                </div>
            </form>
        </div>
    </div>
    <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
            <p class="text-left my-3 mx-2">Quick Recorder</p>
        </div>
        <div class="p-2">
            <span class="span-link">Tiny Gizmo</span>
            <ul class="list-unstyled">
                <li>GZ-1000 <span>$12.00</span></li>
                <li><span class="span-link">Add To Cart</span></li>
            </ul>
        </div>
    </div>
    <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
            <p class="text-left my-3 mx-2">Sign Up For Contact List</p>
        </div>
        <div class="p-2">
            <form>
                <div class="form-group p-1">
                    <p>Sign Up For Contact List LogIn</p>
                    <p><span class="span-link" (click)="goToLogin()">Login </span>THE ADMINISTRATOR</p>
                </div>
            </form>
        </div>
    </div>
    <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
            <p class="text-left my-3 mx-2">Mini Poll</p>
        </div>
        <div class="p-2">
            <form>
                <div class="form-group p-1">
                    <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
                    <select class="form-select form-select my-2" aria-label=".form-select example">
                        <option selected>Right Side</option>
                        <option value="1">Right Side</option>
                        <option value="2">Left Side/option>
                    </select>
                    <p>*</p>
                    <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
                    <select class="form-select form-select my-2" aria-label=".form-select example">
                        <option selected>Right Side</option>
                        <option value="1">Right Side</option>
                        <option value="2">Left Side</option>
                    </select>
                    <p>*</p>
                    <input class="btn btn-outline-secondary" type="submit" value="Vote!">
                </div>
            </form>
        </div>
    </div>
   <!--  <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
            <p class="text-left my-3 mx-2">Browse Forums</p>
        </div>
        <div class="p-2 pb-4">
            <form>
                <span class="span-link">Ask the Experts</span><br>
                <span class="span-link">Ask the Experts</span><br>
                <span class="span-link">Gizmos</span><br>
                <span class="span-link">Gizmos</span><br>
                <span class="span-link">Widgets</span><br>
                <span class="span-link">Widgets</span><br>
            </form>
        </div>
    </div>
    <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
            <p class="text-left my-3 mx-2">Browse Content</p>
        </div>
        <div class="p-2 pb-4">
            <form>
                <span class="span-link">Widgets</span><br>
                <span class="span-link">Store Policies 2</span><br>
                <span class="span-link">Gizmos</span><br>
                <span class="span-link">Cookie Policy</span><br>
                <span class="span-link">Policies</span><br>
                <span class="span-link">Store Policies</span><br>
                <span class="span-link">Store Policies 2</span><br>
                <span class="span-link">Widgets</span><br>
                <span class="span-link">Policies</span><br>
                <span class="span-link">Gizmos</span><br>
                <span class="span-link">Cookie Policy</span><br>
                <span class="span-link">Store Policies</span><br>
            </form>
        </div>
    </div>
    <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
            <p class="text-left my-3 mx-2">Browse Blogs</p>
        </div>
        <div class="p-2 pb-4">
            <form>
                <span class="span-link">Big AI</span><br>
                <span class="span-link">Big AI</span><br>
                <span class="span-link">Mad Max</span><br>
                <span class="span-link">Mad Max</span><br>
                <span class="span-link">xczx</span><br>
            </form>
        </div>
    </div> -->
</div>