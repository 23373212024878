import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';
import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SideBarService {

  constructor(private http: HttpClient,private _ApiService: ApiService,) { }

  getCatalogList(): Observable<any> {
    return this.http.get(URLS.getCatalogList)
  }

  getLocale(): Observable<any> {
    return this.http.get(URLS.getLocale)
  }
  postLocale(id: any) {
    let getLocale = URLS.getLocale + "?newLocale=" + id;
    return this.http.post(getLocale,'');
  }
  
  clearCompare() {
    let clearCompare = URLS.clearCompare;
    return this.http.post(clearCompare,'');
  }
  
  keywordSearchCatalog(FormData: any,data:any) {
    let keywordSearchCatalog = URLS.keywordSearchCatalog+ "?viewIndex=" + data.pageNo + "&viewSize=" + data.pageSize;
    return this.http.post(keywordSearchCatalog,FormData);
  }
  
  getquote(FormData: any,data:any) {
    let getquote = URLS.getquote+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getquote,FormData);
  }
  
  checkoutOptions(id:any,req:any) {
    let checkoutOptions = URLS.checkoutOptions+ "?checkoutpage=" + id;
    return this.http.post(checkoutOptions,req);
  }
  
  processOrder(id:any) {
    let processOrder=URLS.processOrder+"?checkoutpage="+id;
    return this.http.post(processOrder,'');
  }
  summaryPageMessage(id:any): Observable<any> {
    let summaryPageMessage = URLS.summaryPageMessage + "?communicationEventId=" + id;
    return this.http.get(summaryPageMessage);
  }
   
  messageListSecond(id:any): Observable<any> {
    let messageListSecond = URLS.messageListSecond + "?partyIdFrom=" + id;
    return this.http.get(messageListSecond);
  }
   
  messageListFirst(id:any): Observable<any> {
    let messageListFirst = URLS.messageListFirst + "?partyIdTo=" + id;
    return this.http.get(messageListFirst);
  } 
  getCategoryByCatalog(id:any): Observable<any> {
    let getCategoryByCatalog = URLS.getCategoryByCatalog + "?CURRENT_CATALOG_ID=" + id;
    return this.http.get(getCategoryByCatalog);
  }
 getCompareProducts(id:any): Observable<any> {
    let getCompareProducts = URLS.getCompareProducts+ "?CURRENT_CATALOG_ID=" + id;
    return this.http.get(getCompareProducts);
  }
  
  getquickCheckout(): Observable<any> {
    let getquickCheckout = URLS.getquickCheckout;
    return this.http.get(getquickCheckout);
  }

  
  
  addToComparePost(id: any) {
    let addToComparePost = URLS.addToComparePost + "?productId=" + id;
    return this.http.post(addToComparePost,'');
  } 
  getProductMain(id:any): Observable<any> {
    let getProductMain = URLS.getProductMain + "?CURRENT_CATALOG_ID=" + id ;
    return this.http.get(getProductMain);
   
  }
  
  
  getShoppingList(): Observable<any> {
    let getShoppingList = URLS.getShoppingList;
    return this.http.get(getShoppingList);
  }
  postShoppingList(): Observable<any> {
    let getShoppingList = URLS.getShoppingList;
    return this.http.post(getShoppingList,'');
  }
  
  verifyConfig(form:any): Observable<any> {
    let verifyConfig = URLS.verifyConfig;
    return this.http.post(verifyConfig,form);
  }
  
  getshowCart(): Observable<any> {
    let getshowCart = URLS.getshowCart;
    return this.http.get(getshowCart);
  }
  
  configDetailsEvent(form:any,id:any): Observable<any> {
    let configDetailsEvent = URLS.configDetailsEvent+ "?product_id=" + id;
    return this.http.post(configDetailsEvent,form);
  }
  
  advSearchDataDropdown(id:any): Observable<any> {
    let advSearchDataDropdown = URLS.advSearchDataDropdown+ "?CURRENT_CATALOG_ID=" + id;
    return this.http.get(advSearchDataDropdown);
  }
  
  getConfigureList(id:any): Observable<any> {
    let getConfigureList = URLS.getConfigureList+ "?productId=" + id;
    return this.http.get(getConfigureList);
  }
  contactExpire(id:any): Observable<any> {
    let contactExpire = URLS.contactExpire+ "?contactMechId=" + id;
    return this.http.get(contactExpire);
  }
  
  
  getMainFeaturedProduct(id:any,data:any): Observable<any> {
    let getMainFeaturedProduct = URLS.getMainFeaturedProduct + "?CURRENT_CATALOG_ID=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getMainFeaturedProduct);
  }
  
  addProductTags(id:any,tag:any): Observable<any> {
    let addProductTags = URLS.addProductTags + "?productId=" + id + "&productTags=" + tag;
    return this.http.post(addProductTags,'');
  }
  paymentDefault(defaultPayMeth:any,productStoreId:any): Observable<any> {
    let paymentDefault = URLS.paymentDefault + "?defaultPayMeth=" + defaultPayMeth + "&productStoreId=" + productStoreId;
    return this.http.get(paymentDefault);
  }
  
  getProductTags(): Observable<any> {
    let addProductTags = URLS.addProductTags ;
    return this.http.get(addProductTags);
  }
/*   getCompareProducts(id: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ecommerce/main/compareProducts?CURRENT_CATALOG_ID=${id}`);
}
addToComparePost(id: any): Promise<any> {
  return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ecommerce/main/addToCompare?productId=${id}`, {});
} */
getProductMainData(id: any): Promise<any> {
  return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ecommerce/main?CURRENT_CATALOG_ID=${id}`);
}
removeAddToCompare(id: any): Promise<any> {
  return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ecommerce/main/removeCompare?productId=${id}`, {});
}
getPartyContentList(id:any): Observable<any> {
  const getPartyContent = URLS.getPartyContentLists.replace(":partyId", id);
  return this.http.get(getPartyContent);
}
downloadView(id:any): Observable<any> {
  const getDownload = URLS.downloadPartyContent + "?contentId=" + id;
  return this.http.get(getDownload, { responseType: "blob" });
}
getParty(): Observable<any> {
  const getPartyContent = URLS.partyContentDropdown;
  return this.http.get(getPartyContent);
}

getAllPromotion(): Observable<any> {
  const getAllPromotion = URLS.getAllPromotion;
  return this.http.get(getAllPromotion);
}

clearOrder(): Observable<any> {
  const clearOrder = URLS.clearOrder;
  return this.http.post(clearOrder, '');
}
findOrder(data:any): Observable<any> {
  const orderList = URLS.findOrdersList;
  return this.http.post(orderList, data);
}
updateCustomerProfile(data:any): Observable<any> {
  const updateCustomerProfile = URLS.updateCustomerProfile;
  return this.http.put(updateCustomerProfile, data);
}
addToCartConfig(data:any): Observable<any> {
  const addToCartConfig = URLS.addToCartConfig;
  return this.http.post(addToCartConfig, data);
}

customerProfile(): Observable<any> {
  const customerProfile = URLS.customerProfile;
  return this.http.get(customerProfile);
}
deleteContent(contentId:any,fromDate:any,partyContentTypeId:any,partyId:any){
  let deleteContentType = URLS.deletePartyContent + "?contentId=" + contentId + "&fromDate=" + fromDate + "&partyContentTypeId=" + partyContentTypeId + "&partyId=" + partyId;
  return this.http.delete(deleteContentType);
}
attachPartyContent(formData:any, partyContentTypeId:any, partyId:any): Observable<any> {
  const getPartyContent =
    URLS.attachPartyContent +
    "?partyContentTypeId=" +
    partyContentTypeId +
    "&partyId=" +
    partyId;
  return this.http.post(getPartyContent, formData);
}
getquoteById(id:any) {
  let getquote = URLS.getquote+ "/" + id;
  return this.http.get(getquote);
}

addItemToCart(form:any,id:any): Observable<any> {
  let addItemToCart = URLS.addItemToCart+ "?productId=" + id;
  return this.http.post(addItemToCart,form);
}


addListToCart(form:any,id:any): Observable<any> {
  let addListToCart = URLS.addListToCart+ "?shoppingListId=" + id;
  return this.http.post(addListToCart,form);
}

removeProductCart(form:any): Observable<any> {
  let removeProductCart = URLS.removeProductCart;
  return this.http.post(removeProductCart,form);
}


quickCheckout(): Observable<any> {
  const quickCheckout = URLS.quickCheckout;
  return this.http.get(quickCheckout);
}

addtocartbulk(form:any): Observable<any> {
  const addtocartbulk = URLS.addtocartbulk;
  return this.http.post(addtocartbulk,form);
}
orderSummary(id:any): Observable<any> {
  let orderSummary = URLS.orderSummary+"/"+id;
  return this.http.get(orderSummary);
}

getPurchaseHistory(id:any): Observable<any> {
  let getPurchaseHistory = URLS.getPurchaseHistory+"?filter="+id;
  return this.http.get(getPurchaseHistory);
}

getReturnItem(id:any): Observable<any> {
  let getReturnItem = URLS.getReturnItem+"?filter="+id;
  return this.http.get(getReturnItem);
}




}