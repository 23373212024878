import { Component, OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MyAccountsService } from '../my-account/my-accounts.service';
import { SideBarService } from '../side-bar/side-bar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shopping-list',
  templateUrl: './shopping-list.component.html',
  styleUrls: ['./shopping-list.component.css']
})
export class ShoppingListComponent implements OnInit {
indexValue:any;
  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  listItems:any[]=[];
  shoppingListIds: any;
  shoppingListIdsArray: any[]=[];
  tagCloudList: any;
  shoppingListId: any;
  shoppingListIdArray: any[]=[];
  editShoppingListForm: FormGroup;
  editShoppingListData: any;
  updateShoppingListForm: FormGroup;
  shoppingListTypeIdDataArray: any[]=[];
  ShoppingListId: any;
  partyId:any;
  addItemShoppingListForm: FormGroup;
  ProductId: any;
  ProductIdArray: any[]=[];
  showCart: any;
  specialOffers: any;
  summary: any;
  alldata: any;
  updateListItemForm: FormGroup;
  show: boolean | undefined;
  updateShoppingListItemPat: any;
  compareProductList: any;
  catalogId: string | null;
  allData: any;
  priceList: any;
  constructor(readonly router: Router, private sidebarService: SideBarService,
    private myAccountsService : MyAccountsService,
    private toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    ) { 

      this.editShoppingListForm = this._FormBuilder.group({
        shoppingList: ['', [Validators.required]],
       
      });
      this.updateShoppingListForm = this._FormBuilder.group({
        ListName: ['', [Validators.required]],
        Description: ['', [Validators.required]],
        listType: ['', [Validators.required]],
        public: ['', [Validators.required]],
        active: ['', [Validators.required]],
        parentList: ['', [Validators.required]],
       
      });
      this.addItemShoppingListForm = this._FormBuilder.group({
        productId: ['', [Validators.required]],
        quantity: ['', [Validators.required]],
       
      });

      this.updateListItemForm = this._FormBuilder.group({
        product: [''],
        quantity: [''],
        price: [''],
        total: ['']
       });

      this.catalogId = localStorage.getItem("catalog")
      
    }

  ngOnInit(): void {
    this.partyId = localStorage.getItem('partyId');

    this.ShoppingListId = localStorage.getItem('ShoppingListId');

    this.catalogId = localStorage.getItem("catalog");

    this.editShoppingListAll();
    this.getShoppingListTypeIdList();
    this.getProductTags();
    this.getProductId();
    
    this.getCompareProducts();
    // this.getShoppingListEcom();
  }
  getProductId() {
    this.spinner.show();
    this.myAccountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productName,
          value: value.productId
        })
      }
    })
   
  }

  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
      if(res.httpStatus == 200){
        this.showCart = res.data.productsList;
        this.specialOffers = res.data.specialOffers;
        this.summary = res.data.summary;
      }
      

    })
  }

  removeShoppingListItem(product:any) {
    Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.myAccountsService.removeShoppingListItem(product.ListShoppingList.shoppingListId,product.ListShoppingList.shoppingListItemSeqId).subscribe((res: any) => {
        if (res.success) {
          this.getShoppingListEcom();
          this.getshowCart();
        }
        else {
          this.toastr.error("Error");
        }
      })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })
}

clearCompare() {
     
  
  this.sidebarService.clearCompare().subscribe((res: any) => {
    if (res.success) {
      this.toastr.success(res.data.successMessage);
      this.getCompareProducts();

    }
  }, (err) => {
 
    this.toastr.error(err.message);

  });
 
}

updateShoppingListItem() {
    this.spinner.show();
    const form={
      "quantity": this.updateListItemForm.value.quantity,
      "quantityPurchased":'' ,
      "shoppingListId": this.updateShoppingListItemPat.ListShoppingList?.shoppingListId,
      "shoppingListItemSeqId":this.updateShoppingListItemPat.ListShoppingList?.shoppingListItemSeqId,
    }
    this.myAccountsService.updateShoppingListItem(form).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.getShoppingListEcom();
        this.getshowCart();
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  updateShoppingListItemPatch(product:any) {
    
      console.log(product);
    
    this.show=false;
    this.updateShoppingListItemPat=product;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.updateListItemForm;
        formValue.patchValue({
          quantity: product?.ListShoppingList?.quantity,
          product: product?.ListShoppingList?.productId,
          price: product?.shoppingListProduct[0]?.price,
          total:product?.total
     })
      }, 2000);
      this.spinner.hide();
  }
  

  getShoppingListEcom() {
    this.myAccountsService.getShoppingListEcom(this.ShoppingListId).subscribe((res: any) => {
     
      this.allData = res.data.products;
      this.priceList=res.data.total;
     })
  }
  

  getCompareProducts() {
    this.sidebarService.getCompareProducts(this.catalogId).subscribe((res: any) => {
      this.compareProductList = res.data.productList;

    })
  } 
  removeAddToCompare(id:any): void {

    this.sidebarService.removeAddToCompare(id)
      .then(data => {
        if (data.success) {
          this.toastr.success(data.data.successMessage);
        this.getCompareProducts();
        }
      });
  
  }
  
  addItemToShoppingList() {
    this.spinner.show();
    const form={
      "productId": this.addItemShoppingListForm.value.productId,
      "quantity":  this.addItemShoppingListForm.value.quantity,
      "shoppingListId":this.ShoppingListId
    }
    this.myAccountsService.addItemToShoppingList(form).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.getShoppingListEcom();
        this.getshowCart();
        this.addItemShoppingListForm.reset();
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  } 
  updateShoppingList() {
    this.spinner.show();
    const form={

      "description":this.editShoppingListForm.value.Description,
      "isActive":this.editShoppingListForm.value.active,
      "isPublic":this.editShoppingListForm.value.public,
      "listName": this.editShoppingListForm.value.ListName,
      "parentShoppingListId": this.editShoppingListForm.value.parentList,
      "partyId":this.partyId,
      "shoppingListId":this.ShoppingListId,
      "shoppingListTypeId": this.editShoppingListForm.value.listType,
    }
    this.myAccountsService.updateShoppingList(form).subscribe((res: any) => {
      
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  } 
  createCustRequestFromShoppingList() {
    this.spinner.show();
    this.myAccountsService.createCustRequestFromShoppingLists(this.ShoppingListId).subscribe((res: any) => {
      
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }  
  getShoppingListTypeIdList(): void {
    this.spinner.show();
    this.myAccountsService.getShoppingListTypeIdList().subscribe((res) => {
      let shoppingListTypeIdData = res.data;
      this.spinner.hide();
      for (const value of shoppingListTypeIdData) {
        this.shoppingListTypeIdDataArray.push({
          label: value.shoppingListTypeIdData.description,
          value: value.shoppingListTypeIdData.shoppingListTypeId,
        });
      }
    });
   
  }
  editShoppingList() {
    this.myAccountsService.editShoppingList(this.editShoppingListForm.value.shoppingList).subscribe((res: any) => {
      this.editShoppingListData = res.data[0].ListShoppingList;
      localStorage.setItem('ShoppingListId',(this.editShoppingListForm.value.shoppingList));
     
     setTimeout(() => {
      const form =  this.updateShoppingListForm;
      form.patchValue({
        ListName:  this.editShoppingListData.listName,
        Description:  this.editShoppingListData.description ,
        listType:  this.editShoppingListData.shoppingListTypeId ,
        public:  this.editShoppingListData.isPublic ,
        active:  this.editShoppingListData.isActive ,
        parentList:  this.editShoppingListData.parentShoppingListId ,


      })     }, 1000);
    

    })
  }
  editShoppingListAll() {
    this.spinner.show();
    this.myAccountsService.editShoppingListAll().subscribe((res: any) => {
      this.shoppingListId = res.data;
      this.spinner.hide();
      for (const value of this.shoppingListId) {
        this.shoppingListIdArray.push({
          label: value.ListShoppingList.listName,
          value: value.ListShoppingList.shoppingListId
        })
      }
    })

  }
  getProductTags() {
    this.sidebarService.getProductTags().subscribe((res: any) => {
      this.tagCloudList = res.data.tagCloudList;

    })
  }
  getShoppingList() {
    this.spinner.show();
    this.sidebarService.getShoppingList().subscribe((res: any) => {
      this.shoppingListIds = res.data.shoppingListIds;
      this.spinner.hide();
      for (const value of this.shoppingListIds) {
        this.shoppingListIdsArray.push({
          label: value.listName,
          value: value.shoppingListId
        })
      }
    })

  }
  
  onSubmit() {
   const form =
    {
      "productStoreId": ""
    }
    this.myAccountsService.createEmptyShoppingList(form).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success('Created Successfully.');
        this.getShoppingList();

      }
    }, (err) => {
   
      this.toastr.error(err.message);

    });
   
  }
  goToViewCart(){
    this.router.navigate(["/view-cart"]);
  }
  goToViewAllPromotions(){
    this.router.navigate(["/view-all-promotions"]);
  }
  goToShowPromotionDetails(){
    this.router.navigate(["/show-promotion-details"]);
  }

  goToQuickCheckOut(){
    this.router.navigate(["/quick-check-out"]);
  }
  goToOnePageCheckOut(){
    this.router.navigate(["/one-page-check-out"]);
  }

  goToCheckOut(){
    this.router.navigate(["/check-out-option1"]);
  }

}
