<div class="container-fluid p-0 pt-3">
  <div class="card" style="border: none!important;">
    <div class="card-title px-1 pt-1 pb-0" style="background-color: white!important;border:none!important">
      <div class="row">
        <div class="col-lg-3">
          <h6>Personal Information</h6>
        </div>
        <div class="col-lg-9 text-end">
          <span class="span-link" (click)="goToUpdatePersonalInformation()">Update</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row" *ngIf="this.getCustomerProfile">
        <div class="col-lg-6">
          <dl class="row">
            <p class="col-lg-2">Name</p>
            <p class="col-lg-10">{{this.getCustomerProfile.personalTitle}}.{{this.getCustomerProfile.firstName}} {{this.getCustomerProfile.lastName}}</p>
            <p class="col-lg-2">Nick Name</p>
            <p class="col-lg-10">{{this.getCustomerProfile.nickname}}</p>
            <p class="col-lg-2">Gender</p>
            <p class="col-lg-10">{{this.getCustomerProfile.gender}}</p>
            <p class="col-lg-2">Birth Date</p>
            <p class="col-lg-10">{{this.getCustomerProfile.birthDate|date:'yyyy-MM-dd hh:mm:ss'}}</p>
            <p class="col-lg-2">Height</p>
            <p class="col-lg-10">{{this.getCustomerProfile.height}}</p>
            <p class="col-lg-2">Weight</p>
            <p class="col-lg-10">{{this.getCustomerProfile.weight}}</p>
            <p class="col-lg-2">Marital Status</p>
            <p class="col-lg-10">{{this.getCustomerProfile.maritalStatusEnumId}}</p>
          </dl>
        </div>
        <div class="col-lg-6">
          <dl class="row">
            <p class="col-lg-3">Mothers Maiden Name</p>
            <p class="col-lg-9">{{this.getCustomerProfile.mothersMaidenName}}</p>
            <p class="col-lg-3">Passport Number</p>
            <p class="col-lg-9">{{this.getCustomerProfile.passportNumber}}</p>
            <p class="col-lg-3">Passport Expire Date</p>
            <p class="col-lg-9">{{this.getCustomerProfile.passportExpireDate|date:'yyyy-MM-dd hh:mm:ss'}}</p>
            <p class="col-lg-3">Years Work</p>
            <p class="col-lg-9">{{this.getCustomerProfile.totalYearsWorkExperience}}</p>
            <p class="col-lg-3">Comments</p>
            <p class="col-lg-9">{{this.getCustomerProfile.comments}}</p>
          </dl>
        </div>
      </div>
    </div>
  </div>
</div>