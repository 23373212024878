<div id="ecom-mainarea" class="row mb-2 mx-2 mt-2">
  <div id="div3" class="col-lg-12 order-lg-2 order-1">
    <ng-container *ngIf="show">
      <h2>Update EFT Account</h2>
    </ng-container>
    <ng-container *ngIf="!show">
      <h2>Add New EFT Account</h2>
    </ng-container>
    <form [formGroup]="eftAccountForm" name="editeftaccountform">
      <div class="form-group">
        <a class="btn btn-outline-secondary me-2" (click)="cancelSubmit()">Go Back</a>
        <ng-container *ngIf="show">
          <a class="btn btn-outline-secondary" (click)="update()">Update</a>
        </ng-container>
        <ng-container *ngIf="!show">
          <a class="btn btn-outline-secondary" (click)="submit()">Save</a>
        </ng-container>
      </div>
      <label class="required">Name on Account</label>
      <div class="row mb-2">
        <div class="col-sm-6">
          <input type="text" class="form-control" name="nameOnAccount" placeholder="Enter name on account" value="" formControlName="nameOnAccount">
        </div>
      </div>
      <label class="required">Company Name on Account</label>
      <div class="row mb-2">
        <div class="col-sm-6">
          <input type="text" class="form-control" name="companyNameOnAccount" placeholder="Enter company name on account" value="" formControlName="companyNameOnAccount"> 
        </div>
      </div>
      <label class="required">Bank Name<span style="color:red!important">*</span></label>
      <div class="row mb-2">
        <div class="col-sm-6">
          <input type="text" class="form-control" name="bankName" value="" placeholder="Enter bank name" formControlName="bankName">
        </div>
      </div>
      <label class="required">Routing Number<span style="color:red!important">*</span></label>
      <div class="row mb-2">
        <div class="col-sm-6">
          <input type="text" class="form-control" name="routingNumber" placeholder="Enter routing number" value="" formControlName="routingNumber">
        </div>
      </div>
      <label class="required">Account Type<span style="color:red!important">*</span></label>
      <div class="row mb-2">
        <div class="col-sm-6">
          <p-dropdown name="accountType" [options]="accountTypes" formControlName="accountType" placeholder="Enter account type">
          </p-dropdown>
        </div>
      </div>
      <label class="required">Account Number<span style="color:red!important">*</span></label>
      <div class="row mb-2">
        <div class="col-sm-6">
          <input type="text" class="form-control" name="accountNumber" value="" placeholder="Enter account number" formControlName="accountNumber">
        </div>
      </div>
      <label class="required">Description</label>
      <div class="row mb-2">
        <div class="col-sm-6">
          <input type="text" class="form-control" name="description" value="" placeholder="Enter description" formControlName="description">
        </div>
      </div>
      <label class="mb-2"><strong>Billing Address</strong></label>
      <div class="row mb-2">
        <div class="col-sm-12">
          <input type="radio" name="contactMechId" value="11660" formControlName="contactMechId">
          Shipping Destination Address
          General Correspondence Address
          <label><b>To:</b> David J. Warn</label>
          address<br>
          eed<br>
          CA
          ,&nbsp;
          AA
          12121
          <br>USA
          <div>(Updated:&nbsp;2021-05-19 05:07:47.726)</div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm-12">
          <input type="radio" name="contactMechId" value="11671">
          Billing (AP) Address

          address<br>

          CA
          ,&nbsp;
          AA
          11223
          <br>USA
          <div>(Updated:&nbsp;2021-05-19 07:42:11.944)</div>
        </div>
      </div>

    </form>
    <a class="btn btn-outline-secondary me-2"><span class="glyphicon glyphicon-circle-arrow mb-2-left"
        (click)="cancelSubmit()">Go Back</span></a>
        <ng-container *ngIf="show">
          <a class="btn btn-outline-secondary" (click)="update()">Update</a>
        </ng-container>
        <ng-container *ngIf="!show">
          <a class="btn btn-outline-secondary" (click)="submit()">Save</a>
        </ng-container>
  </div>

  <div id="div4" class="endcolumns">
  </div>
</div>