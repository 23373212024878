<div class="container-fluid p-0 pt-3">
    <div class="card" style="border: none!important;">
      <div class="card-title px-1 pt-1 pb-0" style="background-color: white!important;border:none!important">
        <div class="float-start">
          <h6>File Manager</h6>
        </div>
        <div class="float-end pe-3">
          <span class="span-link" (click)="goToUploadNewFile()">Upload New File</span>
        </div>
      </div>
      <div class="card-body pt-0 ps-1">
        <div class="row"></div>
        <div class="row">
          <div class="col-lg-12 table-scroll">
            <p-table [value]="contentList" [paginator]="true" [rows]="rows" scrollWidth="100%"
              [rowsPerPageOptions]="[10,25,50, 100]" [totalRecords]="total" [scrollable]="true"
              [style]="{'width':'100%','min-width':'600px'}">
              <ng-template pTemplate="header">
                <tr>
                  <th>Content Id</th>
                  <th>Content Name</th>
                  <th>Content Type</th>
                  <th>Party Content Type</th>
                  <th>Status</th>
                  <th>From Date</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-product>
                <tr>
                  <td >{{product.contentId}}
                   
                  </td>
                  <td >{{product.contentName}}</td>
                  <td >{{product.contentType}}</td>
               
                  <td >{{product.partyContentTypeId}}</td>

                  <td>{{product.status}}</td>
                 
                  <td >{{product.fromDate | date:'medium'}}</td>
                
                  <td >
                    <a class="btn btn-outline-secondary me-1" value="Download" (click)="export(product.contentId,product.contentName)">Download</a>
                  <a class="btn btn-outline-secondary" value="Delete"
                  (click)="onDelete(product.contentId,product.fromDate,product.partyContentTypeId,product.partyId)">Delete</a>
                 
                  

                  </td>

                </tr>
              </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td>
                        <span class="text-center">
                            <h6 class="ml-2">No Record Found
                            </h6>
                        </span>
                    </td>
                </tr>
            </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
