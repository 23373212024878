import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MyAccountsService } from '../my-account/my-accounts.service';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.css']
})
export class QuotesComponent implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  quotes:any[]=[];
 
  private finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  partyId: string | null;
  quotesList: any;
  ListQuotesList:any;
  constructor(readonly router: Router, private sidebarService: SideBarService,
    private toastr: ToastrService,
    private myAccountsService : MyAccountsService,
    readonly spinner: NgxSpinnerService,
    ) {
      this.partyId = localStorage.getItem('partyId');
}

  ngOnInit(): void {
    this.getquote();
    this.ListQuotes();
  }
  ListQuotes() {
    this.spinner.show;
    this.myAccountsService.ListQuotes(this.partyId).subscribe((res: any) => {
      this.ListQuotesList = res.data;
    })
    this.spinner.hide;

  }
  getquote() {
    let req={
      "currencyId": "",
      "partyId": this.partyId ,
      "productStoreId": "",
      "quoteId": "",
      "quoteIdSearchType": "Contains",
      "quoteIssueDateFrom": "",
      "quoteIssueDateTo": "",
      "quoteName": "",
      "quoteNameSearchType": "Contains",
      "quoteTypeId": "",
      "salesChannelId": "",
      "statusId": ""
    }
    this.sidebarService.getquote(req,this.finSize).subscribe((res: any) => {
     this.quotesList = res.data;

    })
  }
  
  goToShowPromotionDetails(){
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewAllPromotions(){
    this.router.navigate(["/view-all-promotions"]);
  }
  goToViewQuotes(id:any){
    this.router.navigate(['/view-quotes'],{queryParams:{quoteId:id}});
  }
}
