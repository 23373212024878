<div class="card mx-auto mt-2" style="width: 100%;">
    <div class="card-title">
      <p class="text-left my-3 mx-2">Date</p>
    </div>
    <div class="p-2">
      <form [formGroup]="dateFilterForm">
        <div class="form-group p-1">
            <label class="" style="margin-right: 3% !important;">Enter Date</label>
          <input type="date" placeholder="Enter Date" formControlName="byDate">
          <input type="button" style="margin-left: 3% !important;" name="processButton" (click)="getPurchaseHistory();" class="btn btn-primary" value="Submit Order">

        </div>
      </form>
    </div>
  </div>

<div class="container-fluid p-0 pt-3">
    <div class="card" style="border: none!important;">
      <div class="card-title px-1 pt-1 pb-0" style="background-color: white!important;border:none!important">
        <h6>List</h6>
      </div>
      <div class="card-body pt-0 ps-1">
        <div class="row"></div>
        <div class="row">
          <div class="col-lg-12 table-scroll">
            <p-table  [paginator]="true" [rows]="rows" scrollWidth="100%"
              [rowsPerPageOptions]="[10,25,50, 100]" [totalRecords]="total" [scrollable]="true"
              [style]="{'width':'100%','min-width':'600px'}">
              <ng-template pTemplate="header">
                <tr>
                  <th>Purchase No.</th>
                  <th>Date</th>
                  <th>Amount</th>
                  
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-product>
                <tr>
                  <td>{{product.product}}</td>
                </tr>
                <tr>
                  <td></td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td>
                        <span class="text-center">
                            <h6 class="ml-2">No Record Found
                            </h6>
                        </span>
                      </td>
                </tr>
            </ng-template>
            </p-table>
            
          </div>
        </div>
      </div>
    </div>
  </div>