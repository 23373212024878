import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-upload-new-file',
  templateUrl: './upload-new-file.component.html',
  styleUrls: ['./upload-new-file.component.css']
})
export class UploadNewFileComponent implements OnInit {
  purposeArray:any[]=[]
  partyContentData: any;
  partyContentArray: any[]=[];
  partyId: string | null;
  partyContentTypeId: any;
  fileData: any;
  Editform:FormGroup

  
  constructor(readonly router: Router, private sidebarService: SideBarService,
    private toastr: ToastrService, private _ActivatedRoute: ActivatedRoute) { 
      this.partyId = localStorage.getItem('partyId');
      this.Editform = new FormGroup({
        file: new FormControl('', [
          Validators.required
        ]),
        partyContentTypeId: new FormControl('')
      });
    }
  ngOnInit(): void {
    this.getPartyContent();
  }
  onSubmit() {
   
    let formData = new FormData();
    formData.append('file', this.fileData);
    this.partyContentTypeId = this.Editform.value.partyContentTypeId;
  
    this.sidebarService.attachPartyContent(formData, this.partyContentTypeId, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.router.navigate(["/profile/file-manager"]);

      }
    }, (err) => {
   
      this.toastr.error(err.message);

    });
   
  }
  getPartyContent() {
   
    this.sidebarService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
     
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        });
      }
    });
   
  }
  onpartyContent(event:any) {
    this.partyContentTypeId = event;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  
  }
  goToProfile(){
    this.router.navigate(["/profile/file-manager"]);
  }
  upload(){}
}
