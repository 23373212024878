import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.css']
})
export class PersonalInformationComponent implements OnInit {
  
  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  personal:any[]=[];
  getCustomerProfile: any;
  constructor(readonly router:Router,private sidebarService:SideBarService,
    private _ActivatedRoute: ActivatedRoute,private form: FormBuilder,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.customerProfile();
  }
  customerProfile() {
    this.sidebarService.customerProfile().subscribe((res: any) => {
      this.getCustomerProfile = res.data.personalInfo.person;

    })
  }
  goToUpdatePersonalInformation(){
    this.router.navigate(['/update-personal-information']);
  }
}
