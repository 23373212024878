<div class="container-fluid">
    <div class="row">
        <div class="col-lg-3 order-lg-2 order-2 px-4">
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Choose Catalog</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <p-dropdown [options]="catalogArray" placeholder="Select Catalog" (onChange)="getCategoryByCatalog($event)">
                          </p-dropdown>
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Search Catalog</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <label class="mb-2" for="exampleInputEmail1">Catalog</label>
                          <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp" placeholder="">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="searchCatalogueRadio" id="searchCatalogueRadio" checked>
                            <label class="form-check-label" for="searchCatalogueRadio">Any</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="searchCatalogueRadio" id="searchCatalogueRadio">
                            <label class="form-check-label" for="searchCatalogueRadio">All</label>
                          </div>
                        </div>
                        <div class="form-group p-1">
                            <input class="btn btn-outline-secondary" type="submit" value="find">
                        </div>
                        <div class="form-group p-1">
                            <input class="btn btn-outline-secondary" type="submit" value="AdvancedSearch" (click)="goToAdvancedSearch()">
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
              <div class="card-title">
                <p class="text-left my-3 mx-2">Categories</p>
              </div>
              <div class="p-2">
                <form>
                  <div class="form-group p-1">
                    <p-tree [value]="category" selectionMode="single" ></p-tree>
                  </div>
                </form>
              </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Quick Recorder</p>
                </div>
                <div class="p-2">
                    <span class="span-link">Tiny Gizmo</span>
                    <ul class="list-unstyled">
                        <li>GZ-1000 <span>$12.00</span></li>
                        <li><span class="span-link">Add To Cart</span></li>
                    </ul>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Sign Up For Contact List</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <p>Sign Up For Contact List LogIn</p>
                          <p><span class="span-link">Login </span>THE ADMINISTRATOR</p>
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Mini Poll</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
                          <select class="form-select form-select my-2" aria-label=".form-select example">
                            <option selected>Right Side</option>
                            <option value="1">Right Side</option>
                            <option value="2">Left Side/option>
                          </select>
                          <p>*</p>
                          <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
                          <select class="form-select form-select my-2" aria-label=".form-select example">
                            <option selected>Right Side</option>
                            <option value="1">Right Side</option>
                            <option value="2">Left Side</option>
                          </select>
                          <p>*</p>
                          <input class="btn btn-outline-secondary" type="submit" value="Vote!">
                        </div>
                    </form>
                </div>
            </div>
           <!--  <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Forums</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Ask the Experts</span><br>
                        <span class="span-link">Ask the Experts</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Widgets</span><br>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Content</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Store Policies 2</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Cookie Policy</span><br>
                        <span class="span-link">Policies</span><br>
                        <span class="span-link">Store Policies</span><br>
                        <span class="span-link">Store Policies 2</span><br>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Policies</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Cookie Policy</span><br>
                        <span class="span-link">Store Policies</span><br>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Blogs</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Big AI</span><br>
                        <span class="span-link">Big AI</span><br>
                        <span class="span-link">Mad Max</span><br>
                        <span class="span-link">Mad Max</span><br>
                        <span class="span-link">xczx</span><br>
                      </form>
                </div>
            </div> -->
        </div>
        <div class="col-lg-6 order-lg-2 order-1 px-4">
            <div id="product-detail" class="card mx-auto mt-2"> 
                <div class="row"> 
                 <div class="col-auto mt-3 ms-2"> <a class="btn btn-outline-secondary">Previous</a> 
                 </div> 
                 <div class="col-auto mt-3"> <a class="btn btn-outline-secondary">Next</a> 
                 </div> 
                </div> 
                <hr> 
                <div class="card-body"> 
                 <div class="row"> 
                  <div class="col-lg-4"> 
                   <div id="detailImageBox"> 
                    <img id="detailImage" src="" name="mainImage" alt=""> 
                   </div> 
                   <div id="additionalImageBox"> 
                   </div> 
                  </div> 
                  <div class="col-lg-8"> 
                   <h2>Financial Account Activation</h2> 
                   <p>Balance Account Activation</p> 
                   <p>FA-001</p> 
                   <div> <strong> Your Price: From <span class="regularPrice"> $1.00 </span> </strong> 
                   </div> 
                   <div>
                    &nbsp;
                   </div> 
                   <div> <span class="span-link">Tell-A-Friend</span> 
                   </div> 
                   <div id="addItemForm"> 
                    <form method="post" action="" name="addform" style="margin: 0;"> 
                     <div class="form-group my-2"> <label for="order_item_attr_comment"> Comment </label> 
                      <input type="text" class="form-control" name="order_item_attr_comment" id="order_item_attr_comment"> 
                     </div> 
                     <div class="form-group my-2"> 
                      <div> <select name="FTAMOUNT" class="form-control">  <option value="" selected="">Amount</option><option value="FA-001-30">$30.00</option><option value="FA-001-O">Other Amount</option></select> 
                      </div> <span id="product_uom"></span> 
                      <input type="hidden" name="product_id" value="FA-001"> 
                      <input type="hidden" name="add_product_id" value="NULL"> 
                      <div> <strong><span id="product_id_display"> </span></strong> <strong> 
                        <div id="variant_price_display"></div> </strong> 
                      </div> 
                     </div> 
                     <div id="add_amount" class="hidden form-group my-2"> <label>Amount:</label> 
                      <input type="text" class="form-control" name="add_amount" value=""> 
                     </div> 
                     <div class="form-group my-2"> <label>Quantity:</label> 
                      <div class="input-group"> 
                       <input name="quantity" class="form-control" id="quantity" value="1" size="4" maxlength="4" type="text" disabled="">
                       <span class="input-group-btn"> <span class="span-link" id="addToCart" name="addToCart" class="btn btn-outline-secondary">Add to Cart </span></span> 
                      </div> 
                     </div> 
                    </form> 
                   </div> 
                   <div> 
                    <br> You Must <span class="span-link">Login</span> To Add Selected Items To Shopping List.&nbsp; 
                   </div>  
                   <p>&nbsp;</p> 
                   <ul class="list-inline"> 
                    <li class="list-inline-item"> <span class="span-link">$30.00</span> <a> <img src="" class="cssImgSmall" alt=""> </a> </li> 
                    <li class="list-inline-item"> <span class="span-link">Other Amount</span> <a> <img src="" class="cssImgSmall" alt=""> </a> </li> 
                   </ul> 
                   <div id="long-description"> 
                    <div></div> 
                    <div></div> 
                   </div> 
                  </div> 
                 </div> 
                 <hr> 
                 <div id="reviews"> 
                  <h4>Customer Reviews :</h4> 
                  <p>This product hasn't been reviewed yet. <span class="span-link">Be The First To Review This Product!</span> </p> 
                 </div> 
                 <div id="associated-products"> 
                 </div> 
                 <hr> 
                 <div class="product-tags"> 
                  <h4>Product Tags</h4> 
                  <div class="form-group"> <label>Add your tags:</label> 
                   <form method="post" action="" name="addProductTags"> 
                    <input type="hidden" name="productId" value="FA-001"> 
                    <div class="input-group"> 
                     <input class="inputProductTags form-control" type="text" value="" name="productTags" id="productTags" size="40">
                     <span class="input-group-btn"><input class="buttonProductTags btn btn-outline-secondary" type="submit" value="Add Tags" name="addTag"></span> 
                    </div> 
                   </form> 
                  </div> <span>Use spaces to separate tags. Use single quotes(') for phrases.</span> 
                 </div> 
                 <form action="" method="post" name="productTagsearchform" id="productTagsearchform"> 
                  <input type="hidden" name="keywordTypeId" value="KWT_TAG"> 
                  <input type="hidden" name="statusId" value="KW_APPROVED"> 
                  <input type="hidden" name="clearSearch" value="Y"> 
                  <input type="hidden" name="VIEW_SIZE" value="10"> 
                  <input type="hidden" name="PAGING" value="Y"> 
                  <input type="hidden" name="SEARCH_STRING" id="productTagStr"> 
                 </form> 
                </div> 
               </div>
        </div>
        <div class="col-lg-3 order-lg-2 order-3 px-4">
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Language</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <p-dropdown [options]="localeArray" placeholder="Select Language">
                          </p-dropdown>
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Cart Summary</p>
                </div>
                <div class="p-2">
                    <form>
                      <div class="card-body">
                        <table class="table">
                          <thead>
                          <tr>
                            <th>Qty</th>
                            <th>Item </th>
                            <th>Subtotal</th>
                          </tr>
                          </thead>
                          <tfoot>
                          <tr>
                            <td colspan="3">
                            Total: $10.80
                            </td>
                          </tr>
                          </tfoot>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>
                                    <span class="span-link">Tiny Gizmo</span>
                              </td>
                              <td>$12.00</td>
                            </tr>
                          </tbody>
                        </table>
                          <ul>
                            <li><span class="span-link" (click)="goToViewCart()">View Cart</span></li>
                            <li><span class="span-link" (click)="goToQuickCheckOut()">Check out</span></li>
                            <li><span class="span-link" (click)="goToQuickCheckOut()">Quick Checkout</span></li>
                            <li><span class="span-link" (click)="goToOnePageCheckOut()">One Page Checkout</span></li>
                          </ul>
                      </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
              <div class="card-title">
                <p class="text-left my-3 mx-2">Compare Products</p>
              </div>
              <div class="p-1">
                <form>
                  <div class="card-body">
                    <p-table [value]="messages" [paginator]="false" [rows]="rows"
                                  scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100]"
                                  [totalRecords]="total" [scrollable]="true" [style]="{'width':'100%','min-width':'600px'}">
                          <ng-template pTemplate="header"></ng-template>
                          <ng-template pTemplate="body">
                          <tr>
                          <td>
                          Team Product
                          </td>
                          <td>
                            <form method="post" action="" name="removeFromCompare0form">
                              <input type="hidden" name="productId" value="10110">
                            </form>
                            <a class="btn btn-link btn-sm">Remove</a>
                          </td>
                        </tr>
                        </ng-template><ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td>
                      <span class="text-center">
                          <h6 class="ml-2">No Record Found
                          </h6>
                      </span>
                  </td>
              </tr>
          </ng-template>
          </p-table>
                    <div>
                      <a href="" class="btn btn-outline-secondary">Clear All</a>
                    </div>
                    <div> <!--Modal Button-->
                      <a class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal">Compare Products</a>
                    </div> <!--Modal Popup Dialog Box-->
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <div class="card m-5">
                              <div class="card-header">
                                <strong>Compare Products</strong>
                              </div>
                              <div class="card-body">
                                <table class="table">
                                  <tbody><tr>
                                    <td>&nbsp;</td>
                                      <td style="width:100%;">
                                        <img src="" alt="Small Image"><br>
                                        Team Product<br>
                            
                                            <span class="regularPrice">$1,000.00</span>
                                      </td>
                                  </tr>
                                  <tr>
                                    <td>Brand Name</td>
                                      <td>Team</td>
                                  </tr>
                                  <tr>
                                    <td>Product Description</td>
                                      <td></td>
                                  </tr>
                                  <tr>
                                    <td>Long Description</td>
                                      <td></td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                      <td>
                                        <div class="productbuy">
                                          <form method="post" action="" name="compare2FormAdd0">
                                            <input type="hidden" name="add_product_id" value="10110">
                                            <input type="text" size="5" name="quantity" value="1">
                                            <input type="hidden" name="clearSearch" value="N">
                                          </form>
                                          <a class="buttontext">Add to Cart </a>
                                        </div>
                                      </td>
                                  </tr>
                                </tbody></table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Special Offers</p>
                </div>
                <div class="p-1">
                    <form>
                        <div class="form-group">
                            <ul>
                                <li>
                                  <p>
                                    <a (click)="goToShowPromotionDetails()"  class="linktext">Details</a>
                                    Free Shipping in Utah
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    <a (click)="goToShowPromotionDetails()"  class="linktext">Details</a>
                                    10% off entire purchase
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    <a (click)="goToShowPromotionDetails()"  class="linktext">Details</a>
                                    Buy 3 Get 2 Free in the Widgets [200] or any sub-category (except the Small Widgets [201] category and sub-categories, but always including the Micro Widgets [20111] category), limit to two per order
                                  </p>
                                </li>
                            </ul>
                        </div>
                        <div class="form-group py-2 ps-4">
                            <input class="btn btn-outline-secondary" type="submit" value="View All Promotions" (click)="goToViewAllPromotions()">
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Did You Know?</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group">
                            <ul>
                                <li>
                                  <p>
                                    The use of gizmos has been shown to have no negative effect on personal longetivity.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Did you know that widget users live twice as long as their non-widget counterparts?
                                  </p>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
              <div class="card-title">
                <div class="float-start">
                  <p class="text-left my-3 mx-2">Last Categories</p>
                </div>
                <div class="float-end my-3 mx-2">
                  <span class="span-link">[Clear]</span>
                </div>
              </div>
              <div class="card-body">
                <ul class="browsecategorylist">
                  <li class="browsecategorytext">
                    <span class="span-link">
                      PROMOTIONS
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
              <div class="card-title">
                <div class="float-start">
                  <p class="text-left my-3 mx-2">Last Products</p>
                </div>
                <div class="float-end my-3 mx-2">
                  <span class="span-link">[Clear] </span><span class="span-link">[More]</span>
                </div>
              </div>
              <div class="card-body">
                <ul>
                  <li>
                    <a href="" class="linktext">
                      Gift Card Activation
                    </a>
                    <ul class="list-unstyled">
                      <li>
                        GC-001
                        From
                        <span class="normalPrice">
                          $1.00</span>
                      </li>
                      <li>
                        <a href="" class="buttons">
                          <span style="white-space: nowrap;">Choose Variations...</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="" class="linktext">
                      Enchiladas
                    </a>
                    <ul class="list-unstyled">
                      <li>
                        ENCHILADAS
                        Product Aggregated Price:
                        <span class="basePrice">
                          $18.00 </span>
                      </li>
                      <li>
                        <form method="post" action=""
                          name="lastviewed1form" style="margin: 0;">
                          <fieldset>
                            <input type="hidden" name="add_product_id" value="ENCHILADAS">
                            <input type="hidden" name="quantity" value="1">
                            <input type="hidden" name="clearSearch" value="N">
                            <a href="javascript:document.lastviewed1form.submit()" class="buttons">
                              <span style="white-space: nowrap;">
                                Add 1 To Cart
                              </span>
                            </a>
                          </fieldset>
                        </form>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="" class="linktext">
                      Financial Account Activation
                    </a>
                    <ul class="list-unstyled">
                      <li>
                        FA-001
                        From
                        <span class="normalPrice">
                          $1.00</span>
                      </li>
                      <li>
                        <a href="" class="buttons">
                          <span style="white-space: nowrap;">Choose Variations...</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="" class="linktext">
                      Soft Drink
                    </a>
                    <ul class="list-unstyled">
                      <li>
                        12121
                      </li>
                      <li>
                        <a href="" class="buttons">
                          <span style="white-space: nowrap;">Choose Amount...</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="" class="linktext">
                      Gift Card Reload
                    </a>
                    <ul class="list-unstyled">
                      <li>
                        GC-002
                      </li>
                      <li>
                        <a href="" class="buttons">
                          <span style="white-space: nowrap;">Choose Amount...</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Tag Cloud</p>
                </div>
                <div class="p-2">
                    <a style="font-size: 8pt;">
                      big
                    </a>
                    <a style="font-size: 8pt;" >
                      black
                    </a>
                    <a style="font-size: 8pt;" >
                      chrome
                    </a>
                    <a style="font-size: 11pt;" >
                      demonstration
                    </a>
                    <a style="font-size: 14pt;" >
                      gizmos
                    </a>
                    <a style="font-size: 11pt;" >
                      red
                    </a>
                    <a style="font-size: 9.5pt;" >
                      service
                    </a>
                    <a style="font-size: 9.5pt;" >
                      small
                    </a>
                    <a style="font-size: 8pt;" >
                      standard
                    </a>
                    <a style="font-size: 8pt;" >
                      tiny
                    </a>
                    <a style="font-size: 9.5pt;" >
                      widget
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


