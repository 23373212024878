import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../services/services.service';

@Component({
  selector: 'app-create-email',
  templateUrl: './create-email.component.html',
  styleUrls: ['./create-email.component.css']
})
export class CreateEmailComponent implements OnInit {

  emailForm!: FormGroup;
  email:any;
  show!: boolean;
  solicitationArray: any[] = [];
  partyId: any;
  contactMechId: any;
  constructor(readonly router:Router, readonly _ActivatedRoute: ActivatedRoute,
    private createContactService:ServicesService,private form:FormBuilder,private _ToastrService:ToastrService) { }

  ngOnInit(): void {
    this.partyId=localStorage.getItem('partyId');
    this.show=false;
    this._ActivatedRoute.queryParams.subscribe(params=>{
      this.email=params['email'];
      this.contactMechId=params['contactMechId'];
      if(this.email){
        this.show=true;
      }
    })
    this.solicitationArray = [
      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      }
    ];
    this.emailForm=this.form.group({
        "allowSolicitation": [''],
        "contactMechId": [''],
        "emailAddress": [''],
        "partyId": [this.partyId]
    });
  }

  goToProfile(){
    this.router.navigate(['/profile/contact-information']);
  }
  onSubmit(){
    this.createContactService.createEmail(this.emailForm.value).subscribe(res=>{
      if (res.success) {
        this._ToastrService.success(res.data.successMessage);
        //this.spinner.hide();
        this.router.navigate(['/profile/contact-information'])
      } else if (res.success == false) {
        this._ToastrService.error(res.data.message);
        //this.spinner.hide();
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
          //this.spinner.hide();
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
          //this.spinner.hide();
          this._ToastrService.error(err.error.message);
        }
      }
    });
  }
  onUpdate(){
    this.emailForm.patchValue({
      contactMechId:this.contactMechId
    });
    this.createContactService.updateEmail(this.emailForm.value).subscribe(res=>{
      if (res.success) {
        this._ToastrService.success(res.data.successMessage);
        //this.spinner.hide();
        this.router.navigate(['/profile/contact-information'])
      } else if (res.success == false) {
        this._ToastrService.error(res.data.message);
        //this.spinner.hide();
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
          //this.spinner.hide();
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
          //this.spinner.hide();
          this._ToastrService.error(err.error.message);
        }
      }
    });
  }
}
