import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../services/services.service';

@Component({
  selector: 'app-add-gift-card',
  templateUrl: './add-gift-card.component.html',
  styleUrls: ['./add-gift-card.component.css']
})
export class AddGiftCardComponent implements OnInit {

  cardNumber:any;
  pinNumber:any;
  show!: boolean;
  partyId: any;
  editMode: boolean;
  giftForm: FormGroup;
  expirationMonth = [
    {
      label: '1 (Jan)',
      value: '1'
    },
    {
      label: '2 (Feb)',
      value: '2'
    },
    {
      label: '3 (Mar)',
      value: '3'
    },
    {
      label: '4 (Apr)',
      value: '4'
    },
    {
      label: '5 (May)',
      value: '5'
    },
    {
      label: '6 (Jun)',
      value: '6'
    },
    {
      label: '7 (Jul)',
      value: '7'
    },
    {
      label: '8 (Aug)',
      value: '8'
    },
    {
      label: '9 (Sep)',
      value: '9'
    },
    {
      label: '10 (Oct)',
      value: '10'
    },
    {
      label: '11 (Nov)',
      value: '11'
    },
    {
      label: '12 (Dec)',
      value: '12'
    }];
  expirationYear = [
    {
      label: '2020',
      value: '2020'
    },
    {
      label: '2021',
      value: '2021'
    },
    {
      label: '2022',
      value: '2022'
    },
    {
      label: '2023',
      value: '2023'
    },

    {
      label: '2024',
      value: '2024'
    },
    {
      label: '2025',
      value: '2025'
    },
    {
      label: '2026',
      value: '2026'
    },
    {
      label: '2027',
      value: '2027'
    },
    {
      label: '2028',
      value: '2028'
    },
    {
      label: '2029',
      value: '2029'
    },
    {
      label: '2030',
      value: '2030'
    }];
  giftCard: any;
  paymentMethodId: string;
  contactMechId: any;
  constructor(
    private _ToastrService: ToastrService,
    private _CRMAccountService: ServicesService,
    readonly _FormBuilder: FormBuilder,
    private _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  ) {
    this.editMode = false;
    this.giftForm = this._FormBuilder.group({
      cardNumber: ['', [Validators.required]],
      description: [''],
      expMonth: ['', Validators.required],
      expYear: ['', Validators.required],
      pinNumber: ['', Validators.required]
    });
    this.partyId = localStorage.getItem('partyId');
    this.paymentMethodId = '';
  }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=>{
      this.cardNumber=params["cardNumber"];
      this.pinNumber=params["pinNumber"];
      this.paymentMethodId=params["paymentMethodId"];
    });
    if (this.paymentMethodId) {
      this.show = true;
      this.getPaymentMethod();
    }
  }
  getPaymentMethod(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'GIFT_CARD')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.giftCard = data.data.giftCardData;
          this.giftForm.patchValue({
            ...data.data.giftCardData, ...{
              description: data.data.paymentMethodData.description
            }
          });
          this.editMode = true;
        }
      });
  }
  submit(): void {
    this.spinner.show();
    
      this._CRMAccountService.postGiftCard(this.partyId, {
        ...this.giftForm.value, ...{
          contactMechId: this.contactMechId
        }
      }).subscribe((res: any) => {
          if (res.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.cancelSubmit();
          }
          }, (err) => {
          
            //this.spinner.hide();
            for (const value of err.error.errors) {
              this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
            }
        });
    
  }
  update(): void {
    this.spinner.show();
    

      this._CRMAccountService.updateGiftCard(this.partyId, this.paymentMethodId, {
        ...this.giftCard, ...this.giftForm.value
      })
      .subscribe((res: any) => {
        if (res.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.cancelSubmit();
        }
        }, (err) => {
          
          //this.spinner.hide();
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
          }
      });
    

  }

  cancelSubmit(): void {
    this.giftForm.reset();
    this._Router.navigate(['/profile/payment-method'], { queryParams: { partyId: this.partyId } });
  }

  goToQuickCheckOut(){
    this._Router.navigate(["/profile/payment-method"]);
  }
}
