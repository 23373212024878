import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SideBarService } from './side-bar.service';
import { TreeNode } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  catalog: any;
  catalogArray: any[] = [];
  locale: any;
  localeArray: any[] = [];
  id: any;
  selectedFiles: any;
  category: TreeNode[] = [];
  language: any;
  tagCloudList: any;

  constructor(readonly router: Router, private sidebarService: SideBarService,
    private toastr: ToastrService,) {

  }

  ngOnInit(): void {
    this.getCatalogList();
    this.getLocale();
    this.getProductTags();

  }
  getProductTags() {
    this.sidebarService.getProductTags().subscribe((res: any) => {
      this.tagCloudList = res.data.tagCloudList;

    })
  }

  goToFeaturedProducts() {
    this.router.navigate(["/"]);
  }
  goToAdvancedSearch() {
    this.router.navigate(["/advanced-search"]);
  }
  goToViewAllPromotions() {
    this.router.navigate(["/view-all-promotions"]);
  }
  goToPromotionDetails() {
    this.router.navigate(["/show-promotion-details"]);
  }
  goToLogin() {
    this.router.navigate([""]);
  }
  getCatalogList() {
    //this.spinner.show();
    this.sidebarService.getCatalogList().subscribe((res: any) => {
      this.catalog = res.data;
      //this.spinner.hide();
      for (const value of this.catalog) {
        this.catalogArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })

  }

  getLocale() {
    //this.spinner.show();
    this.sidebarService.getLocale().subscribe((res: any) => {
      this.locale = res.data;
      //this.spinner.hide();
      for (const value of this.locale) {
        this.localeArray.push({
          label: value.value,
          value: value.key
        })
      }
    })

  }
  
  getLanguage(event:any) {
    this.language=event.value;
    this.sidebarService.postLocale(this.language).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
       
      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);
      
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
       
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
        
          this.toastr.error(err.error.message);
        }
      }
    })
  
 
}
  
  getCategoryByCatalog(event:any){
    this.id=event.value;

    this.sidebarService.getCategoryByCatalog(this.id).subscribe((res: any) => {
      this.category = res.data;
      this.router.navigate(["/"],{ queryParams: { categoryId: this.id } });
    })
  }
}
