import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from './register.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  array: any[] = [];
  stateArray: any = [];
  public stateData: any = [];
  countryId: any;
  countryName: any;
  countryGeoId!: string;
  registerForm!: FormGroup;
  constructor(readonly router: Router, private toastr: ToastrService,
    private registerService: RegisterService,
    readonly createCustomer: RegisterService, private form: FormBuilder) {
    this.registerForm = this.form.group({

      confirmPassword: ['', [Validators.required, Validators.min(5)]],
      customerAddress1: ['', [Validators.required]],
      customerAddress2: ['', [Validators.required]],
      customerAddressAllowSql: ['', [Validators.required]],
      customerCity: ['', [Validators.required]],
      customerCountry: ['', [Validators.required]],
      customerEmail: ['', [Validators.required, Validators.email]],
      customerEmailAllowSql: ['', [Validators.required]],
      customerFaxAllowSql: [''],
      customerFaxArea: ['', [Validators.pattern(/^[0-9]+$/)]],
      customerFaxContact: ['', [Validators.pattern(/^[0-9]+$/)]],
      customerFaxCountry: ['', [Validators.pattern(/^(\+?\d{1,3}|\d{1,4})$/)]],
      customerHomeAllowSql: [''],
      customerHomeArea: ['', [Validators.pattern(/^[0-9]+$/)]],
      customerHomeContact: ['', [Validators.pattern(/^[0-9]+$/)]],
      customerHomeCountry: ['', [Validators.pattern(/^(\+?\d{1,3}|\d{1,4})$/)]],
      customerHomeExt: ['', [Validators.pattern(/^[0-9]+$/)]],
      customerMobileAllowSql: [''],
      customerMobileArea: ['', [Validators.pattern(/^[0-9]+$/)]],
      customerMobileContact: ['', [Validators.pattern(/^[0-9]+$/)]],
      customerMobileCountry: ['', [Validators.pattern(/^(\+?\d{1,3}|\d{1,4})$/)]],
      customerPostalCode: ['', [Validators.required]],
      customerState: ['', [Validators.required]],
      customerWorkAllowSql: [''],
      customerWorkArea: ['', [Validators.pattern(/^[0-9]+$/)]],
      customerWorkContact: ['', [Validators.pattern(/^[0-9]+$/)]],
      customerWorkCountry: ['', [Validators.pattern(/^(\+?\d{1,3}|\d{1,4})$/)]],
      customerWorkExt: ['', [Validators.pattern(/^[0-9]+$/)]],
      password: ['', [Validators.required, Validators.min(5)]],
      passwordHint: [''],
      userFirstName: ['', [Validators.required]],
      userLastName: ['', [Validators.required]],
      userMiddleName: [''],
      userTitle: [''],
      username: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {


    this.getCountryList();
  }
  goTosideBar() {
    this.router.navigate(["/side-bar"]);
  }
  getCountryList() {
    //this.spinner.show();
    this.registerService.getCountry().subscribe(res => {
      var country = res.data.countryList;
      //this.spinner.hide();
      country.forEach((element: any) => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })

  }
  getStateList() {
    //this.spinner.show();
    this.registerService.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      //this.spinner.hide();
      this.stateData.forEach((stateLists: any) => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
  }
  onCountryChange(event: any) {
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }
  onCheckBoxChange(_event: any) {
    if (_event.target.checked) {
      this.registerForm.value.username = this.registerForm.value.customerEmail;
    }
    else {
      this.registerForm.value.username = '';
    }

  }
  onSubmit() {
    //this.spinner.show();
    if (this.registerForm.valid) {
      this.createCustomer.createCustomer(this.registerForm.value).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Created Successfully");
          //this.spinner.hide();
          this.router.navigate(['/login'])
        } else if (res.success == false) {
          this.toastr.error(res.data.responseMessage);
          //this.spinner.hide();
        }
      }, (err) => {
        if (err.error.success == false) {
          if (err.error.errors) {
            // this.spinner.hide();
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + " " + value.fieldError);
            }

          }
          else if (err.error.success == false) {
            //this.spinner.hide();
            this.toastr.error(err.error.message);
          }
        }
      })
    }
    else {
      this.toastr.error("Fill all mandatory fields"); ("")
    }
  }
  goToLogin() {
    this.router.navigate(["/login"]);
  }
}
