import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from '../services/services.service';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css']
})
export class ShippingComponent implements OnInit {
  carrierShipMethods: any;

  constructor(readonly router: Router, private accountsService: ServicesService,
    private sidebarService:SideBarService,) { }

  ngOnInit(): void {
    this.customerProfile();
  }
  customerProfile() {
    this.sidebarService.customerProfile().subscribe((res: any) => {
      this.carrierShipMethods = res.data.defaultShipmentMethod.carrierShipMethods;

    })
  }
}
