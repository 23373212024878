<div class="container-fluid">
  <div class="row">
    <div class="col-lg-3 order-lg-2 order-2 px-4">
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Choose Catalog</p>
        </div>
        <div class="p-2">
          <form [formGroup]="CatalogForm">
            <div class="form-group p-1">
              <label class="mb-2" for="exampleInputEmail1">Choose Catalog</label>
              <p-dropdown [options]="catalogArray" placeholder="Select Catalog" formControlName="catalogId"
                (onChange)="getCategoryByCatalog($event)">
              </p-dropdown>
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width:100%">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Search Catalog</p>
        </div>
        <div class="p-2">
          <form [formGroup]="findForm">
            <div class="form-group p-1">
                <label class="mb-2" for="exampleInputEmail1">Search Catalog</label>
                <input type="email" class="form-control mb-2" formControlName="findCatalog" id="username" aria-describedby="emailHelp"
                    placeholder="">
                <div class="form-check">
                    <input class="form-check-input" type="radio" value="OR" formControlName="radioAll" name="searchCatalogueRadio"
                        id="searchCatalogueRadio" checked>
                    <label class="form-check-label" for="searchCatalogueRadio">Any</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" value="AND" formControlName="radioAll" type="radio" name="searchCatalogueRadio"
                        id="searchCatalogueRadio">
                    <label class="form-check-label" for="searchCatalogueRadio">All</label>
                </div>
            </div>
            <div class="form-group p-1">
                <input class="btn btn-outline-secondary" type="submit" value="Find" (click)="goToFindCatalog()">
            </div>
            <div class="form-group p-1">
                <input class="btn btn-outline-secondary" type="submit" value="Advanced Search"
                    (click)="goToAdvancedSearch()">
            </div>
        </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Categories</p>
        </div>
        <div class="p-2">
          <form>
            <div class="form-group p-1">
              <p-tree [value]="category" selectionMode="single"></p-tree>
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Quick Recorder</p>
        </div>
        <div class="p-2">
          <span class="span-link">Tiny Gizmo</span>
          <ul class="list-unstyled">
            <li>GZ-1000 <span>$12.00</span></li>
            <li><span class="span-link">Add To Cart</span></li>
          </ul>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Sign Up For Contact List</p>
        </div>
        <div class="p-2">
          <form>
            <div class="form-group p-1">
              <p>Sign Up For Contact List LogIn</p>
              <p><span class="span-link">Login </span>THE ADMINISTRATOR</p>
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Mini Poll</p>
        </div>
        <div class="p-2">
          <form>
            <div class="form-group p-1">
              <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
              <select class="form-select form-select my-2" aria-label=".form-select example">
                <option selected>Right Side</option>
                <option value="1">Right Side</option>
                <option value="2">Left Side/option>
              </select>
              <p>*</p>
              <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
              <select class="form-select form-select my-2" aria-label=".form-select example">
                <option selected>Right Side</option>
                <option value="1">Right Side</option>
                <option value="2">Left Side</option>
              </select>
              <p>*</p>
              <input class="btn btn-outline-secondary" type="submit" value="Vote!">
            </div>
          </form>
        </div>
      </div>
      <!-- <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Browse Forums</p>
        </div>
        <div class="p-2 pb-4">
          <form>
            <span class="span-link">Ask the Experts</span><br>
            <span class="span-link">Ask the Experts</span><br>
            <span class="span-link">Gizmos</span><br>
            <span class="span-link">Gizmos</span><br>
            <span class="span-link">Widgets</span><br>
            <span class="span-link">Widgets</span><br>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Browse Content</p>
        </div>
        <div class="p-2 pb-4">
          <form>
            <span class="span-link">Widgets</span><br>
            <span class="span-link">Store Policies 2</span><br>
            <span class="span-link">Gizmos</span><br>
            <span class="span-link">Cookie Policy</span><br>
            <span class="span-link">Policies</span><br>
            <span class="span-link">Store Policies</span><br>
            <span class="span-link">Store Policies 2</span><br>
            <span class="span-link">Widgets</span><br>
            <span class="span-link">Policies</span><br>
            <span class="span-link">Gizmos</span><br>
            <span class="span-link">Cookie Policy</span><br>
            <span class="span-link">Store Policies</span><br>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Browse Blogs</p>
        </div>
        <div class="p-2 pb-4">
          <form>
            <span class="span-link">Big AI</span><br>
            <span class="span-link">Big AI</span><br>
            <span class="span-link">Mad Max</span><br>
            <span class="span-link">Mad Max</span><br>
            <span class="span-link">xczx</span><br>
          </form>
        </div>
      </div> -->
    </div>
    <div class="col-lg-6 order-lg-2 order-1 px-4" *ngIf="this.productMap">
      <div id="productdetail" class="card mx-auto mt-2" style="width:100%!important">
        <table class="table table-responsive-sm">
          <tbody>
            <tr>
              <td> <a> <img [src]="this.productMap.productLargeImageUrl" alt="Image"> </a> </td>
              <td>
                <h2>{{this.productMap.productName}}</h2>
                <div>
                  {{this.productMap.productDescription}}
                </div>
                <div>
                  <b> {{this.productMap.productId}}</b>
                </div>
                <div>
                  Product Aggregated Price: <span *ngIf="!show" id="totalPrice" class="basePrice"> ${{this.productMap.totalPrice}}</span>
                  <span *ngIf="show" id="totalPrice" class="basePrice"> {{ this.totalPrice}}</span>
                </div>
                <div>
                  &nbsp;
                </div>
                <div> <a>Tell-A-Friend</a>
                </div>
                <form [formGroup]="addToCart">
                  <input type="hidden" name="product_id" value="ENCHILADAS">
                  <input type="hidden" name="add_product_id" value="ENCHILADAS">
                 
                  <div class="input-group">
                    <input type="text" size="5" name="quantity" formControlName="quantity" class="form-control form-control-sm"> <span
                      class="input-group-button"> <a href="javascript:addItem()"
                        class="btn btn-outline-secondary btn-sm" (click)="addToCartConfig()"> Add to Cart </a> </span>
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <td> You Must <a class="buttontext">Login</a> To Add Selected Items To Shopping List.&nbsp; </td>
            </tr>
            <tr>
              <td> </td>
            </tr>
            <tr>
              <td colspan="2">
                <form name="configform" id="configFormId" method="post" action="">
                  <input type="hidden" name="add_product_id" value="ENCHILADAS">
                  <input type="hidden" name="add_category_id" value="">
                  <input type="hidden" name="quantity" value="1">
                  <input type="hidden" name="product_id" value="ENCHILADAS">
                  <div> <a class="btn btn-outline-secondary btn-sm" (click)="onSubmit();">Verify Configuration</a>
                  </div>
                  <br>
                
                  <table>
                    <tbody>
                     
                      <tr >
                        <td>
                          <div>
                            {{this.configQuestions.question}}
                          </div>  <div *ngIf="this.configQuestions.showDetailsButton=='Y'"> <a href="#TEXMEX0000" class="btn btn-outline-secondary btn-sm">Details</a>
                          </div>
                        </td>
                      </tr>
                      <tr >
                        <td>
                          <div class="form-check" *ngFor="let option of this.configQuestions.optionsList"> 
                            <input (click)="selectRadio(this.configQuestions.index,option.value,'')" type="radio" name="radioName" class="form-check-input" value={{option.value}}> <label>
                              {{option.description}}&nbsp;  ${{option.shownPrice}}</label>
                          </div>
                          
                        </td>
                      </tr>
                      <tr >
                        <td>
                          <div>
                            {{this.configQuestions1.question}}
                          </div>  <div *ngIf="this.configQuestions1.showDetailsButton=='Y'"> <a href="#TEXMEX0000" class="btn btn-outline-secondary btn-sm">Details</a>
                          </div>
                        </td>
                      </tr>
                      <tr >
                        <td>
                          <div class="form-check" *ngFor="let option of this.configQuestions1.optionsList"> 
                            <input (click)="selectRadio(this.configQuestions1.index,option.value,'')" type="radio" name="radioName1" class="form-check-input" value={{option.value}}> <label>
                              {{option.description}}&nbsp;  ${{option.shownPrice}}</label>
                          </div>
                          
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            {{this.configQuestions2.question}}
                          </div>  <div *ngIf="this.configQuestions2.showDetailsButton=='Y'"> <a href="#TEXMEX0000" class="btn btn-outline-secondary btn-sm">Details</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="form-check" *ngFor="let option of this.configQuestions2.optionsList"> 
                            <input (click)="selectRadio(this.configQuestions2.index,option.value,'Check')" type="checkbox" name="radioName2" class="form-check-input" value={{option.value}}> <label>
                              {{option.description}}&nbsp;  ${{option.shownPrice}}</label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            {{this.configQuestions3.question}}
                          </div>
                          <div *ngIf="this.configQuestions3.showDetailsButton=='Y'"> <a href="#TEXMEX0000" class="btn btn-outline-secondary btn-sm">Details</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="form-check" *ngFor="let option of this.configQuestions3.optionsList"> 
                            <input type="checkbox" (click)="selectRadio(this.configQuestions3.index,option.value,'Check')" name="radioName3" class="form-check-input" value={{option.value}}> <label>
                              {{option.description}}&nbsp;  ${{option.shownPrice}}</label>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions4">
                        <td>
                          <div>
                            {{this.configQuestions4.question}}
                          </div>  <div *ngIf="this.configQuestions4.showDetailsButton=='Y'"> <a href="#TEXMEX0000" class="btn btn-outline-secondary btn-sm">Details</a>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions4">
                        <td>
                          <div class="form-check" *ngFor="let option of this.configQuestions4.optionsList"> 
                            <input type="radio" (click)="selectRadio(this.configQuestions4.index,option.value,'')" name="radioName4" class="form-check-input" value={{option.value}}> <label>
                              {{option.description}}&nbsp;  ${{option.shownPrice}}</label>
                          </div>
                          
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions5">
                        <td>
                          <div>
                            {{this.configQuestions5.question}}
                          </div>  <div *ngIf="this.configQuestions5.showDetailsButton=='Y'"> <a href="#TEXMEX0000" class="btn btn-outline-secondary btn-sm">Details</a>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions5">
                        <td>
                          <div class="form-check" *ngFor="let option of this.configQuestions5.optionsList"> 
                            <input type="radio" (click)="selectRadio(this.configQuestions5.index,option.value,'')" name="radioName5" class="form-check-input" value={{option.value}}> <label>
                              {{option.description}}&nbsp;  ${{option.shownPrice}}</label>
                          </div>
                          
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions6">
                        <td>
                          <div>
                            {{this.configQuestions6.question}}
                          </div>  <div *ngIf="this.configQuestions6.showDetailsButton=='Y'"> <a href="#TEXMEX0000" class="btn btn-outline-secondary btn-sm">Details</a>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions6">
                        <td>
                          <div class="form-check" *ngFor="let option of this.configQuestions6.optionsList"> 
                            <input type="radio" (click)="selectRadio(this.configQuestions6.index,option.value,'')" name="radioName6" class="form-check-input" value={{option.value}}> <label>
                              {{option.description}}&nbsp;  ${{option.shownPrice}}</label>
                          </div>
                          
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions7">
                        <td>
                          <div>
                            {{this.configQuestions7.question}}
                          </div>  <div *ngIf="this.configQuestions7.showDetailsButton=='Y'"> <a href="#TEXMEX0000" class="btn btn-outline-secondary btn-sm">Details</a>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions7">
                        <td>
                          <div class="form-check" *ngFor="let option of this.configQuestions7.optionsList"> 
                            <input type="radio" (click)="selectRadio(this.configQuestions7.index,option.value,'')" name="radioName7" class="form-check-input" value={{option.value}}> <label>
                              {{option.description}}&nbsp;  ${{option.shownPrice}}</label>
                          </div>
                          
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions8">
                        <td>
                          <div>
                            {{this.configQuestions8.question}}
                          </div>  <div *ngIf="this.configQuestions8.showDetailsButton=='Y'"> <a href="#TEXMEX0000" class="btn btn-outline-secondary btn-sm">Details</a>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions8">
                        <td>
                          <div class="form-check" *ngFor="let option of this.configQuestions8.optionsList"> 
                            <input type="radio" (click)="selectRadio(this.configQuestions8.index,option.value,'')" name="radioName8" class="form-check-input" value={{option.value}}> <label>
                              {{option.description}}&nbsp;  ${{option.shownPrice}}</label>
                          </div>
                          
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions9">
                        <td>
                          <div>
                            {{this.configQuestions9.question}}
                          </div>  <div *ngIf="this.configQuestions9.showDetailsButton=='Y'"> <a href="#TEXMEX0000" class="btn btn-outline-secondary btn-sm">Details</a>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="this.configQuestions9">
                        <td>
                          <div class="form-check" *ngFor="let option of this.configQuestions9.optionsList"> 
                            <input type="radio" (click)="selectRadio(this.configQuestions9.index,option.value,'')" name="radioName9" class="form-check-input" value={{option.value}}> <label>
                              {{option.description}}&nbsp;  ${{option.shownPrice}}</label>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div>
                  This product hasn't been reviewed yet.
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2"> <span class="span-link">Be The First To Review This Product!</span> </td>
            </tr>
          </tbody>
        </table>
        <table class="table">
        </table>
      </div>
    </div>
    <div class="col-lg-3 order-lg-2 order-3 px-4">
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Language</p>
        </div>
        <div class="p-2">
          <form>
            <div class="form-group p-1">
              <p-dropdown [options]="localeArray" placeholder="Select Language">
              </p-dropdown>
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Cart Summary</p>
        </div>
        <div class="p-2">
          <form>
            <div class="card-body">
              <table class="table">
                <thead>
                <tr>
                  <th>Qty</th>
                  <th>Item </th>
                  <th>Subtotal</th>
                </tr>
                </thead>
                <tfoot>
                <tr>
                  <td colspan="3"  *ngIf="this.summary">
                  Total: ${{this.summary.cartTotal}}
                  </td>
                </tr>
                </tfoot>
                <tbody>
                  <tr  *ngFor="let product of this.showCart;let indexOfelement=index;">
                    <td>{{indexOfelement+1}}</td>
                    <td>
                          <span class="span-link">{{product.productName}}</span>
                    </td>
                    <td>${{product.itemTotal}}</td>
                  </tr>
                </tbody>
              </table>
                <ul>
                  <li><span class="span-link" (click)="goToViewCart()">View Cart</span></li>
                  <li><span class="span-link" (click)="goToCheckOut()">Check out</span></li>
                  <li><span class="span-link" (click)="goToQuickCheckOut()">Quick Checkout</span></li>
                  <li><span class="span-link" (click)="goToOnePageCheckOut()">One Page Checkout</span></li>
                </ul>
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Compare Products</p>
        </div>
        <div class="p-1">
          <form>
            <div class="card-body" *ngFor="let compare of compareProductList;">
              <label>{{compare.productData.productName}}</label>&nbsp;<span (click)="removeAddToCompare(compare.productData.productId)" class="span-link">Remove</span>
              <div>
                <a href="" class="btn btn-outline-secondary" (click)="clearCompare()">Clear All</a>
              </div>
              <div>
                <!--Modal Button-->
                <a class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal">Compare
                  Products</a>
              </div>
              <!--Modal Popup Dialog Box-->
             
            </div>
           
          </form>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="card m-5">
                <div class="card-header">
                  Compare Products
                </div>
                <div class="card-body">
                  <div class="row table-scroll">
                    <div class="col-6" *ngFor="let compareItem of compareProductList;">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td style="width:100%;">
                              <img [src]="compareItem.smallImageUrl" alt="Small Image"><br>
                            <span > {{compareItem.productData.productName}}</span> <br>
    
                              <span class="regularPrice">${{compareItem.productPrice.price}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Brand Name</td>
                            <td>{{compareItem.productData.brandName}}</td>
                          </tr>
                          <tr>
                            <td>Product Description</td>
                            <td>{{compareItem.productData.description}}</td>
                          </tr>
                          <tr>
                            <td>Long Description</td>
                            <td>{{compareItem.productData.longDescription}}</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              <div class="productbuy">
                                <form method="post" action="" name="compare2FormAdd0">
                                  <input type="hidden" name="add_product_id" value="10110">
                                  <input type="text" size="5" name="quantity" value="1">
                                  <input type="hidden" name="clearSearch" value="N">
                                </form>
                                <a class="buttontext">Add to Cart </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Special Offers</p>
        </div>
        <div class="p-1">
          <form>
            <div class="form-group">
              <ul *ngFor="let offer of this.specialOffers">
                <li>
                  <p>
                    <a (click)="goToShowPromotionDetails()" class="linktext">Details</a>
                  {{offer.promoText}}
                  </p>
                </li>
                
              </ul>
            </div>
            <div class="form-group py-2 ps-4">
              <input class="btn btn-outline-secondary" type="submit" value="View All Promotions"
                (click)="goToViewAllPromotions()">
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Did You Know?</p>
        </div>
        <div class="p-2">
          <form>
            <div class="form-group">
              <ul>
                <li>
                  <p>
                    The use of gizmos has been shown to have no negative effect on personal longetivity.
                  </p>
                </li>
                <li>
                  <p>
                    Did you know that widget users live twice as long as their non-widget counterparts?
                  </p>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <div class="float-start">
            <p class="text-left my-3 mx-2">Last Categories</p>
          </div>
          <div class="float-end my-3 mx-2">
            <span class="span-link">[Clear]</span>
          </div>
        </div>
        <div class="card-body">
          <ul class="browsecategorylist">
            <li class="browsecategorytext">
              <span class="span-link">
                PROMOTIONS
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <div class="float-start">
            <p class="text-left my-3 mx-2">Last Products</p>
          </div>
          <div class="float-end my-3 mx-2">
            <span class="span-link">[Clear] </span><span class="span-link">[More]</span>
          </div>
        </div>
        <div class="card-body">
          <ul>
            <li>
              <a href="" class="linktext">
                Gift Card Activation
              </a>
              <ul class="list-unstyled">
                <li>
                  GC-001
                  From
                  <span class="normalPrice">
                    $1.00</span>
                </li>
                <li>
                  <a href="" class="buttons">
                    <span style="white-space: nowrap;">Choose Variations...</span>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="" class="linktext">
                Enchiladas
              </a>
              <ul class="list-unstyled">
                <li>
                  ENCHILADAS
                  Product Aggregated Price:
                  <span class="basePrice">
                    $18.00 </span>
                </li>
                <li>
                  <form method="post" action=""
                    name="lastviewed1form" style="margin: 0;">
                    <fieldset>
                      <input type="hidden" name="add_product_id" value="ENCHILADAS">
                      <input type="hidden" name="quantity" value="1">
                      <input type="hidden" name="clearSearch" value="N">
                      <a href="javascript:document.lastviewed1form.submit()" class="buttons">
                        <span style="white-space: nowrap;">
                          Add 1 To Cart
                        </span>
                      </a>
                    </fieldset>
                  </form>
                </li>
              </ul>
            </li>
            <li>
              <a href="" class="linktext">
                Financial Account Activation
              </a>
              <ul class="list-unstyled">
                <li>
                  FA-001
                  From
                  <span class="normalPrice">
                    $1.00</span>
                </li>
                <li>
                  <a href="" class="buttons">
                    <span style="white-space: nowrap;">Choose Variations...</span>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="" class="linktext">
                Soft Drink
              </a>
              <ul class="list-unstyled">
                <li>
                  12121
                </li>
                <li>
                  <a href="" class="buttons">
                    <span style="white-space: nowrap;">Choose Amount...</span>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="" class="linktext">
                Gift Card Reload
              </a>
              <ul class="list-unstyled">
                <li>
                  GC-002
                </li>
                <li>
                  <a href="" class="buttons">
                    <span style="white-space: nowrap;">Choose Amount...</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Tag Cloud</p>
        </div>
        <div class="p-2">
          <a  *ngFor="let cloud of tagCloudList"  [style.font-size.pt]="cloud.fontSize"
                  href="https://34.196.47.126:8443/ecommerce/control/tagsearch?SEARCH_STRING=big&amp;keywordTypeId=KWT_TAG&amp;statusId=KW_APPROVED">
                  {{cloud.tag}}
                </a>
        </div>
      </div>
    </div>
  </div>
</div>