<div class="col-lg-12">
    <div class="card mx-auto mt-2" style="width: 100%;">
      <div class="card-title">
        <p class="text-left my-3 mx-2">Language</p>
      </div>
      <div class="p-2">
        <form>
          <div class="form-group p-1">
            <p-dropdown [options]="localeArray" placeholder="Select Language" (onChange)="getLanguage($event)">
            </p-dropdown>
          </div>
        </form>
      </div>
    </div>
    <div class="card mx-auto mt-2" style="width: 100%;">
      <div class="card-title">
        <p class="text-left my-3 mx-2">Cart Summary</p>
      </div>
      <div class="p-2">
        <form>
          <div class="form-group p-1">
            <p>Shopping Cart Empty</p>
          </div>
        </form>
      </div>
    </div>
    <div class="card mx-auto mt-2" style="width: 100%;">
      <div class="card-title">
        <p class="text-left my-3 mx-2">Compare Products</p>
      </div>
      <div class="p-1">
        <form>
          <div class="card-body" *ngFor="let compare of compareProductList;">
            <label>{{compare.productData.productName}}</label>&nbsp;<span (click)="removeAddToCompare(compare.productData.productId)" class="span-link">Remove</span>
            <div>
              <a href="" class="btn btn-outline-secondary" (click)="clearCompare()">Clear All</a>
            </div>
            <div>
              <!--Modal Button-->
              <a class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal">Compare
                Products</a>
            </div>
            <!--Modal Popup Dialog Box-->
           
          </div>
         
        </form>
      </div>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="card m-5">
              <div class="card-header">
                Compare Products
              </div>
              <div class="card-body">
                <div class="row table-scroll">
                  <div class="col-6" *ngFor="let compareItem of compareProductList;">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                          <td style="width:100%;">
                            <img [src]="compareItem.smallImageUrl" alt="Small Image"><br>
                          <span > {{compareItem.productData.productName}}</span> <br>
  
                            <span class="regularPrice">${{compareItem.productPrice.price}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Brand Name</td>
                          <td>{{compareItem.productData.brandName}}</td>
                        </tr>
                        <tr>
                          <td>Product Description</td>
                          <td>{{compareItem.productData.description}}</td>
                        </tr>
                        <tr>
                          <td>Long Description</td>
                          <td>{{compareItem.productData.longDescription}}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>
                            <div class="productbuy">
                              <form method="post" action="" name="compare2FormAdd0">
                                <input type="hidden" name="add_product_id" value="10110">
                                <input type="text" size="5" name="quantity" value="1">
                                <input type="hidden" name="clearSearch" value="N">
                              </form>
                              <a class="buttontext">Add to Cart </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="card mx-auto mt-2" style="width: 100%;">
      <div class="card-title">
        <p class="text-left my-3 mx-2">Special Offers</p>
      </div>
      <div class="p-1">
        <form>
          <div class="form-group">
            <ul>
              <li>
                <p>
                  <a class="linktext" (click)="goToPromotionDetails()">Details</a>
                  Free Shipping in Utah
                </p>
              </li>
              <li>
                <p>
                  <a (click)="goToPromotionDetails()" class="linktext">Details</a>
                  10% off entire purchase
                </p>
              </li>
              <li>
                <p>
                  <a (click)="goToPromotionDetails()" class="linktext">Details</a>
                  Buy 3 Get 2 Free in the Widgets [200] or any sub-category (except the Small Widgets [201] category
                  and sub-categories, but always including the Micro Widgets [20111] category), limit to two per order
                </p>
              </li>
            </ul>
          </div>
          <div class="form-group py-2 ps-4">
            <input class="btn btn-outline-secondary" type="submit" value="View All Promotions"
              (click)="goToViewAllPromotions()">
          </div>
        </form>
      </div>
    </div>
    <div class="card mx-auto mt-2" style="width: 100%;">
      <div class="card-title">
        <p class="text-left my-3 mx-2">Did You Know?</p>
      </div>
      <div class="p-2">
        <form>
          <div class="form-group">
            <ul>
              <li>
                <p>
                  The use of gizmos has been shown to have no negative effect on personal longetivity.
                </p>
              </li>
              <li>
                <p>
                  Did you know that widget users live twice as long as their non-widget counterparts?
                </p>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
    <div class="card mx-auto mt-2" style="width: 100%;">
      <div class="card-title">
        <p class="text-left my-3 mx-2">Tag Cloud</p>
      </div>
      <div class="p-2">
        <a  *ngFor="let cloud of tagCloudList"  [style.font-size.pt]="cloud.fontSize"
                  href="https://34.196.47.126:8443/ecommerce/control/tagsearch?SEARCH_STRING=big&amp;keywordTypeId=KWT_TAG&amp;statusId=KW_APPROVED">
                  {{cloud.tag}}
                </a>
      </div>
    </div>
  </div>
