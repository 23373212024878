import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RandomService } from '../random.service';
import { SideBarService } from '../side-bar/side-bar.service';
import { TreeNode } from 'primeng/api';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-one-page-check-out',
  templateUrl: './one-page-check-out.component.html',
  styleUrls: ['./one-page-check-out.component.css']
})
export class OnePageCheckOutComponent implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  catalogId: string | null;
  showCart: any;
  specialOffers: any;
  summary: any;
  partyTaxAuthInfoAndDetailList: any;
  address: any;
  partyTaxIdentificationArray: any[]=[];
  partyTaxIdentification: any;
  partyTaxIdentificationData: any;
  id: any;
  mechId: any;
  selecttaxAuthPartyGeoIds: any;
  partyId: any;
  exempt: any;
  shipmentMethods: any;
  otherdata: any;
  paymentOptions: any;
  paymentMethodMapList: any;
  payInfo: any;
  constructor(
    readonly router: Router, private sidebarService: SideBarService,
    private toastr: ToastrService,readonly Form:FormBuilder,
    private activeRoute: ActivatedRoute
  ) { 
    this.catalogId = localStorage.getItem("catalog");
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
      // this.giftCard = params["giftCard"];
      // this.giftCardAmount = params["giftCardAmount"];
      // this.giftCardNumber = params["giftCardNumber"];
      // this.payment=params["payment"];
      // this.amountValue=params["amountValue"];
      // this.checkout=params["checkout"];
      // this.sCode=params['sCode']
   
    });
    this.getshowCart();
    this.checkoutOptions();
  }

  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
      this.showCart = res.data.productsList;
      this.specialOffers = res.data.specialOffers;
      this.summary = res.data.summary;

    })
  }

  removeProductCart(product:any) {
    Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      let form = {
        "removeSelected": '',
        "selectAll": '',
        "selectedItem": '',
        "shoppingListId":product.ListShoppingList?.shoppingListId,
        "update_0":'' ,
      }
      this.sidebarService.removeProductCart(form).subscribe((res: any) => {
        if (res.success) {
          this.getshowCart();
        }
        else {
          this.toastr.error("Error");
        }
      })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })
}

checkoutOptions() {
  this.id="";
  let req={

  }
  this.sidebarService.checkoutOptions(this.id,req).subscribe((res: any) => {
    this.address = res.data.address;
    this.partyTaxAuthInfoAndDetailList=res.data.partyTaxIdentification.partyTaxAuthInfoAndDetailList
    this.partyTaxIdentification = res.data.partyTaxIdentification.taxAuthPartyGeoIds;
    for (const value of this.partyTaxIdentification) {
      this.partyTaxIdentificationArray.push({
        label: value.geoName,
        value: value.partyId + '::' +value.taxAuthGeoId
      })
    }
    this.partyTaxIdentificationData = res.data.partyTaxIdentification;
    

 

  })
}

checkoutOptions1() {
  this.id="shippingaddress";
  let req={
    
    "shipping_contact_mech_id": this.mechId,
    "taxAuthPartyGeoIds":this.selecttaxAuthPartyGeoIds,
    "partyTaxId": this.partyId,
    "isExempt":  this.exempt
  }
  this.sidebarService.checkoutOptions(this.id,req).subscribe((res: any) => {
   this.shipmentMethods = res.data.shipmentMethods;
   
   this.otherdata=res.data;
    
    

  })
}

// checkoutOptions() {
//   this.id = "shippingoptions";
//   let req = {

//     "shipping_method": this.shipmentId,
//     "may_split": this.splitValueData,
//     "shipping_instructions":  this.shipping_instructions,
//     "correspondingPoId":  this.correspondingPoId,
//     "is_gift": this.radioValueGift,
//     "gift_message":  this.gift_message,
//     "order_additional_emails": this.order_additional_emails,
//     "internalCode": "",
//     "shipBeforeDate": "",
//     "shipAfterDate": ""
//   }
//   this.sidebarService.checkoutOptions(this.id, req).subscribe((res: any) => {
//     this.paymentOptions = res.data.paymentOptions;
//     this.paymentMethodMapList=res.data.paymentMethodMapList

//     //this.otherdata = res.data;



//   })
// }

// checkoutOptionsfinal() {
//   this.id = "payment";
 
//   this.payInfo=JSON.parse(this.paymentArray);
//   let req = {
   
//     "BACK_PAGE": "checkoutoptions",
//     "issuerId": "",
//     [this.amountValue]:this.sCode,
//     "checkOutPaymentId":this.checkout,
//     "addGiftCard": "Y",
//     "singleUseGiftCard": this.giftCard,
//     "giftCardNumber":  this.giftCardNumber,
//     "giftCardAmount":this.giftCardAmount
//   }
//   this.sidebarService.checkoutOptions(this.id, req).subscribe((res: any) => {
//     this.orderView=res.data.orderView.orderInformation;
//     this.shippingInformation=res.data.shippingInformation.listShipAddr[0];
//     this.orderItemList=res.data.orderItems.orderItemList;
//     this.otherdetails=res.data.otherdetails;
//    this.paymentOptions = res.data.paymentOptions;
//     this.paymentMethodMapList=res.data.paymentMethodMapList
//     this.paymentInformation=res.data.orderView.paymentInformation;
//     this.otherdata = res.data;



//   })
// }

// processOrder() {
//   this.id = "payment";
 
//   this.sidebarService.processOrder(this.id).subscribe((res: any) => {
//     if (res.success) {
//       this.toastr.success("Created Successfully");
//       this.OrderID=res.data.orderId;
//       this.router.navigate(['/view-order'], { queryParams: { OrderID: this.OrderID } });


//     } else if (res.success == false) {
//       this.toastr.error(res.data.responseMessage);

//     }
//   }, (err) => {
//     if (err.error.success == false) {
//       if (err.error.errors) {

//         for (const value of err.error.errors) {
//           this.toastr.error(value.fieldName + " " + value.fieldError);
//         }

//       }
//       else if (err.error.success == false) {

//         this.toastr.error(err.error.message);
//       }
//     }
//   })


// }

}
