import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { RandomService } from '../random.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  show: boolean;
  username: any;
  lastname: any;
  token:any;

  constructor(readonly router: Router, private random: RandomService) {
    this.show = false;
    this.username = localStorage.getItem("firstName");
    this.lastname = localStorage.getItem("lastName");
  }

  ngOnInit(): void {
    this.random.currentMessageSubscriber.subscribe((data: any) => {
      this.token=localStorage.getItem("token");
      if (this.token) {
        this.show = true;
        this.username = localStorage.getItem("firstName");
        this.lastname = localStorage.getItem("lastName");
      }
      else {
        this.show = false;
    
        this.username="";
        this.lastname="";
      }
    })
  }

  goToRegister() {
    this.router.navigate(["/register"]);
  }
  goToLogin() {
    this.router.navigate(["/login"]);
  }
  goToMain() {
    this.router.navigate(["/"]);
  }
  goToMessages() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/header-messages"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  goToQuotes() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/quotes"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  goToRequests() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/requests"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  goToShoppingList() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/shoppingList"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  goToQuickAdd() {
    this.router.navigate(["/quick-add"]);
  }

  goToProfile() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/profile"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  goToViewCart() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/view-cart"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  goToCheckOutOption1() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/check-out-option1"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  goToQuickCheckOut() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/quick-check-out"]);
    }else{
      this.router.navigate(["/login"]);
    }
  
  }
  goToOnePageCheckOut() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/one-page-check-out"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  logout() {

    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("token");
    localStorage.removeItem("partyId");
    localStorage.removeItem("adminSecurity");
    localStorage.removeItem("email");
    localStorage.removeItem("paymentArray");
    this.router.navigate(["/login"]);
    this.random.notify({ isRefresh: false });
  }
  goToContactUs() {
    this.router.navigate(["/contact-us"]);
  }
}
