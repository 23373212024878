import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SideBarService } from '../side-bar/side-bar.service';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-choose-product-amount',
  templateUrl: './choose-product-amount.component.html',
  styleUrls: ['./choose-product-amount.component.css']
})
export class ChooseProductAmountComponent implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  catalog: any;
  catalogArray: any[]=[];
  locale: any;
  localeArray: any[]=[];
  id: any;
  selectedFiles: any;
  category: TreeNode[] = [];
  
  constructor(readonly router:Router,private sidebarService:SideBarService) { }

  ngOnInit(): void {
    this.getCatalogList();
    this.getLocale();
  }

  goToAdvancedSearch(){
    this.router.navigate(["/advanced-search"]);
  }
  goToFeaturedProducts(){
    this.router.navigate(["/"]);
  }
  goToConfigureProduct(){
    this.router.navigate(["/configure-product"]);
  }
  goToChooseProductAmount(){
    this.router.navigate(["/choose-product-amount"]);
  }
  goToChooseVariation(){
    this.router.navigate(["/choose-variation"]);
  }
  goToViewAllPromotions(){
    this.router.navigate(["/view-all-promotions"]);
  }
  goToShowPromotionDetails(){
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewCart(){
    this.router.navigate(["/view-cart"]);
  }
  goToQuickCheckOut(){
    this.router.navigate(["/quick-check-out"]);
  }
  goToOnePageCheckOut(){
    this.router.navigate(["/one-page-check-out"]);
  }
  getCatalogList() {
    //this.spinner.show();
    this.sidebarService.getCatalogList().subscribe((res: any) => {
      this.catalog = res.data;
      //this.spinner.hide();
      for (const value of this.catalog) {
        this.catalogArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })
  
  }

  getLocale() {
    //this.spinner.show();
    this.sidebarService.getLocale().subscribe((res: any) => {
      this.locale = res.data;
      //this.spinner.hide();
      for (const value of this.locale) {
        this.localeArray.push({
          label: value.value,
          value: value.key
        })
      }
    })
  
  }
  getCategoryByCatalog(event:any){
    this.id=event.value;
    this.sidebarService.getCategoryByCatalog(this.id).subscribe((res: any) => {
      this.category = res.data;

    })
  }
}
