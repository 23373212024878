import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../../../side-bar/side-bar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-data-filter',
  templateUrl: './data-filter.component.html',
  styleUrls: ['./data-filter.component.css']
})
export class DataFilterComponent implements OnInit {
  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  orders:any[]=[];
  empty:string="No Record Found!";
  allData: any;
  dateFilterForm: FormGroup;

  constructor(readonly router: Router, private sidebarService: SideBarService,
    private toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,) {
      this.dateFilterForm=this._FormBuilder.group({
        byDate:['']
      })
     }

  ngOnInit(): void {
    
  }

  getPurchaseHistory() {
    this.sidebarService.getPurchaseHistory(this.dateFilterForm.value.byDate).subscribe((res: any) => {
     this.allData = res.data.products;
     })
  }

}
