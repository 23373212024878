<div class="container-fluid">
    <div class="row">
        <div class="col-lg-3 order-lg-2 order-2 px-4">
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Choose Catalog</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                            <select class="form-select form-select my-2" aria-label=".form-select example" (change)="goToFeaturedProducts()">
                                <option selected>HH Catalogue</option>
                                <option value="1">HH Catalogue</option>
                                <option value="2">Demo Catalogue</option>
                                <option value="3">Google Catalogue</option>
                                <option value="4">eBay Catalogue</option>
                              </select>
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Search Catalog</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <label class="mb-2" for="exampleInputEmail1">Catalog</label>
                          <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp" placeholder="">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="searchCatalogueRadio" id="searchCatalogueRadio" checked>
                            <label class="form-check-label" for="searchCatalogueRadio">Any</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="searchCatalogueRadio" id="searchCatalogueRadio">
                            <label class="form-check-label" for="searchCatalogueRadio">All</label>
                          </div>
                        </div>
                        <div class="form-group p-1">
                            <input class="btn btn-outline-secondary" type="submit" value="find">
                        </div>
                        <div class="form-group p-1">
                            <input class="btn btn-outline-secondary" type="submit" value="Advacned Search" (click)="goToAdvancedSearch()">
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Categories</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Quick Recorder</p>
                </div>
                <div class="p-2">
                    <span class="span-link">Tiny Gizmo</span>
                    <ul class="list-unstyled">
                        <li>GZ-1000 <span>$12.00</span></li>
                        <li><span class="span-link">Add To Cart</span></li>
                    </ul>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Sign Up For Contact List</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <p>Sign Up For Contact List LogIn</p>
                          <p><span class="span-link">Login </span>THE ADMINISTRATOR</p>
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Mini Poll</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
                          <select class="form-select form-select my-2" aria-label=".form-select example">
                            <option selected>Right Side</option>
                            <option value="1">Right Side</option>
                            <option value="2">Left Side/option>
                          </select>
                          <p>*</p>
                          <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
                          <select class="form-select form-select my-2" aria-label=".form-select example">
                            <option selected>Right Side</option>
                            <option value="1">Right Side</option>
                            <option value="2">Left Side</option>
                          </select>
                          <p>*</p>
                          <input class="btn btn-outline-secondary" type="submit" value="Vote!">
                        </div>
                    </form>
                </div>
            </div>
           <!--  <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Forums</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Ask the Experts</span><br>
                        <span class="span-link">Ask the Experts</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Widgets</span><br>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Content</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Store Policies 2</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Cookie Policy</span><br>
                        <span class="span-link">Policies</span><br>
                        <span class="span-link">Store Policies</span><br>
                        <span class="span-link">Store Policies 2</span><br>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Policies</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Cookie Policy</span><br>
                        <span class="span-link">Store Policies</span><br>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Blogs</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Big AI</span><br>
                        <span class="span-link">Big AI</span><br>
                        <span class="span-link">Mad Max</span><br>
                        <span class="span-link">Mad Max</span><br>
                        <span class="span-link">xczx</span><br>
                      </form>
                </div>
            </div> -->
        </div>
        <div class="col-lg-9 order-lg-2 order-1 px-4">
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                        <p class="text-left my-3 mx-4">Special Offers</p>
                </div>
                <div class="p-2">
                    <div class="row px-4 py-2">
                        <table class="table table-responsive-sm">
                            <tbody>
                                <tr>
                                  <td class="p-3"><span class="span-link" (click)="goToShowPromotionDetails()">Detail</span></td>
                                   <td class="p-3">Get $500 off any item in the Small Gizmos [101] category, limit 1 per order, 2 per customer, 3 for entire promotion. Discount not to exceed the price of the item.</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9018" class="button">Details</a></td>
                                   <td class="p-3">Spend more than $150 on your favorite gizmos (all products in Gizmo [100] or sub-category) and get a free <a href="/ecommerce/control/product?category_id=20111&amp;product_id=GZ-1006">Open Gizmo</a>!</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9019" class="button">Details</a></td>
                                   <td class="p-3">Spend more than $1000 in any 12 months on our fabulous Widgets and Gizmos and get a 5% discount.</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9020" class="button">Details</a></td>
                                   <td class="p-3">Get 1 Free [WG-1111], between 15:00 - 18:00 at weekdays</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9021" class="button">Details</a></td>
                                   <td class="p-3">With special code [9021] get products in the Featured Products category for their special promotion price.</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9017" class="button">Details</a></td>
                                   <td class="p-3">Buy $50 of your favorite gizmos (all products in Gizmo [100] or sub-category) get a free <a href="/ecommerce/control/product?category_id=20111&amp;product_id=GZ-1006">Open Gizmo</a>! Limit 2 per order.</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9016" class="button">Details</a></td>
                                   <td class="p-3">Get $500 off any item in the Small Gizmos [101] category, limit 1 per order, 2 per customer, 3 for entire promotion. Discount not to exceed the price of the item.</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9017" class="button">Details</a></td>
                                   <td class="p-3">Buy $50 of your favorite gizmos (all products in Gizmo [100] or sub-category) get a free <a href="/ecommerce/control/product?category_id=20111&amp;product_id=GZ-1006">Open Gizmo</a>! Limit 2 per order.</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9018" class="button">Details</a></td>
                                   <td class="p-3">Spend more than $150 on your favorite gizmos (all products in Gizmo [100] or sub-category) and get a free <a href="/ecommerce/control/product?category_id=20111&amp;product_id=GZ-1006">Open Gizmo</a>!</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9019" class="button">Details</a></td>
                                   <td class="p-3">Spend more than $1000 in any 12 months on our fabulous Widgets and Gizmos and get a 5% discount.</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9020" class="button">Details</a></td>
                                   <td class="p-3">Get 1 Free [WG-1111], between 15:00 - 18:00 at weekdays</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9022" class="button">Details</a></td>
                                   <td class="p-3">Free Shipping in Utah</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9021" class="button">Details</a></td>
                                   <td class="p-3">With special code [9021] get products in the Featured Products category for their special promotion price.</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9022" class="button">Details</a></td>
                                   <td class="p-3">Free Shipping in Utah</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9015" class="button">Details</a></td>
                                   <td class="p-3">Buy 4 items for $50 from Purple Gizmo [GZ-5005], Rainbow Gizmo [GZ-1004], Round Gizmo [GZ-2644] or Square Gizmo [GZ-2002] limit 2 per customer</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9015" class="button">Details</a></td>
                                   <td class="p-3">Buy 4 items for $50 from Purple Gizmo [GZ-5005], Rainbow Gizmo [GZ-1004], Round Gizmo [GZ-2644] or Square Gizmo [GZ-2002] limit 2 per customer</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9000" class="button">Details</a></td>
                                   <td class="p-3">Spend more than $100 on your favorite widgets and gizmos and get a free <a href="/ecommerce/control/product?category_id=20111&amp;product_id=WG-1111">Micro Chrome Widget</a>!</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9010" class="button">Details</a></td>
                                   <td class="p-3">20% off any one item, either GZ-1005 (.NIT Gizmo) or GZ-1006 (Open Gizmo) with a limit of 1 per order</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9011" class="button">Details</a></td>
                                   <td class="p-3">10% off entire purchase</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9012" class="button">Details</a></td>
                                   <td class="p-3">$10 off entire purchase of $50 or more with promo code [9000], limit use of code to one per customer, limit for three uses total for the code</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9013" class="button">Details</a></td>
                                   <td class="p-3">Buy 3 Get 2 Free in the Widgets [200] or any sub-category (except the Small Widgets [201] category and sub-categories, but always including the Micro Widgets [20111] category), limit to two per order</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9014" class="button">Details</a></td>
                                   <td class="p-3">Buy 1 Big Gizmo [GZ-8544] or Massive Gizmo [GZ-7000] Get 1 Purple Gizmo [GZ-5005] At 50% off with promo code [9001] (must be associated with Party or Email), no limit</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9000" class="button">Details</a></td>
                                   <td class="p-3">Spend more than $100 on your favorite widgets and gizmos and get a free <a href="/ecommerce/control/product?category_id=20111&amp;product_id=WG-1111">Micro Chrome Widget</a>!</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9010" class="button">Details</a></td>
                                   <td class="p-3">20% off any one item, either GZ-1005 (.NIT Gizmo) or GZ-1006 (Open Gizmo) with a limit of 1 per order</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9011" class="button">Details</a></td>
                                   <td class="p-3">10% off entire purchase</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9012" class="button">Details</a></td>
                                   <td class="p-3">$10 off entire purchase of $50 or more with promo code [9000], limit use of code to one per customer, limit for three uses total for the code</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9013" class="button">Details</a></td>
                                   <td class="p-3">Buy 3 Get 2 Free in the Widgets [200] or any sub-category (except the Small Widgets [201] category and sub-categories, but always including the Micro Widgets [20111] category), limit to two per order</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9014" class="button">Details</a></td>
                                   <td class="p-3">Buy 1 Big Gizmo [GZ-8544] or Massive Gizmo [GZ-7000] Get 1 Purple Gizmo [GZ-5005] At 50% off with promo code [9001] (must be associated with Party or Email), no limit</td>
                                </tr>
                                <tr>
                                  <td class="p-3"><a href="https://34.196.47.126:8443/ecommerce/control/showPromotionDetails?productPromoId=9020" class="button">Details</a></td>
                                   <td class="p-3">Get 1 Free [WG-1111], between 15:00 - 18:00 at weekdays</td>
                                </tr>
                            </tbody>
                            </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

