import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  partyId: string | null;
  orderList: any;
  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  orders:any[]=[];

  constructor(readonly router:Router,private sidebarService:SideBarService,
    private _ActivatedRoute: ActivatedRoute,private form: FormBuilder,
    private toastr: ToastrService,) {
      this.partyId = localStorage.getItem("partyId");
     }

  ngOnInit(): void {
    this.findOrder();
  }
  findOrder() {
    let req={
      billingAccountId: "",
      correspondingPoId: "",
      countryGeoId: "",
      createdBy: "",
      externalId: "",
      filterInventoryProblems: "N",
      filterPOsOpenPastTheirETA: "N",
      filterPOsWithRejectedItems: "N",
      filterPartiallyReceivedPOs: "N",
      gatewayAvsResult: "",
      gatewayScoreResult: "",
      goodIdentificationIdValue: "",
      goodIdentificationTypeId: "",
      hasBackOrders: "",
      hideFields: "Y",
      includeCountry: "Y",
      internalCode: "",
      inventoryItemId: "",
      isViewed: "",
      lookupFlag: "Y",
      maxDate: "",
      minDate: "",
      orderId: "",
      orderName: "",
      orderStatusId: [],
      orderTypeId: "SALES_ORDER",
      orderWebSiteId: "",
      partyId:this.partyId,
      paymentStatusId: "",
      productId: "",
      productStoreId: "",
      roleTypeId: "",
      salesChannelEnumId: "",
      serialNumber: "",
      shipmentMethod: "",
      showAll: "",
      softIdentifier: "",
      userLoginId: "",
    }
    this.sidebarService.findOrder(req).subscribe((res: any) => {
      this.orderList = res.data.listOrders;

    })
  }
 

  goToViewOrder(){
    this.router.navigate(['/view-order']);
  }
}
