<div class="container mt-5">
   
    <div class="row">
        <div class="col-lg-12 px-4">
            <div class="card mx-auto mt-2 mb-4" style="width: 100%;border-radius: 5px;">
                    <div class="card-title" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div class="float-start">
                            <h4 class="text-left my-3 mx-4">1. Where shall we ship it?</h4>
                        </div>
                    </div>
             
                    <div>
                        <div class="card-body"> 
                            <div class="form-group p-2">
                                <label class="py-2" for="shipToCustomerPartyId">Ship to Party:</label>
                                <select name="shipToCustomerPartyId" id="shipToCustomerPartyId" class="form-control p-2">
                                    <option value="">{{this.emailId}}</option>
                                </select> 
                            </div> 
                            <div class="btn-toolbar p-2"> <a class="btn btn-outline-secondary me-2" (click)="toNewAddress()">Add New Address</a> 
                            </div> 
                            <td *ngFor="let valueAddes of address"> 
                                <div class="form-check"> 
                                 <input type="radio" class="form-check-input" name="shipping_contact_mech_id" 
                                 (change)="addContactMech(valueAddes.shippingAddress.contactMechId)" > 
                                 
                                 <label> <b>To:</b>&nbsp;{{valueAddes.shippingAddress.toName}}<br>
                                  Attn {{valueAddes.shippingAddress.attnName}}<br>
                                  {{valueAddes.shippingAddress.address1}},{{valueAddes.shippingAddress.address2}}
                                  <br>{{valueAddes.city}} {{valueAddes.shippingAddress.stateProvinceGeoId}} 
                                  <a class="buttontext" (click)="toAddress(valueAddes)">Update</a> </label> 
                                </div> 
                            </td>
                            <!-- <div class="btn-toolbar p-2"> <a class="btn btn-outline-secondary me-2" (click)="toAddress()">Add New Address</a> 
                            </div>  -->
                            <!-- <div class="p-2"> 
                                <input type="radio" name="shipping_contact_mech_id" class="form-check-input" id="shipping_contact_mech_id" value="11660" onclick="javascript:submitForm(document.checkoutInfoForm, 'SA', null);" checked=""> <label for="shipping_contact_mech_id"> <strong>To:</strong>&nbsp;David J. Warn <strong>Address:</strong>&nbsp;address eed CA AA 12121 USA </label> 
                            </div>  -->
                            <!-- <div class="p-2">
                                <a class="btn btn-secondary">Update</a>
                            </div>  -->
                        </div>
                    </div>
                
            </div>
        </div>
        <div class="col-lg-12 px-4">
            <div class="card mx-auto mt-2 mb-4" style="width: 100%;border-radius: 5px;">
                <div class="card-title"  type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">
                    <div class="float-start">
                        <h4 class="text-left my-3 mx-4">2. How shall we ship it?</h4>
                    </div>
                </div>
                <div  >
                    <div class="card-body pt-2 pb-4 px-4"> 
                        <div class="row" *ngFor="let ship of shipmentMethods"> 
                            <div class="col-lg-4 col-sm-6"> 
                             <div class="form-check"> 
                              <input type="radio" (click)="radioShip(ship.shippingMethodId)" name="shipping_method" class="form-check-input" id="shipping_method_0" value="NEXT_DAY@UPS"> <label for="shipping_method_0"> {{ship.carrierShipmentMethod.partyId}}&nbsp; {{ship.carrierShipmentMethod.description}}-${{ship.shippingEst}}  </label> 
                             </div> 
                            </div> 
                            
                          
                           </div> 
                           <h5>Ship all at once, or 'as available'?</h5> 
                           <div class="form-check"> 
                            <input type="radio" class="form-check-input" (click)="split(false)"  name="may_split" value="false"> <label>Please wait until the entire order is ready before shipping.</label> 
                           </div> 
                           <div class="form-check"> 
                            <input class="form-check-input" (click)="split(true)" type="radio" name="may_split" value="true"> <label>Please ship items I ordered as they become available (you may incur additional shipping charges).</label> 
                            <h5>Special Instructions</h5> 
                            <textarea rows="3" wrap="hard" name="shipping_instructions"
                            [(ngModel)]="this.otherdata.shipping_instructions" [ngModelOptions]="{standalone: true}">
           
                            </textarea> 
                            <div> 
                             <h5>Is This a Gift?</h5> 
                             <div class="form-check form-check-inline"> 
                              <input type="radio" class="form-check-input" (click)="radioGift(true)" name="is_gift" id="is_gift" value="true"> <label class="form-check-label" for="is_gift">Yes</label> 
                             </div> 
                             <div class="form-check form-check-inline"> 
                              <input type="radio" class="form-check-input" (click)="radioGift(false)"  name="is_gift" id="is_not_gift" value="false"> <label class="form-check-label" for="is_not_gift">No</label> 
                             </div> 
                            </div> 
                            <h5>PO Number</h5> <textarea rows="3" wrap="hard" name="correspondingPoId"
                            [(ngModel)]="this.otherdata.correspondingPoId" [ngModelOptions]="{standalone: true}"></textarea> 
                            <h5>Gift Message</h5> <textarea rows="3" wrap="hard" name="gift_message"
                            [(ngModel)]="this.otherdata.gift_message" [ngModelOptions]="{standalone: true}"></textarea> 
                            <h5>Email Addresses</h5> 
                            <div>
                             Your order will be sent to the following email addresses:
                            </div> 
                            <div> <strong> {{this.emailId}} </strong> 
                            </div> 
                            <div>
                             Your may update these in your <a class="buttontext">Profile</a>.
                            </div> 
                            <br> <label for="order_additional_emails">You may add other comma separated email addresses here that will be used only for the current order:</label> 
                            <input type="text" class="form-control" name="order_additional_emails" id="order_additional_emails"
                            [(ngModel)]="this.otherdata.order_additional_emails" [ngModelOptions]="{standalone: true}" > 
                           </div>
                           
                    </div>
                </div>
            
            </div>
        </div>
        <div class="col-lg-12 px-4">
            <div class="card mx-auto mt-2 mb-4" style="width: 100%;border-radius: 5px;">
                <div class="card-title"  type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree" aria-expanded="true" aria-controls="collapsethree">
                    <div class="float-start">
                        <h4 class="text-left my-3 mx-4">3. How shall you pay?</h4>
                    </div>
                </div>
                <div >
                    <div class="card-body pt-2 pb-4 px-4"> 
                        <h5>Add:</h5> 
                        <div class="btn-toolbar form-group mb-3"> 
                          <a class="btn btn-outline-secondary mr-2" (click)="goToAddCreditCard()">Credit Card</a> 
                          <a class="btn btn-outline-secondary mr-2" (click)="goToAddEftAccount()" style="margin-right: 15px;margin-left: 15px">EFT Account</a> 
                          <a class="btn btn-outline-secondary">Split Payment</a> 
                        </div> 
                        <div class="form-check"> 
                         <input type="radio" id="checkOutEXT_OFFLINE" class="form-check-input" (click)="radioPayment('','EXT_OFFLINE','')"
                          name="checkOutPaymentId" value="EXT_OFFLINE"> <label for="checkOutEXT_OFFLINE">{{this.paymentOptions.EXT_OFFLINE}}</label> 
                        </div> 
                        <div class="form-check"> 
                         <input type="radio" id="checkOutEXT_COD" class="form-check-input" (click)="radioPayment('','EXT_COD','')"
                         name="checkOutPaymentId" value="EXT_COD"> <label for="checkOutEXT_COD">{{this.paymentOptions.EXT_COD}}</label> 
                        </div> 
                        <div class="form-check"> 
                         <input type="radio" id="checkOutEXT_WORLDPAY" class="form-check-input" (click)="radioPayment('','EXT_WORLDPAY','')"
                          name="checkOutPaymentId" value="EXT_WORLDPAY"> <label for="checkOutEXT_WORLDPAY">{{this.paymentOptions.EXT_WORLDPAY}}</label> 
                        </div> 
                        <div class="form-check"> 
                         <input type="radio" id="checkOutEXT_PAYPAL" class="form-check-input"  (click)="radioPayment('','EXT_PAYPAL','')"
                         name="checkOutPaymentId" value="EXT_PAYPAL"> <label for="checkOutEXT_PAYPAL">{{this.paymentOptions.EXT_PAYPAL}}</label> 
                        </div> 
                        <div class="form-check" *ngFor="let credit of this.paymentMethodMapList"> 
                         <input (click)="radioPayment(credit.paymentMethod.paymentMethodTypeId,credit.paymentMethod.paymentMethodId,credit.securityCode)"
                          *ngIf="credit.paymentMethod.paymentMethodTypeId=='CREDIT_CARD'||credit.paymentMethod.paymentMethodTypeId=='GIFT_CARD'" type="radio" class="form-check-input" name="checkOutPaymentId">
                         <input  (click)="radioPayment(credit.paymentMethod.paymentMethodTypeId,credit.paymentMethod.paymentMethodId,'')"
                         *ngIf="credit.paymentMethod.paymentMethodTypeId=='EFT_ACCOUNT'" type="radio" class="form-check-input" name="checkOutPaymentId" >
                         <label                   *ngIf="credit.paymentMethod.paymentMethodTypeId=='CREDIT_CARD'">CC:&nbsp;
                           {{credit.crdCard.cardType}}:{{credit.crdCard.expireDate}}
                         </label> 
                         <label                   *ngIf="credit.paymentMethod.paymentMethodTypeId=='GIFT_CARD'">Gift:&nbsp;
                          {{credit.giftCard.cardNumber}}:{{credit.paymentMethod.description}}
                        </label> 
                         <label  *ngIf="credit.paymentMethod.paymentMethodTypeId=='EFT_ACCOUNT'">CC:&nbsp;{{credit.paymentMethod.paymentMethodTypeId}}:{{credit.paymentMethod.paymentMethodId}}<br>{{credit.paymentMethod.description}}
                        </label> 
                        <a class="buttontext">Update</a><span *ngIf="credit.paymentMethod.paymentMethodTypeId=='CREDIT_CARD'||credit.paymentMethod.paymentMethodTypeId=='GIFT_CARD'"> &nbsp;Card Security Code&nbsp;
                        <input type="text" size="5" maxlength="10" name="securityCode"  id="securityCode"
                        [(ngModel)]="credit.securityCode" [ngModelOptions]="{standalone: true}"> 
                      </span>
                        </div> 
                        <div class="form-check"> 
                         <input type="checkbox" class="form-check-input" id="addGiftCard" name="addGiftCard"  #saveUserNameCheckBox
                         [checked]="this.paymentOptions.singleUseGiftCard" 
                         (change)="onSaveUsernameChanged(saveUserNameCheckBox.checked)"> <label for="addGiftCard">Use Gift Card Not On File</label> 
                        </div> 
                        <div class="form-group"> <label for="giftCardNumber">Number</label> 
                         <input type="text" class="form-control"
                         [(ngModel)]="this.paymentOptions.giftCardNumber" [ngModelOptions]="{standalone: true}"
                          name="giftCardNumber" id="giftCardNumber" value="" onfocus="document.checkoutInfoForm.addGiftCard.checked=true;"> 
                        </div> 
                        <div class="form-group"> <label for="giftCardAmount">Amount</label> 
                         <input type="text" class="form-control" name="giftCardAmount"
                         [(ngModel)]="this.paymentOptions.giftCardAmount" [ngModelOptions]="{standalone: true}"
                         
                         id="giftCardAmount" value="" onfocus="document.checkoutInfoForm.addGiftCard.checked=true;"> 
                        </div> 
                    </div>
                    <div class="row" style="padding: 23px 24px;"> 
                        <div class="col-auto me-auto"> <a class="btn btn-secondary" (click)="goToViewCart()">Back to Shopping Cart</a> 
                        </div> 
                        <div class="col-auto"> <a class="btn btn-primary" (click)="goToFinalCheckOutReview(this.paymentOptions)">Continue to Final Order Review</a> 
                        </div> 
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</div>

