import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SideBarService } from '../side-bar/side-bar.service';
import { TreeNode } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-configure-product',
  templateUrl: './configure-product.component.html',
  styleUrls: ['./configure-product.component.css']
})
export class ConfigureProductComponent implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  catalog: any;
  catalogArray: any[]=[];
  locale: any;
  localeArray: any[]=[];
  id: any;
  selectedFiles: any;
  category: TreeNode[] = [];
  productMap: any;
  productId: any;
  configQuestions: any;
  findForm:FormGroup;
  addToCart:FormGroup;
  private finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  MainFeaturedProduct: any;
  CatalogForm!: FormGroup;
  catalogId: string | null;
  configQuestions1: any;
  configQuestions2: any;
  configQuestions3: any;
  configQuestions4: any;
  configQuestions5: any;
  verifiedArray: any[]=[];
  tagCloudList: any;
  item0: any;
  itemB: any;
  itemA: any;
  itemC: any;
  itemD: any;
  itemE: any;
  configQuestions6: any;
  configQuestions7: any;
  configQuestions8: any;
  configQuestions9: any;
  itemF: any;
  itemG: any;
  itemH: any;
  itemI: any;
  verifiedArrayDataCheckbox: any;
  totalPrice: any;
  show=false;
  showCart: any;
  compareProductList: any;
  summary: any;
  specialOffers: any;
  constructor(readonly router:Router,private sidebarService:SideBarService,
    private _ActivatedRoute: ActivatedRoute,private form: FormBuilder,
    private toastr: ToastrService,) { 
     // localStorage.setItem('catalog','DemoCatalog')
      this.catalogId = localStorage.getItem("catalog");
      this.CatalogForm = this.form.group({

        catalogId: [''],
       
      });
      this.findForm = this.form.group({
        findCatalog:[''],
        radioAll:['']
      });
      this.addToCart = this.form.group({
        quantity: ['1'],
        
      });
    }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.productId = params["productId"];
     
    });
    this.getCatalogList();
    this.getLocale();
    this. getConfigureList();
    this. getCategoryById();
    setTimeout(() => {
      const formValue = this.CatalogForm;
    formValue.patchValue({
      catalogId:this.catalogId,
     
     
    })
    }, 2000);
this.getProductTags();
this.getCompareProducts();
this.getshowCart();
  }
  removeAddToCompare(id:any): void {

    this.sidebarService.removeAddToCompare(id)
      .then(data => {
        if (data.success) {
          this.toastr.success(data.data.successMessage);
        this.getCompareProducts();
        }
      });
  
  }
  
  clearCompare() {
     
  
    this.sidebarService.clearCompare().subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.getCompareProducts();
  
      }
    }, (err) => {
   
      this.toastr.error(err.message);
  
    });
   
  }
  getProductTags() {
    this.sidebarService.getProductTags().subscribe((res: any) => {
      this.tagCloudList = res.data.tagCloudList;

    })
  }
  getCompareProducts() {
    this.sidebarService.getCompareProducts(this.catalogId).subscribe((res: any) => {
      this.compareProductList = res.data.productList;

    })
  } 
  getCategoryById() {
    this.sidebarService.getCategoryByCatalog(this.catalogId).subscribe((res: any) => {
      this.category = res.data;

    })
  }
  getConfigureList() {
    
    this.sidebarService.getConfigureList(this.productId).subscribe((res: any) => {
      this.productMap = res.data.productMap;

      this.configQuestions=res.data.configQuestions[0];
      this.configQuestions1=res.data.configQuestions[1];
      this.configQuestions2=res.data.configQuestions[2];
      this.configQuestions3=res.data.configQuestions[3];
      this.configQuestions4=res.data.configQuestions[4];
      this.configQuestions5=res.data.configQuestions[5];
      this.configQuestions6=res.data.configQuestions[6];
      this.configQuestions7=res.data.configQuestions[7];
      this.configQuestions8=res.data.configQuestions[8];
      this.configQuestions9=res.data.configQuestions[9];
    
   
    })

  }
  selectRadio(i:any,value:any,check:any){
   var item:any={}
    item [i] = value;
    
   // this.configDetailsEvent();
   var exists = this.verifiedArray.filter(function (o) {
    return o.hasOwnProperty(i);
  }).length > 0;
  
  if (exists) {

  
   for (let [index, val] of this.verifiedArray.entries()) {
   if (val.hasOwnProperty(i)) {
    this.verifiedArray.splice(index, 1);

    console.log(index);
    
  }}
  if(check!="Check"){
  this.verifiedArray.push(item);
  }
    
  } else {
    this.verifiedArray.push(item);
  }
  this.verifiedArrayDataCheckbox= this.verifiedArray.filter(function (e) {return e != null;});
  this.configDetailsEvent();
  }
 
   
  onSubmit() {
  
    const req = {
      "productId": this.productId,
      "questions":this.verifiedArrayDataCheckbox
      
    }
    this.sidebarService.verifyConfig(req).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Verified Successfully");
       // this.verifiedArray=[];

      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })
  }
  
 
  addToCartConfig() {
    const req = {
      "productId": this.productId,
      "questions":this.verifiedArrayDataCheckbox,
      "quantity": this.addToCart.value.quantity,
      
    }
    this.sidebarService.addToCartConfig(req).subscribe((res: any) => {
      if (res.success) {
      
        this.toastr.success("Created Successfully");
   
        this.getshowCart()
      //  this.verifiedArray=[];

      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })
  }
  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
      this.showCart = res.data.productsList;
      this.specialOffers = res.data.specialOffers;
      this.summary = res.data.summary;

    })
  }
  configDetailsEvent() {
   
  
    const req = {
      "productId": this.productId,
      "questions":this.verifiedArrayDataCheckbox,
      "quantity": this.addToCart.value.quantity,
      
    }
    this.sidebarService.configDetailsEvent(req,this.productId).subscribe((res: any) => {
      if (res.success) {
        this.show=true;
        this.totalPrice=res.data.totalPrice;
       // this.toastr.success("Created Successfully");
      //  this.verifiedArray=[];

      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })
  }
  goToFindCatalog(){
    this.router.navigate(["/find-catalog"],{queryParams:{findCatalog:this.findForm.value.findCatalog,type:this.findForm.value.radioAll}});
  }
  goToAdvancedSearch(){
    this.router.navigate(["/advanced-search"]);
  }
  goToFeaturedProducts(){
    this.router.navigate(["/"]);
  }
  goToConfigureProduct(){
    this.router.navigate(["/configure-product"]);
  }
  goToChooseProductAmount(){
    this.router.navigate(["/choose-product-amount"]);
  }
  goToChooseVariation(){
    this.router.navigate(["/choose-variation"]);
  }
  goToViewAllPromotions(){
    this.router.navigate(["/view-all-promotions"]);
  }
  goToShowPromotionDetails(){
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewCart(){
    this.router.navigate(["/view-cart"]);
  }
  goToQuickCheckOut(){
    this.router.navigate(["/quick-check-out"]);
  }
  goToCheckOut(){
    this.router.navigate(["/check-out-option1"]);
  }

  goToOnePageCheckOut(){
    this.router.navigate(["/one-page-check-out"]);
  }
  getCatalogList() {
    //this.spinner.show();
    this.sidebarService.getCatalogList().subscribe((res: any) => {
      this.catalog = res.data;
      //this.spinner.hide();
      for (const value of this.catalog) {
        this.catalogArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })
  
  }

  getLocale() {
    //this.spinner.show();
    this.sidebarService.getLocale().subscribe((res: any) => {
      this.locale = res.data;
      //this.spinner.hide();
      for (const value of this.locale) {
        this.localeArray.push({
          label: value.value,
          value: value.key
        })
      }
    })
  
  }

  getCategoryByCatalog(event:any){
    this.id=event.value;
    this.sidebarService.getCategoryByCatalog(this.id).subscribe((res: any) => {
      this.category = res.data;

    })
  }
}

