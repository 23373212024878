<div class="container-fluid">
    <div id="ecom-mainarea" class="row mx-2 mt-2">
        <div id="div3" class="col-lg-12 order-lg-2 order-1">
            <ng-container *ngIf="show">
                <h2>Update Address</h2>
            </ng-container>
            <ng-container *ngIf="!show">
                <h2>Add New Address</h2>
            </ng-container>
            <form>
                <a class="btn btn-outline-secondary me-2" (click)="goToProfile()">Go Back</a>
                <ng-container *ngIf="show">
                    <a class="btn btn-outline-secondary" (click)="updateAddress()">Update</a>
                </ng-container>
                <ng-container *ngIf="!show">
                    <a class="btn btn-outline-secondary" (click)="onSubmit()">Save</a>
                </ng-container>
                <form [formGroup]="addressForm">
                    <div class="my-2">
                        <label class="required">Attn Name<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control mb-2" maxlength="60" name="attnName"
                                     placeholder="Enter attn name" value=""
                                    formControlName="attnName">
                            </div>
                        </div>
                        <label class="required">To Name<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control mb-2" maxlength="60" name="toName"
                                     placeholder="Enter to name" value=""
                                    formControlName="toName">
                            </div>
                        </div>
                        <label>Allow Solicitation</label>
                        <div class="row">
                            <div class="col-sm-6 mb-2">
                                <p-dropdown [options]="solicitationArray" formControlName="allowSolicitation">
                                </p-dropdown>
                            </div>
                        </div>
                        <label class="required">Address Line 1<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control mb-2" maxlength="60" name="address1"
                                    [(ngModel)]="address1" placeholder="Enter address line 1" value=""
                                    formControlName="address1">
                            </div>
                        </div>
                        <label class="required">Address Line 2<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control mb-2" maxlength="60" name="address2"
                                    [(ngModel)]="address2" placeholder="Enter address line 2" value=""
                                    formControlName="address2">
                            </div>
                        </div>
                        <label class="required">City<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control mb-2" maxlength="60" name="city"
                                    [(ngModel)]="city" placeholder="Enter city" value=""
                                    formControlName="city">
                            </div>
                        </div>
                        <label class="required">Country<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <p-dropdown [options]="countryArray" formControlName="countryGeoId">
                                </p-dropdown>
                            </div>
                        </div>
                        <label class="required">State<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <p-dropdown [options]="stateArray" placeholder="State"
                                formControlName="stateProvinceGeoId">
                                </p-dropdown>
                            </div>
                        </div>
                        <label class="required">Pin Code</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control mb-2" maxlength="60" name="postalCode"
                                  [(ngModel)]="postalCode"  placeholder="Enter pin code" value="" formControlName="postalCode">
                            </div>
                        </div>
                        <label>Mech Purpose Type Id<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <p-dropdown [options]="contactMechTypeId" formControlName="contactMechPurposeTypeId">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </form>
            </form>
            <a class="btn btn-outline-secondary me-2" (click)="goToProfile()">Go Back</a>
            <ng-container *ngIf="show">
                <a class="btn btn-outline-secondary" (click)="onUpdate()">Update</a>
            </ng-container>
            <ng-container *ngIf="!show">
                <a class="btn btn-outline-secondary" (click)="onSubmit()">Save</a>
            </ng-container>
        </div>
        <div id="div4" class="endcolumns">
        </div>
    </div>
</div>
