import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../services/services.service';

@Component({
  selector: 'app-add-eft-account',
  templateUrl: './add-eft-account.component.html',
  styleUrls: ['./add-eft-account.component.css']
})
export class AddEftAccountComponent implements OnInit {

  partyId: any;
  eftAccountForm: FormGroup;
  editMode: boolean;
  accountData: any;
  contactMechId: string;
  isEftAccount: boolean;
  eftAccount: any;
  paymentMethodId!: string;
  show!: boolean;
  accountTypes = [
    {
      label: 'Checking',
      value: 'Checking'
    },
    {
      label: 'Savings',
      value: 'Savings'
    }];
  constructor(
    private _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    private _CRMAccountService: ServicesService,
    private _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  ) {
    this.eftAccountForm = this._FormBuilder.group({
      accountNumber: ['', [Validators.required]],
      accountType: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      companyNameOnAccount: [''],
      contactMechId: [''],
      description: [''],
      nameOnAccount: ['', [Validators.required]],
      routingNumber: ['', [Validators.required]]
    });
    this.editMode = false;
    this.contactMechId = '';
    this.isEftAccount = false;
  }
  ngOnInit(): void {
    this.show=false;
    this.partyId = localStorage.getItem('partyId');
    this.isEftAccount = this._ActivatedRoute.snapshot.queryParams.isEft === 'false' ? false : true;
    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
    this._ActivatedRoute.queryParams.subscribe(params => {
      console.log(params);
      if(params["paymentMethodId"]){
        this.show=true;
      }
    });
    this.getAccountDetails();
    if (this.paymentMethodId) {
      this.getPaymentMethod();
    }
  }
  getPaymentMethod(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'EFT_ACCOUNT')
      .then((data: { success: any; data: { eftAccountData: { [x: string]: any; postalAddressList?: any; }; paymentMethodData: { description: any; }; }; }) => {
        if (data.success) {
          this.spinner.hide();
          this.eftAccount = data.data.eftAccountData;
          this.contactMechId = data.data.eftAccountData.postalAddressList[0].contactMech.contactMechId;
          this.eftAccountForm.patchValue({
            ...data.data.eftAccountData, ...{
              description: data.data.paymentMethodData.description
            }
          });
          this.editMode = true;

        }
      });

  }
  getAccountDetails(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.partyId)
      .then((data: { success: any; data: any; }) => {
        if (data.success) {
          this.spinner.hide();
          this.accountData = data.data;
        }
      });

  }
  getAddress(event: any, contactMechId: string): void {
    this.contactMechId = (event.target.checked) ? contactMechId : '';
  }
  submit(): void {
    this.spinner.show();
    
      this._CRMAccountService.postEftAccount(this.partyId, {
        ...this.eftAccountForm.value, ...{
          contactMechId: this.contactMechId
        }
      }).subscribe((res: any) => {
          if (res.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.cancelSubmit();
          }
          }, (err) => {
         
            //this.spinner.hide();
            for (const value of err.error.errors) {
              this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
            }
        });
    
  }
  update(): void {
    this.spinner.show();
    
      this._CRMAccountService.updateEftAccount(this.partyId, this.paymentMethodId, {
        ...this.eftAccount, ...this.eftAccountForm.value, contactMechId: this.contactMechId
      })
      .subscribe((res: any) => {
        if (res.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.cancelSubmit();
        }
        }, (err) => {
        
          //this.spinner.hide();
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
          }
      });
    

  }
  cancelSubmit(): void {
    this.eftAccountForm.reset();
    this._Router.navigate(['/profile/payment-method'], { queryParams: { partyId: this.partyId } });
  }

  goToQuickCheckOut() {
    this._Router.navigate(["/quick-check-out"]);
  }
}
