import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  getCustomerProfile: any;
  getMessageList: any;
  show: boolean;
  constructor(readonly router:Router,private sidebarService:SideBarService,
    private _ActivatedRoute: ActivatedRoute,private form: FormBuilder,
    private toastr: ToastrService,
    ) { this.show = true}

  ngOnInit(): void {
    this.customerProfile();
    this.show = true;
  }
  customerProfile() {
    this.sidebarService.customerProfile().subscribe((res: any) => {
      this.getMessageList = res.data.messagesList;

    })
  }
 }
