<div class="container-fluid">
    <div class="row">
        <div id="div3" class="col-lg-9 mt-2">

            <div>
                <span class="buttontextdisabled">Create Order</span>
            </div>
            <br>
            <div class="lefthalf">
                <div class="screenlet">
                    <div class="screenlet-title-bar">
                        <div class="h3" *ngIf="this.quotesList">Quote ID&nbsp;{{this.quotesList.quoteId}}&nbsp;Information</div>
                    </div>
                    <div class="screenlet-body">
                        <table cellspacing="0" class="basic-table">
                            <tbody *ngIf="this.quotesList" >
                                <tr>
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Type
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        {{quotesList.quoteTypeId}}
                                    </td>
                                </tr>
                                
        
                                <tr>
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Sales Channel
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        {{quotesList.salesChannelEnumId}}
                                    </td>
                                </tr>
                                
        
                                <tr>
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Status
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        {{quotesList.statusId}}
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Party
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        {{quotesList.partyId}}
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Quote Name
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        {{quotesList.quoteName}}
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Description
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        {{quotesList.description}}
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Currency
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        {{quotesList.currencyUomId}}
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Product Store
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        {{this.quotesList.productStoreId}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="righthalf">
                <div class="screenlet">
                    <div class="screenlet-title-bar">
                        <div class="h3">Date</div>
                    </div>
                    <div class="screenlet-body">
                        <table cellspacing="0" class="basic-table">
                            <tbody *ngIf="this.quoteData">
                                <tr >
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Quote Issue Date
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        {{this.quoteData.issueDate}}
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Valid From Date
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        {{this.quoteData.validFromDate}}
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Valid Through Date
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        {{this.quoteData.validThruDate}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="screenlet">
                    <div class="screenlet-title-bar">
                        <div class="h3">Quote Roles</div>
                    </div>
                    <div class="screenlet-body">
                        <table cellspacing="0" class="basic-table">
                            <tbody>
                                <tr>
                                    <td align="right" valign="top" width="15%" class="label">
                                        &nbsp;Internal Organization
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                    <td valign="top" width="80%">
                                        Your Company Name Here
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="screenlet" id="screenlet_1">
                    <div class="screenlet-title-bar">
                        <ul>
                            <li class="h3">Quote Notes</li>
        
                        </ul><br class="clear">
                    </div>
                    <div id="screenlet_1_col" class="screenlet-body">
                        <table cellspacing="0" class="basic-table hover-bar">
                            <thead>
                                <tr class="header-row">
                                    <td>
                                        <label for="ListQuoteNotes_noteName" id="ListQuoteNotes_noteName_title">Note
                                            Name</label>
                                    </td>
                                    <td>
                                        <label for="ListQuoteNotes_noteInfo" id="ListQuoteNotes_noteInfo_title">Note
                                            Info</label>
                                    </td>
                                    <td>
                                        <label for="ListQuoteNotes_noteDateTime" id="ListQuoteNotes_noteDateTime_title">Note
                                            Date Time</label>
                                    </td>
                                    <td>
                                        <label for="ListQuoteNotes_noteParty" id="ListQuoteNotes_noteParty_title">Note
                                            Party</label>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
        
                                        string
                                    </td>
                                    <td>
        
                                        TEST
                                    </td>
                                    <td>
        
                                        2020-11-09 16:59:02.317
                                    </td>
                                    <td>
        
                                        admin
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        
            <div class="clear">
            </div>
        </div>
        <div class="col-lg-3">
            <app-right-side-bar></app-right-side-bar>
        </div>
    </div>
</div>