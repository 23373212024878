
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { LoaderService } from './loader.service';


@Injectable()
export class AppInterceptor implements HttpInterceptor {
  baseUrl;

  constructor(
    private router: Router,public loaderService: LoaderService
  ) {
    this.baseUrl = environment.host_api_url;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

   
    req = req.clone({ url: this.baseUrl + req.url });
    let ok: string;
    const token = localStorage.getItem('token');
   
    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer' + ' ' + token)
      
       
      });
    }
    const catalogId = localStorage.getItem("catalog");
    req = req.clone({
      headers: req.headers.set('currentCatalogId', '' + ' ' + catalogId)
    
     
    });
    const language = localStorage.getItem("language");
    req = req.clone({
      headers: req.headers.set('locale', '' + ' ' + language)
    
     
    });
    this.loaderService.show();
    req = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin', '*') });
   
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
         
          if (event instanceof HttpResponse) {
            this.loaderService.hide();
          }
         
        },
        error => {
          if (error instanceof HttpErrorResponse && (error.status === 401)) {
           
            return;
          } else if (error.status === 401) {
           
          }
        }
      )
      
    );
  }
}
