<div class="container-fluid">
    <div id="ecom-mainarea" class="row mx-2 mt-2">
        <div id="div3" class="col-lg-12 order-lg-2 order-1">
            <h2>Upload New File</h2>
            <form name="uploadNewFile" [formGroup]="Editform">
                <a class="btn btn-outline-secondary me-2" (click)="goToProfile()">Go Back</a>
                <a class="btn btn-outline-secondary" (click)="onSubmit()">Upload</a>
                <div>
                    <label class="mt-2 d-block">Choose File</label>
                    <div class="row my-2">
                        <div class="col-sm-6">
                            <input type="file"   class="form-control" id="exampleInputEmail1" formControlName="file"
                            aria-describedby="emailHelp" placeholder="" (change)="fileProgress($event)"
                            accept="">
                           
                        </div>
                    </div>
                    <label>Select Purpose</label>
                    <div class="row my-2">
                        <div class="col-sm-6">
                            <p-dropdown  formControlName="partyContentTypeId"
                                      [(ngModel)]="partyContentTypeId" name="partyContentTypeId" placeholder="Enter Party Content"
                                      (ngModelChange)="onpartyContent(partyContentTypeId)" [options]="partyContentArray"
                                      optionlabel="label"></p-dropdown>
                        </div>
                    </div>
                    <label>Select Mime Type</label>
                    <div class="row my-2">
                        <div class="col-sm-6">
                            <p-dropdown [options]="purposeArray" placeholder="Select mime type">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </form>
            <a class="btn btn-outline-secondary me-2" (click)="goToProfile()">Go Back</a>
            <a class="btn btn-outline-secondary" (click)="onSubmit()">Upload</a>
        </div>
        <div id="div4" class="endcolumns">
        </div>
    </div>
</div>