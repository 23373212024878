import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RandomService } from '../random.service';
import { SideBarService } from '../side-bar/side-bar.service';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-view-cart',
  templateUrl: './view-cart.component.html',
  styleUrls: ['./view-cart.component.css']
})
export class ViewCartComponent implements OnInit {
  showCart: any;
  specialOffers: any;
  summary: any;

  catalog: any;
  catalogArray: any[] = [];
  locale: any;
  localeArray: any[] = [];
  id: any;
  selectedFiles: any;
  category: TreeNode[] = [];
  language: any;
  findForm:FormGroup;
  CatalogForm:FormGroup;
  catalogId: string | null;
  value:any;
  addtoCartForm: FormGroup;
  cartItems: any;
  allDATA: any;
  showCART: any;
  specialOFFERS: any;
  Summary: any;
  shippingMethodList: any;
  address: any;

  constructor(
    readonly router: Router, 
    private sidebarService: SideBarService,
    private toastr: ToastrService,
    readonly Form:FormBuilder
    ) {
      this.catalogId = localStorage.getItem("catalog");
      this.CatalogForm = this.Form.group({
  
        catalogId: [''],
       
      });
      this.findForm = this.Form.group({
        findCatalog:[''],
        radioAll:['']
      });

      this.addtoCartForm = this.Form.group({
        productNumber:[''],
        quantity:['']
      });

      


  }

  ngOnInit(): void {
    // this.getquickCheckout();
    this.getCatalogList();
    // this.getCategoryByCat();
    this.getshowCart();
  }
  getquickCheckout() {
    this.sidebarService.getquickCheckout().subscribe((res: any) => {
      this.shippingMethodList=res.data.shippingMethodList;
     //this.quotesList = res.data;
     this.address=res.data.address;
    })
  }
  getCategoryByCat(){
   
    localStorage.setItem('catalogNew',(this.id));
    this.value=localStorage.getItem("catalogNew");
    this.sidebarService.getCategoryByCatalog(this.value).subscribe((res: any) => {
      this.category = res.data;
     // this.router.navigate(["/"]);
    })
  }
  getCategoryByCatalog(event:any){
    this.id=event.value;
    localStorage.setItem('catalogNew1',(this.id));
    this.value=localStorage.getItem("catalogNew1");
    this.sidebarService.getCategoryByCatalog(this.value).subscribe((res: any) => {
      this.category = res.data;
      this.router.navigate(["/"]);
    })
  }
  getCatalogList() {
    //this.spinner.show();
    this.sidebarService.getCatalogList().subscribe((res: any) => {
      this.catalog = res.data;
      //this.spinner.hide();
      for (const value of this.catalog) {
        this.catalogArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })

  }
  goToAdvancedSearch() {
    this.router.navigate(["/advanced-search"]);
  }
  goToFindCatalog(){
    this.router.navigate(["/find-catalog"],{queryParams:{findCatalog:this.findForm.value.findCatalog,type:this.findForm.value.radioAll}});
  }
  

  // getshowCart() {
  //   this.sidebarService.getshowCart().subscribe((res: any) => {
  //     if(res.success == true){
  //       this.showCart = res.data.productsList;
  //       this.specialOffers = res.data.specialOffers;
  //       this.summary = res.data.summary;
  //     }
  //   })
  // }
  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
        this.showCart = res.data.productsList;
        this.specialOffers = res.data.specialOffers;
        this.summary = res.data.summary;
    })
  }


  addItemToCart(): void {
    const form ={
      "productId": this.addtoCartForm.value.productNumber,
      "quantity": this.addtoCartForm.value.quantity,
}    
      this.sidebarService.addItemToCart(form,this.addtoCartForm.value.productNumber).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.addtoCartForm.reset();
        this.allDATA=res.data;
        this.showCART = res.data.cartSummary.productsList;
        this.specialOFFERS = res.data.specialOffers;
        this.Summary = res.data.cartSummary.summary;
        this.getshowCart();
      }
    }, (err) => {
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }



  onClearOrder() {
  
    this.sidebarService.clearOrder().subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
       
      }
    }, (err) => {
   
      this.toastr.error(err.message);

    });
   
  }
  goToFeaturedProducts(){
    this.router.navigate(["/"]);
  }
  goToCheckOutOption1(){
    this.router.navigate(["/check-out-option1"]);
  }
  goToQuickAdd(){
    this.router.navigate(["/quick-add"]);
  }

  goToConfigureProduct(id: any) {
    this.router.navigate(["/configure-product"], { queryParams: { productId: id } })
  }
}
