<div class="container-fluid">
    <div id="ecom-mainarea" class="row mx-2 mt-2">
        <div id="div3" class="col-lg-12 order-lg-2 order-1">
            <ng-container *ngIf="show">
                <h2>Update Address</h2>
            </ng-container>
            <ng-container *ngIf="!show">
                <h2>Add New Address</h2>
            </ng-container>
            <form>
                <a class="btn btn-outline-secondary me-2" (click)="goToProfile()">Go Back</a>
                <ng-container *ngIf="show">
                    <a class="btn btn-outline-secondary" (click)="onUpdate()">Update</a>
                </ng-container>
                <ng-container *ngIf="!show">
                    <a class="btn btn-outline-secondary" (click)="onSubmit()">Save</a>
                </ng-container>
                <form [formGroup]="emailForm">
                    <div class="my-2">
                        <label class="required">Email Address<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control mb-2" maxlength="60" name="emailAddress"
                                     placeholder="Enter email address" [(ngModel)]="email" value="" formControlName="emailAddress">
                            </div>
                        </div>
                        <label>Allow Solicitation</label>
                        <div class="row">
                            <div class="col-sm-6 mb-2">
                                <p-dropdown [options]="solicitationArray" formControlName="allowSolicitation">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </form>
            </form>
            <a class="btn btn-outline-secondary me-2" (click)="goToProfile()">Go Back</a>
            <ng-container *ngIf="show">
                <a class="btn btn-outline-secondary" (click)="onUpdate()">Update</a>
            </ng-container>
            <ng-container *ngIf="!show">
                <a class="btn btn-outline-secondary" (click)="onSubmit()">Save</a>
            </ng-container>
        </div>
        <div id="div4" class="endcolumns">
        </div>
    </div>
</div>

