import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from '../services/services.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';


@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit {

  partyId: any;
  total = 0;
  pageNo = 1;
  rows = 50;
  pageSize = 100;
  rowNumber = 0;
  search = "";
  paymentMethods: any[] = [];
  billingAccounts: any[] = [];
  constructor(readonly router: Router, private _CRMAccountService: ServicesService,
    private toastr: ToastrService,private sidebarService:SideBarService,
    readonly spinner: NgxSpinnerService) {
    this.paymentMethods = [];
    this.billingAccounts = [];
    this.partyId = localStorage.getItem('partyId');
  }

  ngOnInit(): void {
    this.getPaymentMethods();
  }

  getBillingAccounts(): void {
    this.spinner.show();
    this._CRMAccountService.getBillingAccounts(this.partyId)
      .then(data => {
        if (data.success) {
          this.billingAccounts = data.data.map((value: any) => {
            return {
              ...value, ...{ type: 'BILLING', paymentMethodTypeId: 'BILLING' }
            };
          });
          this.paymentMethods = [...this.paymentMethods, ...this.billingAccounts];
        }
        this.spinner.hide();
      });

  }

  getPaymentMethods(): void {
    this.spinner.show();
    this._CRMAccountService.getAllPayment(this.partyId, false)
      .then(data => {
        if (data.success) {
          this.paymentMethods = data.data.map((value: { paymentMethod: { paymentMethodTypeId: any; }; giftCard: any; eftAccount: any; certifiedCheckAccount: any; creditCard: any; companyCheckAccount: any; personalCheckAccount: any; }) => {
            switch (value.paymentMethod.paymentMethodTypeId) {
              case 'GIFT_CARD':
                return {
                  ...value.giftCard, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Gift Card'
                  }
                };
                break;
              case 'EFT_ACCOUNT':
                return {
                  ...value.eftAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'EFT Account'
                  }
                };
                break;
              case 'CERTIFIED_CHECK':
                return {
                  ...value.certifiedCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Certified Check'
                  }
                };
                break;
              case 'CREDIT_CARD':
                return {
                  ...value.creditCard, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Credit Card'
                  }
                };
                break;
              case 'COMPANY_CHECK':
                return {
                  ...value.companyCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Company Check'
                  }
                }
                break;
              case 'PERSONAL_CHECK':
                return {
                  ...value.personalCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Personal Check'
                  }
                };
            }
          });
          this.getBillingAccounts();
          this.spinner.hide();
        }
      });

  }

  removePaymentMethod(type: string, paymentMethodId: string): void {
    if (type === 'BILLING') {
      this._CRMAccountService.removeBillingAccount(this.partyId, paymentMethodId)
        .then(data => {
          if (data.success) {
            this.getPaymentMethods();
          }

        });
    }
    else {
      this._CRMAccountService.removeGiftCard(paymentMethodId)
        .then(data => {
          if (data.success) {
            this.getPaymentMethods();
          }
        });
    }
  }
  updatePaymentMethod(product: any) {
    if (product.paymentMethodTypeId == "CREDIT_CARD") {
      this.router.navigate(["/add-credit-card"],
        {
          queryParams: {
            cardNumber: product.cardNumber, cardType: product.cardType,
            firstNameOnCard: product.firstNameOnCard, lastNameOnCard: product.lastNameOnCard,
            paymentMethodId: product.paymentMethodId
          }
        });
    }
    if (product.paymentMethodTypeId == "EFT_ACCOUNT") {
      this.router.navigate(["/add-eft-account"],
        {
          queryParams: {
            cardNumber: product.cardNumber, cardType: product.cardType,
            firstNameOnCard: product.firstNameOnCard, lastNameOnCard: product.lastNameOnCard,
            paymentMethodId: product.paymentMethodId
          }
        });
    }
    if (product.paymentMethodTypeId == "GIFT_CARD") {
      this.router.navigate(["/add-gift-card"],
        {
          queryParams: {
            cardNumber: product.cardNumber, pinNumber: product.pinNumber,
            paymentMethodId: product.paymentMethodId
          }
        });
    }
  }
  
  paymentDefault(id:any,storeid:any) {
   
    this.sidebarService.paymentDefault(id,storeid).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Default Set Successfully");
        this.getPaymentMethods();
      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })
  }
  goToAddCreditCard() {
    this.router.navigate(["/add-credit-card"]);
  }
  goToAddGiftCard() { 
    this.router.navigate(["/add-gift-card"]);
  }
  goToAddEftAccount() {
    this.router.navigate(["/add-eft-account"]);
  }
}
