import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-quick-check-out',
  templateUrl: './quick-check-out.component.html',
  styleUrls: ['./quick-check-out.component.css']
})
export class QuickCheckOutComponent implements OnInit {
  shippingMethodList: any;
  emailId: string | null;
  catalogId: string | null;
  address: any;
  contactMechId: any;
  dataPartyId: any;
  id: any;
  shipmentMethods: any;
  otherdata: any;
  partyId: any;
  mechId: any;
  exempt: any;
  selecttaxAuthPartyGeoIds: any;
  shipmentId: any;
  splitValue: any;
  radioValueGift: any;
  paymentOptions: any;
  paymentMethodMapList: any;
  amountValue: any;
  checkOutPaymentId: any;
  sCode: any;
  giftCard: any;

  constructor(
    readonly router:Router, 
    private sidebarService: SideBarService,
    private activeRoute:ActivatedRoute,
    )
   { 
    this.emailId = localStorage.getItem("email");
    this.catalogId = localStorage.getItem("catalog");
   }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.mechId = params["mechId"];
      this.exempt = params["exempt"];
      this.selecttaxAuthPartyGeoIds=params["selecttaxAuthPartyGeoIds"];
    });
    this.getquickCheckout();
    this.checkoutOptions();
    this.checkoutOptions2();

  }
  radioShip(id:any){
    this.shipmentId=id;
  }
  split(value:any){
    this.splitValue=value;
  }
  radioGift(value:any){
    this.radioValueGift=value;
  }
  radioPayment(type:any,id:any,scode:any){
    this.amountValue ="amount_" + id;
     this.checkOutPaymentId=id,
     this.sCode=scode
 }
 public onSaveUsernameChanged(value:any){
  if (value == true) {
    this.giftCard = 'Y'
  }
  else {
    this.giftCard = 'N'
  }
}
  checkoutOptions() {
    this.dataPartyId=localStorage.getItem("partyId");
    this.id="shippingaddress";
    let req={
      "shipping_contact_mech_id": this.mechId,
      "taxAuthPartyGeoIds":this.selecttaxAuthPartyGeoIds,
      "partyTaxId": this.dataPartyId,
      "isExempt":  this.exempt
    }
    this.sidebarService.checkoutOptions(this.id,req).subscribe((res: any) => {
     this.shipmentMethods = res.data.shipmentMethods;
     this.otherdata=res.data;
    })
  }
  checkoutOptions2() {
    this.id = "shippingoptions";
    let req = {

      "shipping_method": this.shipmentId,
      "may_split": this.radioValueGift,
      "shipping_instructions":  "",
      "correspondingPoId":  "",
      "is_gift": this.radioValueGift,
      "gift_message":  "",
      "order_additional_emails": "",
      "internalCode": "",
      "shipBeforeDate": "",
      "shipAfterDate": ""
    }
    this.sidebarService.checkoutOptions(this.id, req).subscribe((res: any) => {
      this.paymentOptions = res.data.paymentOptions;
      this.paymentMethodMapList=res.data.paymentMethodMapList
      //this.otherdata = res.data;
    })
  }
  goToFinalCheckOutReview(product:any) {
    this.id = "shippingoptions";
    let req = {

      "shipping_method": this.shipmentId,
      "may_split": this.radioValueGift,
      "shipping_instructions":  "",
      "correspondingPoId":  "",
      "is_gift": this.radioValueGift,
      "gift_message":  "",
      "order_additional_emails": "",
      "internalCode": "",
      "shipBeforeDate": "",
      "shipAfterDate": ""
    }
    this.sidebarService.checkoutOptions(this.id, req).subscribe((res: any) => {
      this.paymentOptions = res.data.paymentOptions;
      this.paymentMethodMapList=res.data.paymentMethodMapList;
      this.router.navigate(["/final-check-out-review"],{ queryParams: {giftCard:this.giftCard, giftCardAmount:product.giftCardAmount,giftCardNumber:product.giftCardNumber,amountValue:this.amountValue,checkout:this.checkOutPaymentId,sCode:this.sCode}});
      //this.otherdata = res.data;
    })

 }
  
  getquickCheckout() {
    
    this.sidebarService.getquickCheckout().subscribe((res: any) => {
      this.shippingMethodList=res.data.shippingMethodList;
     //this.quotesList = res.data;
     this.address=res.data.address;

    })
  }
  goToViewCart(){
    this.router.navigate(["/view-cart"]);
  }
  // goToFinalCheckOutReview(){
  //   this.router.navigate(["/final-check-out-review"]);
  // }
  goToAddCreditCard(){
    this.router.navigate(["/add-credit-card"]);
  }
  goToAddEftAccount(){
    this.router.navigate(["/add-eft-account"]);
  }
  toNewAddress()
  {
    this.router.navigate(['/create-address']);
  }

  addContactMech(id:any){
    this.contactMechId=id;
  }

  toAddress(valueAddes: any) {
    this.router.navigate(["/create-address"], {
      queryParams: {
        toName:valueAddes.shippingAddress.toName,
        attnName:valueAddes.shippingAddress.attnName,
        address1: valueAddes.shippingAddress.address1,
        address2:valueAddes.shippingAddress.address2,
        city:valueAddes.city,
        stateProvinceGeoId: valueAddes.shippingAddress.stateProvinceGeoId,
        
      }
    })

}
  
}
