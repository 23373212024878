import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyAccountsService {
  constructor(private http: HttpClient) { 
  }
  sendMessage(form:any): Observable<any> {
    let sendMessage = URLS.sendMessage;
    return this.http.post(sendMessage,form);
  }
  ListQuotesByQuoteId(id:any): Observable<any> {
    let ListQuotesByQuoteId = URLS.ListQuotesByQuoteId + "?quoteId=" + id;
    return this.http.get(ListQuotesByQuoteId);
  }
  ListQuotes(id:any): Observable<any> {
  const ListQuotes = URLS.ListQuotes+ "?partyId=" + id;
  return this.http.get(ListQuotes);
}
editShoppingList(id:any): Observable<any> {
  const editShoppingList = URLS.editShoppingList+ "?shoppingListId=" + id;
  return this.http.get(editShoppingList);
}
ListRequests(id:any): Observable<any> {
  const ListRequests = URLS.ListRequests+ "?fromPartyId=" + id;
  return this.http.get(ListRequests);
}
ListRequestsByCustRequestId(id:any): Observable<any> {
  const ListRequestsByCustRequestId = URLS.ListRequestsByCustRequestId + "?custRequestId=" + id;
  return this.http.get(ListRequestsByCustRequestId);
}
addItemToShoppingList(form:any): Observable<any> {
  let addItemToShoppingList = URLS.addItemToShoppingList;
  return this.http.post(addItemToShoppingList,form);
}
updateShoppingList(form:any): Observable<any> {
  let updateShoppingList = URLS.updateShoppingList;
  return this.http.post(updateShoppingList,form);
}
createEmptyShoppingList(form:any): Observable<any> {
  let createEmptyShoppingList = URLS.createEmptyShoppingList;
  return this.http.post(createEmptyShoppingList,form);
}
createCustRequestFromShoppingLists(id:any): Observable<any> {
  let createCustRequestFromShoppingLists = URLS.createCustRequestFromShoppingLists+ "?shoppingListId=" + id;
  return this.http.post(createCustRequestFromShoppingLists,"");
}

getShoppingListEcom(id:any): Observable<any> {
  const getShoppingListEcom = URLS.getShoppingListEcom+"?shoppingListId="+id;
  return this.http.get(getShoppingListEcom);
}
editShoppingListAll(): Observable<any> {
  const editShoppingListAll = URLS.editShoppingListAll;
  return this.http.get(editShoppingListAll);
}
getProductId(): Observable<any> {
  const getProductId = URLS.getProductId;
  return this.http.get(getProductId);
}
getShoppingListTypeIdList(): Observable<any> {
  const getShoppingListTypeIdList = URLS.getShoppingListTypeIdList;
  return this.http.get(getShoppingListTypeIdList);
}

updateShoppingListItem(form:any): Observable<any> {
  let updateShoppingListItem = URLS.updateShoppingListItem;
  return this.http.put(updateShoppingListItem,form);
}

removeShoppingListItem(shoppingListId:any,shoppingListItemSeqId:any){
  let removeShoppingListItem = URLS.removeShoppingListItem + "?shoppingListId=" + shoppingListId + "&shoppingListItemSeqId=" + shoppingListItemSeqId;
  return this.http.delete(removeShoppingListItem);
}

createShippingAddress(form:any): Observable<any> {
  let createShippingAddress = URLS.createShippingAddress;
  return this.http.post(createShippingAddress,form);
}

updateShippingAddress(form:any): Observable<any> {
  let updateShippingAddress = URLS.updateShippingAddress;
  return this.http.post(updateShippingAddress,form);
}

orderHistories(): Observable<any> {
  const orderHistories = URLS.orderHistories;
  return this.http.get(orderHistories);
}






}
