<div id="ecom-mainarea" class="row mx-2">
    <div id="div3" class="col-lg-12 order-lg-2 order-1">
      <h2>Edit Personal Information</h2>&nbsp;
      <form id="editpersonform1" [formGroup]="Editform" class="mr-2" name="editpersonform">
        <div class="form-group">
          <a class="btn btn-outline-secondary" (click)="goToProfile()">Go Back</a>
          <a class="btn btn-outline-secondary" (click)="onSubmit()">Save</a>
        </div>
         
            <div class="row">
              <div class="col-sm-6">
                <label>Title</label>
                <select name="personalTitle" class="form-control custom-select" formControlName="personalTitle">
                
                  <option > --</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Dr.">Dr.</option>
                </select>
              </div>
              <div class="col-sm-6">
                <label>Height</label>
                <input type="text" class="form-control" name="height" formControlName="height">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
              <label>First name</label>
                <input type="text" class="form-control" name="firstName" formControlName="firstName">
              </div>
              <div class="col-sm-6">
                <label>Weight</label>
                <input type="text" class="form-control" name="weight" formControlName="weight">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label>Middle initial</label>
                <input type="text" class="form-control" name="middleName" formControlName="middleName">
              </div>
              <div class="col-sm-6">
                <label>Mothers Maiden Name</label>
                <input type="text" class="form-control" name="mothersMaidenName" formControlName="mothersMaidenName">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label>Last name</label>
                <input type="text" class="form-control" name="lastName" formControlName="lastName">
              </div>
              <div class="col-sm-6">
                <label>Marital Status</label>
                <select name="maritalStatusEnumId" class="form-control custom-select" formControlName="maritalStatusEnumId">
                    <option selected="selected" value="SINGLE">Single</option>
                    <option value="MARRIED">Married</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
              <label>Suffix</label>
              <input type="text" class="form-control" name="suffix" formControlName="suffix">
              </div>
              <div class="col-sm-6">
                <label>Passport Number</label>
                <input type="text" class="form-control" name="passportNumber" formControlName="passportNumber">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label>Nick Name</label>
                <input type="text" class="form-control" name="nickname" formControlName="nickname">
              </div>
              <div class="col-sm-6">
                <label>Passport Expire Date</label>
                <input type="date" class="form-control" name="passportExpireDate" formControlName="passportExpireDate">
               
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label>Gender</label>
                <select name="gender" class="form-control custom-select" formControlName="gender">
                
                  <option> --</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </div>
              <div class="col-sm-6">
                <label>Total Years Work Experience</label>
                <input type="text" class="form-control" name="totalYearsWorkExperience" formControlName="totalYearsWorkExperience">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label>Birth Date</label>
                <input type="date" class="form-control" name="birthDate" formControlName="birthDate">
               
              </div>
              <div class="col-sm-6">
                <label>Comment</label>
                <input type="text" class="form-control" name="comments" formControlName="comments">
              </div>
            </div>
      </form>
      <div class="form-group">
        <a class="btn btn-outline-secondary" (click)="goToProfile()">Go Back</a>
        <a id="editpersonform3" class="btn btn-outline-secondary" (click)="onSubmit()">Save</a>
      </div>
     </div>
    <div id="div4" class="endcolumns">
     </div> </div>
