import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { SideBarService } from '../side-bar/side-bar.service';
import { TreeNode } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-feaured-products',
  templateUrl: './feaured-products.component.html',
  styleUrls: ['./feaured-products.component.css']
})
export class FeauredProductsComponent implements OnInit {

  total = 0;
  pageNo = 1;
  rows = 50;
  pageSize = 100;
  rowNumber = 0;
  search = "";
  messages: any[] = [];
  catalog: any;
  catalogArray: any[] = [];
  locale: any;
  localeArray: any[] = [];
  id: any;
  selectedFiles: any;
  category: TreeNode[] = [];
  categoryId: any;
  productMain: any;
  findForm: FormGroup;

  private finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  MainFeaturedProduct: any;
  CatalogForm!: FormGroup;
  catalogId: any;
  value: any;
  compareProductList: any;
  productIdData: any;
  tagCloudList: any;
  allPromotions: any;
  language: any;
  showCart: any;
  specialOffers: any;
  summary: any;
  searchForm: FormGroup;
  img_missing:any='/assets/missing_img.jpeg'
  constructor(readonly router: Router, private form: FormBuilder, private sidebarService: SideBarService,
    private activatedRoute: ActivatedRoute, readonly Form: FormBuilder,
    private toastr: ToastrService) {
    localStorage.setItem('catalog', 'DemoCatalog')
    this.catalogId = localStorage.getItem("catalog");
    this.CatalogForm = this.form.group({

      catalogId: [''],

    });
    this.findForm = this.Form.group({
      findCatalog: [''],
      radioAll: ['']
    });
    this.searchForm =this.form.group({
      searchProduct:['']
    })
  }

  ngOnInit(): void {
    this.getAllPromotion();
    this.getCatalogList();
    this.getLocale();
    this.getProductTags();
    this.getCategoryById();
    this.getshowCart();
    this.getMainFeaturedProduct();
    this.getProductMain();
    setTimeout(() => {
      const formValue = this.CatalogForm;
      formValue.patchValue({
        catalogId: this.catalogId,


      })
    }, 2000);
  }
  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
      this.showCart = res.data.productsList;
      this.specialOffers = res.data.specialOffers;
      this.summary = res.data.summary;

    })
  }
  goToAdvancedSearch() {
    this.router.navigate(["/advanced-search"]);
  }
  goToFindCatalog() {
    this.router.navigate(["/find-catalog"], { queryParams: { findCatalog: this.findForm.value.findCatalog, type: this.findForm.value.radioAll } });
  }
  goToFeaturedProducts() {
    this.router.navigate(["/"]);
  }

  addPromoTag(id: any) {
    this.router.navigate(["/products"], { queryParams: { productId: id } })
  }
  goToConfigureProduct(id: any) {
    this.router.navigate(["/configure-product"], { queryParams: { productId: id } })
  }
  goToChooseProductAmount() {
    this.router.navigate(["/choose-product-amount"]);
  }
  goToChooseVariation() {
    this.router.navigate(["/choose-variation"]);
  }
  goToViewAllPromotions() {
    this.router.navigate(["/view-all-promotions"]);
  }
  goToShowPromotionDetails() {
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewCart() {
    this.router.navigate(["/view-cart"]);
  }
  goToQuickCheckOut() {
    this.router.navigate(["/quick-check-out"]);
  }
  goToOnePageCheckOut() {
    this.router.navigate(["/one-page-check-out"]);
  }
  getCatalogList() {
    //this.spinner.show();
    this.sidebarService.getCatalogList().subscribe((res: any) => {
      this.catalog = res.data;
      //this.spinner.hide();
      for (const value of this.catalog) {
        this.catalogArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })

  }

  getLocale() {
    //this.spinner.show();
    this.sidebarService.getLocale().subscribe((res: any) => {
      this.locale = res.data;
      //this.spinner.hide();
      for (const value of this.locale) {
        this.localeArray.push({
          label: value.value,
          value: value.key
        })
      }
    })

  }


  getLanguage(event: any) {
    this.language = event.value;
    localStorage.setItem('language', this.language)
    this.sidebarService.postLocale(this.language).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");

      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })


  }
  getCategoryByCatalog(event: any) {
    this.id = event.value;
    localStorage.setItem('catalog', (this.id));
    this.value = localStorage.getItem("catalog");
    this.getMainFeaturedProductById(this.value);
    this.getCategoryById();
    this.sidebarService.getProductMain(this.value).subscribe((res: any) => {
      this.productMain = res.data.finalProductCatList;

    })

  }
  getMainFeaturedProductById(id: any) {
    this.sidebarService.getMainFeaturedProduct(id, this.finSize).subscribe((res: any) => {
      this.MainFeaturedProduct = res.data.productFeatured;

    })
  }
  getAllPromotion() {
    this.sidebarService.getAllPromotion().subscribe((res: any) => {
      this.allPromotions = res.data;

    })
  }


  getProductTags() {
    this.sidebarService.getProductTags().subscribe((res: any) => {
      this.tagCloudList = res.data.tagCloudList;

    })
  }
  getCategoryById() {
    this.sidebarService.getCategoryByCatalog(this.catalogId).subscribe((res: any) => {
      this.category = res.data;

    })
  }
  getProductMain() {
    this.sidebarService.getProductMain(this.catalogId).subscribe((res: any) => {
      this.productMain = res.data.finalProductCatList;

    })
  }


  getMainFeaturedProduct() {
    this.sidebarService.getMainFeaturedProduct(this.catalogId, this.finSize).subscribe((res: any) => {
      this.MainFeaturedProduct = res.data.productFeatured;

    })
  }
  getCompareProducts() {
    this.sidebarService.getCompareProducts(this.catalogId).subscribe((res: any) => {
      this.compareProductList = res.data.productList;

    })
  }


  addToCompare(id: any) {
    this.sidebarService.getProductMain(this.catalogId)
      .subscribe(
        (data: any) => {


          this.productMain = data.finalProductCatList;


          this.sidebarService.addToComparePost(id).subscribe((res: any) => {
            if (res.success) {
              this.toastr.success(res.data.successMessage);
              this.getCompareProducts();
            } else if (res.success == false) {
              this.toastr.error(res.data.responseMessage);

            }
          }, (err) => {
            if (err.error.success == false) {
              if (err.error.errors) {

                for (const value of err.error.errors) {
                  this.toastr.error(value.fieldName + " " + value.fieldError);
                }

              }
              else if (err.error.success == false) {

                this.toastr.error(err.error.message);
              }
            }
          })
        })



  }


  removeAddToCompare(id: any): void {

    this.sidebarService.removeAddToCompare(id)
      .then(data => {
        if (data.success) {
          this.toastr.success(data.data.successMessage);
          this.getCompareProducts();
        }
      });

  }

  clearCompare() {


    this.sidebarService.clearCompare().subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.getCompareProducts();

      }
    }, (err) => {

      this.toastr.error(err.message);

    });

  }
  goToCheckOut() {
    this.router.navigate(["/check-out-option1"]);
  }
}
