import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private _ApiService: ApiService, private http: HttpClient) { }


  getCategoryByCatalog(id: any): Observable<any> {
    let getCategoryByCatalog = URLS.getCategoryByCatalog + "?CURRENT_CATALOG_ID=" + id;
    return this.http.get(getCategoryByCatalog);
  }
  getCatalogList(): Observable<any> {
    return this.http.get(URLS.getCatalogList)
  }
  getLocale(): Observable<any> {
    return this.http.get(URLS.getLocale)
  }
  createCreditCard(partyId: string, formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/creditCard/${partyId}`, formData);
  }
  postCreditCard(partyId: string, formData: any) {
    let postCreditCard = URLS.postCreditCard + '/' + partyId;
    return this.http.post(postCreditCard, formData);
  }
  postEftAccount(partyId: string, formData: any) {
    let postEftAccount = URLS.postEftAccount + '/' + partyId;
    return this.http.post(postEftAccount, formData);
  }
  postGiftCard(partyId: string, formData: any) {
    let postGiftCard = URLS.postGiftCard + '/' + partyId;
    return this.http.post(postGiftCard, formData);
  }
  updateCreditCard(partyId: string, paymentMethodId: string, formData: any) {
    let updateCreditCard = URLS.updateCreditCard + '/' + partyId + '/' + paymentMethodId;
    return this.http.put(updateCreditCard, formData);
  }
  updateEftAccount(partyId: string, paymentMethodId: string, formData: any) {
    let updateEftAccount = URLS.updateEftAccount + '/' + partyId + '/' + paymentMethodId;
    return this.http.put(updateEftAccount, formData);
  }
  updateGiftCard(partyId: string, paymentMethodId: string, formData: any) {
    let updateGiftCard = URLS.updateGiftCard + '/' + partyId + '/' + paymentMethodId;
    return this.http.put(updateGiftCard, formData);
  }

  getPaymentMethodById(partyId: string, paymentMethodId: string, type: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods?partyId=${partyId}&paymentMethodId=${paymentMethodId}&type=${type}`);
  }
  getAccountById(partyId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/accounts/${partyId}`);
  }
  getCreditCardTypes(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/creditCard/types`);
  }
  // updateCreditCard(partyId: string, paymentMethodId: string, formData: any): Promise<any> {
  //   return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/creditCard/${partyId}/${paymentMethodId}`, formData);
  // }
  createEftAccount(partyId: string, formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/eftAccount/${partyId}`, formData);
  }
  createCheckAccount(partyId: string, formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/checkAccount/${partyId}`, formData);
  }
  // updateEftAccount(partyId: string, paymentMethodId: string, formData: any): Promise<any> {
  //   return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/eftAccount/${partyId}/${paymentMethodId}`, formData);
  // }
  getAllPayment(partyId: string, showOld: boolean): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/${partyId}?showOld=${showOld}`);
  }
  getBillingAccounts(partyId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/billingAccount/${partyId}`);
  }
  removeBillingAccount(partyId: string, billingAccountId: string): Promise<any> {
    return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/billingAccount/${partyId}/${billingAccountId}`);
  }
  removeGiftCard(paymentMethodId: string): Promise<any> {
    return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/${paymentMethodId}`);
  }
  createGiftCard(partyId: string, formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/${partyId}`, formData);
  }
  // updateGiftCard(partyId: string, paymentMethodId: string, formData: any): Promise<any> {
  //   return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/${partyId}/${paymentMethodId}`, formData);
  // }

  getContactById(Id: string): Observable<any> {
    let getAccountDetail = URLS.getContactById + '/' + Id
    return this.http.get(getAccountDetail);
  }
  createContact(data: any): Observable<any> {
    return this.http.post(URLS.createContact, data)
  }
  updateContact(data: any): Observable<any> {
    return this.http.put(URLS.updateContact, data)
  }
  getContactPurposes(partyId:any,mechId:any):Observable<any>{
    let getContact = URLS.getContactPurpose +'?partyId='+partyId +'&preContactMechTypeId='+mechId
    return this.http.get(getContact)
  }
  createPostalAddress(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/partyPostalAddress`, formData);
}
  updatePostalAddress(formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/partyPostalAddress`, formData);
}
  createEmail(data:any):Observable<any>{
    return this.http.post(URLS.createEmail,data)
  }
  updateEmail(data:any):Observable<any>{
    return this.http.put(URLS.createEmail,data)
  }
}
