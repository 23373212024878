import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';
import { TreeNode } from 'primeng/api';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.css']
})
export class AdvancedSearchComponent implements OnInit {
  sortedByList: any;
  sortedByListArray: any[] = [];
  catalog: any;
  catalogArray: any[] = [];
  locale: any;
  localeArray: any[] = [];
  id: any;
  selectedFiles: any;
  category: TreeNode[] = [];
  language: any;
  advancedSearchForm: FormGroup;
  colorArray: any[] = [];
  sizeArray: any[] = [];
  licenseArray: any[] = [];
  catalogId: string | null;
  sizeColor: any;
  Color: any;
  License: any;
  Size: any;
  EColorArray: any[] = [];
  ELicenseArray: any[] = [];
  ESizeArray: any[] = [];
  keywordSearch: any;

  total = 0;
  pageNo = 0;
  rows = 50;
  pageSize = 100;
  rowNumber = 0;
  private finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  show = false;
  constructor(readonly router: Router, private sidebarService: SideBarService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService, private form: FormBuilder) {
    this.catalogId = localStorage.getItem("catalog");
    this.colorArray = [{
      label: 'Black',
      value: '9000'
    }, {
      label: 'Silver',
      value: '9001'
    }];
    this.licenseArray = [{
      label: 'ASL2',
      value: '8004'
    }, {
      label: 'BSD',
      value: '8002'
    }, {
      label: 'GPL',
      value: '8001'
    }, {
      label: 'LGPL',
      value: '8000'
    }, {
      label: 'MIT',
      value: '8003'
    }];
    this.sizeArray = [{
      label: '3-Wheel',
      value: '9002'
    }, {
      label: '4-Wheel',
      value: '9003'
    }];
    this.advancedSearchForm = this.form.group({
      keyWords: [''],
      keyWordsOption: ['OR'],
      color: [''],
      license: [''],
      size: [''],
      sortedBy: [''],
      sortedByOption: ['OR'],
      subCategory: ['']
    });
  }

  ngOnInit(): void {
    this.getCatalogList();
    this.getLocale();
    this.advSearchDataDropdown();
  }
  keywordSearchCatalog() {
    this.show = true;
    const req = {
      "clearSearch": "Y",
      "color": this.advancedSearchForm.value.color,
      "license": this.advancedSearchForm.value.license,
      "noContains": "",
      "operator": this.advancedSearchForm.value.keyWordsOption,
      "searchCatalogId": "",
      "searchCategoryId": "CATALOG1_SEARCH",
      "searchString": this.advancedSearchForm.value.keyWords,
      "searchsubCategories": this.advancedSearchForm.value.subCategory,
      "size": this.advancedSearchForm.value.size,
      "sortAscending": "",
      "sortOrder": this.advancedSearchForm.value.sortedBy,

    }
    this.sidebarService.keywordSearchCatalog(req, this.finSize).subscribe((res: any) => {
      this.keywordSearch = res.data;


    })

  }
  advSearchDataDropdown() {
    //this.spinner.show();
    this.sidebarService.advSearchDataDropdown(this.catalogId).subscribe((res: any) => {
      this.sortedByList = res.data.sortedByList;
      this.Color = res.data.Color;
      this.License = res.data.License;
      this.Size = res.data.Size;
      //this.spinner.hide();
      for (const value of this.sortedByList) {
        this.sortedByListArray.push({
          label: value.description,
          value: value.value
        })
      }
      for (const value of this.Color) {
        this.EColorArray.push({
          label: value.description,
          value: value.productFeatureId
        })
      }
      for (const value of this.License) {
        this.ELicenseArray.push({
          label: value.description,
          value: value.productFeatureId
        })
      }
      for (const value of this.Size) {
        this.ESizeArray.push({
          label: value.description,
          value: value.productFeatureId
        })
      }


    })

  }
  getCatalogList() {
    //this.spinner.show();
    this.sidebarService.getCatalogList().subscribe((res: any) => {
      this.catalog = res.data;
      //this.spinner.hide();
      for (const value of this.catalog) {
        this.catalogArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })

  }

  getLocale() {
    //this.spinner.show();
    this.sidebarService.getLocale().subscribe((res: any) => {
      this.locale = res.data;
      //this.spinner.hide();
      for (const value of this.locale) {
        this.localeArray.push({
          label: value.value,
          value: value.key
        })
      }
    })

  }

  getLanguage(event: any) {
    this.language = event.value;
    this.sidebarService.postLocale(this.language).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");

      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })


  }

  getCategoryByCatalog(event: any) {
    this.id = event.value;
    this.sidebarService.getCategoryByCatalog(this.id).subscribe((res: any) => {
      this.category = res.data;
      this.router.navigate(["/"], { queryParams: { categoryId: this.id } });
    })
  }
  goToFeaturedProducts() {
    this.router.navigate(["/"]);
  }
  goToAdvancedSearch() {
    this.router.navigate(["/advanced-search"]);
  }
  goToViewAllPromotions() {
    this.router.navigate(["/view-all-promotions"]);
  }
  goToPromotionDetails() {
    this.router.navigate(["/show-promotion-details"]);
  }
  goToLogin() {
    this.router.navigate([""]);
  }
  goToConfigureProduct() {
    this.router.navigate(["/configure-product"]);
  }
  goToChooseProductAmount() {
    this.router.navigate(["/choose-product-amount"]);
  }
  goToChooseVariation() {
    this.router.navigate(["/choose-variation"]);
  }

  goToShowPromotionDetails() {
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewCart() {
    this.router.navigate(["/view-cart"]);
  }
  goToQuickCheckOut() {
    this.router.navigate(["/quick-check-out"]);
  }
  goToOnePageCheckOut() {
    this.router.navigate(["/one-page-check-out"]);
  }
 /*  addToCompare(id: any) {

    this.sidebarService.addToComparePost(id).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);

      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })


  } */
}
