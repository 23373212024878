import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ServicesService } from '../services/services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from '../register/register.service';
import { MyAccountsService } from '../my-account/my-accounts.service';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-split-payment',
  templateUrl: './split-payment.component.html',
  styleUrls: ['./split-payment.component.css']
})
export class SplitPaymentComponent implements OnInit {
  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  splitShipmentForm: FormGroup;
  showCart: any;
  specialOffers: any;
  summary: any;
  constructor(
    readonly _GlobalResourceService: RegisterService,
    readonly _CRMAccountService: ServicesService,
    readonly _FormBuilder: FormBuilder,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _ToastrService: ToastrService,
    private myAccountsService : MyAccountsService,
    private sidebarService: SideBarService,
  ) 
  { 

    this.splitShipmentForm=this._FormBuilder.group({
      shippingAddress: [''],
      shippingQuantity: [''],
      carrierPartyId: [''],
      isGift: [''],
      specialInstruction: [''],
      giftMessage: [''],
    })
  }

  ngOnInit(): void {
  }

  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
      this.showCart = res.data.productsList;
      this.specialOffers = res.data.specialOffers;
      this.summary = res.data.summary;

    })
  }
  
  toNewAddress()
  {
    this._Router.navigate(['/create-address'])
  }

}
