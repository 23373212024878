import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compare-products-pop-up',
  templateUrl: './compare-products-pop-up.component.html',
  styleUrls: ['./compare-products-pop-up.component.css']
})
export class CompareProductsPopUpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
