<footer class="page-footer font-small">
    <!-- Copyright -->
    <div class="container-fluid" style="border-top:2px solid #dee2e6 !important; margin-top: 150px !important;">
      <div class="row mx-5">
        <div class="col-lg-1 d-none d-lg-block"></div>
        <div class="col-lg-2 col-4 mt-4  text-start">
          <h6>Company</h6>
          <p class="m-0 p-0"><span class="span-link" routerLink="/about-us">About Us</span></p>
          <p class="m-0 p-0"><span class="span-link">Careers</span></p>
          <p class="m-0 p-0"><span class="span-link">News</span></p>
        </div>
        <div class="col-lg-2 col-4 mt-4  text-start">
          <h6>My Account</h6>
          <p class="m-0 p-0"><span class="span-link">Pay My Bills</span></p>
          <p class="m-0 p-0"><span class="span-link">Order Status</span></p>
          <p class="m-0 p-0"><span class="span-link">Royalist Rewards</span></p>
          <p class="m-0 p-0"><span class="span-link">Signup for emails</span></p>
          <p class="m-0 p-0"><span class="span-link">Promotions</span></p>
        </div>
        <div class="col-lg-2 col-4 mt-4  text-start">
          <h6>Customer Service</h6>
          <p class="m-0 p-0"><span class="span-link" (click)="toContactUs()">Contact Us</span></p>
          <p class="m-0 p-0"><span class="span-link">Gift Card</span></p>
          <p class="m-0 p-0"><span class="span-link">FAQ’s & Help</span></p>
        </div>
        <div class="col-lg-2 col-4 mt-4  text-start">
          <h6>Ordering</h6>
          <p class="m-0 p-0"><span class="span-link">Payment Status</span></p>
          <p class="m-0 p-0"><span class="span-link">Shipping &Handling</span></p>
          <p class="m-0 p-0"><span class="span-link">Return & Exchange</span></p>
        </div>
        <div class="col-lg-2 col-4 mt-4  text-start">
          <h6>Browse Content</h6>
          <p class="m-0 p-0"><span class="span-link">Privacy Policy</span></p>
          <p class="m-0 p-0"><span class="span-link">Cookie Policy</span></p>
          <p class="m-0 p-0"><span class="span-link">Terms & Condition</span></p>
          <p class="m-0 p-0"><span class="span-link">Store Policy</span></p>
        </div>
        <div class="col-lg-1 d-none d-lg-block"></div>
        <div class="col-lg-1 d-none d-lg-block"></div>
        <div class="col-lg-2 col-4 mt-4  text-start">
          <h6>Browse Blog</h6>
          <p class="m-0 p-0"><span class="span-link">Content 1</span></p>
          <p class="m-0 p-0"><span class="span-link">Content 2</span></p>
          <p class="m-0 p-0"><span class="span-link">Content 3</span></p>
        </div>
        <div class="col-lg-2 col-6 mt-4  text-start">
          <h6>Browse Forum</h6>
          <p class="m-0 p-0"><span class="span-link">Content/Discussion 1</span></p>
          <p class="m-0 p-0"><span class="span-link">Content/Discussion 2</span></p>
          <p class="m-0 p-0"><span class="span-link">Content/Discussion 3</span></p>
        </div>
        <div class="col-lg-2 col-4 mt-4  text-start">
          <h6>Social Media</h6>
          <p class="m-0 p-0"><span class="span-link">Facebook</span></p>
          <p class="m-0 p-0"><span class="span-link">Twitter</span></p>
        </div>
      </div>
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
          <div class="footer-copyright text-center py-1 mt-5"><a href="#" >About Us</a>
          </div>
          <div class="footer-copyright text-center py-1">Copyright © 2020 Phyrst Inc. - <a href="www.phyrsts.com">www.phyrsts.com</a>
          </div>
        </div>
        <div class="col-3">
          <div class="footer-copyright text-start py-1 mt-5"><a href="#">See Store Policies Here</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Copyright -->
  </footer>
