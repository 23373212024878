import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { RandomService } from '../random.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegisterService } from '../register/register.service';
import { SideBarService } from '../side-bar/side-bar.service';
import { MyAccountsService } from '../my-account/my-accounts.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  show: boolean;
  username: any;
  lastname: any;
  token: any;
  total = 0;
  pageNo = 1;
  rows = 50;
  pageSize = 100;
  rowNumber = 0;
  search = "";
  quotes: any[] = [];
  user: string | null;
  partyId: string | null;
  contactForm!: FormGroup;
  communicationEventTypeId: any;
  communicationEventId: any;
  summaryPageMessageList: any;
  constructor(readonly router: Router, private toastr: ToastrService,
    private registerService: RegisterService, private random: RandomService,
    readonly createCustomer: RegisterService, private form: FormBuilder,
    readonly spinner: NgxSpinnerService,
    private _ActivatedRoute: ActivatedRoute,
    private sidebarService:SideBarService,
    private myAccountsService : MyAccountsService) {
    this.show = false;
    this.username = localStorage.getItem("firstName");
    this.lastname = localStorage.getItem("lastName");
    this.user = localStorage.getItem("email");
    this.partyId = localStorage.getItem("partyId");
    this.contactForm = this.form.group({
      subject: [''],
      message: [''],
    });
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.communicationEventId = params["communicationEventId"];
    });
    this.random.currentMessageSubscriber.subscribe((data: any) => {
      this.token = localStorage.getItem("token");
      if (this.token) {
        this.show = true;
        this.username = localStorage.getItem("firstName");
        this.lastname = localStorage.getItem("lastName");
      }
      else {
        this.show = false;

        this.username = "";
        this.lastname = "";
      }
    })
this.messageListSecond();
  }

  messageListSecond() {
    this.spinner.show;
    this.sidebarService.summaryPageMessage(this.communicationEventId).subscribe((res: any) => {
       this.summaryPageMessageList = res.data[0].data[0];
     })
     setTimeout(() => {
      const form = this.contactForm;
      form.patchValue({
        subject:this.summaryPageMessageList.subject ,
        message:this.summaryPageMessageList.content 
      })
      
     }, 1000);
    this.spinner.hide;
   }
  cancel() {
    this.router.navigate(['/']);
  }
  sendMessage() {
    const req = {
      "communicationEventTypeId": "WEB_SITE_COMMUNICATI",
      "contactMechTypeId": "WEB_ADDRESS",
      "content":this.contactForm.value.message,
      "note": "",
      "partyIdFrom":this.partyId,
      "partyIdTo": this.partyId,
      "subject": this.contactForm.value.subject,
    }
    this.myAccountsService.sendMessage(req).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");

      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })
  }
  onSubmit() {
    const req = {
      "content": this.contactForm.value.message,
      "partyIdFrom": this.partyId,
      "subject": this.contactForm.value.subject
    }
    this.createCustomer.postContactUs(req).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");

      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })
  }

  goToShowPromotionDetails() {
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewAllPromotions() {
    this.router.navigate(["/view-all-promotions"])
  }

  toMainPage()
  {
    this.router.navigate(['/login'])
  }
}
