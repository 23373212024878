import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-final-check-out-review',
  templateUrl: './final-check-out-review.component.html',
  styleUrls: ['./final-check-out-review.component.css']
})
export class FinalCheckOutReviewComponent implements OnInit {
  catalogId: string | null;
  giftCard: any;
  giftCardAmount: any;
  giftCardNumber: any;
  paymentArray:any;
  id: any;
  payment: any;
  payInfo: any;
  orderView: any;
  shippingInformation: any;
  orderItemList: any;
  otherdetails: any;
  amountValue: any;
  checkout: any;
  sCode: any;
  paymentOptions: any;
  paymentMethodMapList: any;
  otherdata: any;
  paymentInformation: any;
  OrderID: any;

  constructor(
    readonly router: Router, 
    private activeRoute: ActivatedRoute, 
    private sidebarService: SideBarService, 
    private toastr: ToastrService
    ) 
    {
      this.catalogId = localStorage.getItem("catalog");
      this.paymentArray= localStorage.getItem("paymentArray");
    }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
      this.giftCard = params["giftCard"];
      this.giftCardAmount = params["giftCardAmount"];
      this.giftCardNumber = params["giftCardNumber"];
      this.payment=params["payment"];
      this.amountValue=params["amountValue"];
      this.checkout=params["checkout"];
      this.sCode=params['sCode']
   
    });
    this.checkoutOptions();
  }
  
  processOrder() {
    this.id = "payment";
   
    this.sidebarService.processOrder(this.id).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Order Created Successfully");
        this.OrderID=res.data.orderId;
        this.router.navigate(['/view-order'], { queryParams: { OrderID: res.data.orderId } });
      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);


      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })

  
  }
  checkoutOptions() {
    this.id = "payment";
   
    this.payInfo=JSON.parse(this.paymentArray);
    let req = {
      "checkoutpage": this.id,
      "BACK_PAGE": "checkoutoptions",
      "issuerId": "",
      "checkOutPaymentId":this.checkout,
      "singleUseGiftCard": this.giftCard,
      "giftCardNumber":  this.giftCardNumber,
      "giftCardAmount":this.giftCardAmount
    }
    this.sidebarService.checkoutOptions(this.id, req).subscribe((res: any) => {
      this.orderView=res.data.orderView.orderInformation;
      this.shippingInformation=res.data.shippingInformation.listShipAddr[0];
      this.orderItemList=res.data.orderItems.orderItemList;
      this.otherdetails=res.data.otherdetails;
     this.paymentOptions = res.data.paymentOptions;
      this.paymentMethodMapList=res.data.paymentMethodMapList
      this.paymentInformation=res.data.orderView.paymentInformation;
      this.otherdata = res.data;



    })
  }

}
