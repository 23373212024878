import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SideBarService } from '../side-bar/side-bar.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-quick-add',
  templateUrl: './quick-add.component.html',
  styleUrls: ['./quick-add.component.css']
})
export class QuickAddComponent implements OnInit {
  showCart: any;
  specialOffers: any;
  summary: any;
  search="";
  messages:any[]=[];
  locale: any;
  localeArray: any[] = [];
  address: any;
  partyTaxIdentification: any;
  id: any;
  partyTaxIdentificationData: any;
  groupNameValue: any;
  contactMechId: any;
  selecttaxAuthPartyGeoIds:any;
  partyTaxIdentificationArray: any[]=[];
  compareProductList: any;
  partyTaxAuthInfoAndDetailList: any;
  catalogId: string | null;
  addAllToCartForm: FormGroup;

  constructor(readonly router:Router,
    private sidebarService: SideBarService,
    private toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    ) {
      this.catalogId = localStorage.getItem("catalog");

      this.addAllToCartForm = this._FormBuilder.group({
        // product: [''],
        // quantity: [''],
        // price: [''],
        // total: ['']
        quantity_GZ_2644:[''],
        quantity_GZ_8544: [''],
        quantity_WG_1111: [''],
        quantity_WG_5569: [''],
       });
     }

  ngOnInit(): void {
  }
  
  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
      this.showCart = res.data.productsList;
      this.specialOffers = res.data.specialOffers;
      this.summary = res.data.summary;

    })
  }

  addtocartbulk(): void {
    const form ={
      "productId": this.addAllToCartForm.value.productNumber,
      "quantity": this.addAllToCartForm.value.quantity,
}    
      this.sidebarService.addtocartbulk(form).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.addAllToCartForm.reset();
        
        this.getshowCart();
      }
    }, (err) => {
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }


  getCompareProducts() {
    this.sidebarService.getCompareProducts(this.catalogId).subscribe((res: any) => {
      this.compareProductList = res.data.productList;

    })
  } 
  removeAddToCompare(id:any): void {

    this.sidebarService.removeAddToCompare(id)
      .then(data => {
        if (data.success) {
          this.toastr.success(data.data.successMessage);
        this.getCompareProducts();
        }
      });
  
  }
  
  clearCompare() {
     
  
    this.sidebarService.clearCompare().subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.getCompareProducts();
  
      }
    }, (err) => {
   
      this.toastr.error(err.message);
  
    });
   
  }
  
  

goToFeaturedProducts(){
    this.router.navigate(["/"]);
  }


  goToShowPromotionDetails(){
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewAllPromotions(){
    this.router.navigate(["/view-all-promotions"]);
  }

  goToViewCart(){
    this.router.navigate(["/view-cart"]);
  }
  goToQuickCheckOut(){
    this.router.navigate(["/quick-check-out"]);
  }
  goToOnePageCheckOut(){
    this.router.navigate(["/one-page-check-out"]);
  }
  

  goToCheckOutOption1(){
    this.router.navigate(["/check-out-option1"]);
  }
  goToCheckOut(){
    this.router.navigate(["/check-out-option1"]);
  }
}
