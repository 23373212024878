<div class="card mx-auto mt-4" style="width: 25%!important; min-width: 16rem!important;">
    <div class="card-title">
        <h3 class="text-left my-3 mx-4">Registered User</h3>
    </div>
    <div class="p-2">
        <form [formGroup]="loginUser">
            <div class="form-group p-1">
              <label class="mb-2" for="exampleInputEmail1">User Name</label>
              <input type="email" class="form-control" id="username" name="username" aria-describedby="emailHelp"
              formControlName="username" placeholder="Enter Username">
              <ng-container *ngIf="loginUser.controls['username'].invalid && (loginUser.controls['username'].dirty||loginUser.controls['username'].touched)">
                  <p class="validate-field">
                      <span style="color:red!important;">Username id required!</span>
                  </p>
              </ng-container>
            </div>
            <div class="form-group p-1 mb-2">
              <label class="my-2" for="exampleInputPassword1">Password</label>
              <input type="password" class="form-control" id="password" name="password"
              formControlName="password" placeholder="Enter Password">
              <ng-container *ngIf="loginUser.controls['password'].invalid && (loginUser.controls['password'].dirty||loginUser.controls['password'].touched)">
                <p class="validate-field">
                    <span style="color:red!important;">Password id required!</span>
                </p>
            </ng-container>
            </div>
            <div class="p-1">
                <button type="submit" class="btn btnHover col-12" (click)="loginuser()" [ngClass]="(loginUser.controls['username'].invalid)||(loginUser.controls['password'].invalid)? 'btn-secondary' : 'btn-primary'">Login</button>
            </div>
            <div>
                <div class="mb-4 text-center">
                    <label for="newCustomerLogin">You may create a new account here: <span class="span-link" (click)="goToRegister()">Create</span></label>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="text-center mt-4">
    <a href="#forgotPasswordCollapse" data-bs-toggle="collapse">Forgot Your password?</a>
</div>
<div class="collapse card mx-auto mt-4" id="forgotPasswordCollapse" style="width: 21rem;">
    <div class="card-title">
        <h3 class="text-left my-3 mx-4">Forgot Your Password</h3>
    </div>
    <div class="p-2">
        <form [formGroup]="forgetUser">
            <div class="form-group p-1">
              <label class="mb-2" for="exampleInputEmail1">User Name</label>
              <input type="email"  formControlName="email" class="form-control" id="username" aria-describedby="emailHelp" placeholder="Enter Username">
            </div>
           <div class="container-fluid p-3">
                <div class="row">
                    <div class="col-6 gx-2">
                        <input class="btn btn-outline-secondary" type="submit" value="Get Password Hint">
                    </div>
                    <div class="col-6 gx-4">
                        <input (click)="onSubmit()" class="btn btn-outline-secondary" type="submit" value="Email password">
                    </div>
                </div>
            </div>
          </form>
    </div>
</div>

