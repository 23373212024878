import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SideBarService } from '../side-bar/side-bar.service';
import { TreeNode } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-right-side-bar',
  templateUrl: './right-side-bar.component.html',
  styleUrls: ['./right-side-bar.component.css']
})
export class RightSideBarComponent implements OnInit {
  catalog: any;
  catalogArray: any[] = [];
  locale: any;
  localeArray: any[] = [];
  id: any;
  selectedFiles: any;
  category: TreeNode[] = [];
  language: any;
  tagCloudList: any;
  productMain: any;
  compareProductList: any;
  catalogId: any;

  constructor(readonly router: Router, private sidebarService: SideBarService,
    private toastr: ToastrService,) {

  }

  ngOnInit(): void {
    this.catalogId = localStorage.getItem('catalog');
    console.log('this.catalogId', this.catalogId);
    this.getCatalogList();
    this.getLocale();
    this.getProductTags();
    this.getCompareProducts();

  }
  getCompareProducts() {
    this.sidebarService.getCompareProducts(this.catalogId).subscribe((res: any) => {
      this.compareProductList = res.data.productList;

    })
  } 
 
  
  addToCompare(id:any) {
    this.sidebarService.getProductMain( this.catalogId )
    .subscribe(
      (data:any) => {
      

      this.productMain = data.finalProductCatList;
      
    
      this.sidebarService.addToComparePost(id).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.getCompareProducts();
        } else if (res.success == false) {
          this.toastr.error(res.data.responseMessage);
        
        }
      }, (err) => {
        if (err.error.success == false) {
          if (err.error.errors) {
         
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + " " + value.fieldError);
            }
  
          }
          else if (err.error.success == false) {
          
            this.toastr.error(err.error.message);
          }
        }
      })
    })
  
  
 
} 
//   catalogId(catalogId: any) {
//     throw new Error('Method not implemented.');
//   }


removeAddToCompare(id:any): void {

  this.sidebarService.removeAddToCompare(id)
    .then(data => {
      if (data.success) {
        this.toastr.success(data.data.successMessage);
      this.getCompareProducts();
      }
    });

}

clearCompare() {
   

  this.sidebarService.clearCompare().subscribe((res: any) => {
    if (res.success) {
      this.toastr.success(res.data.successMessage);
      this.getCompareProducts();

    }
  }, (err) => {
 
    this.toastr.error(err.message);

  });
 
}
  getProductTags() {
    this.sidebarService.getProductTags().subscribe((res: any) => {
      this.tagCloudList = res.data.tagCloudList;

    })
  }
  goToFeaturedProducts() {
    this.router.navigate(["/"]);
  }
  goToAdvancedSearch() {
    this.router.navigate(["/advanced-search"]);
  }
  goToViewAllPromotions() {
    this.router.navigate(["/view-all-promotions"]);
  }
  goToPromotionDetails() {
    this.router.navigate(["/show-promotion-details"]);
  }
  goToLogin() {
    this.router.navigate([""]);
  }
  getCatalogList() {
    //this.spinner.show();
    this.sidebarService.getCatalogList().subscribe((res: any) => {
      this.catalog = res.data;
      //this.spinner.hide();
      for (const value of this.catalog) {
        this.catalogArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })

  }

  getLocale() {
    //this.spinner.show();
    this.sidebarService.getLocale().subscribe((res: any) => {
      this.locale = res.data;
      //this.spinner.hide();
      for (const value of this.locale) {
        this.localeArray.push({
          label: value.value,
          value: value.key
        })
      }
    })

  }
  
  getLanguage(event:any) {
    this.language=event.value;
    this.sidebarService.postLocale(this.language).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
       
      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);
      
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
       
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
        
          this.toastr.error(err.error.message);
        }
      }
    })
  
 
}
  
  getCategoryByCatalog(event:any){
    this.id=event.value;
    this.sidebarService.getCategoryByCatalog(this.id).subscribe((res: any) => {
      this.category = res.data;
      this.router.navigate(["/"],{ queryParams: { categoryId: this.id } });
    })
  }
}
