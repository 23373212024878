import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {URLS} from '../app.constant';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor(private http: HttpClient) { }

  loginDetails(loginUser: { username: string; password: string; }): Observable<any>{
    
    let loginApiURL=URLS.loginApi
    return this.http.post(loginApiURL,loginUser);
  }
  
  resetPassword(id: any) {
    let resetPassword = URLS.resetPassword + "?userLoginId=" + id;
    return this.http.post(resetPassword,"");
  }
}
