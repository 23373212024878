import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    readonly router: Router,
  ) { }

  ngOnInit(): void {
  }

  toContactUs()
  {
    this.router.navigate(['/contact-us'])
  }
}
