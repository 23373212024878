import { Component, OnInit,OnDestroy } from '@angular/core';
import { LoginServiceService } from './login-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {  FormGroup, FormBuilder,Validators } from '@angular/forms';
import { RandomService } from '../random.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginUser!: FormGroup;
  forgetUser!: FormGroup;
  value: any;
  public token!: string;
  
  constructor(private loginService: LoginServiceService, readonly router:Router,
    private form: FormBuilder,private toastr:ToastrService,
    private random:RandomService) {
      this.forgetUser=this.form.group({
        email: ['',[Validators.required]],
      })
     }

  ngOnInit(): void {
    this.loginUser = this.form.group({
      username: ['',[Validators.required]],
      password: ['',[Validators.required]]
    });

  }

  loginuser(){
    this.loginService.loginDetails(this.loginUser.value).subscribe((res) =>{
     this.loginUser = res;
     this.value = res.data.token;
    if(res){
      
    localStorage.setItem('token',(this.value));
    localStorage.setItem('partyId',(res.data.userLogin.partyId));
    localStorage.setItem('email',(res.data.email));
    localStorage.setItem('adminSecurity',(res.data.securityGroups[0]));
    localStorage.setItem('firstName',(res.data.firstName));
    localStorage.setItem('lastName',(res.data.lastName));
   this.toastr.success("Login Successfully");
   this.token = localStorage.getItem('token')!;
   this.router.navigate(['/']);
   this.random.notify(this.value);
      
 
    }
  else if(res.success == false) {
      this.toastr.error("Login failed, display error to user");
   }
  },(err) => {
    this.toastr.error(err.error.message);
 }
 );
  }
  
  onSubmit(){
    
    this.loginService.resetPassword(this.forgetUser.value.email).subscribe((res:any) =>{
      if (res.success) {
        this.toastr.success("Created Successfully");
       
      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);
       
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
        
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
          
          this.toastr.error(err.error.message);
        }
      }
    })
  }
  goToRegister(){
    this.router.navigate(["register"]);
  }
}
