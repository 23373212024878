<div class="container-fluid px-4">
    <div class="card mt-3" style="border: none!important;">
        <div class="card-title" style="background-color: white!important; border:none!important">
            <h5 class="text-left pt-4 px-4">My Account</h5>
        </div>
        <div class="card-body pt-0">
            <div class="row px-2">
                <div class="col-lg-3">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              OVERVIEW
                            </button>
                          </h2>
                          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <ul class="list-group"><h6>List of Recent Purchases by</h6>
                                    <li class="list-group-item" routerLink="date-filter" routerLinkActive="router-link-active" ><span >Date</span></li>
                                    <li class="list-group-item" routerLink="amount-filter" routerLinkActive="router-link-active"><span >Amount</span></li>
                                    <li class="list-group-item"><span >Status</span></li>
                                </ul>
                                <ul class="list-group"><h6></h6>
                                    <li class="list-group-item"><span >Track Items</span></li>
                                    <li class="list-group-item"><span >My settings</span></li>
                                    <li class="list-group-item" routerLink="shipping" routerLinkActive="router-link-active">Shipping</li>
                                    <li class="list-group-item" routerLink="payment-method" routerLinkActive="router-link-active">Payment</li>
                                </ul>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              PURCHASES
                            </button>
                          </h2>
                          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <ul class="list-group"><h6>Purchase History</h6>
                                    <li class="list-group-item" class="list-group-item" routerLink="order-history" routerLinkActive="router-link-active">Purchase history</li>
                                    <li class="list-group-item" routerLink="purchase-products" routerLinkActive="router-link-active" ><span >Purchase</span></li>
                                    <li class="list-group-item" routerLink="order" routerLinkActive="router-link-active">Orders</li>
                                    <li class="list-group-item" routerLink="return-products" routerLinkActive="router-link-active"><span >Returns</span></li>
                                    <li class="list-group-item" routerLink="reorder-items" routerLinkActive="router-link-active"><span >Reorder Items</span></li>
                                    <li class="list-group-item" class="list-group-item" routerLink="quotes" routerLinkActive="router-link-active">Quotes</li>
                                    <li class="list-group-item" routerLink="messages" routerLinkActive="router-link-active">Messages</li>
                                </ul>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              PRODUCT LIST
                            </button>
                          </h2>
                          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <ul class="list-group"><h6>Wish List</h6>
                                    <li class="list-group-item" routerLink="all-my-list" routerLinkActive="router-link-active"><span > All my lists</span></li>
                                    <li class="list-group-item" routerLink="my-wish-list" routerLinkActive="router-link-active"><span >My list</span></li>
                                </ul>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingEight">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#headingEight" aria-expanded="false" aria-controls="headingEight">
                              LIST
                            </button>
                          </h2>
                          <div id="headingEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <ul class="list-group"><h6>Wish List</h6>
                                    <li class="list-group-item" (click)="goToMessages()" routerLinkActive="router-link-active"><span>Messages</span></li>
                                    <li class="list-group-item" (click)="goToQuotes()" routerLinkActive="router-link-active"><span>Quotes</span></li>
                                    <li class="list-group-item" (click)="goToRequests()" routerLinkActive="router-link-active"><span>Request</span></li>
                                    <li class="list-group-item" (click)="goToShoppingList()" routerLinkActive="router-link-active"><span>Shopping List</span></li>
                                    <li class="list-group-item" (click)="goToQuickAdd()" routerLinkActive="router-link-active"><span>Quick Add</span></li>


                                </ul>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                BILLING
                              </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                  <ul class="list-group"><h6>Invoices</h6>
                                      <li class="list-group-item"><span >Account balance</span></li>
                                      <li class="list-group-item"><span >Credits</span></li>
                                  </ul>
                                  <ul class="list-group"><h6></h6>
                                    <li class="list-group-item" routerLink="transiction-history" routerLinkActive="router-link-active"><span >Transaction History</span></li>
                                    <li class="list-group-item" routerLink="print-statement" routerLinkActive="router-link-active"><span >Print a statement</span></li>
                                  </ul>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFive">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                SETTINGS
                              </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                  <ul class="list-group"><h6>Profile information</h6>
                                      <li class="list-group-item" routerLink="personal-information" routerLinkActive="router-link-active">Personal Information</li>
                                      <li class="list-group-item" routerLink="contact-information" routerLinkActive="router-link-active">Contact Information</li>
                                     
                                      <li class="list-group-item" routerLink="file-manager" routerLinkActive="router-link-active">File Manager</li>
                                  </ul>
                                  <ul class="list-group"><h6>Email Preferences</h6>
                                      <li class="list-group-item"><span >Newsletter</span></li>
                                      <li class="list-group-item"><span >Subscription</span></li>
                                  </ul>
                                  <ul class="list-group"><h6>Surveys</h6>
                                      <li class="list-group-item"><span >Address Book</span></li>
                                      <li class="list-group-item"><span >Credit Cards</span></li>
                                      <li class="list-group-item"><span > Update your Password</span></li>
                                      <li class="list-group-item"><span >Manage Subscriptions</span></li>
                                  </ul>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingSix">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                CASES
                              </button>
                            </h2>
                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                  <ul class="list-group"><h6>Support Cases</h6>
                                      <li class="list-group-item"><span >Reply by Subject</span></li>
                                      <li class="list-group-item"><span >Type of inquiry</span></li>
                                      <li class="list-group-item"><span >Message</span></li>
                                  </ul>
                                  <ul class="list-group"><h6></h6>
                                      <li class="list-group-item"><span >Submit New Case</span></li>
                                  </ul>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item" style="border-bottom:none!important">
                            <h2 class="accordion-header" id="headingSeven">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                ACCOUNT MANAGEMENT
                              </button>
                            </h2>
                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                  <ul class="list-group"><h6></h6>
                                      <li class="list-group-item"><span >Manage Users</span></li>
                                      <li class="list-group-item"><span >Settings</span></li>
                                      <li class="list-group-item"><span >Automatically approve orders below $1,000</span></li>
                                      <li class="list-group-item"><span >Allow users to enter orders above their limit</span></li>
                                      <li class="list-group-item"><span >Allow change item line quantities</span></li>
                                  </ul>
                              </div>
                            </div>
                          </div>
                      </div>
                </div>
                <div class="col-lg-9">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>