<div class="container-fluid">
    <div class="row">
        <div id="div3" class="col-lg-9 mt-2 px-4">
            <div class="card mb-2">
                <div class="card-title p-2">
                    <strong>
                        Order Number
                        <a
                            class="btn btn-sm">{{allData.orderId}}</a>
                        Information
                        [ <a
                            class="btn btn-sm">PDF</a> ]
                    </strong>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>
                            Name : {{allData.createdBy}}
                      
                        </li>
                        <li>
                            Status : {{allData.statusId}}
                        </li>
                        <li>
                            Date : {{allData.createdTxStamp|date :'yyyy-MM-dd'}}
                            
                        </li>
                    </ul>
                </div>
            </div>
        
            <div class="card mb-2">
                <div class="card-title p-2">
                    <strong>Payment Information</strong>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>
                            {{paymentInfo.paymentType}}
                        </li>
                    </ul>
                </div>
            </div>
        
            <div class="card mb-2">
                <div class="card-title p-2">
                    <strong>Shipping Information</strong>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>
                            <ul class="list-unstyled">
                                <li>
                                    Destination [00001]
                                    To: Test User
                                </li>
                                <li>
                                    Attn : Test User 1
                                </li>
                                <li>
                                    address
                                </li>
                                <li>
                                    address
                                </li>
                                <li>
                                    CA
                                    , Armed Forces Americas
                                    11223
                                </li>
                                <li>
                                    United States
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul class="list-unstyled">
                                <li>
                                    Method:
                                    UPS
                                    Guaranteed Next Day
                                </li>
                                <li>
        
                                </li>
                            </ul>
                        </li>
                        <li>
                            Splitting Preference:
                            Please wait until the entire order is ready before shipping.
        
                        </li>
                        <li>
                            Gift?
                            This order is not a gift.
        
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mb-2">
                <form name="sendMeThisEveryMonthForm">
                    <input type="hidden" name="orderId" value="WSCO10730">
                    <input type="hidden" name="frequency" value="6">
                    <input type="hidden" name="intervalNumber" value="1">
                    <input type="hidden" name="shoppingListTypeId" value="SLT_AUTO_REODR">
                </form>
                <form name="addCommonToCartForm">
                    <input type="hidden" name="add_all" value="false">
                    <input type="hidden" name="orderId" value="WSCO10730">
                    <div class="card-title p-2">
                        <strong>
                            Order Items
                            <a
                                class="btn btn-sm float-right">Add All to Cart</a>
                            <a
                                class="btn btn-sm float-right">Add Checked to Cart</a>
                            <button class="btn btn-sm float-right" type="submit">Send me this
                                every month</button>
                        </strong>
                    </div>
                    <div class="card-body">
                        <table  class="table table-responsive-sm">
                            <thead class="thead-light">
                                <tr>
                                    <th>Product</th>
                                    <th>Qty Ordered</th>
                                    <th>Qty Picked</th>
                                    <th>Qty Shipped</th>
                                    <th>Qty Cancelled</th>
                                    <th class="amount">Unit Price</th>
                                    <th class="amount">Adjustments</th>
                                    <th class="amount">Subtotal</th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <tfoot *ngFor="let product of orderInfo">
                                <tr>
                                    <th colspan="7">Subtotal</th>
                                    <td class="amount">-${{product.orderItemSubTotal</td>
                                    <td colspan="3"></td>
                                </tr>
                                <tr>
                                    <th colspan="7">Promotion</th>
                                    <td class="amount">-${{product.adjustments</td>
                                    <td colspan="3"></td>
                                </tr>
                                <tr>
                                    <th colspan="7">Promotion</th>
                                    <td class="amount">-$10.00</td>
                                    <td colspan="3"></td>
                                </tr>
                                <tr>
                                    <th colspan="7">Promotion</th>
                                    <td class="amount">-$0.71</td>
                                    <td colspan="3"></td>
                                </tr>
                                <tr>
                                    <th colspan="7">Shipping and Handling</th>
                                    <td class="amount">$10.00</td>
                                    <td colspan="3"></td>
                                </tr>
                                <tr>
                                    <th colspan="7">Sales Tax</th>
                                    <td class="amount">$0.00</td>
                                    <td colspan="3"></td>
                                </tr>
                                <tr>
                                    <td colspan="3"></td>
                                    <td colspan="5"></td>
                                    <td colspan="3"></td>
                                </tr>
                                <tr>
                                    <th colspan="7">Grand Total</th>
                                    <td class="amount">
                                        {{allData.grandTotal}} </td>
                                    <td colspan="3"></td>
                                </tr>
                            </tfoot>
                            <tbody *ngFor="let product of orderInfo">
                                <tr>
                                    <td colspan="11"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <a class="linktext">{{product.productId}} {{product.itemDescription}} 
                                            Quantity :[{{product?.atpQuantityByFacility}}]
                                        </a>
                                    </td>
                                    <td>
                                        {{product.quantity}}
                                    </td>
                                    <td>
                                        {{product.pickedQty}}
                                    </td>
                                    <td>
                                        
                                    </td>
                                    <td>
                                        {{product.cancelQuantity}}
                                    </td>
                                    <td class="amount">
                                        {{product.unitPrice}} </td>
                                    <td class="amount">
                                        {{product.adjustments}} </td>
                                    <td class="amount">
                                        {{product.orderItemSubTotal</td>
                                    <td></td>
                                    <td>
                                        <input name="item_id" value="00001" type="checkbox">
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td class="row"><label class="col-sm-6 col-form-label">Reason</label>
                                        <select name="irm_00001" class="form-control custom-select col-sm-4">
                                            <option value=""></option>
                                            <option value="OICR_MISORDER_ITEM">Mis-Ordered Item</option>
                                            <option value="OICR_CHANGE_MIND">Changed Mind</option>
                                        </select>
                                        <label class="col-sm-6 col-form-label">Comments</label>
                                        <input class="form-control col-sm-2" type="text" name="icm_00001" value="" size="30"
                                            maxlength="60">
                                    </td>
                                    <td>
                                        <a
                                            class="d-inline-block mt-2">Cancel</a>
                                        <input type="hidden" name="orderItemSeqId" value="00001">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Ship Group: [{{product.orderItemSeqId}}] address
                                    </td>
                                    <td>
                                        1
                                    </td>
                                    <td colspan="9"></td>
                                </tr>
                                <tr>
                                    <td colspan="11"></td>
                                </tr>
                            </tbody>
                        </table>
        
                    </div>
                </form>
            </div>
        </div>
        <div class="col-lg-3 px-4">
            <app-right-side-bar></app-right-side-bar>
        </div>
    </div>
</div>