<div class="container-fluid">
    <div>
        <div class="row banner_div banner_section">
            <div class="col-md-12">
              <h2>{{this.productMap.productName?this.productMap.productName:"N/A"}}</h2>
            </div>
        </div>
        <div class="row" style="margin-top: 7%;">
            <div class="col-lg-1"></div>
        
            <div class="col-lg-5">
    
                <div class="row">
                    <div class="col-3">
                        <div>
                            <a (mouseover)="myimgcode($event)">
                                <img [src]="img[0]?img[0].objectInfo:img_missing" alt="img" width="100%" height="60px;">
                            </a>
                        </div>
                        <div class="pt-3">
                            <a (mouseover)="myimgcode($event)">
                                <img [src]="img[1]?img[1].objectInfo:img_missing" alt="img" width="100%" height="60px;">
                            </a>
                        </div>
                        <div class="pt-3">
                            <a (mouseover)="myimgcode($event)">
                                <img [src]="img[2]?img[2].objectInfo:img_missing" alt="img" width="100%" height="60px;">
                            </a>
                        </div>
                    </div>
                    <div class="col-9">
                        <a>
                            <img [src]="current_img?current_img:this.img[0].objectInfo" alt="img" width="100%">
                        </a>
                    </div>
    
                </div>
    
            </div>
            <div class="col-lg-4">
                <a routerLink="/">
                    <p>Back to Shop</p>
                </a>
                <h5>{{this.productMap.productName?this.productMap.productName:"N/A"}}</h5>
                <!-- <Span class="mt-3">ADD A REVIEW</Span> -->
    
                <h5>${{this.productMap.totalPrice?this.productMap.totalPrice:"N/A"}}</h5>
                <ul style="list-style: none;padding: 0;">
                    <li class="pt-3">
                        <span><strong>Availablity : </strong></span>
                        <span>In Stocks</span>
    
                    </li>
                    <!-- <li class="pt-3">
                        <span><strong>Product Code : </strong></span>
                        <span>#EM1216</span>
    
                    </li>
                    <li class="pt-3">
                        <span><strong>Tags :</strong></span>
                        <span>TanBeltsLeather</span>
    
                    </li> -->
                </ul>
                <div class="mt-3 mb-3">
                    <span><strong>Description :</strong></span>

                    <span>
                        {{this.productMap.productDescription}}
                    </span>
                </div>
                <!-- <ul style="padding: 0;">
                    <li>Pure Leather</li>
                    <li>Water Proof</li>
                    <li>Steel Buckle</li>

                </ul> -->
                <div>
                    <form [formGroup]="addtoCartForm">
                        <div class="row">
                            <!-- <div class="col-lg-2">
                                <p class="pt-2">Quantity:</p>
                            </div> -->
                            <div class="col-lg-12">
                                <input type="number" min="1" class="form-control mb-2" formControlName="quantity" id="username" aria-describedby="emailHelp"
                                    placeholder="Quantity" style="width: 27%;">
                            </div>
                        </div>
                    </form>
                    <!-- <select class="form-select form-select my-2" aria-label=".form-select example" style="width: 27%;">
                        <option selected>Quantity</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </select> -->

                </div>
                <div class="mt-4">
                    <!-- <p>
                        <a>Add To Wishlist</a>
                    </p> -->
                </div>
                <div>
                    <button class="btn btn-lg btn-warning mr-3" style="margin-right: 15px;" (click)="addItemToCart()">Add To Cart</button>
                    <button class="btn btn-lg btn-warning mr-3" (click)="addtoQuickBook()">Buy Now</button>

                </div>
            </div>
        

    
        </div>
        <div class="container">
            <div class="card mx-auto mt-5" style="width: 100%;">
                <div class="card-title">
                    <h5 class="text-left my-3 mx-2"><strong>Product List</strong> </h5>
                </div>
                <div class="p-2">
                    <form [formGroup]="CatalogForm">
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
 
   
    <div  class="row" style="display: none;">
        <div class="col-lg-3 order-lg-2 order-2 px-4">
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Choose Catalog</p>
                </div>
                <div class="p-2">
                    <form [formGroup]="CatalogForm">
                        <div class="form-group p-1">
                            <label class="mb-2" for="exampleInputEmail1">Choose Catalog</label>
                            <p-dropdown [options]="catalogArray" placeholder="Select Catalog"
                                formControlName="catalogId" (onChange)="getCategoryByCatalog($event)">
                            </p-dropdown>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width:100%">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Search Catalog</p>
                </div>
                <div class="p-2">
                    <form [formGroup]="findForm">
                        <div class="form-group p-1">
                            <label class="mb-2" for="exampleInputEmail1">Search Catalog</label>
                            <input type="email" class="form-control mb-2" formControlName="findCatalog" id="username"
                                aria-describedby="emailHelp" placeholder="">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="OR" formControlName="radioAll"
                                    name="searchCatalogueRadio" id="searchCatalogueRadio" checked>
                                <label class="form-check-label" for="searchCatalogueRadio">Any</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" value="AND" formControlName="radioAll" type="radio"
                                    name="searchCatalogueRadio" id="searchCatalogueRadio">
                                <label class="form-check-label" for="searchCatalogueRadio">All</label>
                            </div>
                        </div>
                        <div class="form-group p-1">
                            <input class="btn btn-outline-secondary" type="submit" value="Find"
                                (click)="goToFindCatalog()">
                        </div>
                        <div class="form-group p-1">
                            <input class="btn btn-outline-secondary" type="submit" value="Advanced Search"
                                (click)="goToAdvancedSearch()">
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Categories</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                            <p-tree [value]="category" selectionMode="single"></p-tree>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Quick Recorder</p>
                </div>
                <div class="p-2">
                    <span class="span-link">Tiny Gizmo</span>
                    <ul class="list-unstyled">
                        <li>GZ-1000 <span>$12.00</span></li>
                        <li><span class="span-link">Add To Cart</span></li>
                    </ul>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Sign Up For Contact List</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                            <p>Sign Up For Contact List LogIn</p>
                            <p><span class="span-link">Login </span>THE ADMINISTRATOR</p>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Mini Poll</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                            <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box
                                show?</label>
                            <select class="form-select form-select my-2" aria-label=".form-select example">
                                <option selected>Right Side</option>
                                <option value="1">Right Side</option>
                                <option value="2">Left Side/option>
                            </select>
                            <p>*</p>
                            <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box
                                show?</label>
                            <select class="form-select form-select my-2" aria-label=".form-select example">
                                <option selected>Right Side</option>
                                <option value="1">Right Side</option>
                                <option value="2">Left Side</option>
                            </select>
                            <p>*</p>
                            <input class="btn btn-outline-secondary" type="submit" value="Vote!">
                        </div>
                    </form>
                </div>
            </div>
           <!--  <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Forums</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Ask the Experts</span><br>
                        <span class="span-link">Ask the Experts</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Widgets</span><br>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Content</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Store Policies 2</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Cookie Policy</span><br>
                        <span class="span-link">Policies</span><br>
                        <span class="span-link">Store Policies</span><br>
                        <span class="span-link">Store Policies 2</span><br>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Policies</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Cookie Policy</span><br>
                        <span class="span-link">Store Policies</span><br>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Blogs</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Big AI</span><br>
                        <span class="span-link">Big AI</span><br>
                        <span class="span-link">Mad Max</span><br>
                        <span class="span-link">Mad Max</span><br>
                        <span class="span-link">xczx</span><br>
                    </form>
                </div>
            </div> -->
        </div>
        <div class="col-lg-6 order-lg-2 order-1 px-4">
            <div id="productdetail" class="card mx-auto mt-2" style="width:100%!important">
                <div class="row ps-2">
                    <div class="col-auto mt-3 ml-2"> <a class="btn btn-outline-secondary">Previousss</a>
                    </div>
                    <div class="col-auto mt-3"> <a class="btn btn-outline-secondary">Next</a>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <table class="table table-responsive-sm">
                        <tbody>
                            <tr>
                                <td> <a> <img [src]="this.productMap.productLargeImageUrl" alt="Image"> </a> </td>
                                <td>
                                    <h2>{{this.productMap.productName}}</h2>
                                    <div>
                                        {{this.productMap.productDescription}}
                                    </div>
                                    <div>
                                        <b> {{this.productMap.productId}}</b>
                                    </div>
                                    <div>
                                        Product Aggregated Price: <span id="totalPrice" class="basePrice">
                                            ${{this.productMap.totalPrice}}</span>
                                    </div>
                                    <div>
                                        &nbsp;
                                    </div>
                                    <div> <a>Tell-A-Friend</a>
                                    </div>
                                    <form method="post" action="" name="addform">
                                        <input type="hidden" name="product_id" value="ENCHILADAS">
                                        <input type="hidden" name="add_product_id" value="ENCHILADAS">

                                        <div class="input-group">
                                            <input type="text" size="5" name="quantity" value="1"
                                                class="form-control form-control-sm"> <span class="input-group-button">
                                                <a href="javascript:addItem()" class="btn btn-outline-secondary btn-sm">
                                                    Add to Cart </a> </span>
                                        </div>
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <td> You Must <a class="buttontext">Login</a> To Add Selected Items To Shopping
                                    List.&nbsp; </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table">
                    </table>
                    <hr>
                    <div id="reviews"> 
                        <h4>Customer Reviews :</h4> 
                        <p>This product hasn't been reviewed yet. <a class="linktext">Be The First To Review This Product!</a> </p> 
                       </div>
                    <hr>
                    <div class="product-tags">
                        <h4>Product Tags</h4>
                        <div class="form-group"> <label>Add your tags:</label>
                            <form [formGroup]="tagForm" name="addProductTags">
                                <input type="hidden" name="productId" value="10110">
                                <div class="input-group">
                                    <input class="inputProductTags form-control" type="text" value="" name="productTags"
                                    formControlName="tag"  id="productTags" size="40"> <span class="input-group-btn"><input
                                            class="buttonProductTags btn btn-outline-secondary" type="submit"
                                            value="Add Tags" name="addTag" (click)="onSubmit()"></span>
                                </div>
                            </form>
                        </div> <span>Use spaces to separate tags. Use single quotes(') for phrases.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 order-lg-2 order-3 px-4">
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Language</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                            <p-dropdown [options]="localeArray" placeholder="Select Language">
                            </p-dropdown>
                        </div>
                    </form>
                </div>
            </div>

            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                  <p class="text-left my-3 mx-2">Cart Summary</p>
                </div>
                <div class="p-2">
                  <form>
                    <div class="card-body">
                      <table class="table">
                        <thead>
                        <tr>
                          <th>Qty</th>
                          <th>Item </th>
                          <th>Subtotal</th>
                        </tr>
                        </thead>
                        <tfoot>
                        <tr>
                          <td colspan="3"  *ngIf="this.summary">
                          {{this.summary.cartTotal}}
                          </td>
                        </tr>
                        </tfoot>
                        <tbody>
                          <tr  *ngFor="let product of this.showCart;let indexOfelement=index;">
                            <td>{{indexOfelement+1}}</td>
                            <td>
                                  <span class="span-link">{{product.productName}}</span>
                            </td>
                            <td>${{product.itemTotal}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <ul>
                        <li><span class="span-link" (click)="goToViewCart()">View Cart</span></li>
                        <li><span class="span-link" (click)="goToCheckOut()">Check out</span></li>
                        <li><span class="span-link" (click)="goToQuickCheckOut()">Quick Checkout</span></li>
                        <li><span class="span-link" (click)="goToOnePageCheckOut()">One Page Checkout</span></li>
                      </ul>
                       
                    </div>
                  </form>
                </div>
              </div>
            <!-- <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Cart Summary</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="card-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Qty</th>
                                        <th>Item </th>
                                        <th>Subtotal</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <td colspan="3">
                                            Total: $10.80
                                        </td>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <span class="span-link">Tiny Gizmo</span>
                                        </td>
                                        <td>$12.00</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ul>
                                <li><span class="span-link" (click)="goToViewCart()">View Cart</span></li>
                                <li><span class="span-link" (click)="goToQuickCheckOut()">Check out</span></li>
                                <li><span class="span-link" (click)="goToQuickCheckOut()">Quick Checkout</span></li>
                                <li><span class="span-link" (click)="goToOnePageCheckOut()">One Page Checkout</span>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div> -->
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Compare Products</p>
                </div>
                <div class="p-1">
                    <form>
                        <div class="card-body">
                            <p-table [value]="messages" [paginator]="false" [rows]="rows" scrollWidth="100%"
                                [rowsPerPageOptions]="[10,25,50, 100]" [totalRecords]="total" [scrollable]="true"
                                [style]="{'width':'100%','min-width':'600px'}">
                                <ng-template pTemplate="header"></ng-template>
                                <ng-template pTemplate="body">
                                    <tr>
                                        <td>
                                            Team Product
                                        </td>
                                        <td>
                                            <form method="post" action="" name="removeFromCompare0form">
                                                <input type="hidden" name="productId" value="10110">
                                            </form>
                                            <a class="btn btn-link btn-sm">Remove</a>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" let-columns>
                                    <tr>
                                        <td>
                                            <span class="text-center">
                                                <h6 class="ml-2">No Record Found
                                                </h6>
                                            </span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div>
                                <a href="" class="btn btn-outline-secondary">Clear All</a>
                            </div>
                            <div>
                                <!--Modal Button-->
                                <a class="btn btn-outline-secondary" data-bs-toggle="modal"
                                    data-bs-target="#exampleModal">Compare Products</a>
                            </div>
                            <!--Modal Popup Dialog Box-->
                            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="card m-5">
                                                <div class="card-header">
                                                    <strong>Compare Products</strong>
                                                </div>
                                                <div class="card-body">
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                                <td style="width:100%;">
                                                                    <img src="" alt="Small Image"><br>
                                                                    Team Product<br>

                                                                    <span class="regularPrice">$1,000.00</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Brand Name</td>
                                                                <td>Team</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Product Description</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Long Description</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                                <td>
                                                                    <div class="productbuy">
                                                                        <form method="post" action=""
                                                                            name="compare2FormAdd0">
                                                                            <input type="hidden" name="add_product_id"
                                                                                value="10110">
                                                                            <input type="text" size="5" name="quantity"
                                                                                value="1">
                                                                            <input type="hidden" name="clearSearch"
                                                                                value="N">
                                                                        </form>
                                                                        <a class="buttontext">Add to Cart </a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Special Offers</p>
                </div>
                <div class="p-1">
                    <form>
                        <div class="form-group">
                            <ul>
                                <li>
                                    <p>
                                        <a (click)="goToShowPromotionDetails()" class="linktext">Details</a>
                                        Free Shipping in Utah
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <a (click)="goToShowPromotionDetails()" class="linktext">Details</a>
                                        10% off entire purchase
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <a (click)="goToShowPromotionDetails()" class="linktext">Details</a>
                                        Buy 3 Get 2 Free in the Widgets [200] or any sub-category (except the Small
                                        Widgets [201] category
                                        and sub-categories, but always including the Micro Widgets [20111] category),
                                        limit to two per order
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div class="form-group py-2 ps-4">
                            <input class="btn btn-outline-secondary" type="submit" value="View All Promotions"
                                (click)="goToViewAllPromotions()">
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Did You Know?</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group">
                            <ul>
                                <li>
                                    <p>
                                        The use of gizmos has been shown to have no negative effect on personal
                                        longetivity.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Did you know that widget users live twice as long as their non-widget
                                        counterparts?
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <div class="float-start">
                        <p class="text-left my-3 mx-2">Last Categories</p>
                    </div>
                    <div class="float-end my-3 mx-2">
                        <span class="span-link">[Clear]</span>
                    </div>
                </div>
                <div class="card-body">
                    <ul class="browsecategorylist">
                        <li class="browsecategorytext">
                            <span class="span-link">
                                PROMOTIONS
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <div class="float-start">
                        <p class="text-left my-3 mx-2">Last Products</p>
                    </div>
                    <div class="float-end my-3 mx-2">
                        <span class="span-link">[Clear] </span><span class="span-link">[More]</span>
                    </div>
                </div>
                <div class="card-body">
                    <ul>
                        <li>
                            <a href="" class="linktext">
                                Gift Card Activation
                            </a>
                            <ul class="list-unstyled">
                                <li>
                                    GC-001
                                    From
                                    <span class="normalPrice">
                                        $1.00</span>
                                </li>
                                <li>
                                    <a href="" class="buttons">
                                        <span style="white-space: nowrap;">Choose Variations...</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="" class="linktext">
                                Enchiladas
                            </a>
                            <ul class="list-unstyled">
                                <li>
                                    ENCHILADAS
                                    Product Aggregated Price:
                                    <span class="basePrice">
                                        $18.00 </span>
                                </li>
                                <li>
                                    <form method="post" action="" name="lastviewed1form" style="margin: 0;">
                                        <fieldset>
                                            <input type="hidden" name="add_product_id" value="ENCHILADAS">
                                            <input type="hidden" name="quantity" value="1">
                                            <input type="hidden" name="clearSearch" value="N">
                                            <a href="javascript:document.lastviewed1form.submit()" class="buttons">
                                                <span style="white-space: nowrap;">
                                                    Add 1 To Cart
                                                </span>
                                            </a>
                                        </fieldset>
                                    </form>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="" class="linktext">
                                Financial Account Activation
                            </a>
                            <ul class="list-unstyled">
                                <li>
                                    FA-001
                                    From
                                    <span class="normalPrice">
                                        $1.00</span>
                                </li>
                                <li>
                                    <a href="" class="buttons">
                                        <span style="white-space: nowrap;">Choose Variations...</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="" class="linktext">
                                Soft Drink
                            </a>
                            <ul class="list-unstyled">
                                <li>
                                    12121
                                </li>
                                <li>
                                    <a href="" class="buttons">
                                        <span style="white-space: nowrap;">Choose Amount...</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="" class="linktext">
                                Gift Card Reload
                            </a>
                            <ul class="list-unstyled">
                                <li>
                                    GC-002
                                </li>
                                <li>
                                    <a href="" class="buttons">
                                        <span style="white-space: nowrap;">Choose Amount...</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Tag Cloud</p>
                </div>
                <div class="p-2">
                    <a  *ngFor="let cloud of tagCloudList"  [style.font-size.pt]="cloud.fontSize"
                    href="https://34.196.47.126:8443/ecommerce/control/tagsearch?SEARCH_STRING=big&amp;keywordTypeId=KWT_TAG&amp;statusId=KW_APPROVED">
                    {{cloud.tag}}
                  </a>
                </div>
            </div>
        </div>
    </div>
</div>