import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from '../services/services.service';
import { SideBarService } from '../side-bar/side-bar.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.css']
})
export class ContactInformationComponent implements OnInit {

  total = 0;
  pageNo = 1;
  rows = 50;
  pageSize = 100;
  rowNumber = 0;
  search = "";
  personal: any[] = [];
  accountDetails: any;
  isAdditionalDetail!: boolean;
  isJobDetail!: boolean;
  contactInformation: any;
  relatedContact: any;
  accountName: any;
  addr!: string;
  emailId: any;
  phoneNumber!: string;
  partyId!: any;
  getcontactInformation: any;
  constructor(readonly router: Router, private accountsService: ServicesService,
    private sidebarService:SideBarService,) { }

  ngOnInit(): void {
    this.partyId = localStorage.getItem('partyId');
    //this.partyId = 'Company';
    this.accountDetail();
    this.customerProfile();
  }

  goToCreateContactInformation() {
    this.router.navigate(["/create-contact"]);
  }
  goToCreateAddress() {
    this.router.navigate(['/create-address']);
   }
  goToCreateEmail() {
    this.router.navigate(['/create-email']);
   }
   customerProfile() {
    this.sidebarService.customerProfile().subscribe((res: any) => {
      this.getcontactInformation = res.data.contactInformation;

    })
  }
  accountDetail() {
    //this.spinner.show();
    this.accountsService.getContactById(this.partyId).subscribe(res => {
      this.accountDetails = res.data;
      res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;
      this.relatedContact = res.data.relatedContacts;
      this.accountName = res.data.groupName;
      this.addr = res.data.address.address1 + ' ' + res.data.address.city + ' ' + res.data.address.postalCode + ' ' + res.data.address.countryGeoId;
      this.emailId = res.data.email.infoString;
      this.phoneNumber = res.data.telenumber.countryCode + ' ' + res.data.telenumber.areaCode + ' ' + res.data.telenumber.contactNumber;
      
    })
    //this.spinner.hide();
  }

  update(product: any) {
    if (product.contactMech.contactMechTypeId === "TELECOM_NUMBER") {
      this.router.navigate(["/create-contact"], {
        queryParams: {
          countryCode: product.telecomNumber.countryCode,
          areaCode: product.telecomNumber.areaCode, contactNumber: product.telecomNumber.contactNumber,
          contactMechId:product.telecomNumber.contactMechId
        }
      })
    }
    if (product.contactMech.contactMechTypeId === "POSTAL_ADDRESS") {
      this.router.navigate(["/create-address"], {
        queryParams: {
          address1: product.postalAddress.address1, address2:product.postalAddress.address2,
          state: product.postalAddress.stateProvinceGeoId, city: product.postalAddress.city,
          postalCode:product.postalAddress.postalCode,country:product.postalAddress.countryGeoId,
          contactMechId:product.postalAddress.contactMechId
        }
      })
    }
    if (product.contactMech.contactMechTypeId === "EMAIL_ADDRESS") {
      this.router.navigate(["/create-email"], {
        queryParams: {
          email:product.contactMech.infoString,contactMechId:product.contactMech.contactMechId
        }
      })
    }
  }
 
  onDelete(id:any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, expire it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.sidebarService.contactExpire(id).subscribe(res => {
         
          this.customerProfile();
        });
        Swal.fire(
          'Expired!',
          'Expired Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
   
  }
}
