import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';



@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(
        private _ToastrService: ToastrService,
        private _HttpClient: HttpClient
    ) { }


    postRequest<T>(url: string, body: any, headers?: HttpHeaders): Promise<T> {
        let request;
        if (headers) {
            request = this._HttpClient.post(url, body, { headers, withCredentials: true });
        } else {
            request = this._HttpClient.post(url, body);
        }
        return request
            .toPromise()
            .then(res => {
                this.handleResponseException(res);
                return res as T;
            }).catch(err => {
                this.handleError(err);
                return {} as T;
            });
    }
    getRequest<T>(url: string, headers?: HttpHeaders): Promise<T> {
        let request;
        if (headers) {
            request = this._HttpClient.get(url, { headers, withCredentials: true });
        } else {
            request = this._HttpClient.get(url);
        }
        return request
            .toPromise()
            .then(res => {
                return res as T;
            }).catch(err => {
                this.handleError(err);
                return {} as T;
            });
    }
    putRequest<T>(url: string, body: any, headers?: HttpHeaders): Promise<T> {
        let request;
        if (headers) {
            request = this._HttpClient.put(url, body, { headers });
        } else {
            request = this._HttpClient.put(url, body);
        }
        return request
            .toPromise()
            .then(res => {
                return res as T;
            }).catch(err => {
                this.handleError(err);
                return {} as T;
            });
    }
    deleteRequest<T>(url: string, headers?: HttpHeaders): Promise<T> {
        let request;
        if (headers) {
            request = this._HttpClient.delete(url, { headers });
        } else {
            request = this._HttpClient.delete(url);
        }
        return request
            .toPromise()
            .then(res => {
                return res as T;
            }).catch(err => {
                this.handleError(err);
                return {} as T;
            });
    }
    handleError(err: HttpErrorResponse | any): void {
        if (err.status === 500) {
            this._ToastrService.error('Server not available');
        } else if (err.status === 404) {
            this._ToastrService.error('Method/API not available');
        } else if (err.status === 400) {
            // this._ToastrService.error('Bad Request');
            if (err.error.errors) {
              /*   err.error.errors.forEach(value => {
                    this._ToastrService.error(`${value.fieldName} ${value.fieldError}`);
                }); */
            } /* else {
                this._ToastrService.error(err.error.message);
                err.error.debugMessage ? this._ToastrService.error(err.error.debugMessage) : 'Error'
            } */
        }
    }
    handleResponseException(err: any): void {
        if (err.status === 200) {
        this._ToastrService.error(err.data.responseMessage);
        }
    }
}