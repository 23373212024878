<div class="container-fluid">
  <!-- <div class="row">
    <div class="col-lg-12 mt-2">
      <div class="d-lg-flex justify-content-end">
        <div class="px-lg-3 ps-0" style="border-right: 2px solid grey!important;"><span style="color:red!important">FREE SHIPPING ON ALL ORDERS!</span></div>
        <div class="px-lg-3 ps-0" style="border-right: 2px solid grey!important;"><span class="span-grey" (click)="goToContactUs()">CONTACT US</span></div>
        <div class="px-lg-3 ps-0" style="border-right: 2px solid grey!important;" *ngIf="!this.show"><span class="span-grey" (click)="goToLogin()">SIGN IN</span></div>
        <div class="px-lg-3 ps-0" style="border-right: 2px solid grey!important;" *ngIf="this.show"><span class="span-grey" (click)="logout()">SIGN OUT</span></div>
        <div class="px-lg-3 ps-0"><span class="span-grey" (click)="goToRegister()">CREATE AN ACCOUNT</span></div>
      </div>
    </div>
  </div> -->
  <!-- New UI code -->
  <div class="row header_div">
    <div style="padding: 5px 60px;">
      <div class="user_profile">
        <span class="top_icon cart_icon_div">
          <a routerLink="/view-cart" class="nav-itemnotifications fontsize header_img">
            <i class="fa fa-shopping-cart shop_cart_img" style="font-size:15px;color:#FFFFFF;margin-left: -5px;"></i>

            
          </a>
        </span>

        <span class="save_icon_div">
          <a routerLink="/shoppingList" class="nav-item header_img messages fontsize">
            <i class="fa fa-heart" style="font-size:15px;color:#FFFFFF;"></i>
          </a>
        </span>
        <span class="save_icon_div">
          <a  class="nav-item header_img messages fontsize" title="News">
            <i class="fa fa-newspaper-o" style="font-size:15px;color:#FFFFFF;"></i>
          </a>
        </span>
        <span class="save_icon_div">
          <a  class="nav-item header_img messages fontsize" title="Info">
            <i class="fa fa-volume-control-phone" style="font-size:17px;color:#FFFFFF;"></i>
          </a>
        </span>
        <span class="save_icon_div">
          <a  class="nav-item header_img messages fontsize" title="User">
            <i class="fa fa-user-circle-o" style="font-size:16px;color:#FFFFFF;"></i>
          </a>
        </span>

        <div class="dropdown" style="margin-left: 12px;">
          <a class="" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <img src="../../assets/ecomm-user_img.jpg" class="avatar login-user-img" alt="Avatar">
          </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li ><a class="dropdown-item" routerLink="/profile/personal-information">My Profile</a></li>
            <li ><a class="dropdown-item" (click)="goToProfile()">My Account</a></li>
            <!-- <li><a class="dropdown-item" routerLink="/profile/order-history">Account</a></li> -->
            <li><a class="dropdown-item" routerLink="/header-messages">Messages</a></li>
            <li style="border-bottom: none;"><a class="dropdown-item" (click)="logout()"><span>Logout</span></a></li>
          </ul>
        </div>
      </div>
    </div>
  

  </div>
  <div class="row">
    <nav class="navbar navbar-expand-lg navbar-dark"
    style="background: #FFFFFF;padding: 0px;">
    <a class="navbar-brand" routerLink="/">
      <img class="img-fluid nav_logo_img" src="../../assets/FULLBLACkWHITE.jpg" alt="Logo">
    </a>
    <button class="navbar-toggler mx-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent" style="flex-direction: row-reverse;height: 32px;flex-wrap: wrap;">
      <!-- <ul class="navbar-nav me-auto mb-0 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link text-white px-3 px-lg-2 " (click)="goToMain()">Main</a>
          
        </li>
        <li class="nav-item">
          <div class="dropdown">
            <button class="btn btn-info dropdown-toggle pt-2 px-3 px-lg-2  nav-link text-start" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
              style="background-color: rgba(255, 255, 255, 0);color:white!important;font-size:0.9rem!important;
              border: none!important;border-radius:0!important;width:100%!important;outline: none!important;box-shadow: none!important;"
              aria-expanded="false">
              Products
            </button>
            <div class="dropdown-menu" style="min-width: auto!important; margin:12px 0 0 0!important; padding: 0!important;border-radius: 0!important;
            border-top:6px solid rgb(4, 126, 219)!important;" aria-labelledby="dropdownMenuButton1">
                <div class="d-lg-flex sm-row">
                  <div class="col-lg-12 px-4 mt-3 mt-lg-1 px-lg-2">
                    <li><a class="dropdown-item" (click)="goToProfile()">My Account</a></li>
                    <li><a class="dropdown-item" (click)="goToViewCart()">View Cart</a></li>
                    <li><a class="dropdown-item" (click)="goToCheckOutOption1()">Checkout</a></li>
                    <li><a class="dropdown-item" (click)="goToQuickCheckOut()">Quick Checkout</a></li>
                    <li><a class="dropdown-item" (click)="goToOnePageCheckOut()">One Page Checkout</a></li>
                    <li><a class="dropdown-item">Logout</a></li>
                  </div>
                  <div class="col-lg-3 px-4 mt-lg-1 px-lg-2">
                    <li><a class="dropdown-item" (click)="goToProfile()">My Account</a></li>
                    <li><a class="dropdown-item" (click)="goToViewCart()">View Cart</a></li>
                    <li><a class="dropdown-item" (click)="goToCheckOutOption1()">Checkout</a></li>
                    <li><a class="dropdown-item" (click)="goToQuickCheckOut()">Quick Checkout</a></li>
                    <li><a class="dropdown-item" (click)="goToOnePageCheckOut()">One Page Checkout</a></li>
                    <li><a class="dropdown-item">Logout</a></li>
                  </div>
                  <div class="col-lg-3 px-4 mt-lg-1 px-lg-2">
                    <li><a class="dropdown-item" (click)="goToProfile()">My Account</a></li>
                    <li><a class="dropdown-item" (click)="goToViewCart()">View Cart</a></li>
                    <li><a class="dropdown-item" (click)="goToCheckOutOption1()">Checkout</a></li>
                    <li><a class="dropdown-item" (click)="goToQuickCheckOut()">Quick Checkout</a></li>
                    <li><a class="dropdown-item" (click)="goToOnePageCheckOut()">One Page Checkout</a></li>
                    <li><a class="dropdown-item">Logout</a></li>
                  </div>
                  <div class="col-lg-3 px-4 mb-3 mb-lg-1 mt-lg-1 px-lg-2">
                    <li><a class="dropdown-item" (click)="goToProfile()">My Account</a></li>
                    <li><a class="dropdown-item" (click)="goToViewCart()">View Cart</a></li>
                    <li><a class="dropdown-item" (click)="goToCheckOutOption1()">Checkout</a></li>
                    <li><a class="dropdown-item" (click)="goToQuickCheckOut()">Quick Checkout</a></li>
                    <li><a class="dropdown-item" (click)="goToOnePageCheckOut()">One Page Checkout</a></li>
                    <li><a class="dropdown-item">Logout</a></li>
                  </div>
                </div>
            </div>
          </div>
        </li>
      </ul> -->
      <ul class="navbar-nav mb-2 mb-lg-0">
        <li class="nav-item " routerLinkActive="active">
          <a class="nav-link text-white px-3 px-lg-2 " (click)="goToMain()">Home</a>
          
        </li>
        <li class="nav-item">
          <div class="dropdown a">
            <button class="btn btn-info dropdown-toggle pt-2 px-3 px-lg-2  nav-link text-start" type="button" id="dropdownMenuButton1" data-bs-toggle=""
              style="background-color: rgba(255, 255, 255, 0);font-size:0.9rem!important;
              border: none!important;border-radius:0!important;width:100%!important;outline: none!important;box-shadow: none!important;font-weight: 500;"
              aria-expanded="false">
              Products
            </button>
            <div class="dropdown-menu b" style="min-width: auto!important; margin:12px 0 0 0!important; padding: 0!important;border-radius: 0!important;
            border-top:6px solid rgb(4, 126, 219)!important;margin-top: 0px !important;" aria-labelledby="dropdownMenuButton1">
                <div class="d-lg-flex sm-row">
                  <div class="col-lg-12 px-4 mt-3 mt-lg-1 px-lg-2">
                    <!-- <li><a class="dropdown-item" (click)="goToProfile()">My Account</a></li> -->
                    <li><a class="dropdown-item" (click)="goToViewCart()">View Cart</a></li>
                    <li><a class="dropdown-item" (click)="goToCheckOutOption1()">Checkout</a></li>
                    <li><a class="dropdown-item" (click)="goToQuickCheckOut()">Quick Checkout</a></li>
                    <li><a class="dropdown-item" (click)="goToOnePageCheckOut()">One Page Checkout</a></li>
              
                  </div>
                </div>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white px-3 px-lg-2">Industry</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white px-3 px-lg-2">Solutions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white px-3 px-lg-2">Resources</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white px-3 px-lg-2">Partner</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white px-3 px-lg-2">Service</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white px-3 px-lg-2 ">Company</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white px-3 px-lg-2 " routerLink="/contact-us">Contact Us
          </a>
        </li>
      </ul>
    </div>
    </nav>
  </div>
  
  

  <!-- New UI code end -->

  <!-- <div class="row">
    <div class="col-lg-2 col-sm-12 col-12 mt-2 mb-1">
      <div class="d-flex justify-content-left">
        <img class="img-fluid logo" src="assets/phyrstLogo.png" alt="Logo">
      </div>
    </div>
    <div class="col-lg-8 d-none d-lg-block">
      <div class="mt-2 mb-3">

      </div>
    </div>
    <div class="col-lg-2 col-sm-12 col-12" style="--bs-gutter-x: 0.0rem;">
      <div class="mt-3 mb-3 mx-2"><span *ngIf="this.show">Welcome {{this.username}} {{this.lastname}}</span>
        <div class="dropdown">
          <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            style="background-color: white!important;border: 2px solid rgb(0, 94, 166)!important;"
            aria-expanded="false">
            Shopping Cart
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li ><a class="dropdown-item"  (click)="goToProfile()">My Account</a></li>
            <li><a class="dropdown-item"  (click)="goToViewCart()">View Cart</a></li>
            <li><a class="dropdown-item"  (click)="goToCheckOutOption1()">Checkout</a></li>
            <li><a class="dropdown-item"  (click)="goToQuickCheckOut()">Quick Checkout</a></li>
            <li><a class="dropdown-item"  (click)="goToOnePageCheckOut()">One Page Checkout</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div> -->
</div>
