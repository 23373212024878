import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-show-promotion-details',
  templateUrl: './show-promotion-details.component.html',
  styleUrls: ['./show-promotion-details.component.css']
})
export class ShowPromotionDetailsComponent implements OnInit {
  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  locale: any;
  localeArray: any[] = [];
  address: any;
  partyTaxIdentification: any;
  id: any;
  partyTaxIdentificationData: any;
  groupNameValue: any;
  contactMechId: any;
  selecttaxAuthPartyGeoIds:any;
  partyTaxIdentificationArray: any[]=[];
  showCart: any;
  specialOffers: any;
  summary: any;
  catalogId: string | null;
  compareProductList: any;
  partyTaxAuthInfoAndDetailList: any;

  constructor(readonly router:Router,
    private sidebarService: SideBarService,
    private toastr: ToastrService) { 
      this.catalogId = localStorage.getItem("catalog");
    }

  ngOnInit(): void {
    this.getCompareProducts();
    this.getshowCart();

  }

  getCompareProducts() {
    this.sidebarService.getCompareProducts(this.catalogId).subscribe((res: any) => {
      this.compareProductList = res.data.productList;

    })
  } 
  removeAddToCompare(id:any): void {

    this.sidebarService.removeAddToCompare(id)
      .then(data => {
        if (data.success) {
          this.toastr.success(data.data.successMessage);
        this.getCompareProducts();
        }
      });
  
  }
  
  clearCompare() {
     
  
    this.sidebarService.clearCompare().subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.getCompareProducts();
  
      }
    }, (err) => {
   
      this.toastr.error(err.message);
  
    });
   
  }
  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
      this.showCart = res.data.productsList;
      this.specialOffers = res.data.specialOffers;
      this.summary = res.data.summary;

    })
  }

  goToFeaturedProducts(){
    this.router.navigate(["/"]);
  }
  goToAdvancedSearch(){
    this.router.navigate(["/advanced-search"]);
  }
  goToViewAllPromotions(){
    this.router.navigate(["/view-all-promotions"]);
  }
  goToShowPromotionDetails(){
    this.router.navigate(["/show-promotion-details"]);
  }

  goToViewCart(){
    this.router.navigate(["/view-cart"]);
  }
  goToQuickCheckOut(){
    this.router.navigate(["/quick-check-out"]);
  }
  goToOnePageCheckOut(){
    this.router.navigate(["/one-page-check-out"]);
  }
  

  goToCheckOutOption1(){
    this.router.navigate(["/check-out-option1"]);
  }
  goToCheckOut(){
    this.router.navigate(["/check-out-option1"]);
  }
}


