<div class="container-fluid p-0">
    <div class="row">
        <div class="col-lg-12 p-0">
            <div class="card" style="border: none!important;">
                <div class="card-title" style="background-color: white!important;border:none!important">
                    <div class="float-start">
                        <h6 class="text-left mt-3 mx-3"><h6>Messages</h6></h6>
                    </div>
                    <div class="float-end mt-3 me-4">
                        <span class="span-link">View Sent</span>
                    </div>
                </div>
                <div class="p-2 pt-0">
                    <div class="form-group px-2 table-scroll">
                        <p-table [value]="getMessageList" [paginator]="true" [rows]="rows"
                            scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100]"
                            [totalRecords]="total" [scrollable]="true" [style]="{'width':'100%','min-width':'600px'}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Subject</th>
                                    <th>Sent Date</th>
                                    <th>Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product>
                                <tr>
                                    <td>{{product.partyIdFrom}}</td>
                                    <td>{{product.partyIdTo}}</td>
                                    <td>{{product.subject}}</td>
                                    <td>{{product.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                    <td> <span class="span-link">Read</span></td>
                                </tr>
                            </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td>
                      <span class="text-center">
                          <h6 class="ml-2">No Record Found
                          </h6>
                      </span>
                  </td>
              </tr>
          </ng-template>
          </p-table>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
