<div class="container-fluid p-0 pt-3">
    <div class="card" style="border: none!important;">
      <div class="card-title px-1 pt-1 pb-0" style="background-color: white!important;border:none!important">
        <h6>Order history for orders entered by you</h6>
      </div>
      <div class="card-body pt-0 ps-1">
        <div class="row"></div>
        <div class="row">
          <div class="col-lg-12 table-scroll">
            <p-table [value]="orderList" [paginator]="true" [rows]="rows" scrollWidth="100%"
              [rowsPerPageOptions]="[10,25,50, 100]" [totalRecords]="total" [scrollable]="true"
              [style]="{'width':'100%','min-width':'600px'}">
              <ng-template pTemplate="header">
                <tr>
                  <th>Date</th>
                  <th>Order Nbr</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Invoices</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-product>
                <tr>
                  <td>{{product.orderDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                  <td>{{product.orderId}}</td>
                  <td>${{product.grandTotal}}</td>
                  <td>{{product.status}}</td>
                  <td>{{product.product}}</td>
                  <td><span class="span-link" (click)="goToViewOrder()">View</span></td>
                </tr>
               
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td>
                        <span class="text-center">
                            <h6 class="ml-2">No Record Found
                            </h6>
                        </span>
                      </td>
                </tr>
            </ng-template>
            </p-table>
            
          </div>
        </div>
      </div>
    </div>
    <div class="card" style="border: none!important;">
        <div class="card-title px-1 pt-1 pb-0" style="background-color: white!important;border:none!important">
          <h6>Order history for orders send to you</h6>
        </div>
        <div class="card-body pt-0 ps-1">
          <div class="row"></div>
          <div class="row">
            <div class="col-lg-12 table-scroll">
              <p-table [value]="orders" [paginator]="true" [rows]="rows" scrollWidth="100%"
                [rowsPerPageOptions]="[10,25,50, 100]" [totalRecords]="total" [scrollable]="true"
                [style]="{'width':'100%','min-width':'600px'}">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Date</th>
                    <th>Order Nbr</th>
                    <th>Amount</th>
                    <th>Status</th>
                   
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                  <tr>
                    <td>{{product.orderDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                    <td>{{product.orderId}}</td>
                    <td>${{product.grandTotal}}</td>
                    <td>{{product.status}}</td>
                   
                  </tr>
                 
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                      <td>
                          <span class="text-center">
                              <h6 class="ml-2">No Record Found
                              </h6>
                          </span>
                        </td>
                  </tr>
              </ng-template>
              </p-table>
              
            </div>
          </div>
        </div>
      </div>
      <div class="card" style="border: none!important;">
        <div class="card-title px-1 pt-1 pb-0" style="background-color: white!important;border:none!important">
          <h6>Downloads Available Title</h6>
        </div>
        <div class="card-body pt-0 ps-1">
          <div class="row"></div>
          <div class="row">
            <div class="col-lg-12 table-scroll">
              <p-table [value]="orders" [paginator]="true" [rows]="rows" scrollWidth="100%"
                [rowsPerPageOptions]="[10,25,50, 100]" [totalRecords]="total" [scrollable]="true"
                [style]="{'width':'100%','min-width':'600px'}">
                <ng-template pTemplate="header">
                  <tr>
                   
                    <th>Order Nbr</th>
                    <th>Product Name</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Action</th>
                   
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                  <tr>
                    <td>{{product.orderDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                    <td>{{product.orderId}}</td>
                    <td>${{product.grandTotal}}</td>
                    <td>{{product.status}}</td>
                    <td>{{product.status}}</td>
                   
                  </tr>
                 
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                      <td>
                          <span class="text-center">
                              <h6 class="ml-2">No Record Found
                              </h6>
                          </span>
                        </td>
                  </tr>
              </ng-template>
              </p-table>
              
            </div>
          </div>
        </div>
      </div>
  </div>