<div class="container-fluid">
    <div class="row banner_div banner_section">
        <div class="col-md-12">
          <h1 style="font-size: 3.25rem;font-weight: 500;">Here's what's in your bag.</h1>
          <h4 class="mt-4">Our latest news and learning articles.</h4>
        </div>
      </div>
      <div class="container">
        <div class="row">
            <div class="col-lg-12 mt-4" *ngFor="let item of showCart">
                <div class="card mx-auto mt-2" style="width: 100%;padding: 8px;">
                    <div class="p-2">
                        <form>
                            <div class="row" style="text-align: center;align-items: center;">
                                <div class="col-lg-3">
                                    <div>
                                        <img [src]="item.product.smallImageUrl" alt="Product Image" class="cart-image mr-3"> 
                                        <!-- <img src="../../assets/belt1_img.jpg" alt="" width="50%"> -->
                                    </div>
                                    
                                </div>
                                <div class="col-lg-4">
                                    
                                    <h5>{{item.productId}} - {{item.productName}}</h5>
                                    <p> Delivery in 3-4 days | Free 10 Days Replacement Policy</p>
                                </div>
                                <div class="col-lg-2">
                                    <input class="inputBox form-control" type="number" name="update_0"
                            [(ngModel)]="item.quantity" [ngModelOptions]="{standalone: true}" placeholder="Quantity">
                                    <!-- <input type="text" size="5" maxlength="10" name="securityCode_10220" value="" placeholder="Quantity">  -->
                                </div>
                                <div class="col-lg-2">${{item.unitPrice}}</div>
                                <div class="col-lg-1"><strong>X</strong></div>
    
    
    
                            </div>
                        </form>
                    </div>
                </div>
            </div>
         
        </div>
        <div class="summary_all_view_div" *ngIf="this.summary">
            <div class="mt-4 summary_all_view_sub_div" style="width: 40%;">
                <h1>Total Summary :</h1>
                <div class="summay_cart_view">
                    <p><strong>Subtotal :</strong> </p>
                    <span>${{this.summary.subTotal}}</span>
                </div>
                <div class="summay_cart_view">
                    <p><strong>Adjustment :</strong> </p>
                    <span>${{this.summary.adjustments[0].amount}} </span>
                </div>
                <div style="border:1px solid #e1e1e1 ;"></div>
                <div class="summay_cart_view">
                    <h4>Cart Total :</h4>
                    <span>${{this.summary.cartTotal}}</span>
                </div>
                <div class="mt-3" style="text-align: end;">
                    <button class="btn-lg btn" style="background-color: #ff5722;color: #FFFFFF;font-size: 18px;font-weight: 400;" routerLink="/check-out-option1">Checkout</button>
                </div>
            </div>
           

        </div>
        
      </div>
    
    <div class="row" style="display: none;">
        <div class="col-lg-3 order-lg-2 order-2 px-4">
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Choose Catalog</p>
                </div>
                <div class="p-2">
                    <form [formGroup]="CatalogForm">
                        <div class="form-group p-1">
                          <label class="mb-2" for="exampleInputEmail1">Choose Catalog</label>
                          <p-dropdown [options]="catalogArray" placeholder="Select Catalog" formControlName="catalogId"
                            (onChange)="getCategoryByCatalog($event)">
                          </p-dropdown>
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width:100%">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Search Catalog</p>
                </div>
                <div class="p-2">
                    <form [formGroup]="findForm">
                        <div class="form-group p-1">
                            <label class="mb-2" for="exampleInputEmail1">Search Catalog</label>
                            <input type="email" class="form-control mb-2" formControlName="findCatalog" id="username" aria-describedby="emailHelp"
                                placeholder="">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="OR" formControlName="radioAll" name="searchCatalogueRadio"
                                    id="searchCatalogueRadio" checked>
                                <label class="form-check-label" for="searchCatalogueRadio">Any</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" value="AND" formControlName="radioAll" type="radio" name="searchCatalogueRadio"
                                    id="searchCatalogueRadio">
                                <label class="form-check-label" for="searchCatalogueRadio">All</label>
                            </div>
                        </div>
                        <div class="form-group p-1">
                            <input class="btn btn-outline-secondary" type="submit" value="Find" (click)="goToFindCatalog()">
                        </div>
                        <div class="form-group p-1">
                            <input class="btn btn-outline-secondary" type="submit" value="Advacned Search"
                                (click)="goToAdvancedSearch()">
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Categories</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                            <p-tree [value]="category" selectionMode="single"></p-tree>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Quick Recorder</p>
                </div>
                <div class="p-2">
                    <span class="span-link">Tiny Gizmo</span>
                    <ul class="list-unstyled">
                        <li>GZ-1000 <span>$12.00</span></li>
                        <li><span class="span-link">Add To Cart</span></li>
                    </ul>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Sign Up For Contact List</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                            <p>Sign Up For Contact List LogIn</p>
                            <p><span class="span-link">Login </span>THE ADMINISTRATOR</p>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Mini Poll</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                            <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box
                                show?</label>
                            <select class="form-select form-select my-2" aria-label=".form-select example">
                                <option selected>Right Side</option>
                                <option value="1">Right Side</option>
                                <option value="2">Left Side/option>
                            </select>
                            <p>*</p>
                            <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box
                                show?</label>
                            <select class="form-select form-select my-2" aria-label=".form-select example">
                                <option selected>Right Side</option>
                                <option value="1">Right Side</option>
                                <option value="2">Left Side</option>
                            </select>
                            <p>*</p>
                            <input class="btn btn-outline-secondary" type="submit" value="Vote!">
                        </div>
                    </form>
                </div>
            </div>
           <!--  <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Forums</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Ask the Experts</span><br>
                        <span class="span-link">Ask the Experts</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Widgets</span><br>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Content</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Store Policies 2</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Cookie Policy</span><br>
                        <span class="span-link">Policies</span><br>
                        <span class="span-link">Store Policies</span><br>
                        <span class="span-link">Store Policies 2</span><br>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Policies</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Cookie Policy</span><br>
                        <span class="span-link">Store Policies</span><br>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Blogs</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Big AI</span><br>
                        <span class="span-link">Big AI</span><br>
                        <span class="span-link">Mad Max</span><br>
                        <span class="span-link">Mad Max</span><br>
                        <span class="span-link">xczx</span><br>
                    </form>
                </div>
            </div> -->
        </div>
        <div class="col-lg-9 order-lg-2 order-1 px-4">
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <div class="p-3">
                        <span class="span-link">Continue Shopping</span>
                        <span class="span-link ps-2" (click)="goToCheckOutOption1()">Check Out</span>
                        <span class="span-link ps-2" (click)="goToQuickAdd()">Quick Add</span>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <form [formGroup]="addtoCartForm">
                        <div class="row">
                            <div class="col-lg-2">
                                <p class="pt-2">Product Number</p>
                            </div>
                            <div class="col-lg-2">
                                <input type="email" class="form-control mb-2" formControlName="productNumber" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-2">
                                <p class="pt-2">Quantity:</p>
                            </div>
                            <div class="col-lg-2">
                                <input type="email" class="form-control mb-2" formControlName="quantity" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-2">
                                <input class="btn btn-outline-secondary" value="Add To Cart" (click)="addItemToCart()" type="submit">
                            </div>
                            <div class="col-lg-2"></div>
                        </div>
                    </form>
                </div>
            </div>
            <div> 
                <div class="mt-2"> 
                 <div> 
                  <ul class="list-inline"> 
                   <li class="list-inline-item"> <span class="span-link">Recalculate Cart</span> </li> 
                   <li class="list-inline-item" (click)="onClearOrder()"> <span class="span-link">Empty Cart</span> </li> 
                   <li class="list-inline-item"> <span class="span-link">Remove Selected </span> </li> 
                  </ul> 
                 </div> 
                 <div class="row mb-2"> 
                  <div class="col-xl-12"> <span class="h3">Shopping Cart</span> <a class="btn btn-primary float-end" (click)="goToCheckOutOption1()">Check out</a> 
                  </div> 
                 </div> 
                </div> 
                <div class="table-scroll"> 
                    <form method="post" action="" name="cartform"> 
                     <input type="hidden" name="removeSelected" value="false"> 
                     <table class="table  table-responsive-sm"> 
                      <thead class="thead-light"> 
                       <tr> 
                        <th></th> 
                        <th scope="row">Product</th> 
                        <th scope="row">&nbsp;</th> 
                        <th scope="row">Quantity</th> 
                        <th class="amount">Unit Price</th> 
                        <th class="amount">Adjustments</th> 
                        <th class="amount">Item Total</th> 
                        <th> <input type="checkbox" name="selectAll" value="0" class="selectAll"> </th> 
                       </tr> 
                      </thead> 
                      <tbody> 
                       <tr id="cartItemDisplayRow_0"  *ngFor="let item of showCart"> 
                        <td> &nbsp; </td> 
                        <td> 
                         <div class="media"> <a href=""> <img [src]="item.product.smallImageUrl" alt="Product Image" class="cart-image mr-3"> </a> 
                          <div class="media-body"> 
                           <h4 class="mt-0"> <a href="" (click)="goToConfigureProduct(item.productId)">{{item.productId}} - {{item.productName}} </a> </h4> {{item.description}} 
                          </div> 
                         </div> </td> 
                        <td> &nbsp; </td> 
                        <td> <input class="inputBox form-control" type="number" name="update_0"
                            [(ngModel)]="item.quantity" [ngModelOptions]="{standalone: true}"> </td> 
                        <td class="amount">${{item.unitPrice}}</td> 
                        <td class="amount">${{item.adjustments}}</td> 
                        <td class="amount">${{item.itemTotal}}</td> 
                        
                        <td> <input type="checkbox" name="selectedItem" value="0" class="selectAllChild"> </td> 
                       </tr> 
                      </tbody> 
                      <tfoot *ngIf="this.summary"> 
                       <tr class="thead-light"> 
                        <th colspan="8"> Summary: </th> 
                       </tr> 
                       <tr> 
                        <th colspan="6" style="background-color: white!important;">Sub Total:</th> 
                        <td class="amount" colspan="1"> ${{this.summary.subTotal}}</td> 
                       </tr>
                       <tr *ngFor="let promo of this.summary.adjustments"> 
                        <th colspan="6" style="background-color: white!important;"> Adjustment - Promotion <a href=""> Details </a> : </th> 
                        <td class="amount" colspan="1"> -${{this.summary.adjustments[0].amount}} </td> 
                       </tr>                                            
                       <tr> 
                        <th colspan="6" style="background-color: white!important;">Cart Total:</th> 
                        <td class="amount" colspan="1"> ${{this.summary.cartTotal}} </td> 
                       </tr> 
                       <tr> 
                        <td colspan="8">&nbsp;</td> 
                       </tr> 
                       <tr> 
                        <td colspan="8"> 
                         <div class="input-group"> <select class="form-control selectBox" name="shoppingListId"> <option value="10111">New Shopping List</option> <option value="10101">New Shopping List 01</option> <option value="">---</option> <option value="">New Shopping List</option> </select> <span class="input-group-btn"> <a href="javascript:addToList();" class="btn btn-outline-secondary"> Add Selected to List </a> </span> 
                         </div> </td> 
                       </tr> 
                       <tr> 
                        <td colspan="8"> <a href="" class="btn btn-outline-secondary btn-sm"> Create a Request For Quote </a> </td> 
                       </tr> 
                       <tr> 
                        <td colspan="8"> 
                         <div class="form-check"> 
                          <input type="checkbox" class="form-check-input" onclick="javascript:document.cartform.submit()" name="alwaysShowcart"> <label>Always View Cart After Adding An Item.</label> 
                         </div> </td> 
                       </tr> 
                      </tfoot> 
                     </table> 
                    </form> 
                </div>
               </div>
               <div class="row"> 
                <div class="col-xl-6"> 
                 <div> 
                  <h3>Promotion/Coupon Codes</h3> 
                 </div> 
                 <div> 
                  <div> 
                   <form method="post" action="https://34.196.47.126:8443/ecommerce/control/addpromocode/showcart" name="addpromocodeform"> 
                    <div class="input-group"> 
                     <input type="text" class="form-control form-control-sm" size="15" name="productPromoCodeId" value=""> <span class="input-group-btn"> <input type="submit" class="btn btn-outline-secondary btn-sm" value="Add Code"> </span> 
                    </div> 
                   </form> 
                  </div> 
                 </div> 
                </div> 
                <div class="col-xl-6"> 
                 <div> 
                  <h3>Special Offers</h3> 
                 </div> 
                 <div> 
                  <ul *ngFor="let offer of this.specialOffers"> 
                   <li> <a >[Details]</a> {{offer.promoText}} </li> 
                  
                  </ul> 
                  <div> <a href="https://34.196.47.126:8443/ecommerce/control/showAllPromotions" class="btn btn-outline-secondary btn-sm">View All Promotions</a> 
                  </div> 
                 </div> 
                </div> 
               </div>
        </div>
    </div>
</div>