import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transiction-history',
  templateUrl: './transiction-history.component.html',
  styleUrls: ['./transiction-history.component.css']
})
export class TransictionHistoryComponent implements OnInit {
  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  orders:any[]=[];
  empty:string="No Record Found!";

  constructor(readonly router:Router) { }

  ngOnInit(): void {
  }

}
