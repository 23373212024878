import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MyAccountsService } from '../my-account/my-accounts.service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  orders:any[]=[];
  empty:string="No Record Found!";
  orderHistory: any;
  constructor(readonly router:Router,
    private myAccountsService : MyAccountsService,) { }

  ngOnInit(): void {
  }

  orderHistories() {
    this.myAccountsService.orderHistories().subscribe((res: any) => {
      this.orderHistory = res.data.productsList;
      
    })
  }
}
