<div class="container-fluid">
  <div class="row">
    <div class="col-lg-3 px-4">
      <app-left-side-bar></app-left-side-bar>
    </div>
    <div class="col-lg-6 order-lg-2 order-1 px-4">
      <h2 class="text-center my-4 mx-1">No PROMOTION Category</h2>
    </div>
    <div class="col-lg-3 order-lg-2 order-3 px-4">
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Language</p>
        </div>
        <div class="p-2">
          <form>
            <div class="form-group p-1">
              <p-dropdown [options]="localeArray" placeholder="Select Language" (onChange)="getLanguage($event)">
              </p-dropdown>
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Cart Summary</p>
        </div>
        <div class="p-2">
          <form>
            <div class="form-group p-1">
              <p>Shopping Cart Empty</p>
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Compare Products</p>
        </div>
        <div class="p-1">
          <form>
            <div class="form-group p-1">
              <p>You have no products to compare.</p>
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Special Offers</p>
        </div>
        <div class="p-1">
          <form>
            <div class="form-group">
              <ul>
                <li>
                  <p>
                    <a class="linktext" (click)="goToPromotionDetails()">Details</a>
                    Free Shipping in Utah
                  </p>
                </li>
                <li>
                  <p>
                    <a (click)="goToPromotionDetails()" class="linktext">Details</a>
                    10% off entire purchase
                  </p>
                </li>
                <li>
                  <p>
                    <a (click)="goToPromotionDetails()" class="linktext">Details</a>
                    Buy 3 Get 2 Free in the Widgets [200] or any sub-category (except the Small Widgets [201] category
                    and sub-categories, but always including the Micro Widgets [20111] category), limit to two per order
                  </p>
                </li>
              </ul>
            </div>
            <div class="form-group py-2 ps-4">
              <input class="btn btn-outline-secondary" type="submit" value="View All Promotions"
                (click)="goToViewAllPromotions()">
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Did You Know?</p>
        </div>
        <div class="p-2">
          <form>
            <div class="form-group">
              <ul>
                <li>
                  <p>
                    The use of gizmos has been shown to have no negative effect on personal longetivity.
                  </p>
                </li>
                <li>
                  <p>
                    Did you know that widget users live twice as long as their non-widget counterparts?
                  </p>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
      <div class="card mx-auto mt-2" style="width: 100%;">
        <div class="card-title">
          <p class="text-left my-3 mx-2">Tag Cloud</p>
        </div>
        <div class="p-2">
          <a  *ngFor="let cloud of tagCloudList"  [style.font-size.pt]="cloud.fontSize"
                  href="https://34.196.47.126:8443/ecommerce/control/tagsearch?SEARCH_STRING=big&amp;keywordTypeId=KWT_TAG&amp;statusId=KW_APPROVED">
                  {{cloud.tag}}
                </a>
        </div>
      </div>
    </div>
  </div>
</div>