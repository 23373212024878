<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 px-4">
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title mb-0">
                    <div class="float-start">
                        <h6 class="text-left my-3 mx-4">Read Message</h6>
                    </div>

                </div>
                <div class="card-body ps-4">

                    <div class="">
                        <div class="d-flex row border-bottom py-2 ">
                            <div class="col-lg-2">
                                <label for="exampletext">
                                    <h6 class="me-4">From:</h6>
                                </label>
                            </div>
                            <div class="col-lg-10" *ngIf="summaryPageMessageList">
                                <p>{{this.summaryPageMessageList.partyIdFrom}} 
                                   
                                </p>
                            </div>
                        </div>

                        <div class="d-flex row border-bottom py-2 pt-4">
                            <div class="col-lg-2">
                                <label for="exampletext">
                                    <h6 class="me-4">To:</h6>
                                </label>
                            </div>
                            <div class="col-lg-10" *ngIf="summaryPageMessageList">
                                <p>{{this.summaryPageMessageList.partyIdTo}} 
                                </p>
                            </div>
                        </div>
                        <div class="d-flex row border-bottom  py-2 pt-4">
                            <div class="col-lg-2">
                                <label for="exampletext">
                                    <h6 class="me-4">Date:</h6>
                                </label>
                            </div>
                            <div class="col-lg-10" *ngIf="summaryPageMessageList">
                                <p>{{this.summaryPageMessageList.entryDate | date:'yyyy-MM-dd hh:mm:ss'}} 
                                </p>
                            </div>
                        </div>
                        <div class="d-flex row border-bottom  py-2 pt-4">
                            <div class="col-lg-2">
                                <label for="exampletext">
                                    <h6 class="me-4">Subject:</h6>
                                </label>
                            </div>
                            <div class="col-lg-10" *ngIf="summaryPageMessageList">
                                <p>{{this.summaryPageMessageList.subject}} 
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex row  py-2 pt-4">
                                <div class="col-lg-2">
                                    <label for="exampletext">
                                        <h6 class="me-4"></h6>
                                    </label>
                                </div>
                                <div class="col-lg-10" *ngIf="summaryPageMessageList">
                                    <p>{{this.summaryPageMessageList.content}} 
                                    </p>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
               
            </div>
        </div>

    </div>
</div>