<div class="container-fluid">
    <div id="ecom-mainarea" class="row mx-2 mt-2">
      <div id="div3" class="col-lg-12 order-lg-2 order-1">
        <ng-container *ngIf="show">
          <h2>Update Gift Card</h2>
        </ng-container>
        <ng-container *ngIf="!show">
          <h2>Add New Gift Card</h2>
        </ng-container>
        <form [formGroup]="giftForm" name="addcreditcardform">
          <a class="btn btn-outline-secondary me-2" (click)="cancelSubmit()">Go Back</a>
          <ng-container *ngIf="show">
            <a class="btn btn-outline-secondary" (click)="update()">Update</a>
          </ng-container>
          <ng-container *ngIf="!show">
            <a class="btn btn-outline-secondary" (click)="submit()">Save</a>
          </ng-container>
          <div>
            <label class="required">Card Number<span style="color:red!important">*</span></label>
            <div class="row">
              <div class="col-sm-6">
                <input type="text" class="required form-control mb-2" [(ngModel)]="cardNumber"
                  formControlName="cardNumber" placeholder="Enter card number" maxlength="30" name="cardNumber">
              </div>
            </div>
            <label class="required">Pin Number<span style="color:red!important">*</span></label>
            <div class="row">
              <div class="col-sm-6">
                <input type="text" class="required form-control mb-2" [(ngModel)]="pinNumber"
                  formControlName="pinNumber" placeholder="Enter pin number" maxlength="30" name="pinNumber">
              </div>
            </div>
            <label class="required">Expiration Date<span style="color:red!important">*</span></label>
            <div class="row mb-2">
              <div class="col-sm-3">
                <p-dropdown [options]="expirationMonth" formControlName="expMonth">
                </p-dropdown>
              </div>
              <div class="col-sm-3">
                <p-dropdown [options]="expirationYear" formControlName="expYear">
                </p-dropdown>
  
              </div>
            </div>
            <label>Description</label>
            <div class="row mb-2">
              <div class="col-sm-6">
                <input type="text" size="20" formControlName="description" class="form-control" placeholder="Enter description" name="description" value="">
              </div>
            </div>
          </div>
        </form>
        <a class="btn btn-outline-secondary me-2" (click)="cancelSubmit()">Go Back</a>
        <ng-container *ngIf="show">
          <a class="btn btn-outline-secondary" (click)="update()">Update</a>
        </ng-container>
        <ng-container *ngIf="!show">
          <a class="btn btn-outline-secondary" (click)="submit()">Save</a>
        </ng-container>
      </div>
      <div id="div4" class="endcolumns">
      </div>
    </div>
  </div>
