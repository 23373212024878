<div class="container-fluid">
  <div class="row">
    <div class="col-lg-3 order-lg-1 order-2">
      <app-left-side-bar></app-left-side-bar>
    </div>
    <div class="col-lg-6 order-lg-2 order-1" *ngIf="!this.show">
      <div class="card mx-auto mt-2" style="width: 95%!important">
        <div class="card-title">
          <p class="text-left my-3 mx-2"><strong>Advanced Search in Category</strong></p>
        </div>
        <div class="p-2">
          <form [formGroup]="advancedSearchForm">
            <div class="row p-1">
              <div class="col-lg-3">
                <label class="form-check-label" for="searchCatalogueRadio">Include sub-categories?</label>
              </div>
              <div class="col-lg-6">
                <div class="form-check">
                  <label class="form-check-label" for="searchCatalogueRadio">Yes</label>
                  <input class="form-check-input" type="radio" formControlName="subCategory" value="Y"
                    name="searchCatalogueRadio3" id="searchCatalogueRadio3">
                </div>
                <div class="form-check">
                  <label class="form-check-label" for="searchCatalogueRadio">No</label>
                  <input class="form-check-input" formControlName="subCategory" value="N" type="radio"
                    name="searchCatalogueRadio3" id="searchCatalogueRadio3">
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-lg-3">
                <label class="mb-2" for="exampleInputEmail1">Keywords</label>
              </div>
              <div class="col-lg-6">
                <input type="email" class="form-control mb-2" id="username" formControlName="keyWords"
                  aria-describedby="emailHelp" placeholder="">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="keyWordsOption" value="OR"
                    name="searchCatalogueRadio1" id="searchCatalogueRadio1">
                  <label class="form-check-label" for="searchCatalogueRadio">Any</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" formControlName="keyWordsOption" value="AND" type="radio"
                    name="searchCatalogueRadio1" id="searchCatalogueRadio1">
                  <label class="form-check-label" for="searchCatalogueRadio">All</label>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-lg-3">
                <label class="mb-2" for="exampleInputEmail1">Color</label>
              </div>
              <div class=col-lg-6>
                <p-dropdown [options]="EColorArray" formControlNamr="color" placeholder="Select color">
                </p-dropdown>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-lg-3">
                <label class="mb-2" for="exampleInputEmail1">License</label>
              </div>
              <div class=col-lg-6>
                <p-dropdown [options]="ELicenseArray" formControlName="license" placeholder="Select license">
                </p-dropdown>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-lg-3">
                <label class="mb-2" for="exampleInputEmail1">Size</label>
              </div>
              <div class=col-lg-6>
                <p-dropdown [options]="ESizeArray" formControlName="size" placeholder="Select size">
                </p-dropdown>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-lg-3">
                <label class="mb-2" for="exampleInputEmail1">Sorted by</label>
              </div>
              <div class="col-lg-6">
                <p-dropdown [options]="sortedByListArray" formControlName="sortedBy" placeholder="Select Sorted By">
                </p-dropdown>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="sortedByOption" value="OR"
                    name="searchCatalogueRadio2" id="searchCatalogueRadio2">
                  <label class="form-check-label" for="searchCatalogueRadio">Any</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" formControlName="sortedByOption" value="AND" type="radio"
                    name="searchCatalogueRadio2" id="searchCatalogueRadio2">
                  <label class="form-check-label" for="searchCatalogueRadio">All</label>
                </div>
              </div>
            </div>
            <div class="form-group p-1">
              <input class="btn btn-outline-secondary" (click)="keywordSearchCatalog()" type="submit" value="find">
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-6 order-lg-2 order-1" *ngIf="this.show">
      <h1>Product Search, <span class="h2">you searched for:</span></h1>
      <br>
      <ul *ngFor="let data of keywordSearch.searchConstraintStrings">
        <li><a class="buttontext">X</a>&nbsp;{{data}}</li>

      </ul>
      <br>
      <div>Sorted By:{{this.keywordSearch.searchSortOrderString}}</div>
      <br>
      <div><a class="buttontext">Refine Search</a></div>
      <div>
        <select aria-label=".form-select example" class="form-select form-select my-2">
          <option>Page 1 of 1</option>
          <option>Go to Page 1</option>
        </select>
        <b>
          <span>1 - 8 of 8</span>
        </b>
      </div>
      <div class="productsummary-container">
        <div class="col-md-4 products-card m-2" *ngFor="let item of keywordSearch.listProducts">
          <div class="card text-center"> <a class="mt-2">
              <img class="card-img-top" [src]="item.product.originalImageUrl" alt="Item Preview"> </a>
            <div class="card-body">
              <h4 class="card-title"><a class="btn btn-link">{{item.product.productName}}</a></h4>
              <div class="cart-text">
                {{item.product.description}}
                <div>
                  <p><strong>{{item.product.productId}}</strong></p>
                  <dl>
                    <dt></dt>
                    <dd></dd>
                  </dl> <strong> </strong>
                </div>
                <form name="addToCompare0form">
                  <input type="hidden" name="productId" value="PROD_MANUF">
                  <input type="hidden" name="mainSubmitted" value="Y">
                </form>
              </div>
              <div id="productDetailIdPROD_MANUF" style="display:none;">
                <img src="" alt="Large Image"> Product ID {{item.product.productId}} Product Name
                {{item.product.productName}}
                {{item.product.description}}
              </div>
              <p>Your Price: <span class="regularPrice">${{item.price.price}}</span></p>

              <div class="d-flex justify-content-center p-2" *ngIf="item.showForm=='Y'">
                <input type="email" class="form-control" id="username" aria-describedby="emailHelp" placeholder="1">
                <input class="btn btn-outline-secondary btn-sm" type="submit" value="Add To Cart">
              </div>
              <div class="d-flex justify-content-center p-2" *ngIf="item.showOrderConfigureButton=='Y'">
                <a class="btn btn-outline-secondary btn-sm" (click)="goToConfigureProduct()">Configure...</a>
              </div>
              <div class="d-flex justify-content-center p-2" *ngIf="item.orderChooseVariations=='Y'">
                <a class="btn btn-outline-secondary btn-sm" (click)="goToConfigureProduct()">Choose
                  Variations...</a>
              </div>
              <div *ngIf="item.orderChooseAmount=='Y'" class="d-flex justify-content-center p-2">
                <a class="btn btn-outline-secondary btn-sm" (click)="goToConfigureProduct()">Choose Amount...</a>
              </div>
              <div class="d-flex justify-content-center p-2 mb-3" *ngIf="item.showForm=='Y'">
               <!--  <span class="span-link" (click)="addToCompare(item.product.productId)">Add To Compare</span> -->
              </div>
              <div class="d-flex justify-content-center p-2" *ngIf="item.showForm=='Y'">

                <input *ngIf="item.showAddtoCart1=='Y'" class="btn btn-outline-secondary btn-sm" type="submit"
                  value="Add Defualt To Cart">

              </div>
              <div class="d-flex justify-content-center p-2" *ngIf="item.showForm=='Y'">

                <input *ngIf="item.showAddtoCart2=='Y'" class="btn btn-outline-secondary btn-sm" type="submit"
                  value="Add Default To Cart">
              </div>

            </div>
          </div>
        </div>
        <div>
          <select aria-label=".form-select example" class="form-select form-select my-2">
            <option>Page 1 of 1</option>
            <option>Go to Page 1</option>
          </select>
          <b>
            <span>1 - 8 of 8</span>
          </b>
        </div>
      </div>

    </div>
    <div class="col-lg-3 order-lg-3 order-3">
      <app-right-side-bar></app-right-side-bar>
    </div>
  </div>
</div>