import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MyAccountsService } from '../my-account/my-accounts.service';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css']
})
export class RequestsComponent implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  requests:any[]=[];
  partyId: string | null;
  ListRequestsList: any;
  constructor(readonly router:Router,
    readonly spinner: NgxSpinnerService,
    private myAccountsService : MyAccountsService,) {
      this.partyId = localStorage.getItem('partyId');
     }

  ngOnInit(): void {
    this.ListRequests();
  }
  goToRequest(id:any){
    this.router.navigate(['/requests-summary'],{queryParams:{custRequestId:id}});
  }
  ListRequests() {
    this.spinner.show;
    this.myAccountsService.ListRequests(this.partyId).subscribe((res: any) => {
      this.ListRequestsList = res.data;
    })
    this.spinner.hide;

  }
  goToShowPromotionDetails(){
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewAllPromotions(){
    this.router.navigate(["/view-all-promotions"])
  }
}
