<div class="d-flex justify-content-center mt-4 p-1">
    <h2>Request a New Account</h2>
</div>
<div class="d-flex justify-content-center">
    <h3 class="text-center">If you already have an account, <span class="span-link" (click)="goToLogin()">log in here</span></h3>
</div>
<div class="d-flex justify-content-center">
    <button class="btn btn-outline-secondary btn-sm m-1" (click)="goToLogin()">Cancel</button>
    <button class="btn btn-outline-secondary btn-sm m-1" (click)="onSubmit()">Save</button>
</div>
<div class="d-flex justify-content-center">
        <div class="card m-2 p-2" style="width:63%!important;min-width: 16rem!important;">
           <form [formGroup]="registerForm">
            <div class="row">
                <div class="col-12">
                    Fields marked with (<span style="color:red!important;">*</span>) are required.
                </div>
            </div>
            <div class="row">
                <div class="col-6 mt-1">
                    <legend>Full Name</legend>
                    <div class="form-group">
                        <label class="mb-2" for="title">Title</label>
                        <select class="form-select form-select-sm mb-2" placeholder="Select Title"
                         formControlName="userTitle" aria-label=".form-select-sm example">
                            <option value>Select One</option>
                            <option>Mr.</option>
                            <option>Mrs.</option>
                            <option>Ms.</option>
                            <option>Dr.</option>
                        </select>
                        <label class="mb-2" for="title">First Name<span style="color:red!important;">*</span></label>
                        <input type="text" class="form-control form-control-sm mb-2"
                         formControlName="userFirstName"  placeholder="Enter First Name">
                        <ng-container *ngIf="registerForm.controls['userFirstName'].invalid && (registerForm.controls['userFirstName'].dirty||registerForm.controls['userFirstName'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">First name is required!</span>
                            </p>
                        </ng-container>
                        <label class="mb-2" for="title">Middle Initial</label>
                        <input type="text" placeholder="Enter Middle Name" class="form-control form-control-sm mb-2" formControlName="userMiddleName">
                        <label class="mb-2" for="title">Last Name<span style="color:red!important;">*</span></label>
                        <input type="text" placeholder="Enter Last Name" class="form-control form-control-sm mb-2" formControlName="userLastName">
                        <ng-container *ngIf="registerForm.controls['userLastName'].invalid && (registerForm.controls['userLastName'].dirty||registerForm.controls['userLastName'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">Last name is required!</span>
                            </p>
                        </ng-container>
                        <label class="mb-2" for="title">Suffix</label>
                        <input type="text" placeholder="Enter Suffix"  class="form-control form-control-sm mb-5">
                    </div>
                    <hr>
                    <legend>E-Mail Address</legend>
                    <div class="form-group">
                        <label class="mb-2" for="title">E-Mail Address<span style="color:red!important;">*</span></label>
                        <input type="text" placeholder="Enter E-mail Address" class="form-control form-control-sm mb-2" formControlName="customerEmail">
                        <ng-container *ngIf="registerForm.controls['customerEmail'].invalid && (registerForm.controls['customerEmail'].dirty||registerForm.controls['customerEmail'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">Email is required!</span>
                            </p>
                        </ng-container>
                        <label class="mb-2" for="title">Allow Solicitation<span style="color:red!important;">*</span></label>
                        <select class="form-select form-select-sm mb-2" placeholder="Select Allow Solicitation" formControlName="customerEmailAllowSql"
                         aria-label=".form-select-sm example">
                         <option value>Select Allow solicitation</option>
                            <option>Y</option>
                            <option>N</option>
                        </select>
                        <ng-container *ngIf="registerForm.controls['customerEmailAllowSql'].invalid && (registerForm.controls['customerEmailAllowSql'].dirty||registerForm.controls['customerEmailAllowSql'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">Solicitation is required!</span>
                            </p>
                        </ng-container>
                    </div>
                </div>
                <div class="col-6 mt-1">
                    <legend>Shipping Address</legend>
                    <div class="form-group">
                        <label class="mb-2" for="addressLine">Address Line 1<span style="color:red!important;">*</span></label>
                        <input type="text" placeholder="Enter Address Line 1" class="form-control form-control-sm mb-2" formControlName="customerAddress1">
                        <ng-container *ngIf="registerForm.controls['customerAddress1'].invalid && (registerForm.controls['customerAddress1'].dirty||registerForm.controls['customerAddress1'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">Address is required!</span>
                            </p>
                        </ng-container>
                        <label class="mb-2" for="addressLine">Address Line 2<span style="color:red!important;">*</span></label>
                        <input type="text" placeholder="Enter Address Line 2" class="form-control form-control-sm mb-2" formControlName="customerAddress2">
                        <ng-container *ngIf="registerForm.controls['customerAddress2'].invalid && (registerForm.controls['customerAddress2'].dirty||registerForm.controls['customerAddress2'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">Address is required!</span>
                            </p>
                        </ng-container>
                        <label class="mb-2" for="addressLine">City<span style="color:red!important;">*</span></label>
                        <input type="text" placeholder="Enter City" class="form-control form-control-sm mb-2" formControlName="customerCity">
                        <ng-container *ngIf="registerForm.controls['customerCity'].invalid && (registerForm.controls['customerCity'].dirty||registerForm.controls['customerCity'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">City is required!</span>
                            </p>
                        </ng-container>
                        <label class="mb-2" for="addressLine">Zip Postal Code<span style="color:red!important;">*</span></label>
                        <input type="text" placeholder="Enter Zip Postal Code" class="form-control form-control-sm mb-2" formControlName="customerPostalCode">
                        <ng-container *ngIf="registerForm.controls['customerPostalCode'].invalid && (registerForm.controls['customerPostalCode'].dirty||registerForm.controls['customerPostalCode'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">Zip code is required!</span>
                            </p>
                        </ng-container>
                        <label class="mb-2" for="addressLine">Country<span style="color:red!important;">*</span></label><br>
                        <p-dropdown [options]="array" formControlName="customerCountry" 
                         (ngModelChange)="onCountryChange($event)" placeholder="Select Country">
                        </p-dropdown>
                        <br>
                        <ng-container *ngIf="registerForm.controls['customerCountry'].invalid && (registerForm.controls['customerCountry'].dirty||registerForm.controls['customerCountry'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">Country is required!</span>
                            </p>
                        </ng-container>
                        <br>
                        <label class="mb-2" for="addressLine">State/Province<span style="color:red!important;">*</span></label><br>
                        <p-dropdown  [options]="stateArray" formControlName="customerState" placeholder="Select State">
                        </p-dropdown>
                        <br>
                        <ng-container *ngIf="registerForm.controls['customerState'].invalid && (registerForm.controls['customerState'].dirty||registerForm.controls['customerState'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">State is required!</span>
                            </p>
                        </ng-container>
                        <br>
                        <label class="mb-2" for="addressLine">Allow Address Solicitation<span style="color:red!important;">*</span></label>
                        <select class="form-select form-select-sm mb-2" aria-label=".form-select-sm example" 
                        formControlName="customerAddressAllowSql" placeholder="Select Allow Address Solicitation">
                        <option value>Select Allow solicitation</option>
                            <option>Y</option>
                            <option>N</option>
                        </select>
                        <ng-container *ngIf="registerForm.controls['customerAddressAllowSql'].invalid && (registerForm.controls['customerAddressAllowSql'].dirty||registerForm.controls['customerAddressAllowSql'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">Solicitation required!</span>
                            </p>
                        </ng-container>
                    </div>
                </div>
            </div>
            <hr>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <legend>Phone Numbers</legend>
                        <div class="form-group table-scroll">
                            <table class="table table-responsive">
                                <thead class="thead-light">
                                    <tr>
                                        <th></th>
                                        <th>Country</th>
                                        <th>Area Code</th>
                                        <th>Contact Number</th>
                                        <th>Extension</th>
                                        <th>Allow solicitation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="padding-bottom:0rem!important">Home Phone</td>
                                        <td style="padding-bottom:0rem!important">
                                            <input placeholder="Enter Home Phone Country" type="text" size="5" class="form-control form-control-sm" formControlName="customerHomeCountry">
                                            <ng-container *ngIf="registerForm.controls['customerHomeCountry'].invalid && (registerForm.controls['customerHomeCountry'].dirty || registerForm.controls['customerHomeCountry'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Code!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <input placeholder="Enter Area Code" type="text" size="5" class="form-control form-control-sm" formControlName="customerHomeArea">
                                            <ng-container *ngIf="registerForm.controls['customerHomeArea'].invalid && (registerForm.controls['customerHomeArea'].dirty || registerForm.controls['customerHomeArea'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Code!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <input placeholder="Enter Contact Number" type="text" class="form-control form-control-sm" formControlName="customerHomeContact">
                                            <ng-container *ngIf="registerForm.controls['customerHomeContact'].invalid && (registerForm.controls['customerHomeContact'].dirty || registerForm.controls['customerHomeContact'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Number!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <input placeholder="Enter Extension" type="text" size="6" class="form-control form-control-sm" formControlName="customerHomeExt">
                                            <ng-container *ngIf="registerForm.controls['customerHomeExt'].invalid && (registerForm.controls['customerHomeExt'].dirty || registerForm.controls['customerHomeExt'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Code!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <select placeholder="Enter Allow solicitation" class="form-select form-select-sm mb-2" formControlName="customerHomeAllowSql"
                                                aria-label=".form-select-sm example">
                                                <option value>Select Allow solicitation</option>
                                                <option>Y</option>
                                                <option>N</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding-bottom:0rem!important">Business Phone</td>
                                        <td style="padding-bottom:0rem!important">
                                            <input placeholder="Enter Business Phone Country" type="text" size="5" class="form-control form-control-sm" formControlName="customerWorkCountry"> 
                                            <ng-container *ngIf="registerForm.controls['customerWorkCountry'].invalid && (registerForm.controls['customerWorkCountry'].dirty || registerForm.controls['customerWorkCountry'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Code!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <input  placeholder="Enter Area Code" type="text" size="5" class="form-control form-control-sm" formControlName="customerWorkArea">
                                            <ng-container *ngIf="registerForm.controls['customerWorkArea'].invalid && (registerForm.controls['customerWorkArea'].dirty || registerForm.controls['customerWorkArea'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Code!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <input placeholder="Enter Contact Number" type="text" class="form-control form-control-sm" formControlName="customerWorkContact">
                                            <ng-container *ngIf="registerForm.controls['customerWorkContact'].invalid && (registerForm.controls['customerWorkContact'].dirty || registerForm.controls['customerWorkContact'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Number!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <input placeholder="Enter Extension" type="text" size="6" class="form-control form-control-sm" formControlName="customerWorkExt">
                                            <ng-container *ngIf="registerForm.controls['customerWorkExt'].invalid && (registerForm.controls['customerWorkExt'].dirty || registerForm.controls['customerWorkExt'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Code!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <select placeholder="Enter Allow solicitation" class="form-select form-select-sm mb-2" formControlName="customerWorkAllowSql"
                                                aria-label=".form-select-sm example">
                                                <option value>Select Allow solicitation</option>
                                                <option>Y</option>
                                                <option>N</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding-bottom:0rem!important">Fax number</td>
                                        <td style="padding-bottom:0rem!important">
                                            <input placeholder="Enter Fax Number Country" type="text" size="5" class="form-control form-control-sm" formControlName="customerFaxCountry">
                                            <ng-container *ngIf="registerForm.controls['customerFaxCountry'].invalid && (registerForm.controls['customerFaxCountry'].dirty || registerForm.controls['customerFaxCountry'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Code!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <input  placeholder="Enter Area Code" type="text" size="5" class="form-control form-control-sm" formControlName="customerFaxArea">
                                            <ng-container *ngIf="registerForm.controls['customerFaxArea'].invalid && (registerForm.controls['customerFaxArea'].dirty || registerForm.controls['customerFaxArea'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Code!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <input placeholder="Enter Contact Number" type="text" class="form-control form-control-sm" formControlName="customerFaxContact">
                                            <ng-container *ngIf="registerForm.controls['customerFaxContact'].invalid && (registerForm.controls['customerFaxContact'].dirty || registerForm.controls['customerFaxContact'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Number!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
    
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <select placeholder="Enter Allow solicitation" class="form-select form-select-sm mb-2"
                                                aria-label=".form-select-sm example" formControlName="customerFaxAllowSql">
                                                <option value>Select Allow solicitation</option>
                                                <option>Y</option>
                                                <option>N</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding-bottom:0rem!important">Mobile Phone</td>
                                        <td style="padding-bottom:0rem!important">
                                            <input placeholder="Enter Mobile Phone Country" type="text" size="5" class="form-control form-control-sm" formControlName="customerMobileCountry">
                                            <ng-container *ngIf="registerForm.controls['customerMobileCountry'].invalid && (registerForm.controls['customerMobileCountry'].dirty || registerForm.controls['customerMobileCountry'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Code!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <input  placeholder="Enter Area Code" type="text" size="5" class="form-control form-control-sm" formControlName="customerMobileArea">
                                            <ng-container *ngIf="registerForm.controls['customerMobileArea'].invalid && (registerForm.controls['customerMobileArea'].dirty || registerForm.controls['customerMobileArea'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Code!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <input placeholder="Enter Contact Number" type="text" class="form-control form-control-sm" formControlName="customerMobileContact">
                                            <ng-container *ngIf="registerForm.controls['customerMobileContact'].invalid && (registerForm.controls['customerMobileContact'].dirty || registerForm.controls['customerMobileContact'].touched)">
                                                <p class="validate-field p-1 m-0">
                                                    <span style="color:red!important;">Invalid Number!</span>
                                                </p>
                                            </ng-container>
                                        </td>
                                        <td style="padding-bottom:0rem!important">
    
                                        </td>
                                        <td style="padding-bottom:0rem!important">
                                            <select placeholder="Enter Allow solicitation" class="form-select form-select-sm mb-2" formControlName="customerMobileAllowSql"
                                                aria-label=".form-select-sm example">
                                                <option value>Select Allow solicitation</option>
                                                <option>Y</option>
                                                <option>N</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 mt-1">
                    <legend>User Name</legend>
                    <div class="form-group">
                        <div>
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  (change)="onCheckBoxChange($event)">
                            <label class="mb-2 px-2" for="title">Use Email Address</label>
                        </div>
                        <label class="mb-2" for="title">User Name<span style="color:red!important;">*</span></label>
                        <input type="text" placeholder="Enter User Name" class="form-control form-control-sm mb-2" formControlName="username" [(ngModel)]="registerForm.value.username">
                        <ng-container *ngIf="registerForm.controls['username'].invalid && (registerForm.controls['username'].dirty||registerForm.controls['username'].touched)">
                            <p class="validate-field p-1 m-0">
                                <span style="color:red!important;">Username is required!</span>
                            </p>
                        </ng-container>
                    </div>
                </div>
                <div class="col-6 mt-1">
                    <legend>Password</legend>
                    <label class="mb-2" for="title">Password<span style="color:red!important;">*</span></label>
                    <input type="password" placeholder="Enter Password" class="form-control form-control-sm mb-2" formControlName="password">
                    <ng-container *ngIf="registerForm.controls['password'].invalid && (registerForm.controls['password'].dirty||registerForm.controls['password'].touched)">
                        <p class="validate-field p-1 m-0">
                            <span style="color:red!important;">password is required!</span>
                        </p>
                    </ng-container>
                    <label class="mb-2" for="title">Repeat password to confirm*</label>
                    <input type="password" placeholder="Enter Confirm Password" class="form-control form-control-sm mb-2" formControlName="confirmPassword">
                    <ng-container *ngIf="registerForm.value.confirmPassword!=registerForm.value.password">
                        <p class="validate-field p-1 m-0">
                            <span style="color:red!important;">Confirm Password must be same as Password!</span>
                        </p>
                    </ng-container>
                    <label class="mb-2" for="title">Password Hint</label>
                    <input type="password" placeholder="Enter Password Hint" class="form-control form-control-sm mb-2" formControlName="passwordHint">
                </div>
            </div>
           </form>
        </div>
    
</div>
<div class="d-flex justify-content-center">
    <button class="btn btn-outline-secondary btn-sm m-1" (click)="goToLogin()">Cancel</button>
    <button class="btn btn-outline-secondary btn-sm m-1"(click)="onSubmit()">Save</button>
</div>