import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RandomService } from 'src/app/random.service';
import { SideBarService } from 'src/app/side-bar/side-bar.service';

@Component({
  selector: 'app-read-message-summary',
  templateUrl: './read-message-summary.component.html',
  styleUrls: ['./read-message-summary.component.css']
})
export class ReadMessageSummaryComponent implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  getCustomerProfile: any;
  getMessageList: any;
  token: any;
  show: boolean;
  username: any;
  lastname: any;
  partyId: any;
  user: any ;
  communicationEventId: any;
  summaryPageMessageList: any;
  constructor(
    private random: RandomService,
    private _ActivatedRoute: ActivatedRoute,private form: FormBuilder,
    private sidebarService:SideBarService,
    readonly spinner: NgxSpinnerService,

    private toastr: ToastrService,) {  
      this.show = false;
      this.username = localStorage.getItem("firstName");
      this.lastname = localStorage.getItem("lastName");
      this.user = localStorage.getItem("email");
      this.partyId = localStorage.getItem("partyId");
    }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.communicationEventId = params["communicationEventId"];
    });

    this.random.currentMessageSubscriber.subscribe((data: any) => {
      this.token = localStorage.getItem("token");
      if (this.token) {
        this.show = true;
        this.username = localStorage.getItem("firstName");
        this.lastname = localStorage.getItem("lastName");
      }
      else {
        this.show = false;

        this.username = "";
        this.lastname = "";
      }
    })
    this.messageListSecond();
   
  }
 
  messageListSecond() {
   this.spinner.show;
   this.sidebarService.summaryPageMessage(this.communicationEventId).subscribe((res: any) => {
      this.summaryPageMessageList = res.data[0].data[0];
    })
   this.spinner.hide;
  }
}
