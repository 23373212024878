import { environment } from './../environments/environment';

export const URLS = {
    loginApi: '/phyCorp/control/api/v1/ecommerce/login',
    getState: '/phyCorp/control/api/v1/resources/states/accState',
    getCountry: '/phyCorp/control/api/v1/resources/countries',
    createCustomer: '/phyCorp/control/api/v1/ecommerce/createCustomer',
    getMainFeaturedProduct: '/phyCorp/control/api/v1/ecommerce/main/featuredProduct',
    getCatalogList: '/phyCorp/control/api/v1/ecommerce/main/catalogs',
    getLocale: '/phyCorp/control/api/v1/ecommerce/main/locale',
    postContactUs: '/phyCorp/control/api/v1/ecommerce/contactUs',
    getCategoryByCatalog: '/phyCorp/control/api/v1/ecommerce/main/Categories',
    getProductMain: '/phyCorp/control/api/v1/ecommerce/main',
    resetPassword: '/phyCorp/control/api/v1/users/resetPassword',
    postCreditCard: '/phyCorp/control/api/v1/crm/paymentMethods/creditCard',
    postEftAccount:'/phyCorp/control/api/v1/crm/paymentMethods/eftAccount',
    postGiftCard:'/phyCorp/control/api/v1/crm/paymentMethods',
    updateCreditCard:'/phyCorp/control/api/v1/crm/paymentMethods/creditCard',
    updateEftAccount:'/phyCorp/control/api/v1/crm/paymentMethods/eftAccount',
    updateGiftCard:'/phyCorp/control/api/v1/crm/paymentMethods',
    advSearchDataDropdown:'/phyCorp/control/api/v1/ecommerce/main/advSearchData',
    keywordSearchCatalog:'/phyCorp/control/api/v1/ecommerce/main/keywordsearch',
    addToComparePost:'/phyCorp/control/api/v1/ecommerce/main/addToCompare',
    getCompareProducts:'/phyCorp/control/api/v1/ecommerce/main/compareProducts',
    getContactById:'/phyCorp/control/api/v1/hr/employee',
    createContact:'/phyCorp/control/api/v1/hr/employee/teleNumber',
    updateContact:'/phyCorp/control/api/v1/hr/employee/teleNumber',
    removeAddToCompare:'/phyCorp/control/api/v1/ecommerce/main/removeCompare',
    getPartyContentLists: '/phyCorp/control/api/v1/crm/partyContent/:partyId',
    downloadPartyContent: '/phyCorp/control/api/v1/crm/partyContent/view',
    partyContentDropdown: '/phyCorp/control/api/v1/resources/contentPurposeRoleType',
    attachPartyContent: '/phyCorp/control/api/v1/crm/partyContent',
    deletePartyContent: '/phyCorp/control/api/v1/crm/partyContent',
    getContactPurpose:'/phyCorp/control/api/v1/hr/employee/contactPurposes',
    createPostalAddress:'/phyCorp/control/api/v1/hr/employee/postalAddress',
    updatePostalAddress:'/phyCorp/control/api/v1/hr/employee/postalAddress',
    createEmail:'/phyCorp/control/api/v1/hr/employee/email',
    clearCompare: '/phyCorp/control/api/v1/ecommerce/main/clearCompare',
    getShoppingList: '/phyCorp/control/api/v1/ecommerce/shoppingList',
    getConfigureList: '/phyCorp/control/api/v1/ecommerce/main/configure',
    verifyConfig: '/phyCorp/control/api/v1/ecommerce/main/product/verifyConfig',
    addProductTags: '/phyCorp/control/api/v1/ecommerce/main/product/productTags',
    customerProfile: '/phyCorp/control/api/v1/ecommerce/customerProfile',
    findOrdersList: '/phyCorp/control/api/v1/order/lookup',
    addToCartConfig: '/phyCorp/control/api/v1/ecommerce/main/product/addItem',
    getAllPromotion: '/phyCorp/control/api/v1/order/promotions',
    clearOrder: '/phyCorp/control/api/v1/order/clearOrder',
    getquote: '/phyCorp/control/api/v1/crm/getquote',
    configDetailsEvent: '/phyCorp/control/api/v1/ecommerce/main/product/configDetailsEvent',
    getshowCart: '/phyCorp/control/api/v1/ecommerce/main/product/showCart',
    updateCustomerProfile: '/phyCorp/control/api/v1/ecommerce/customerProfile',
    contactExpire: '/phyCorp/control/api/v1/ecommerce/customerProfile/contactExpire',
    paymentDefault: '/phyCorp/control/api/v1/ecommerce/customerProfile/paymentDefault',
    getquickCheckout: '/phyCorp/control/api/v1/ecommerce/main/product/quickCheckout',
    checkoutOptions: '/phyCorp/control/api/v1/ecommerce/main/product/checkoutOptions',
    processOrder: '/phyCorp/control/api/v1/ecommerce/main/product/processOrder',
    messageListSecond: '/phyCorp/control/api/v1/content/messageListSecond',
    messageListFirst: '/phyCorp/control/api/v1/content/messageListFirst',
    summaryPageMessage: '/phyCorp/control/api/v1/content/summaryPageMessage',
    sendMessage: '/phyCorp/control/api/v1/content/sendMessage',
    ListQuotes: '/phyCorp/control/api/v1/content/ListQuotes',
    ListQuotesByQuoteId: '/phyCorp/control/api/v1/content/ListQuotesByQuoteId',
    ListRequests: '/phyCorp/control/api/v1/content/ListRequests',
    ListRequestsByCustRequestId: '/phyCorp/control/api/v1/content/ListRequestsByCustRequestId',
    editShoppingList: '/phyCorp/control/api/v1/content/editShoppingList',
    createEmptyShoppingList: '/phyCorp/control/api/v1/content/createEmptyShoppingList',
    createCustRequestFromShoppingLists: '/phyCorp/control/api/v1/content/createCustRequestFromShoppingLists',
    updateShoppingList: '/phyCorp/control/api/v1/content/updateShoppingList',
    getShoppingListEcom: '/phyCorp/control/api/v1/content/getShoppingList',
    addItemToShoppingList: '/phyCorp/control/api/v1/content/addItemToShoppingList',
    editShoppingListAll: '/phyCorp/control/api/v1/content/editShoppingListAll',
    getShoppingListTypeIdList:'/phyCorp/control/api/v1/party/getShoppingListTypeIdList',
    getProductId:'/phyCorp/control/api/v1/workEffort/getProductId',
    addItemToCart:'/phyCorp/control/api/v1/ecommerce/main/product/addItem',
    quickCheckout:'/phyCorp/control/api/v1/ecommerce/main/product/quickCheckout',
    addListToCart:'/phyCorp/control/api/v1/ecommerce/shoppingList/addListToCart',
    updateShoppingListItem:'/phyCorp/control/api/v1/party/updateShoppingListItem',
    removeShoppingListItem:'/phyCorp/control/api/v1/party/removeShoppingListItem',
    createShippingAddress:'/phyCorp/control/api/v1/order/shipAddress',
    createGiftCard:'/phyCorp/control/api/v1/crm/paymentMethods/creditCard',
    addtocartbulk:'/phyCorp/control/api/v1/ecommerce/shoppingList/addtocartbulk',
    onePageCheckout:'/phyCorp/control/api/v1/ecommerce/main/product/onePageCheckout',
    removeProductCart:'/phyCorp/control/api/v1/ecommerce/main/removeProductCart',
    orderSummary:'/phyCorp/control/api/v1/order/orderSummary',
    updateShippingAddress:'/phyCorp/control/api/v1/order/shipAddress',
    orderHistories:'/phyCorp/control/api/v1/order/orderHistories',
    getPurchaseHistory:'/phyCorp/control/api/v1/ecommerce/main/getPurchaseHistory',
    getReturnItem:'/phyCorp/control/api/v1/ecommerce/main/getReturnItem',

    
    
    
    
    
    
    

    
    

    

}