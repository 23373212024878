import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SideBarService } from '../side-bar/side-bar.service';
import { TreeNode } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  catalog: any;
  catalogArray: any[]=[];
  locale: any;
  localeArray: any[]=[];
  id: any;
  selectedFiles: any;
  category: TreeNode[] = [];
  productMap: any;
  productId: any;
  configQuestions: any;
  findForm:FormGroup;
  
  private finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  MainFeaturedProduct: any;
  CatalogForm!: FormGroup;
  tagForm:FormGroup;
  catalogId: string | null;
  configQuestions1: any;
  configQuestions2: any;
  configQuestions3: any;
  configQuestions4: any;
  configQuestions5: any;
  verifiedArray: any[]=[];
  tagCloudList: any;
  showCart: any;
  specialOffers: any;
  summary: any;
  compareProductList: any;
  allDATA: any;
  showCART: any;
  specialOFFERS: any;
  Summary: any;
  addtoCartForm: FormGroup;
  img: any;
  current_img: any;
  img_missing:any='/assets/missing_img.jpeg'
  constructor(
    readonly router:Router,
    private sidebarService:SideBarService,
    private _ActivatedRoute: ActivatedRoute,
    private form: FormBuilder,
    private toastr: ToastrService,
    readonly Form:FormBuilder
    ) { 
      localStorage.setItem('catalog','DemoCatalog')
      this.catalogId = localStorage.getItem("catalog");
      this.tagForm = this.form.group({
        tag: [''],
       
      });
      this.CatalogForm = this.form.group({

        catalogId: [''],
       
      });
      this.findForm = this.form.group({
        findCatalog:[''],
        radioAll:['']
      });
      this.addtoCartForm = this.Form.group({
        productNumber:[''],
        quantity:['']
      });
    }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.productId = params["productId"];  
    });
    this. getConfigureList();
    this.getCatalogList();
    this.getLocale();
    this. getCategoryById();
    this.getshowCart();
    this.getCompareProducts();
    setTimeout(() => {
      const formValue = this.CatalogForm;
    formValue.patchValue({
      catalogId:this.catalogId,
     
     
    })
    }, 2000);
    this.getProductTags();
    this.current_img=this.img[0].objectInfo;

  }
  addItemToCart(): void {
    if(this.addtoCartForm.value.quantity==""){
      this.toastr.error("Please Add Qunatity");
    }
    else{
      const form ={
        "productId": this.productId,
        "quantity": this.addtoCartForm.value.quantity,
      }    
        this.sidebarService.addItemToCart(form,this.productId).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Item Added Successfully");
          this.allDATA=res.data;
          this.showCART = res.data.cartSummary.productsList;
          this.specialOFFERS = res.data.specialOffers;
          this.Summary = res.data.cartSummary.summary;
          this.getshowCart();
          this.addtoCartForm.reset();
        }
      }, (err) => {
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); 
    }
  
  }
  addItemToCartForQuick(): void {
    if(this.addtoCartForm.value.quantity==""){
      this.toastr.error("Please Add Qunatity");
    }
    else{
      const form ={
        "productId": this.productId,
        "quantity": this.addtoCartForm.value.quantity,
      }    
        this.sidebarService.addItemToCart(form,this.productId).subscribe((res: any) => { 
        if (res.success) {
          this.allDATA=res.data;
          this.showCART = res.data.cartSummary.productsList;
          this.specialOFFERS = res.data.specialOffers;
          this.Summary = res.data.cartSummary.summary;
          this.getshowCart();
          this.addtoCartForm.reset();
          this.router.navigate(["/quick-check-out"])

        }
      }, (err) => {
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); 
    }
  
  }
  addtoQuickBook(){
    this.addItemToCartForQuick();
  }
  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
      if(res.success == true){
        this.showCart = res.data.productsList;
        this.specialOffers = res.data.specialOffers;
        this.summary = res.data.summary;
      }
    })
  }
  myimgcode(e:any){
    console.log("this img", e.target.currentSrc);
    this.current_img=e.target.currentSrc;

  }
  getProductTags() {
    this.sidebarService.getProductTags().subscribe((res: any) => {
      this.tagCloudList = res.data.tagCloudList;

    })
  }
  getCategoryById() {
    this.sidebarService.getCategoryByCatalog(this.catalogId).subscribe((res: any) => {
      this.category = res.data;

    })
  }

  getCompareProducts() {
    this.sidebarService.getCompareProducts(this.catalogId).subscribe((res: any) => {
      this.compareProductList = res.data.productList;

    })
  } 
  removeAddToCompare(id:any): void {

    this.sidebarService.removeAddToCompare(id)
      .then(data => {
        if (data.success) {
          this.toastr.success(data.data.successMessage);
        this.getCompareProducts();
        }
      });
  
  }
  
  clearCompare() {
     
  
    this.sidebarService.clearCompare().subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.getCompareProducts();
  
      }
    }, (err) => {
   
      this.toastr.error(err.message);
  
    });
   
  }
  // getshowCart() {
  //   this.sidebarService.getshowCart().subscribe((res: any) => {
  //     this.showCart = res.data.productsList;
  //     this.specialOffers = res.data.specialOffers;
  //     this.summary = res.data.summary;

  //   })
  // }
  
  getConfigureList() {
    
    this.sidebarService.getConfigureList(this.productId).subscribe((res: any) => {
      this.productMap = res.data.productMap;
      this.img=res.data.images[0].data;
      console.log(this.img)

      this.configQuestions=res.data.configQuestions[0];
      this.configQuestions1=res.data.configQuestions[1];
      this.configQuestions2=res.data.configQuestions[2];
      this.configQuestions3=res.data.configQuestions[3];
      this.configQuestions4=res.data.configQuestions[4];
      this.configQuestions5=res.data.configQuestions[5];
    
   
    })

  }
  
 
 
  onSubmit() {
   
    this.sidebarService.addProductTags( this.productId,this.tagForm.value.tag).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.verifiedArray=[];

      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })
  }
 
  goToFindCatalog(){
    this.router.navigate(["/find-catalog"],{queryParams:{findCatalog:this.findForm.value.findCatalog,type:this.findForm.value.radioAll}});
  }
  goToAdvancedSearch(){
    this.router.navigate(["/advanced-search"]);
  }
  goToFeaturedProducts(){
    this.router.navigate(["/"]);
  }
  goToConfigureProduct(){
    this.router.navigate(["/configure-product"]);
  }
  goToChooseProductAmount(){
    this.router.navigate(["/choose-product-amount"]);
  }
  goToChooseVariation(){
    this.router.navigate(["/choose-variation"]);
  }
  goToViewAllPromotions(){
    this.router.navigate(["/view-all-promotions"]);
  }
  goToShowPromotionDetails(){
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewCart(){
    this.router.navigate(["/view-cart"]);
  }
  goToQuickCheckOut(){
    this.router.navigate(["/quick-check-out"]);
  }
  goToOnePageCheckOut(){
    this.router.navigate(["/one-page-check-out"]);
  }

  goToCheckOut(){
    this.router.navigate(["/check-out-option1"]);
  }
  getCatalogList() {
    //this.spinner.show();
    this.sidebarService.getCatalogList().subscribe((res: any) => {
      this.catalog = res.data;
      //this.spinner.hide();
      for (const value of this.catalog) {
        this.catalogArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })
  
  }

  getLocale() {
    //this.spinner.show();
    this.sidebarService.getLocale().subscribe((res: any) => {
      this.locale = res.data;
      //this.spinner.hide();
      for (const value of this.locale) {
        this.localeArray.push({
          label: value.value,
          value: value.key
        })
      }
    })
  
  }

  getCategoryByCatalog(event:any){
    this.id=event.value;
    this.sidebarService.getCategoryByCatalog(this.id).subscribe((res: any) => {
      this.category = res.data;

    })
  }

}
