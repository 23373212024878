<div class="container-fluid">
    <div class="row">
      <div class="col-lg-9 order-lg-2 order-1 px-4">
        <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="card-title" style="background: #283593;color: #fff;">
                <div class="float-start">
                    <h4 class="text-left my-3 mx-4">2. How shall we ship it?</h4>
                </div>
            </div>
            <div class="card-body pt-2 pb-4 px-4"> 
                <div class="row" *ngFor="let ship of shipmentMethods"> 
                 <div class="col-lg-4 col-sm-6"> 
                  <div class="form-check"> 
                   <input type="radio" (click)="radioShip(ship.shippingMethodId)" name="shipping_method" class="form-check-input" id="shipping_method_0" value="NEXT_DAY@UPS"> <label for="shipping_method_0"> {{ship.carrierShipmentMethod.partyId}}&nbsp; {{ship.carrierShipmentMethod.description}}-${{ship.shippingEst}}  </label> 
                  </div> 
                 </div> 
                 
               
                </div> 
                <h5>Ship all at once, or 'as available'?</h5> 
                <div class="form-check"> 
                 <input type="radio" class="form-check-input" (click)="split(false)"  name="may_split" value="false"> <label>Please wait until the entire order is ready before shipping.</label> 
                </div> 
                <div class="form-check"> 
                 <input class="form-check-input" (click)="split(true)" type="radio" name="may_split" value="true"> <label>Please ship items I ordered as they become available (you may incur additional shipping charges).</label> 
                 <h5>Special Instructions</h5> <textarea rows="3" wrap="hard" name="shipping_instructions"
                 [(ngModel)]="this.otherdata.shipping_instructions" [ngModelOptions]="{standalone: true}">

                 </textarea> 
                 <div> 
                  <h5>Is This a Gift?</h5> 
                  <div class="form-check form-check-inline"> 
                   <input type="radio" class="form-check-input" (click)="radioGift(true)" name="is_gift" id="is_gift" value="true"> <label class="form-check-label" for="is_gift">Yes</label> 
                  </div> 
                  <div class="form-check form-check-inline"> 
                   <input type="radio" class="form-check-input" (click)="radioGift(false)"  name="is_gift" id="is_not_gift" value="false"> <label class="form-check-label" for="is_not_gift">No</label> 
                  </div> 
                 </div> 
                 <h5>PO Number</h5> <textarea rows="3" wrap="hard" name="correspondingPoId"
                 [(ngModel)]="this.otherdata.correspondingPoId" [ngModelOptions]="{standalone: true}"></textarea> 
                 <h5>Gift Message</h5> <textarea rows="3" wrap="hard" name="gift_message"
                 [(ngModel)]="this.otherdata.gift_message" [ngModelOptions]="{standalone: true}"></textarea> 
                 <h5>Email Addresses</h5> 
                 <div>
                  Your order will be sent to the following email addresses:
                 </div> 
                 <div> <strong> {{this.emailId}} </strong> 
                 </div> 
                 <div>
                  Your may update these in your <a class="buttontext">Profile</a>.
                 </div> 
                 <br> <label for="order_additional_emails">You may add other comma separated email addresses here that will be used only for the current order:</label> 
                 <input type="text" class="form-control" name="order_additional_emails" id="order_additional_emails"
                 [(ngModel)]="this.otherdata.order_additional_emails" [ngModelOptions]="{standalone: true}" > 
                </div>
                <div class="row ms-2 mt-2"> 
                    <div class="col-auto ms-1 me-auto"> <a class="btn btn-secondary" (click)="goToViewCart()">Back to Shopping Cart</a> 
                    </div> 
                    <div class="col-auto"> <a class="btn btn-primary" (click)="goToCheckOutOption3(this.otherdata)">Next</a> 
                    </div> 
                </div> 
            </div>
        </div>
      </div>
      <div class="col-lg-3 order-lg-2 order-3 px-4">
        <div class="card mx-auto mt-2" style="width: 100%;">
          <div class="card-title">
            <p class="text-left my-3 mx-2">Language</p>
          </div>
          <div class="p-2">
            <form>
              <div class="form-group p-1">
                <p-dropdown [options]="localeArray" placeholder="Select Language">
                </p-dropdown>
              </div>
            </form>
          </div>
        </div>
        <div class="card mx-auto mt-2" style="width: 100%;">
          <div class="card-title">
            <p class="text-left my-3 mx-2">Cart Summary</p>
          </div>
          <div class="p-2">
            <form>
              <div class="card-body">
                <table class="table">
                  <thead>
                  <tr>
                    <th>Qty</th>
                    <th>Item </th>
                    <th>Subtotal</th>
                  </tr>
                  </thead>
                  <tfoot>
                  <tr>
                    <td colspan="3"  *ngIf="this.summary">
                    Total: ${{this.summary.cartTotal}}
                    </td>
                  </tr>
                  </tfoot>
                  <tbody>
                    <tr  *ngFor="let product of this.showCart;let indexOfelement=index;">
                      <td>{{indexOfelement+1}}</td>
                      <td>
                            <span class="span-link">{{product.productName}}</span>
                      </td>
                      <td>${{product.itemTotal}}</td>
                    </tr>
                  </tbody>
                </table>
                <ul>
                  <li><span class="span-link" (click)="goToViewCart()">View Cart</span></li>
                  <li><span class="span-link" (click)="goToCheckOut()">Check out</span></li>
                  <li><span class="span-link" (click)="goToQuickCheckOut()">Quick Checkout</span></li>
                  <!-- <li><span class="span-link" (click)="goToOnePageCheckOut()">One Page Checkout</span></li> -->
                </ul>
              </div>
            </form>
          </div>
        </div>
        <div class="card mx-auto mt-2" style="width: 100%;">
          <div class="card-title">
            <p class="text-left my-3 mx-2">Compare Products</p>
          </div>
          <div class="p-1">
            <form>
              <div class="card-body" *ngFor="let compare of compareProductList;">
                <label>{{compare.productData.productName}}</label>&nbsp;<span (click)="removeAddToCompare(compare.productData.productId)" class="span-link">Remove</span>
                <div>
                  <a href="" class="btn btn-outline-secondary" (click)="clearCompare()">Clear All</a>
                </div>
                <div>
                  <!--Modal Button-->
                  <a class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal">Compare
                    Products</a>
                </div>
                <!--Modal Popup Dialog Box-->
               
              </div>
             
            </form>
          </div>
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="card m-5">
                  <div class="card-header">
                    Compare Products
                  </div>
                  <div class="card-body">
                    <div class="row table-scroll">
                      <div class="col-6" *ngFor="let compareItem of compareProductList;">
                        <table class="table">
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td style="width:100%;">
                                <img [src]="compareItem.smallImageUrl" alt="Small Image"><br>
                              <span > {{compareItem.productData.productName}}</span> <br>
      
                                <span class="regularPrice">${{compareItem.productPrice.price}}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Brand Name</td>
                              <td>{{compareItem.productData.brandName}}</td>
                            </tr>
                            <tr>
                              <td>Product Description</td>
                              <td>{{compareItem.productData.description}}</td>
                            </tr>
                            <tr>
                              <td>Long Description</td>
                              <td>{{compareItem.productData.longDescription}}</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>
                                <div class="productbuy">
                                  <form method="post" action="" name="compare2FormAdd0">
                                    <input type="hidden" name="add_product_id" value="10110">
                                    <input type="text" size="5" name="quantity" value="1">
                                    <input type="hidden" name="clearSearch" value="N">
                                  </form>
                                  <a class="buttontext">Add to Cart </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="card mx-auto mt-2" style="width: 100%;">
          <div class="card-title">
            <p class="text-left my-3 mx-2">Special Offers</p>
          </div>
          <div class="p-1">
            <form>
              <div class="form-group">
                <ul *ngFor="let offer of this.specialOffers">
                  <li>
                    <p>
                      <a (click)="goToShowPromotionDetails()" class="linktext">Details</a>
                    {{offer.promoText}}
                    </p>
                  </li>
                  
                </ul>
              </div>
              <div class="form-group py-2 ps-4">
                <input class="btn btn-outline-secondary" type="submit" value="View All Promotions"
                  (click)="goToViewAllPromotions()">
              </div>
            </form>
          </div>
        </div>
        <div class="card mx-auto mt-2" style="width: 100%;">
          <div class="card-title">
            <p class="text-left my-3 mx-2">Did You Know?</p>
          </div>
          <div class="p-2">
            <form>
              <div class="form-group">
                <ul>
                  <li>
                    <p>
                      The use of gizmos has been shown to have no negative effect on personal longetivity.
                    </p>
                  </li>
                  <li>
                    <p>
                      Did you know that widget users live twice as long as their non-widget counterparts?
                    </p>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
        <div class="card mx-auto mt-2" style="width: 100%;">
          <div class="card-title">
            <div class="float-start">
              <p class="text-left my-3 mx-2">Last Categories</p>
            </div>
            <div class="float-end my-3 mx-2">
              <span class="span-link">[Clear]</span>
            </div>
          </div>
          <div class="card-body">
            <ul class="browsecategorylist">
              <li class="browsecategorytext">
                <span class="span-link">
                  PROMOTIONS
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="card mx-auto mt-2" style="width: 100%;">
          <div class="card-title">
            <div class="float-start">
              <p class="text-left my-3 mx-2">Last Products</p>
            </div>
            <div class="float-end my-3 mx-2">
              <span class="span-link">[Clear] </span><span class="span-link">[More]</span>
            </div>
          </div>
          <div class="card-body">
            <ul>
              <li>
                <a href="" class="linktext">
                  Gift Card Activation
                </a>
                <ul class="list-unstyled">
                  <li>
                    GC-001
                    From
                    <span class="normalPrice">
                      $1.00</span>
                  </li>
                  <li>
                    <a href="" class="buttons">
                      <span style="white-space: nowrap;">Choose Variations...</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="" class="linktext">
                  Enchiladas
                </a>
                <ul class="list-unstyled">
                  <li>
                    ENCHILADAS
                    Product Aggregated Price:
                    <span class="basePrice">
                      $18.00 </span>
                  </li>
                  <li>
                    <form method="post" action=""
                      name="lastviewed1form" style="margin: 0;">
                      <fieldset>
                        <input type="hidden" name="add_product_id" value="ENCHILADAS">
                        <input type="hidden" name="quantity" value="1">
                        <input type="hidden" name="clearSearch" value="N">
                        <a class="buttons">
                          <span style="white-space: nowrap;">
                            Add 1 To Cart
                          </span>
                        </a>
                      </fieldset>
                    </form>
                  </li>
                </ul>
              </li>
              <li>
                <a href="" class="linktext">
                  Financial Account Activation
                </a>
                <ul class="list-unstyled">
                  <li>
                    FA-001
                    From
                    <span class="normalPrice">
                      $1.00</span>
                  </li>
                  <li>
                    <a href="" class="buttons">
                      <span style="white-space: nowrap;">Choose Variations...</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="" class="linktext">
                  Soft Drink
                </a>
                <ul class="list-unstyled">
                  <li>
                    12121
                  </li>
                  <li>
                    <a href="" class="buttons">
                      <span style="white-space: nowrap;">Choose Amount...</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="" class="linktext">
                  Gift Card Reload
                </a>
                <ul class="list-unstyled">
                  <li>
                    GC-002
                  </li>
                  <li>
                    <a href="" class="buttons">
                      <span style="white-space: nowrap;">Choose Amount...</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="card mx-auto mt-2" style="width: 100%;">
          <div class="card-title">
            <p class="text-left my-3 mx-2">Tag Cloud</p>
          </div>
          <div class="p-2">
            <a style="font-size: 8pt;">
              big
            </a>
            <a style="font-size: 8pt;">
              black
            </a>
            <a style="font-size: 8pt;">
              chrome
            </a>
            <a style="font-size: 11pt;">
              demonstration
            </a>
            <a style="font-size: 14pt;">
              gizmos
            </a>
            <a style="font-size: 11pt;">
              red
            </a>
            <a style="font-size: 9.5pt;">
              service
            </a>
            <a style="font-size: 9.5pt;">
              small
            </a>
            <a style="font-size: 8pt;">
              standard
            </a>
            <a style="font-size: 8pt;">
              tiny
            </a>
            <a style="font-size: 9.5pt;">
              widget
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
