<div class="container-fluid p-0 pt-3">
    <div class="card" style="border: none!important;">
        <div class="card-title px-1 pt-1 pb-0" style="background-color: white!important;border:none!important">
            <div class="float-start">
                <h6>Contact Information</h6>
            </div>
            <div class="float-end pe-3">
                <span class="span-link" (click)="goToCreateContactInformation()">Create telecom</span><span> | </span>
                <span class="span-link" (click)="goToCreateAddress()">Create address</span>
            </div>
        </div>
        <div class="card-body pt-0 ps-1">
            <div class="row"></div>
            <div class="row">
                <div class="col-lg-12 table-scroll">
                    <p-table [value]="getcontactInformation" [paginator]="true" [rows]="rows" scrollWidth="100%"
                    [rowsPerPageOptions]="[10,25,50, 100]" [totalRecords]="total" [scrollable]="true"
                    [style]="{'width':'100%','min-width':'600px'}">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Contact Type</th>
                                <th>Contact Information</th>
                                <th>Soliciting OK</th>
                                <th>Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-product>
                            <tr>
                                <td>{{product.contactMechType.contactMechTypeId}}</td>
                                <td>
                                    <ng-container *ngIf="product.contactMech.contactMechTypeId==='POSTAL_ADDRESS'">
                                        {{product.postalAddress.address1}} ,
                                        {{product.postalAddress.address2}},<br>{{product.postalAddress.stateProvinceGeoId}}
                                        ,
                                        {{product.postalAddress.city}},<br>{{product.postalAddress.postalCode}},{{product.postalAddress.countryGeoId}}
                                    </ng-container>
                                    <ng-container *ngIf="product.contactMech.contactMechTypeId==='EMAIL_ADDRESS'">
                                        {{product.contactMech.infoString}}
                                    </ng-container>
                                    <ng-container *ngIf="product.contactMech.contactMechTypeId==='TELECOM_NUMBER'">
                                        Area Code: {{product.telecomNumber.areaCode}}, Contact Number:
                                        {{product.telecomNumber.contactNumber}}, Country Code:
                                        {{product.telecomNumber.countryCode}}
                                    </ng-container>
                                </td>
                                <td>{{product.partyContactMech.allowSolicitation
                                    ?product.partyContactMech.allowSolicitation :'N' }}
                                </td>
                                <td>
                                    <a class="btn btn-outline-secondary me-1" (click)="update(product)">Update</a>
                                    <a class="btn btn-outline-secondary" (click)="onDelete(product.contactMech.contactMechId)">Expire</a>
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                    
                </div>
            </div>
        </div>
    </div>
</div>