import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SideBarService } from '../side-bar/side-bar.service';
import { TreeNode } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.css']
})
export class LeftSideBarComponent implements OnInit {

  
  catalog: any;
  catalogArray: any[] = [];
  locale: any;
  localeArray: any[] = [];
  id: any;
  selectedFiles: any;
  category: TreeNode[] = [];
  language: any;
  findForm:FormGroup;
  CatalogForm:FormGroup;
  catalogId: string | null;
  value:any;

  constructor(readonly router: Router, private sidebarService: SideBarService,
    private toastr: ToastrService,readonly Form:FormBuilder) {
      this.catalogId = localStorage.getItem("catalog");
      this.CatalogForm = this.Form.group({
  
        catalogId: [''],
       
      });
      this.findForm = this.Form.group({
        findCatalog:[''],
        radioAll:['']
      });

  }

  ngOnInit(): void {
    this.getCatalogList();
    this.getLocale();
   /*  setTimeout(() => {
      const formValue = this.CatalogForm;
    formValue.patchValue({
      catalogId:this.catalogId,
     
     
    })
    }, 2000); */
   
   
  }

  goToFeaturedProducts() {
    this.router.navigate(["/"]);
  }
  goToAdvancedSearch() {
    this.router.navigate(["/advanced-search"]);
  }
  goToViewAllPromotions() {
    this.router.navigate(["/view-all-promotions"]);
  }
  goToPromotionDetails() {
    this.router.navigate(["/show-promotion-details"]);
  }
  goToLogin() {
    this.router.navigate([""]);
  }
  goToFindCatalog(){
    this.router.navigate(["/find-catalog"],{queryParams:{findCatalog:this.findForm.value.findCatalog,type:this.findForm.value.radioAll}});
  }
 

  getLocale() {
    //this.spinner.show();
    this.sidebarService.getLocale().subscribe((res: any) => {
      this.locale = res.data;
      //this.spinner.hide();
      for (const value of this.locale) {
        this.localeArray.push({
          label: value.value,
          value: value.key
        })
      }
    })

  }
  
  getLanguage(event:any) {
    this.language=event.value;
    this.sidebarService.postLocale(this.language).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
       
      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);
      
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
       
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
        
          this.toastr.error(err.error.message);
        }
      }
    })
  
 
}
  
  getCategoryByCatalog(event:any){
    this.id=event.value;
    localStorage.setItem('catalog',(this.id));
    this.value=localStorage.getItem("catalog");
    this.sidebarService.getCategoryByCatalog(this.value).subscribe((res: any) => {
      this.category = res.data;
      this.router.navigate(["/"]);
    })
  }
  getCatalogList() {
    //this.spinner.show();
    this.sidebarService.getCatalogList().subscribe((res: any) => {
      this.catalog = res.data;
      //this.spinner.hide();
      for (const value of this.catalog) {
        this.catalogArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })

  }
}
