<div class="container-fluid">
    <div id="ecom-mainarea" class="row mx-2 mt-2">
        <div id="div3" class="col-lg-12 order-lg-2 order-1">
            <ng-container *ngIf="show">
                <h2>Update Phone Number</h2>
            </ng-container>
            <ng-container *ngIf="!show">
                <h2>Add New Phone Number</h2>
            </ng-container>
            <form>
                <a class="btn btn-outline-secondary me-2" (click)="goToProfile()">Go Back</a>
                <ng-container *ngIf="show">
                    <a class="btn btn-outline-secondary" (click)="onUpdate()">Update</a>
                </ng-container>
                <ng-container *ngIf="!show">
                    <a class="btn btn-outline-secondary" (click)="onSubmit()">Save</a>
                </ng-container>
                <form [formGroup]="contactForm">
                    <div class="my-2">
                        <label>Allow Solicitation</label>
                        <div class="row">
                            <div class="col-sm-6 mb-2">
                                <p-dropdown [options]="solicitationArray" formControlName="allowSolicitation">
                                </p-dropdown>
                            </div>
                        </div>
                        <label class="required">Country Code<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control mb-2" maxlength="60" name="countryCode"
                                    [(ngModel)]="countryCode" placeholder="Enter country code" value=""
                                    formControlName="countryCode">
                            </div>
                        </div>
                        <label class="required">Area Code<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control mb-2" maxlength="60" name="areaCode"
                                    [(ngModel)]="areaCode" placeholder="Enter area code" value=""
                                    formControlName="areaCode">
                            </div>
                        </div>
                        <label class="required">Contact Number<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control mb-2" maxlength="60" name="contactNumber"
                                    [(ngModel)]="contactNumber" placeholder="Enter contact number" value=""
                                    formControlName="contactNumber">
                            </div>
                        </div>
                        <label class="required">Extension</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control mb-2" maxlength="60" name="extension"
                                    placeholder="Enter extension" value="" formControlName="extension">
                            </div>
                        </div>
                        <label>Mech Purpose Type Id<span style="color:red!important">*</span></label>
                        <div class="row">
                            <div class="col-sm-6">
                                <p-dropdown [options]="mechArray" formControlName="contactMechPurposeTypeId">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </form>
            </form>
            <a class="btn btn-outline-secondary me-2" (click)="goToProfile()">Go Back</a>
            <ng-container *ngIf="show">
                <a class="btn btn-outline-secondary" (click)="onUpdate()">Update</a>
            </ng-container>
            <ng-container *ngIf="!show">
                <a class="btn btn-outline-secondary" (click)="onSubmit()">Save</a>
            </ng-container>
        </div>
        <div id="div4" class="endcolumns">
        </div>
    </div>
</div>