<div class="container-fluid px-4">
    <div class="px-4 pt-3 pb-2">
        <h3>One Page Checkout</h3>
    </div>
    <div class="accordion mb-4" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
            <div class="card-title px-4 pt-3 pb-1" id="panelsStayOpen-headingOne">
                <p><strong>Step 1: Shopping Cart</strong></p>
            </div>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne">
                <div class="accordion-body">
                    <div class="p-1">
                        <div class="form-group p-2 table-scroll">
                            <p-table [value]="showCart" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                [rowsPerPageOptions]="[10,25,50, 100]" [totalRecords]="total" [scrollable]="true"
                                [style]="{'width':'100%','min-width':'600px'}">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Item</th>
                                        <th>Description</th>
                                        <th>Unit Price</th>
                                        <th>Quantity</th>
                                        <th>Adjustments </th>
                                        <th>Item Total</th>
                                        <th style="width:6%" >Remove</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-product>
                                    <tr>
                                        <td>{{product.product}}</td>
                                        <td>{{product.productName}}</td>
                                        <td class="amount">${{product.unitPrice}}</td> 
                                        <td>{{product.quantity}}</td>
                                        <td class="amount">${{product.adjustments}}</td> 
                                        <td class="amount">${{product.itemTotal}}</td> 
                                        
                                    </tr>
                                </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td>
                      <span class="text-center">
                          <h6 class="ml-2">No Record Found
                          </h6>
                      </span>
                  </td>
              </tr>
          </ng-template>
          </p-table>
                            <p class="paginate_data">
                                View per page
                            </p>
                        </div>
                    </div>

                    <div class="card mx-auto mt-4" *ngIf="this.summary"  style="width: 100%;">
                        <div class="card-title">
                            <p class="text-left my-3 mx-2"><strong>Shopping List Price Totals - List Created From Order #WSCO10720</strong>></p>
                        </div>
                        <!-- <div class="px-2 pt-3 row">
                          <div class="col-9">
                            <p><strong>Child List Total Price</strong></p>
                          </div>
                          <div class="col-3">
                            <div class="float-end">
                              <p>0${{priceList.childListTotalPrice}}</p>
                            </div>
                          </div>
                        </div> -->
                        <div class="px-2 row">
                          <div class="col-9">
                            <p><strong>Sub Total:</strong></p>
                          </div>
                          <div class="col-3">
                            <div class="float-end">
                              <p>0${{this.summary.subTotal}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="px-2 row">
                          <div class="col-9">
                            <p><strong>Discount</strong></p>
                          </div>
                          <div class="col-3">
                            <div class="float-end">
                              <p>${{this.summary.adjustments[0].amount}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="px-2 pb-4 row">
                          <div class="col-9">
                            <p><strong>Grand Total</strong></p>
                          </div>
                          <div class="col-3">
                            <div class="float-end">
                              <p>${{this.summary.cartTota}}</p>
                            </div>
                          </div>
                        </div>
                    </div> 

                    <!-- <div class="p-1">
                        <div class="form-group p-2 table-scroll">
                            <p-table [value]="summary" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                [rowsPerPageOptions]="[10,25,50, 100]" [totalRecords]="total" [scrollable]="true"
                                [style]="{'width':'100%','min-width':'600px'}">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Summary</th>
                                        <th style="width:6%"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-product>
                                    <tr>
                                    <td class="amount" colspan="1"> ${{summary.subTotal}}</td> 
                                    <td class="amount" colspan="1"> -${{summary.adjustments[0].amount}} </td> 
                                    <td class="amount" colspan="1"> ${{summary.cartTotal}} </td> 

                                    </tr>
                                </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td>
                      <span class="text-center">
                          <h6 class="ml-2">No Record Found
                          </h6>
                      </span>
                  </td>
              </tr>
          </ng-template>
          </p-table>
                            <p class="paginate_data">
                                View per page
                            </p>
                        </div>
                    </div> -->
                    <div class="px-2">
                        <label class="mb-2" for="exampleInputEmail1">Enter Promo Code</label>
                        <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                            placeholder="">
                    </div>
                    <div class="p-2">
                        <button class="btn btn-primary" type="button" data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseTwo" (click)="checkoutOptions()">
                            Continue to step 2
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion mb-4" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
            <div class="card-title px-4 pt-3 pb-1" id="panelsStayOpen-headingOne">
                <p><strong>Step 2: Shipping</strong></p>
            </div>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo">
                <div class="accordion-body">
                    <div class="p-1">
                        <div class="row px-2">
                            <div class="col-lg-6">
                                <label class="mb-2" for="exampleInputEmail1">First Name*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-6">
                                <label class="mb-2" for="exampleInputEmail1">Last Name*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-2">
                                <label class="mb-2" for="exampleInputEmail1">Country*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-2">
                                <label class="mb-2" for="exampleInputEmail1">Area Code*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-2">
                                <label class="mb-2" for="exampleInputEmail1">Contact Number*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-2">
                                <label class="mb-2" for="exampleInputEmail1">Extension</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-4">
                                <label class="mb-2" for="exampleInputEmail1">Email Address*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-6">
                                <label class="mb-2" for="exampleInputEmail1">Address Line 1*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-6">
                                <label class="mb-2" for="exampleInputEmail1">Address Line 2</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">City*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">Zip/Postal Code*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">Country*</label>
                                <select name="shipToCountryGeoId" class="form-select form-select"
                                    id="shipToCountryGeoId" aria-label=".form-select example" aria-invalid="false">
                                    <option value="USA">
                                        United States
                                    </option>
                                    <option value="AFG">Afghanistan</option>
                                    <option value="ALA">Åland islands</option>
                                    <option value="ALB">Albania</option>
                                    <option value="DZA">Algeria</option>
                                    <option value="ASM">American Samoa</option>
                                    <option value="AND">Andorra</option>
                                    <option value="AGO">Angola</option>
                                    <option value="AIA">Anguilla</option>
                                    <option value="ATA">Antarctica</option>
                                    <option value="ATG">Antigua And Barbuda</option>
                                    <option value="ARG">Argentina</option>
                                    <option value="ARM">Armenia</option>
                                    <option value="ABW">Aruba</option>
                                    <option value="AUS">Australia</option>
                                    <option value="AUT">Austria</option>
                                    <option value="AZE">Azerbaijan</option>
                                    <option value="BHS">Bahamas</option>
                                    <option value="BHR">Bahrain</option>
                                    <option value="BGD">Bangladesh</option>
                                    <option value="BRB">Barbados</option>
                                    <option value="BLR">Belarus</option>
                                    <option value="BEL">Belgium</option>
                                    <option value="BLZ">Belize</option>
                                    <option value="BEN">Benin</option>
                                    <option value="BMU">Bermuda</option>
                                    <option value="BTN">Bhutan</option>
                                    <option value="BOL">Bolivia</option>
                                    <option value="BES">Bonaire, Sint Eustatius and Saba</option>
                                    <option value="BIH">Bosnia And Herzegowina</option>
                                    <option value="BWA">Botswana </option>
                                    <option value="BVT">Bouvet Island</option>
                                    <option value="BRA">Brazil</option>
                                    <option value="IOT">British Indian Ocean Territory</option>
                                    <option value="BRN">Brunei Darussalam</option>
                                    <option value="BGR">Bulgaria</option>
                                    <option value="BFA">Burkina Faso</option>
                                    <option value="BDI">Burundi</option>
                                    <option value="KHM">Cambodia</option>
                                    <option value="CMR">Cameroon</option>
                                    <option value="CAN">Canada</option>
                                    <option value="CPV">Cape Verde</option>
                                    <option value="CYM">Cayman Islands</option>
                                    <option value="CAF">Central African Republic</option>
                                    <option value="TCD">Chad</option>
                                    <option value="CHL">Chile</option>
                                    <option value="CHN">China</option>
                                    <option value="CXR">Christmas Island</option>
                                    <option value="CCK">Cocos (keeling) Islands</option>
                                    <option value="COL">Colombia</option>
                                    <option value="COM">Comoros</option>
                                    <option value="COG">Congo</option>
                                    <option value="COD">Congo, The Democratic Republic Of The</option>
                                    <option value="COK">Cook Islands</option>
                                    <option value="CRI">Costa Rica</option>
                                    <option value="CIV">Cote D'ivoire</option>
                                    <option value="HRV">Croatia (local Name: Hrvatska)</option>
                                    <option value="CUB">Cuba</option>
                                    <option value="CUW">Curaçao</option>
                                    <option value="CYP">Cyprus</option>
                                    <option value="CZE">Czech Republic</option>
                                    <option value="DNK">Denmark</option>
                                    <option value="DJI">Djibouti</option>
                                    <option value="DMA">Dominica</option>
                                    <option value="DOM">Dominican Republic</option>
                                    <option value="TLS">East Timor</option>
                                    <option value="ECU">Ecuador</option>
                                    <option value="EGY">Egypt</option>
                                    <option value="SLV">El Salvador</option>
                                    <option value="ENGL">England</option>
                                    <option value="GNQ">Equatorial Guinea</option>
                                    <option value="ERI">Eritrea</option>
                                    <option value="EST">Estonia</option>
                                    <option value="ETH">Ethiopia</option>
                                    <option value="FLK">Falkland Islands (malvinas)</option>
                                    <option value="FRO">Faroe Islands</option>
                                    <option value="FJI">Fiji</option>
                                    <option value="FIN">Finland</option>
                                    <option value="FRA">France</option>
                                    <option value="FXX">France, Metropolitan</option>
                                    <option value="GUF">French Guiana</option>
                                    <option value="PYF">French Polynesia</option>
                                    <option value="ATF">French Southern Territories</option>
                                    <option value="GAB">Gabon</option>
                                    <option value="GMB">Gambia</option>
                                    <option value="GEO">Georgia</option>
                                    <option value="DEU">Germany</option>
                                    <option value="GHA">Ghana</option>
                                    <option value="GIB">Gibraltar</option>
                                    <option value="GRC">Greece</option>
                                    <option value="GRL">Greenland</option>
                                    <option value="GRD">Grenada</option>
                                    <option value="GLP">Guadeloupe</option>
                                    <option value="GUM">Guam</option>
                                    <option value="GTM">Guatemala</option>
                                    <option value="GGY">Guernsey</option>
                                    <option value="GIN">Guinea</option>
                                    <option value="GNB">Guinea-bissau</option>
                                    <option value="GUY">Guyana</option>
                                    <option value="HTI">Haiti</option>
                                    <option value="HMD">Heard And Mc Donald Islands</option>
                                    <option value="VAT">Holy See (vatican City State)</option>
                                    <option value="HND">Honduras</option>
                                    <option value="HKG">Hong Kong</option>
                                    <option value="HUN">Hungary</option>
                                    <option value="ISL">Iceland</option>
                                    <option value="IND">India</option>
                                    <option value="IDN">Indonesia</option>
                                    <option value="IRN">Iran (islamic Republic Of)</option>
                                    <option value="IRQ">Iraq</option>
                                    <option value="IRL">Ireland</option>
                                    <option value="IMN">Isle of Man</option>
                                    <option value="ISR">Israel</option>
                                    <option value="ITA">Italy</option>
                                    <option value="JAM">Jamaica</option>
                                    <option value="JPN">Japan</option>
                                    <option value="JEY">Jersey</option>
                                    <option value="JOR">Jordan</option>
                                    <option value="KAZ">Kazakhstan</option>
                                    <option value="KEN">Kenya</option>
                                    <option value="KIR">Kiribati</option>
                                    <option value="PRK">Korea, Democratic People's Republic Of</option>
                                    <option value="KOR">Korea, Republic Of</option>
                                    <option value="KWT">Kuwait</option>
                                    <option value="KGZ">Kyrgyzstan</option>
                                    <option value="LAO">Lao People's Democratic Republic</option>
                                    <option value="LVA">Latvia</option>
                                    <option value="LBN">Lebanon</option>
                                    <option value="LSO">Lesotho</option>
                                    <option value="LBR">Liberia</option>
                                    <option value="LBY">Libyan Arab Jamahiriya</option>
                                    <option value="LIE">Liechtenstein</option>
                                    <option value="LTU">Lithuania</option>
                                    <option value="LUX">Luxembourg</option>
                                    <option value="MAC">Macau</option>
                                    <option value="MKD">Macedonia, The Former Yugoslav Republic Of</option>
                                    <option value="MDG">Madagascar</option>
                                    <option value="MWI">Malawi</option>
                                    <option value="MYS">Malaysia</option>
                                    <option value="MDV">Maldives</option>
                                    <option value="MLI">Mali</option>
                                    <option value="MLT">Malta</option>
                                    <option value="MHL">Marshall Islands</option>
                                    <option value="MTQ">Martinique</option>
                                    <option value="MRT">Mauritania</option>
                                    <option value="MUS">Mauritius</option>
                                    <option value="MYT">Mayotte</option>
                                    <option value="MEX">Mexico</option>
                                    <option value="FSM">Micronesia, Federated States Of</option>
                                    <option value="MDA">Moldova, Republic Of</option>
                                    <option value="MCO">Monaco</option>
                                    <option value="MNG">Mongolia</option>
                                    <option value="MNE">Montenegro</option>
                                    <option value="MSR">Montserrat</option>
                                    <option value="MAR">Morocco</option>
                                    <option value="MOZ">Mozambique</option>
                                    <option value="MMR">Myanmar</option>
                                    <option value="NAM">Namibia</option>
                                    <option value="NRU">Nauru</option>
                                    <option value="NPL">Nepal</option>
                                    <option value="NLD">Netherlands</option>
                                    <option value="ANT">Netherlands Antilles</option>
                                    <option value="NCL">New Caledonia</option>
                                    <option value="NZL">New Zealand</option>
                                    <option value="NIC">Nicaragua</option>
                                    <option value="NER">Niger</option>
                                    <option value="NGA">Nigeria</option>
                                    <option value="NIRL">NIRL</option>
                                    <option value="NIU">Niue</option>
                                    <option value="NFK">Norfolk Island</option>
                                    <option value="MNP">Northern Mariana Islands</option>
                                    <option value="NOR">Norway</option>
                                    <option value="OMN">Oman</option>
                                    <option value="PAK">Pakistan</option>
                                    <option value="PLW">Palau</option>
                                    <option value="PSE">Palestinian Territory, Occupied</option>
                                    <option value="PAN">Panama</option>
                                    <option value="PNG">Papua New Guinea</option>
                                    <option value="PRY">Paraguay</option>
                                    <option value="PER">Peru</option>
                                    <option value="PHL">Philippines</option>
                                    <option value="PCN">Pitcairn</option>
                                    <option value="POL">Poland</option>
                                    <option value="PRT">Portugal</option>
                                    <option value="PRI">Puerto Rico</option>
                                    <option value="QAT">Qatar</option>
                                    <option value="REU">Reunion</option>
                                    <option value="ROU">Romania</option>
                                    <option value="RUS">Russian Federation</option>
                                    <option value="RWA">Rwanda</option>
                                    <option value="BLM">Saint Barthélemy</option>
                                    <option value="KNA">Saint Kitts And Nevis</option>
                                    <option value="LCA">Saint Lucia</option>
                                    <option value="MAF">Saint Martin (French part)</option>
                                    <option value="VCT">Saint Vincent And The Grenadines</option>
                                    <option value="WSM">Samoa</option>
                                    <option value="SMR">San Marino</option>
                                    <option value="STP">Sao Tome And Principe</option>
                                    <option value="SAU">Saudi Arabia</option>
                                    <option value="SCOT">Scotland</option>
                                    <option value="SEN">Senegal</option>
                                    <option value="SRB">Serbia</option>
                                    <option value="SYC">Seychelles</option>
                                    <option value="SLE">Sierra Leone</option>
                                    <option value="SGP">Singapore</option>
                                    <option value="SXM">Sint Maarten (Dutch part)</option>
                                    <option value="SVK">Slovakia (slovak Republic)</option>
                                    <option value="SVN">Slovenia</option>
                                    <option value="SLB">Solomon Islands</option>
                                    <option value="SOM">Somalia</option>
                                    <option value="ZAF">South Africa</option>
                                    <option value="SGS">South Georgia And The South Sandwich Islands</option>
                                    <option value="SSD">South Sudan</option>
                                    <option value="ESP">Spain</option>
                                    <option value="LKA">Sri Lanka</option>
                                    <option value="SHN">St. Helena</option>
                                    <option value="SPM">St. Pierre And Miquelon</option>
                                    <option value="SDN">Sudan</option>
                                    <option value="SUR">Suriname</option>
                                    <option value="SJM">Svalbard And Jan Mayen Islands</option>
                                    <option value="SWZ">Swaziland</option>
                                    <option value="SWE">Sweden</option>
                                    <option value="CHE">Switzerland</option>
                                    <option value="SYR">Syrian Arab Republic</option>
                                    <option value="TWN">Taiwan</option>
                                    <option value="TJK">Tajikistan</option>
                                    <option value="TZA">Tanzania, United Republic Of</option>
                                    <option value="THA">Thailand</option>
                                    <option value="TGO">Togo</option>
                                    <option value="TKL">Tokelau</option>
                                    <option value="TON">Tonga</option>
                                    <option value="TTO">Trinidad And Tobago</option>
                                    <option value="TUN">Tunisia</option>
                                    <option value="TUR">Turkey</option>
                                    <option value="TKM">Turkmenistan</option>
                                    <option value="TCA">Turks And Caicos Islands</option>
                                    <option value="TUV">Tuvalu</option>
                                    <option value="UGA">Uganda</option>
                                    <option value="UKR">Ukraine</option>
                                    <option value="ARE">United Arab Emirates</option>
                                    <option value="GBR">United Kingdom</option>
                                    <option value="USA" selected="selected">United States</option>
                                    <option value="UMI">United States Minor Outlying Islands</option>
                                    <option value="URY">Uruguay</option>
                                    <option value="UZB">Uzbekistan</option>
                                    <option value="VUT">Vanuatu</option>
                                    <option value="VEN">Venezuela</option>
                                    <option value="VNM">Viet Nam</option>
                                    <option value="VGB">Virgin Islands (british)</option>
                                    <option value="VIR">Virgin Islands (u.s.)</option>
                                    <option value="WALS">WALS</option>
                                    <option value="WLF">Wallis And Futuna Islands</option>
                                    <option value="ESH">Western Sahara</option>
                                    <option value="YEM">Yemen</option>
                                    <option value="YUG">Yugoslavia</option>
                                    <option value="ZMB">Zambia</option>
                                    <option value="ZWE">Zimbabwe</option>
                                </select>
                            </div>
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">State*</label>
                                <select id="shipToStateProvinceGeoId" class="form-select form-select"
                                    name="shipToStateProvinceGeoId" aria-label=".form-select example"
                                    aria-invalid="false">
                                    <option value="AA">Armed Forces Americas</option>
                                    <option value="AE">Armed Forces Europe</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AP">Armed Forces Pacific</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DC">District of Columbia</option>
                                    <option value="DE">Delaware</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="GU">Guam</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="IA">Iowa</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MD">Maryland</option>
                                    <option value="ME">Maine</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MT">Montana</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NY">New York</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VA">Virginia</option>
                                    <option value="VI">Virgin Islands</option>
                                    <option value="VT">Vermont</option>
                                    <option value="WA">Washington</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="p-2">
                        <button class="btn btn-primary" type="button" data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseThree" (click)="checkoutOptions1()">
                            Continue to step 3
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion mb-4" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
            <div class="card-title px-4 pt-3 pb-1" id="panelsStayOpen-headingThree">
                <p><strong>Step 3: Shipping Options</strong></p>
            </div>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree">
                <div class="accordion-body">
                    <div class="p-1">
                        <label for="inputemail">Select Shipping Method*</label>
                        <select id="shipMethod" name="shipMethod" class="form-select required valid"
                            aria-invalid="false">
                            <option value="NEXT_DAY@UPS:9003"> UPS Guaranteed Next Day - $10.00 </option>
                            <option value="AIR@UPS:9002"> UPS Air - $5.00 </option>
                            <option value="GROUND@UPS:9001"> UPS Ground - $3.00 </option>
                            <option value="EXPRESS@USPS:9005"> USPS Express - $0.00 </option>
                            <option value="STANDARD@USPS:9004"> USPS Standard - $0.00 </option>
                            <option value="NO_SHIPPING@_NA_:9000"> _NA_ No Shipping - </option>
                            <option value="EXPRESS@DHL:9006"> DHL Express - $10.00 </option>
                            <option value="NEXT_PM@DHL:9007"> DHL Next Afternoon - $9.00 </option>
                            <option value="SECOND_DAY@DHL:9008"> DHL Second Day - $5.00 </option>
                            <option value="GROUND@DHL:9009"> DHL Ground - $3.00 </option>
                            <option value="NEXT_AM@FEDEX:9010"> FEDEX Next Morning - $0.00 </option>
                            <option value="NEXT_DAY@FEDEX:9011"> FEDEX Guaranteed Next Day - $0.00 </option>
                            <option value="NEXT_PM@FEDEX:9012"> FEDEX Next Afternoon - $0.00 </option>
                            <option value="SECOND_DAY@FEDEX:9013"> FEDEX Second Day - $0.00 </option>
                            <option value="EXPRESS_SAVER@FEDEX:9014"> FEDEX Express Saver - $0.00 </option>
                            <option value="GROUND@FEDEX:9015"> FEDEX Ground - $0.00 </option>
                            <option value="GROUND_HOME@FEDEX:9016"> FEDEX Ground Home Delivery - $0.00 </option>
                        </select>
                    </div>
                    <div class="p-2">
                        <button class="btn btn-primary" type="button" data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseFour">
                            Continue to step 4
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion mb-4" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
            <div class="card-title px-4 pt-3 pb-1" id="panelsStayOpen-headingFour">
                <p><strong>Step 4: Billing</strong></p>
            </div>
            <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFour">
                <div class="accordion-body">
                    <div class="p-1">
                        <div class="row px-2">
                            <div class="col-lg-6">
                                <label class="mb-2" for="exampleInputEmail1">First Name*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-6">
                                <label class="mb-2" for="exampleInputEmail1">Last Name*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">Country*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">Area Code*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">Contact Number*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">Extension</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-2">
                                <label class="mb-2" for="exampleInputEmail1">Card Type*</label>
                                <select name="cardType" id="cardType" class="form-select valid" aria-invalid="false">
                                    <option label="CCT_VISA" value="CCT_VISA">CCT_VISA</option>
                                    <option value="CCT_VISA">Visa</option>
                                    <option value="CCT_MASTERCARD">MasterCard</option>
                                    <option value="CCT_AMERICANEXPRESS">AmericanExpress</option>
                                    <option value="CCT_DINERSCLUB">DinersClub</option>
                                    <option value="CCT_DISCOVER">Discover</option>
                                    <option value="CCT_ENROUTE">EnRoute</option>
                                    <option value="CCT_CARTEBLANCHE">CarteBlanche</option>
                                    <option value="CCT_JCB">JCB</option>
                                    <option value="CCT_SOLO">Solo</option>
                                    <option value="CCT_SWITCH">Switch</option>
                                    <option value="CCT_VISAELECTRON">VisaElectron</option>
                                    <option value="CCT_UATP">UATP</option>
                                </select>
                            </div>
                            <div class="col-lg-4">
                                <label class="mb-2" for="exampleInputEmail1">Card Number*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-2">
                                <label class="mb-2" for="exampleInputEmail1">CVV2*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-2">
                                <label class="mb-2" for="exampleInputEmail1">Month:*</label>
                                <select id="expMonth" name="expMonth" class="form-select required valid"
                                    aria-invalid="false">
                                    <option label="02" value="02">02</option>
                                    <option></option>
                                    <option value="01">01</option>
                                    <option value="02">02</option>
                                    <option value="03">03</option>
                                    <option value="04">04</option>
                                    <option value="05">05</option>
                                    <option value="06">06</option>
                                    <option value="07">07</option>
                                    <option value="08">08</option>
                                    <option value="09">09</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
                            <div class="col-lg-2">
                                <label class="mb-2" for="exampleInputEmail1">Year:*</label>
                                <select id="expYear" name="expYear" class="form-select required valid"
                                    aria-invalid="false">
                                    <option value="2022">2022</option>
                                    <option></option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                    <option value="2029">2029</option>
                                    <option value="2030">2030</option>
                                    <option value="2031">2031</option>
                                </select>
                            </div>
                        </div>
                        <div class="row px-2 py-2">
                            <div class="col-lg-12">
                                <input class="form-check-input" id="useShippingAddressForBilling"
                                    name="useShippingAddressForBilling" type="checkbox" value="N">
                                <label for="useShippingAddressForBilling">Billing address is the same as the shipping address</label>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-6">
                                <label class="mb-2" for="exampleInputEmail1">Address Line 1*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-6">
                                <label class="mb-2" for="exampleInputEmail1">Address Line 2</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">City*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">Zip/Postal Code*</label>
                                <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp"
                                    placeholder="">
                            </div>
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">Country*</label>
                                <select name="shipToCountryGeoId" class="form-select form-select"
                                    id="shipToCountryGeoId" aria-label=".form-select example" aria-invalid="false">
                                    <option value="USA">
                                        United States
                                    </option>
                                    <option value="AFG">Afghanistan</option>
                                    <option value="ALA">Åland islands</option>
                                    <option value="ALB">Albania</option>
                                    <option value="DZA">Algeria</option>
                                    <option value="ASM">American Samoa</option>
                                    <option value="AND">Andorra</option>
                                    <option value="AGO">Angola</option>
                                    <option value="AIA">Anguilla</option>
                                    <option value="ATA">Antarctica</option>
                                    <option value="ATG">Antigua And Barbuda</option>
                                    <option value="ARG">Argentina</option>
                                    <option value="ARM">Armenia</option>
                                    <option value="ABW">Aruba</option>
                                    <option value="AUS">Australia</option>
                                    <option value="AUT">Austria</option>
                                    <option value="AZE">Azerbaijan</option>
                                    <option value="BHS">Bahamas</option>
                                    <option value="BHR">Bahrain</option>
                                    <option value="BGD">Bangladesh</option>
                                    <option value="BRB">Barbados</option>
                                    <option value="BLR">Belarus</option>
                                    <option value="BEL">Belgium</option>
                                    <option value="BLZ">Belize</option>
                                    <option value="BEN">Benin</option>
                                    <option value="BMU">Bermuda</option>
                                    <option value="BTN">Bhutan</option>
                                    <option value="BOL">Bolivia</option>
                                    <option value="BES">Bonaire, Sint Eustatius and Saba</option>
                                    <option value="BIH">Bosnia And Herzegowina</option>
                                    <option value="BWA">Botswana </option>
                                    <option value="BVT">Bouvet Island</option>
                                    <option value="BRA">Brazil</option>
                                    <option value="IOT">British Indian Ocean Territory</option>
                                    <option value="BRN">Brunei Darussalam</option>
                                    <option value="BGR">Bulgaria</option>
                                    <option value="BFA">Burkina Faso</option>
                                    <option value="BDI">Burundi</option>
                                    <option value="KHM">Cambodia</option>
                                    <option value="CMR">Cameroon</option>
                                    <option value="CAN">Canada</option>
                                    <option value="CPV">Cape Verde</option>
                                    <option value="CYM">Cayman Islands</option>
                                    <option value="CAF">Central African Republic</option>
                                    <option value="TCD">Chad</option>
                                    <option value="CHL">Chile</option>
                                    <option value="CHN">China</option>
                                    <option value="CXR">Christmas Island</option>
                                    <option value="CCK">Cocos (keeling) Islands</option>
                                    <option value="COL">Colombia</option>
                                    <option value="COM">Comoros</option>
                                    <option value="COG">Congo</option>
                                    <option value="COD">Congo, The Democratic Republic Of The</option>
                                    <option value="COK">Cook Islands</option>
                                    <option value="CRI">Costa Rica</option>
                                    <option value="CIV">Cote D'ivoire</option>
                                    <option value="HRV">Croatia (local Name: Hrvatska)</option>
                                    <option value="CUB">Cuba</option>
                                    <option value="CUW">Curaçao</option>
                                    <option value="CYP">Cyprus</option>
                                    <option value="CZE">Czech Republic</option>
                                    <option value="DNK">Denmark</option>
                                    <option value="DJI">Djibouti</option>
                                    <option value="DMA">Dominica</option>
                                    <option value="DOM">Dominican Republic</option>
                                    <option value="TLS">East Timor</option>
                                    <option value="ECU">Ecuador</option>
                                    <option value="EGY">Egypt</option>
                                    <option value="SLV">El Salvador</option>
                                    <option value="ENGL">England</option>
                                    <option value="GNQ">Equatorial Guinea</option>
                                    <option value="ERI">Eritrea</option>
                                    <option value="EST">Estonia</option>
                                    <option value="ETH">Ethiopia</option>
                                    <option value="FLK">Falkland Islands (malvinas)</option>
                                    <option value="FRO">Faroe Islands</option>
                                    <option value="FJI">Fiji</option>
                                    <option value="FIN">Finland</option>
                                    <option value="FRA">France</option>
                                    <option value="FXX">France, Metropolitan</option>
                                    <option value="GUF">French Guiana</option>
                                    <option value="PYF">French Polynesia</option>
                                    <option value="ATF">French Southern Territories</option>
                                    <option value="GAB">Gabon</option>
                                    <option value="GMB">Gambia</option>
                                    <option value="GEO">Georgia</option>
                                    <option value="DEU">Germany</option>
                                    <option value="GHA">Ghana</option>
                                    <option value="GIB">Gibraltar</option>
                                    <option value="GRC">Greece</option>
                                    <option value="GRL">Greenland</option>
                                    <option value="GRD">Grenada</option>
                                    <option value="GLP">Guadeloupe</option>
                                    <option value="GUM">Guam</option>
                                    <option value="GTM">Guatemala</option>
                                    <option value="GGY">Guernsey</option>
                                    <option value="GIN">Guinea</option>
                                    <option value="GNB">Guinea-bissau</option>
                                    <option value="GUY">Guyana</option>
                                    <option value="HTI">Haiti</option>
                                    <option value="HMD">Heard And Mc Donald Islands</option>
                                    <option value="VAT">Holy See (vatican City State)</option>
                                    <option value="HND">Honduras</option>
                                    <option value="HKG">Hong Kong</option>
                                    <option value="HUN">Hungary</option>
                                    <option value="ISL">Iceland</option>
                                    <option value="IND">India</option>
                                    <option value="IDN">Indonesia</option>
                                    <option value="IRN">Iran (islamic Republic Of)</option>
                                    <option value="IRQ">Iraq</option>
                                    <option value="IRL">Ireland</option>
                                    <option value="IMN">Isle of Man</option>
                                    <option value="ISR">Israel</option>
                                    <option value="ITA">Italy</option>
                                    <option value="JAM">Jamaica</option>
                                    <option value="JPN">Japan</option>
                                    <option value="JEY">Jersey</option>
                                    <option value="JOR">Jordan</option>
                                    <option value="KAZ">Kazakhstan</option>
                                    <option value="KEN">Kenya</option>
                                    <option value="KIR">Kiribati</option>
                                    <option value="PRK">Korea, Democratic People's Republic Of</option>
                                    <option value="KOR">Korea, Republic Of</option>
                                    <option value="KWT">Kuwait</option>
                                    <option value="KGZ">Kyrgyzstan</option>
                                    <option value="LAO">Lao People's Democratic Republic</option>
                                    <option value="LVA">Latvia</option>
                                    <option value="LBN">Lebanon</option>
                                    <option value="LSO">Lesotho</option>
                                    <option value="LBR">Liberia</option>
                                    <option value="LBY">Libyan Arab Jamahiriya</option>
                                    <option value="LIE">Liechtenstein</option>
                                    <option value="LTU">Lithuania</option>
                                    <option value="LUX">Luxembourg</option>
                                    <option value="MAC">Macau</option>
                                    <option value="MKD">Macedonia, The Former Yugoslav Republic Of</option>
                                    <option value="MDG">Madagascar</option>
                                    <option value="MWI">Malawi</option>
                                    <option value="MYS">Malaysia</option>
                                    <option value="MDV">Maldives</option>
                                    <option value="MLI">Mali</option>
                                    <option value="MLT">Malta</option>
                                    <option value="MHL">Marshall Islands</option>
                                    <option value="MTQ">Martinique</option>
                                    <option value="MRT">Mauritania</option>
                                    <option value="MUS">Mauritius</option>
                                    <option value="MYT">Mayotte</option>
                                    <option value="MEX">Mexico</option>
                                    <option value="FSM">Micronesia, Federated States Of</option>
                                    <option value="MDA">Moldova, Republic Of</option>
                                    <option value="MCO">Monaco</option>
                                    <option value="MNG">Mongolia</option>
                                    <option value="MNE">Montenegro</option>
                                    <option value="MSR">Montserrat</option>
                                    <option value="MAR">Morocco</option>
                                    <option value="MOZ">Mozambique</option>
                                    <option value="MMR">Myanmar</option>
                                    <option value="NAM">Namibia</option>
                                    <option value="NRU">Nauru</option>
                                    <option value="NPL">Nepal</option>
                                    <option value="NLD">Netherlands</option>
                                    <option value="ANT">Netherlands Antilles</option>
                                    <option value="NCL">New Caledonia</option>
                                    <option value="NZL">New Zealand</option>
                                    <option value="NIC">Nicaragua</option>
                                    <option value="NER">Niger</option>
                                    <option value="NGA">Nigeria</option>
                                    <option value="NIRL">NIRL</option>
                                    <option value="NIU">Niue</option>
                                    <option value="NFK">Norfolk Island</option>
                                    <option value="MNP">Northern Mariana Islands</option>
                                    <option value="NOR">Norway</option>
                                    <option value="OMN">Oman</option>
                                    <option value="PAK">Pakistan</option>
                                    <option value="PLW">Palau</option>
                                    <option value="PSE">Palestinian Territory, Occupied</option>
                                    <option value="PAN">Panama</option>
                                    <option value="PNG">Papua New Guinea</option>
                                    <option value="PRY">Paraguay</option>
                                    <option value="PER">Peru</option>
                                    <option value="PHL">Philippines</option>
                                    <option value="PCN">Pitcairn</option>
                                    <option value="POL">Poland</option>
                                    <option value="PRT">Portugal</option>
                                    <option value="PRI">Puerto Rico</option>
                                    <option value="QAT">Qatar</option>
                                    <option value="REU">Reunion</option>
                                    <option value="ROU">Romania</option>
                                    <option value="RUS">Russian Federation</option>
                                    <option value="RWA">Rwanda</option>
                                    <option value="BLM">Saint Barthélemy</option>
                                    <option value="KNA">Saint Kitts And Nevis</option>
                                    <option value="LCA">Saint Lucia</option>
                                    <option value="MAF">Saint Martin (French part)</option>
                                    <option value="VCT">Saint Vincent And The Grenadines</option>
                                    <option value="WSM">Samoa</option>
                                    <option value="SMR">San Marino</option>
                                    <option value="STP">Sao Tome And Principe</option>
                                    <option value="SAU">Saudi Arabia</option>
                                    <option value="SCOT">Scotland</option>
                                    <option value="SEN">Senegal</option>
                                    <option value="SRB">Serbia</option>
                                    <option value="SYC">Seychelles</option>
                                    <option value="SLE">Sierra Leone</option>
                                    <option value="SGP">Singapore</option>
                                    <option value="SXM">Sint Maarten (Dutch part)</option>
                                    <option value="SVK">Slovakia (slovak Republic)</option>
                                    <option value="SVN">Slovenia</option>
                                    <option value="SLB">Solomon Islands</option>
                                    <option value="SOM">Somalia</option>
                                    <option value="ZAF">South Africa</option>
                                    <option value="SGS">South Georgia And The South Sandwich Islands</option>
                                    <option value="SSD">South Sudan</option>
                                    <option value="ESP">Spain</option>
                                    <option value="LKA">Sri Lanka</option>
                                    <option value="SHN">St. Helena</option>
                                    <option value="SPM">St. Pierre And Miquelon</option>
                                    <option value="SDN">Sudan</option>
                                    <option value="SUR">Suriname</option>
                                    <option value="SJM">Svalbard And Jan Mayen Islands</option>
                                    <option value="SWZ">Swaziland</option>
                                    <option value="SWE">Sweden</option>
                                    <option value="CHE">Switzerland</option>
                                    <option value="SYR">Syrian Arab Republic</option>
                                    <option value="TWN">Taiwan</option>
                                    <option value="TJK">Tajikistan</option>
                                    <option value="TZA">Tanzania, United Republic Of</option>
                                    <option value="THA">Thailand</option>
                                    <option value="TGO">Togo</option>
                                    <option value="TKL">Tokelau</option>
                                    <option value="TON">Tonga</option>
                                    <option value="TTO">Trinidad And Tobago</option>
                                    <option value="TUN">Tunisia</option>
                                    <option value="TUR">Turkey</option>
                                    <option value="TKM">Turkmenistan</option>
                                    <option value="TCA">Turks And Caicos Islands</option>
                                    <option value="TUV">Tuvalu</option>
                                    <option value="UGA">Uganda</option>
                                    <option value="UKR">Ukraine</option>
                                    <option value="ARE">United Arab Emirates</option>
                                    <option value="GBR">United Kingdom</option>
                                    <option value="USA" selected="selected">United States</option>
                                    <option value="UMI">United States Minor Outlying Islands</option>
                                    <option value="URY">Uruguay</option>
                                    <option value="UZB">Uzbekistan</option>
                                    <option value="VUT">Vanuatu</option>
                                    <option value="VEN">Venezuela</option>
                                    <option value="VNM">Viet Nam</option>
                                    <option value="VGB">Virgin Islands (british)</option>
                                    <option value="VIR">Virgin Islands (u.s.)</option>
                                    <option value="WALS">WALS</option>
                                    <option value="WLF">Wallis And Futuna Islands</option>
                                    <option value="ESH">Western Sahara</option>
                                    <option value="YEM">Yemen</option>
                                    <option value="YUG">Yugoslavia</option>
                                    <option value="ZMB">Zambia</option>
                                    <option value="ZWE">Zimbabwe</option>
                                </select>
                            </div>
                            <div class="col-lg-3">
                                <label class="mb-2" for="exampleInputEmail1">State*</label>
                                <select id="shipToStateProvinceGeoId" class="form-select form-select"
                                    name="shipToStateProvinceGeoId" aria-label=".form-select example"
                                    aria-invalid="false">
                                    <option value="AA">Armed Forces Americas</option>
                                    <option value="AE">Armed Forces Europe</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AP">Armed Forces Pacific</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DC">District of Columbia</option>
                                    <option value="DE">Delaware</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="GU">Guam</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="IA">Iowa</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MD">Maryland</option>
                                    <option value="ME">Maine</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MT">Montana</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NY">New York</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VA">Virginia</option>
                                    <option value="VI">Virgin Islands</option>
                                    <option value="VT">Vermont</option>
                                    <option value="WA">Washington</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="p-2">
                        <button class="btn btn-primary" type="button" data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseFive">
                            Continue to step 5
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion mb-4" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
            <div class="card-title px-4 pt-3 pb-1" id="panelsStayOpen-headingFive">
                <p><strong>Step 5: Submit Order</strong></p>
            </div>
            <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFive">
                <div class="accordion-body">
                    <div class="p-2">
                        <button class="btn btn-primary" type="button">Submit Order
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>