import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../services/services.service';

@Component({
  selector: 'app-create-contact',
  templateUrl: './create-contact.component.html',
  styleUrls: ['./create-contact.component.css']
})
export class CreateContactComponent implements OnInit {

  contactMechId:any;
  mechTypeId:any;
  partyId: any;
  show = false;
  countryCode: any;
  areaCode: any;
  contactNumber: any;
  solicitationArray: any[] = [];
  mechArray: any[] = [];
  contactForm!: FormGroup;
  mechId: any;
  constructor(readonly _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    private _ToastrService: ToastrService,
    public createContactService: ServicesService) {

  }

  ngOnInit(): void {
    this.partyId = localStorage.getItem('partyId');
    this.mechTypeId="TELECOM_NUMBER";
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.countryCode = params['countryCode'];
      this.areaCode = params['areaCode'];
      this.contactNumber = params['contactNumber'];
      this.contactMechId=params['contactMechId'];
      this.getContactPurpose();
    });
    if (this.countryCode && this.areaCode && this.contactNumber) {
      this.show = true;
    }
    this.contactForm = this._FormBuilder.group(
      {
        "allowSolicitation": [''],
        "areaCode": [''],
        "askForName": [''],
        "contactMechId": [''],
        "contactMechPurposeTypeId": [''],
        "contactMechTypeId": [''],
        "contactNumber": [''],
        "countryCode": [''],
        "extension": [''],
        "partyId": [this.partyId],
        "preContactMechTypeId": [this.mechTypeId]
      });

    this.solicitationArray = [
      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      }
    ];
  }
  goToProfile() {
    this._Router.navigate(["/profile/contact-information"]);
  }
  onSubmit() {
    //this.spinner.show();
    this.createContactService.createContact(this.contactForm.value).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success(res.data.successMessage);
        //this.spinner.hide();
        this._Router.navigate(['/profile/contact-information'])
      } else if (res.success == false) {
        this._ToastrService.error(res.data.message);
        //this.spinner.hide();
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
          //this.spinner.hide();
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
          //this.spinner.hide();
          this._ToastrService.error(err.error.message);
        }
      }
    })

  }
  getContactPurpose(){
      this.createContactService.getContactPurposes(this.partyId,this.mechTypeId).subscribe((res: any) => {
        this.mechId = res.data.purposeTypes;
        //this.spinner.hide();
        for (const value of this.mechId) {
          this.mechArray.push({
            label: value.description,
            value: value.contactMechPurposeTypeId
          })
        }
      })
  }
  onUpdate() {
    //this.spinner.show();
    this.contactForm.patchValue({
      contactMechId:this.contactMechId
    });
    this.createContactService.updateContact(this.contactForm.value).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Form Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/profile/contact-information'])
      }
    }, (err: { error: { debugMessage: string | undefined; }; }) => {
      //this.spinner.hide();
      this._ToastrService.error(err.error.debugMessage);
    })

  }
}
