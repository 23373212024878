import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from 'src/app/side-bar/side-bar.service';

@Component({
  selector: 'app-return-product',
  templateUrl: './return-product.component.html',
  styleUrls: ['./return-product.component.css']
})
export class ReturnProductComponent implements OnInit {
  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  orders:any[]=[];
  empty:string="No Record Found!";
  id: any;
  Alldata: any;

  constructor(readonly router:Router,private sidebarService: SideBarService,
    private toastr: ToastrService) { }
    

  ngOnInit(): void {
  }

  getReturnItem() {
    this.sidebarService.getReturnItem(this.id).subscribe((res: any) => {
      this.Alldata=res.data;
    })
  }

}
