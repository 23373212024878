import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-check-out-option1',
  templateUrl: './check-out-option1.component.html',
  styleUrls: ['./check-out-option1.component.css']
})
export class CheckOutOption1Component implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  locale: any;
  localeArray: any[] = [];
  address: any;
  partyTaxIdentification: any;
  id: any;
  partyTaxIdentificationData: any;
  groupNameValue: any;
  contactMechId: any;
  selecttaxAuthPartyGeoIds:any;
  partyTaxIdentificationArray: any[]=[];
  showCart: any;
  specialOffers: any;
  summary: any;
  catalogId: string | null;
  compareProductList: any;
  partyTaxAuthInfoAndDetailList: any;
  shippingMethodList: any;
  constructor(readonly router:Router,
    private sidebarService: SideBarService,
    private toastr: ToastrService) {
      this.catalogId = localStorage.getItem("catalog");
     }

  ngOnInit(): void {
    this.getquickCheckout();
    this.getLocale();
    this.checkoutOptions();
    this.getCompareProducts();
    this.getshowCart();
  }
  getquickCheckout() {
    this.sidebarService.getquickCheckout().subscribe((res: any) => {
      this.shippingMethodList=res.data.shippingMethodList;
     //this.quotesList = res.data;
     this.address=res.data.address;
    })
  }
  getCompareProducts() {
    this.sidebarService.getCompareProducts(this.catalogId).subscribe((res: any) => {
      this.compareProductList = res.data.productList;

    })
  } 
  removeAddToCompare(id:any): void {

    this.sidebarService.removeAddToCompare(id)
      .then(data => {
        if (data.success) {
          this.toastr.success(data.data.successMessage);
        this.getCompareProducts();
        }
      });
  
  }
  
  clearCompare() {
     
  
    this.sidebarService.clearCompare().subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.getCompareProducts();
  
      }
    }, (err) => {
   
      this.toastr.error(err.message);
  
    });
   
  }
  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
      this.showCart = res.data.productsList;
      this.specialOffers = res.data.specialOffers;
      this.summary = res.data.summary;

    })
  }
  checkoutOptions() {
    this.id="";
    let req={

    }
    this.sidebarService.checkoutOptions(this.id,req).subscribe((res: any) => {
      this.address = res.data.address;
      this.partyTaxAuthInfoAndDetailList=res.data.partyTaxIdentification.partyTaxAuthInfoAndDetailList
      this.partyTaxIdentification = res.data.partyTaxIdentification.taxAuthPartyGeoIds;
      for (const value of this.partyTaxIdentification) {
        this.partyTaxIdentificationArray.push({
          label: value.geoName,
          value: value.partyId + '::' +value.taxAuthGeoId
        })
      }
      this.partyTaxIdentificationData = res.data.partyTaxIdentification;
      

   

    })
  }
  public onSaveUsernameChanged(value:any){
    if (value == true) {
      this.groupNameValue = 'Y'
    }
    else {
      this.groupNameValue = 'N'
    }
}
  goToViewAllPromotions(){
    this.router.navigate(["/view-all-promotions"]);
  }
  goToShowPromotionDetails(){
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewCart(){
    this.router.navigate(["/view-cart"]);
  }
  goToQuickCheckOut(){
    this.router.navigate(["/quick-check-out"]);
  }
  goToOnePageCheckOut(){
    this.router.navigate(["/one-page-check-out"]);
  }
  

  goToCheckOutOption1(){
    this.router.navigate(["/check-out-option1"]);
  }
  goToCheckOut(){
    this.router.navigate(["/check-out-option1"]);
  }
  goToCheckOutOption2(product:any){
    this.router.navigate(["/check-out-option2"],{ queryParams: { selecttaxAuthPartyGeoIds:this.selecttaxAuthPartyGeoIds,partyId:product.partyTaxId,exempt: this.groupNameValue,mechId:this.contactMechId}});
  }
  addContactMech(id:any){
    this.contactMechId=id;
  }

  toSplit()
  {
    this.router.navigate(['/split-ship']);
  }
  
  getLocale() {
    //this.spinner.show();
    this.sidebarService.getLocale().subscribe((res: any) => {
      this.locale = res.data;
      //this.spinner.hide();
      for (const value of this.locale) {
        this.localeArray.push({
          label: value.value,
          value: value.key
        })
      }
    })

  }

  toAddress(valueAddes: any) {
      this.router.navigate(["/create-address"], {
        queryParams: {
          toName:valueAddes.shippingAddress.toName,
          attnName:valueAddes.shippingAddress.attnName,
          address1: valueAddes.shippingAddress.address1,
          address2:valueAddes.shippingAddress.address2,
          city:valueAddes.city,
          stateProvinceGeoId: valueAddes.shippingAddress.stateProvinceGeoId,
          
        }
      })
  
}

toNewAddress()
{
  this.router.navigate(["/create-address"]);
}
}
