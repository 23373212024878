import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RandomService } from '../random.service';
import { SideBarService } from '../side-bar/side-bar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MyAccountsService } from '../my-account/my-accounts.service';

@Component({
  selector: 'app-view-quotes',
  templateUrl: './view-quotes.component.html',
  styleUrls: ['./view-quotes.component.css']
})
export class ViewQuotesComponent implements OnInit {
  quotes: any;
  quotesData: any;
  quotesByQuoteIdList: any;
  quoteId: any;
  quotesList: any;
  quoteData: any;

  constructor(readonly router: Router, private toastr: ToastrService,
    private sidebarService: SideBarService,private random: RandomService,
    private activatedRoute: ActivatedRoute, 
    readonly spinner: NgxSpinnerService,
    private myAccountsService : MyAccountsService,
   private form: FormBuilder) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.quoteId = params["quoteId"];
     
    });
   // this.getquoteById();
    this.ListQuotesByQuoteId();
  }
  ListQuotesByQuoteId() {
    this.spinner.show;
    this.myAccountsService.ListQuotesByQuoteId(this.quoteId).subscribe((res: any) => {
      this.quotesList = res.data[0].ListQuotes;
      this.quoteData = res.data[0];
    })
    this.spinner.hide;

  }
  // getquoteById() {
  //   //this.spinner.show();
  //   this.sidebarService.getquoteById( this.quotes ).subscribe((res: any) => {
  //    this.quotesData = res.data;
    
  //   })

  // }
  

}
