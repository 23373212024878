import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-print-statement',
  templateUrl: './print-statement.component.html',
  styleUrls: ['./print-statement.component.css']
})
export class PrintStatementComponent implements OnInit {
  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  orders:any[]=[];
  empty:string="No Record Found!";

  constructor(readonly router:Router) { }

  ngOnInit(): void {
  }

}
