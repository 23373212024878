<div class="container-fluid p-0 pt-3">
  <div class="card" style="border: none!important;">
    <div class="card-title px-1 pt-1 pb-0" style="background-color: white!important;border:none!important">
      <div class="float-start">
        <h6>Payment Method Information</h6>
      </div>
      <div class="float-end pe-3">
        <span class="span-link" (click)="goToAddCreditCard()">Create New Credit Card</span><span> | </span>
        <span class="span-link" (click)="goToAddGiftCard()">Create New Gift Card</span><span> | </span>
        <span class="span-link" (click)="goToAddEftAccount()">Create New EFT Account</span>
      </div>
    </div>
    <div class="card-body pt-0 ps-1">
      <div class="row"></div>
      <div class="row">
        <div class="col-lg-12 table-scroll">
          <p-table [value]="paymentMethods" [paginator]="true" [rows]="rows" scrollWidth="100%"
            [rowsPerPageOptions]="[10,25,50, 100]" [totalRecords]="total" [scrollable]="true"
            [style]="{'width':'100%','min-width':'600px'}">
            <ng-template pTemplate="header">
              <tr>
                <th>Card Type</th>
                <th>Card Details</th>
                <th>Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
              <tr>
                <td>{{product.type}}</td>
                <td>
                  <ng-container
                    *ngIf="product.paymentMethodTypeId.includes('ACCOUNT') || product.paymentMethodTypeId.includes('CHECK')">
                    <span>{{product.nameOnAccount}},{{product.bankName}}({{product.branchCode}}),A/C
                      {{product.accountNumber}}, (Type:
                      {{product.accountType ? product.accountType :''}}), (Updated At:
                      {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                  </ng-container>
                  <ng-container *ngIf="product.paymentMethodTypeId==='BILLING'">
                    <span>({{product.billingAccountId}}),(Account
                      Limit:${{product.accountLimit}}),(Available Balance:
                      ${{product.accountBalance}}), (Updated At:
                      {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                  </ng-container>
                  <ng-container *ngIf="product.paymentMethodTypeId.includes('CARD')">
                    <span>{{product.cardNumber ? product.cardNumber
                      :''}}</span>, Expire :
                    <span>{{product.expireDate? product.expireDate:''}}</span>,
                    <span> {{product.companyNameOnCard? product.companyNameOnCard:''}}</span>,
                    <span> ,( {{product.firstNameOnCard ? product.firstNameOnCard:''}}
                      {{product.middleNameOnCard ? product.middleNameOnCard:''}}
                      {{product.lastNameOnCard ? product.lastNameOnCard : ''}}),(
                      {{product.cardType}}), (Updated At:
                      {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                  </ng-container>
                </td>
                <td>
                  <a class="btn btn-outline-secondary me-1" value="Update" (click)="updatePaymentMethod(product)">Update</a>
                  <a class="btn btn-outline-secondary" value="Delete"
                    (click)="removePaymentMethod(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)">Delete</a>
                    <a class="btn btn-outline-secondary me-1"  >Set Default</a>
                  </td>
              </tr>
            </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td>
                      <span class="text-center">
                          <h6 class="ml-2">No Record Found
                          </h6>
                      </span>
                  </td>
              </tr>
          </ng-template>
          </p-table>
          <!--  -->
        </div>
      </div>
    </div>
  </div>
</div>