import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-check-out-option3',
  templateUrl: './check-out-option3.component.html',
  styleUrls: ['./check-out-option3.component.css']
})
export class CheckOutOption3Component implements OnInit {

  total = 0;
  pageNo = 1;
  rows = 50;
  pageSize = 100;
  rowNumber = 0;
  search = "";
  messages: any[] = [];
  shipping_instructions: any;
  order_additional_emails: any;
  gift_message: any;
  splitValueData: any;
  correspondingPoId: any;
  gift_messageData: any;
  radioValueGift: any;
  compareProductList: any;
  catalogId: string | null;
  showCart: any;
  specialOffers: any;
  summary: any;
  shipmentId: any;
  id: any;
  paymentOptions: any;
  paymentMethodMapList: any;
  groupNameValue: any;
  arrayPaymet: any[]=[];
  giftCard: any;
  eftAccount: any;
  amountValue: any;
  checkOutPaymentId: any;
  sCode: any;
  constructor(readonly router: Router, private activeRoute: ActivatedRoute
    , private sidebarService: SideBarService, private toastr: ToastrService) {
    this.catalogId = localStorage.getItem("catalog");
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
      this.shipping_instructions = params["shipping_instructions"];
      this.order_additional_emails = params["order_additional_emails"];
      this.gift_message = params["gift_message"];
      this.splitValueData = params["splitValue"];
      this.correspondingPoId = params["correspondingPoId"];
      this.radioValueGift = params["radioValueGift"];
      this.shipmentId = params["shipmentId"]
    });
    this.getshowCart();
    this.getCompareProducts();
    this.checkoutOptions();
  }
  public onSaveUsernameChanged(value:any){
    if (value == true) {
      this.giftCard = 'Y'
    }
    else {
      this.giftCard = 'N'
    }
  }
  checkoutOptions() {
    this.id = "shippingoptions";
    let req = {

      "shipping_method": this.shipmentId,
      "may_split": this.splitValueData,
      "shipping_instructions":  this.shipping_instructions,
      "correspondingPoId":  this.correspondingPoId,
      "is_gift": this.radioValueGift,
      "gift_message":  this.gift_message,
      "order_additional_emails": this.order_additional_emails,
      "internalCode": "",
      "shipBeforeDate": "",
      "shipAfterDate": ""
    }
    this.sidebarService.checkoutOptions(this.id, req).subscribe((res: any) => {
      this.paymentOptions = res.data.paymentOptions;
      this.paymentMethodMapList=res.data.paymentMethodMapList

      //this.otherdata = res.data;



    })
  }
  radioPaymentCheck(type:any,id:any,scode:any){
  
    if(type=="EFT_ACCOUNT"){
 
  /*   let req={
      checkOutPaymentId:id,
    
    }
    this.arrayPaymet.push(req); */
  }
  else{
    let amount ="amount_" + id;
    
    let req={
      checkOutPaymentId:id,
      [amount]:scode
    }
    this.arrayPaymet.push(req);
  }
 
  }
  radioPayment(type:any,id:any,scode:any){
     this.amountValue ="amount_" + id;
      this.checkOutPaymentId=id,
      this.sCode=scode
  }
 
  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
      this.showCart = res.data.productsList;
      this.specialOffers = res.data.specialOffers;
      this.summary = res.data.summary;

    })
  }
  removeAddToCompare(id: any): void {

    this.sidebarService.removeAddToCompare(id)
      .then(data => {
        if (data.success) {
          this.toastr.success(data.data.successMessage);
          this.getCompareProducts();
        }
      });

  }
  getCompareProducts() {
    this.sidebarService.getCompareProducts(this.catalogId).subscribe((res: any) => {
      this.compareProductList = res.data.productList;

    })
  }

  clearCompare() {


    this.sidebarService.clearCompare().subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.getCompareProducts();

      }
    }, (err) => {

      this.toastr.error(err.message);

    });

  }
  goToViewAllPromotions() {
    this.router.navigate(["/view-all-promotions"]);
  }
  goToShowPromotionDetails() {
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewCart() {
    this.router.navigate(["/view-cart"]);
  }
  goToQuickCheckOut() {
    this.router.navigate(["/quick-check-out"]);
  }
  goToOnePageCheckOut() {
    this.router.navigate(["/one-page-check-out"]);
  }

  goToCheckOutOption1() {
    this.router.navigate(["/check-out-option1"]);
  }
  goToCheckOut() {
    this.router.navigate(["/check-out-option1"]);
  }
  goToFinalCheckOutReview(product:any) {
  
     // this.arrayPaymet.push(this.eftAccount);

    this.router.navigate(["/final-check-out-review"],{ queryParams: {giftCard:this.giftCard, giftCardAmount:product.giftCardAmount,giftCardNumber:product.giftCardNumber,amountValue:this.amountValue,checkout:this.checkOutPaymentId,sCode:this.sCode}});
  }
  goToAddCreditCard() {
    this.router.navigate(["/add-credit-card"]);
  }
  goToAddEftAccount() {
    this.router.navigate(["/add-eft-account"]);
  }
}
