<div class="container-fluid">
    <div class="row">
        <div class="col-lg-3 order-lg-2 order-2 px-4">
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Choose Catalog</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                            <select class="form-select form-select my-2" aria-label=".form-select example" (change)="goToFeaturedProducts()">
                                <option selected>HH Catalogue</option>
                                <option value="1">HH Catalogue</option>
                                <option value="2">Demo Catalogue</option>
                                <option value="3">Google Catalogue</option>
                                <option value="4">eBay Catalogue</option>
                              </select>
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Search Catalog</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <label class="mb-2" for="exampleInputEmail1">Catalog</label>
                          <input type="email" class="form-control mb-2" id="username" aria-describedby="emailHelp" placeholder="">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="searchCatalogueRadio" id="searchCatalogueRadio" checked>
                            <label class="form-check-label" for="searchCatalogueRadio">Any</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="searchCatalogueRadio" id="searchCatalogueRadio">
                            <label class="form-check-label" for="searchCatalogueRadio">All</label>
                          </div>
                        </div>
                        <div class="form-group p-1">
                            <input class="btn btn-outline-secondary" type="submit" value="find">
                        </div>
                        <div class="form-group p-1">
                            <input class="btn btn-outline-secondary" type="submit" value="Advacned Search">
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Categories</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Quick Recorder</p>
                </div>
                <div class="p-2">
                    <span class="span-link">Tiny Gizmo</span>
                    <ul class="list-unstyled">
                        <li>GZ-1000 <span>$12.00</span></li>
                        <li><span class="span-link">Add To Cart</span></li>
                    </ul>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Sign Up For Contact List</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <p>Sign Up For Contact List LogIn</p>
                          <p><span class="span-link">Login </span>THE ADMINISTRATOR</p>
                        </div>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Mini Poll</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
                          <select class="form-select form-select my-2" aria-label=".form-select example">
                            <option selected>Right Side</option>
                            <option value="1">Right Side</option>
                            <option value="2">Left Side/option>
                          </select>
                          <p>*</p>
                          <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
                          <select class="form-select form-select my-2" aria-label=".form-select example">
                            <option selected>Right Side</option>
                            <option value="1">Right Side</option>
                            <option value="2">Left Side</option>
                          </select>
                          <p>*</p>
                          <input class="btn btn-outline-secondary" type="submit" value="Vote!">
                        </div>
                    </form>
                </div>
            </div>
           <!--  <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Forums</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Ask the Experts</span><br>
                        <span class="span-link">Ask the Experts</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Widgets</span><br>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Content</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Store Policies 2</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Cookie Policy</span><br>
                        <span class="span-link">Policies</span><br>
                        <span class="span-link">Store Policies</span><br>
                        <span class="span-link">Store Policies 2</span><br>
                        <span class="span-link">Widgets</span><br>
                        <span class="span-link">Policies</span><br>
                        <span class="span-link">Gizmos</span><br>
                        <span class="span-link">Cookie Policy</span><br>
                        <span class="span-link">Store Policies</span><br>
                      </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Browse Blogs</p>
                </div>
                <div class="p-2 pb-4">
                    <form>
                        <span class="span-link">Big AI</span><br>
                        <span class="span-link">Big AI</span><br>
                        <span class="span-link">Mad Max</span><br>
                        <span class="span-link">Mad Max</span><br>
                        <span class="span-link">xczx</span><br>
                      </form>
                </div>
            </div> -->
        </div>
        <div class="col-lg-6 order-lg-2 order-1 px-4">
          <div class="card mx-auto mt-4" style="width:100%!important">
            <div class="card-title">
              <h6 class="text-left my-3 mx-4"><strong>Main Quick Add</strong></h6>
            </div>
            <div class="card-body">
              <div class="p-1">
                <select name="category_id" class="form-select">
                  <option value="CATALOG1_QUICKADD1">Main Quick Add</option>
                  <option value="CATALOG1_QUICKADD1">--</option>
                  <option value="CATALOG1_QUICKADD1">Main Quick Add</option>
                  <option value="CATALOG1_QUICKADD2">Widget Quick Add</option>
                </select>
              </div>
              <div class="p-1">
                <span class="span-link">Choose QuickAdd Category</span>
              </div>
              <div class="p-1">
                <p>For quick orders, you have found the right place!</p>
                <a class="btn btn-outline-secondary ml-3">Add All to Cart</a>
                <form [formGroup]="addAllToCartForm">
                    <div class="card mx-auto mt-4" style="width:100%!important">
                      <div class="card-title">
                        <h6 class="text-left my-3 mx-4"><strong>GZ-2644</strong></h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                        <div class="col-sm-3">
                            <a>Round Gizmo</a>
                        </div>
                        <div class="col-sm-3">
                              List Price: $48.00
                        </div>
                        <div class="col-sm-3">
                            <div class="salePrice">
                              <b>$38.40</b>
                            </div>
                        </div>
                        <div class="col-sm-3">
                              <input type="text" size="5" class="form-control form-control-sm" name="quantity_GZ-2644" value="" formControlName="quantity_GZ_2644">
                        </div>
                        </div>
                      </div>
                    </div>
                    <div class="card mx-auto mt-4" style="width:100%!important">
                      <div class="card-title">
                        <h6 class="text-left my-3 mx-4"><strong>GZ-8544</strong></h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                        <div class="col-sm-3">
                            <a>Big Gizmo</a>
                        </div>
                        <div class="col-sm-3">
                              List Price: $270.00
                        </div>
                        <div class="col-sm-3">
                            <div class="salePrice">
                              <b>$269.99</b>
                            </div>
                        </div>
                        <div class="col-sm-3">
                              <input type="text" size="5" class="form-control form-control-sm" name="quantity_GZ-8544" value="" formControlName="quantity_GZ_8544">
                        </div>
                        </div>
                      </div>
                    </div>
                    <div class="card mx-auto mt-4" style="width:100%!important">
                      <div class="card-title">
                        <h6 class="text-left my-3 mx-4"><strong>WG-1111</strong></h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                        <div class="col-sm-3">
                            <a>Micro Chrome Widget</a>
                        </div>
                        <div class="col-sm-3">
                              List Price: $60.00
                        </div>
                        <div class="col-sm-3">
                            <div class="salePrice">
                              <b>$59.99</b>
                            </div>
                        </div>
                        <div class="col-sm-3">
                              <input type="text" size="5" class="form-control form-control-sm" name="quantity_WG-1111" value="" formControlName="quantity_WG_1111">
                        </div>
                        </div>
                      </div>
                    </div>
                    <div class="card mx-auto mt-4" style="width:100%!important">
                      <div class="card-title">
                        <h6 class="text-left my-3 mx-4"><strong>WG-5569</strong></h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                        <div class="col-sm-3">
                            <a>Tiny Chrome Widget</a>
                        </div>
                        <div class="col-sm-3">
                              List Price: $60.00
                        </div>
                        <div class="col-sm-3">
                            <div class="salePrice">
                              <b>$48.00</b>
                            </div>
                        </div>
                        <div class="col-sm-3">
                              <input type="text" size="5" class="form-control form-control-sm" name="quantity_WG-5569" value="" formControlName="quantity_WG_5569">
                        </div>
                        </div>
                      </div>
                    </div>
                    <div class="card mx-auto mt-4" style="width:100%!important">
                      <div class="card-title">
                        <h6 class="text-left my-3 mx-4"><strong>WG-9943</strong></h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                        <div class="col-sm-3">
                            <a>Giant Widget with variant explosion</a>
                        </div>
                        <div class="col-sm-3">
                              List Price: $550.00
                        </div>
                        <div class="col-sm-3">
                            <div class="salePrice">
                              <b>$440.00</b>
                            </div>
                        </div>
                        <div class="col-sm-3">
                              <!-- <input type="text" size="5" class="form-control form-control-sm" name="quantity_GZ-2644" value=""> -->
                              <span><a>Choose Variations...</a></span>
                        </div>
                        </div>
                      </div>
                    </div>
                </form>
                <!-- <div class="card mx-auto mt-4" style="width:100%!important">
                  <div class="card-title">
                    <h6 class="text-left my-3 mx-4"><strong>GZ-2644</strong></h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                    <div class="col-sm-3">
                        <a>Round Gizmo</a>
                    </div>
                    <div class="col-sm-3">
                          List Price: $48.00
                    </div>
                    <div class="col-sm-3">
                        <div class="salePrice">
                          <b>$38.40</b>
                        </div>
                    </div>
                    <div class="col-sm-3">
                          <input type="text" size="5" class="form-control form-control-sm" name="quantity_GZ-2644" value="">
                    </div>
                    </div>
                  </div>
                </div>
                <div class="card mx-auto mt-4" style="width:100%!important">
                  <div class="card-title">
                    <h6 class="text-left my-3 mx-4"><strong>GZ-2644</strong></h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                    <div class="col-sm-3">
                        <a>Round Gizmo</a>
                    </div>
                    <div class="col-sm-3">
                          List Price: $48.00
                    </div>
                    <div class="col-sm-3">
                        <div class="salePrice">
                          <b>$38.40</b>
                        </div>
                    </div>
                    <div class="col-sm-3">
                          <input type="text" size="5" class="form-control form-control-sm" name="quantity_GZ-2644" value="">
                    </div>
                    </div>
                  </div>
                </div>
                <div class="card mx-auto mt-4" style="width:100%!important">
                  <div class="card-title">
                    <h6 class="text-left my-3 mx-4"><strong>GZ-2644</strong></h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                    <div class="col-sm-3">
                        <a>Round Gizmo</a>
                    </div>
                    <div class="col-sm-3">
                          List Price: $48.00
                    </div>
                    <div class="col-sm-3">
                        <div class="salePrice">
                          <b>$38.40</b>
                        </div>
                    </div>
                    <div class="col-sm-3">
                          <input type="text" size="5" class="form-control form-control-sm" name="quantity_GZ-2644" value="">
                    </div>
                    </div>
                  </div>
                </div>
                <div class="card mx-auto mt-4" style="width:100%!important">
                  <div class="card-title">
                    <h6 class="text-left my-3 mx-4"><strong>GZ-2644</strong></h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                    <div class="col-sm-3">
                        <a>Round Gizmo</a>
                    </div>
                    <div class="col-sm-3">
                          List Price: $48.00
                    </div>
                    <div class="col-sm-3">
                        <div class="salePrice">
                          <b>$38.40</b>
                        </div>
                    </div>
                    <div class="col-sm-3">
                          <input type="text" size="5" class="form-control form-control-sm" name="quantity_GZ-2644" value="">
                    </div>
                    </div>
                  </div>
                </div>
                <div class="card mx-auto mt-4" style="width:100%!important">
                  <div class="card-title">
                    <h6 class="text-left my-3 mx-4"><strong>GZ-2644</strong></h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                    <div class="col-sm-3">
                        <a>Round Gizmo</a>
                    </div>
                    <div class="col-sm-3">
                          List Price: $48.00
                    </div>
                    <div class="col-sm-3">
                        <div class="salePrice">
                          <b>$38.40</b>
                        </div>
                    </div>
                    <div class="col-sm-3">
                          <input type="text" size="5" class="form-control form-control-sm" name="quantity_GZ-2644" value="">
                    </div>
                    </div>
                  </div>
                </div> -->
                <div class="mt-3">
                  <a class="btn btn-outline-secondary">Add All to Cart</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 order-lg-2 order-3 px-4">
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Language</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                            <select class="form-select form-select my-2" aria-label=".form-select example">
                                <option lang="sq" dir="ltr" value="sq">
                                  shqip
                                </option>
                                <option lang="sq-AL" dir="ltr" value="sq_AL">
                                  shqip (Shqipëria)
                                </option>
                                <option lang="ar" dir="rtl" value="ar">
                                  العربية
                                </option>
                                <option lang="ar-DZ" dir="rtl" value="ar_DZ">
                                  العربية (الجزائر)
                                </option>
                                <option lang="ar-BH" dir="rtl" value="ar_BH">
                                  العربية (البحرين)
                                </option>
                                <option lang="ar-EG" dir="rtl" value="ar_EG">
                                  العربية (مصر)
                                </option>
                                <option lang="ar-IQ" dir="rtl" value="ar_IQ">
                                  العربية (العراق)
                                </option>
                                <option lang="ar-JO" dir="rtl" value="ar_JO">
                                  العربية (الأردن)
                                </option>
                                <option lang="ar-KW" dir="rtl" value="ar_KW">
                                  العربية (الكويت)
                                </option>
                                <option lang="ar-LB" dir="rtl" value="ar_LB">
                                  العربية (لبنان)
                                </option>
                                <option lang="ar-LY" dir="rtl" value="ar_LY">
                                  العربية (ليبيا)
                                </option>
                                <option lang="ar-MA" dir="rtl" value="ar_MA">
                                  العربية (المغرب)
                                </option>
                                <option lang="ar-OM" dir="rtl" value="ar_OM">
                                  العربية (سلطنة عمان)
                                </option>
                                <option lang="ar-QA" dir="rtl" value="ar_QA">
                                  العربية (قطر)
                                </option>
                                <option lang="ar-SA" dir="rtl" value="ar_SA">
                                  العربية (السعودية)
                                </option>
                                <option lang="ar-SD" dir="rtl" value="ar_SD">
                                  العربية (السودان)
                                </option>
                                <option lang="ar-SY" dir="rtl" value="ar_SY">
                                  العربية (سوريا)
                                </option>
                                <option lang="ar-TN" dir="rtl" value="ar_TN">
                                  العربية (تونس)
                                </option>
                                <option lang="ar-AE" dir="rtl" value="ar_AE">
                                  العربية (الإمارات)
                                </option>
                                <option lang="ar-YE" dir="rtl" value="ar_YE">
                                  العربية (اليمن)
                                </option>
                                <option lang="be" dir="ltr" value="be">
                                  беларускі
                                </option>
                                <option lang="be-BY" dir="ltr" value="be_BY">
                                  беларускі (Беларусь)
                                </option>
                                <option lang="bg" dir="ltr" value="bg">
                                  български
                                </option>
                                <option lang="bg-BG" dir="ltr" value="bg_BG">
                                  български (България)
                                </option>
                                <option lang="ca" dir="ltr" value="ca">
                                  català
                                </option>
                                <option lang="ca-ES" dir="ltr" value="ca_ES">
                                  català (Espanya)
                                </option>
                                <option lang="zh" dir="ltr" value="zh">
                                  中文
                                </option>
                                <option lang="zh-CN" dir="ltr" value="zh_CN">
                                  中文 (中国)
                                </option>
                                <option lang="zh-HK" dir="ltr" value="zh_HK">
                                  中文 (香港)
                                </option>
                                <option lang="zh-SG" dir="ltr" value="zh_SG">
                                  中文 (新加坡)
                                </option>
                                <option lang="zh-TW" dir="ltr" value="zh_TW">
                                  中文 (台灣)
                                </option>
                                <option lang="hr" dir="ltr" value="hr">
                                  hrvatski
                                </option>
                                <option lang="hr-HR" dir="ltr" value="hr_HR">
                                  hrvatski (Hrvatska)
                                </option>
                                <option lang="cs" dir="ltr" value="cs">
                                  čeština
                                </option>
                                <option lang="cs-CZ" dir="ltr" value="cs_CZ">
                                  čeština (Česká republika)
                                </option>
                                <option lang="da" dir="ltr" value="da">
                                  Dansk
                                </option>
                                <option lang="da-DK" dir="ltr" value="da_DK">
                                  Dansk (Danmark)
                                </option>
                                <option lang="nl" dir="ltr" value="nl">
                                  Nederlands
                                </option>
                                <option lang="nl-BE" dir="ltr" value="nl_BE">
                                  Nederlands (België)
                                </option>
                                <option lang="nl-NL" dir="ltr" value="nl_NL">
                                  Nederlands (Nederland)
                                </option>
                                <option lang="en" dir="ltr" value="en">
                                  English
                                </option>
                                <option lang="en-AU" dir="ltr" value="en_AU">
                                  English (Australia)
                                </option>
                                <option lang="en-CA" dir="ltr" value="en_CA">
                                  English (Canada)
                                </option>
                                <option lang="en-IN" dir="ltr" value="en_IN">
                                  English (India)
                                </option>
                                <option lang="en-IE" dir="ltr" value="en_IE">
                                  English (Ireland)
                                </option>
                                <option lang="en-MT" dir="ltr" value="en_MT">
                                  English (Malta)
                                </option>
                                <option lang="en-NZ" dir="ltr" value="en_NZ">
                                  English (New Zealand)
                                </option>
                                <option lang="en-PH" dir="ltr" value="en_PH">
                                  English (Philippines)
                                </option>
                                <option lang="en-SG" dir="ltr" value="en_SG">
                                  English (Singapore)
                                </option>
                                <option lang="en-ZA" dir="ltr" value="en_ZA">
                                  English (South Africa)
                                </option>
                                <option lang="en-GB" dir="ltr" value="en_GB">
                                  English (United Kingdom)
                                </option>
                                <option lang="en-US" dir="ltr" value="en_US" selected="selected">
                                  English (United States)
                                </option>
                                <option lang="et" dir="ltr" value="et">
                                  Eesti
                                </option>
                                <option lang="et-EE" dir="ltr" value="et_EE">
                                  Eesti (Eesti)
                                </option>
                                <option lang="fi" dir="ltr" value="fi">
                                  suomi
                                </option>
                                <option lang="fi-FI" dir="ltr" value="fi_FI">
                                  suomi (Suomi)
                                </option>
                                <option lang="fr" dir="ltr" value="fr">
                                  français
                                </option>
                                <option lang="fr-BE" dir="ltr" value="fr_BE">
                                  français (Belgique)
                                </option>
                                <option lang="fr-CA" dir="ltr" value="fr_CA">
                                  français (Canada)
                                </option>
                                <option lang="fr-FR" dir="ltr" value="fr_FR">
                                  français (France)
                                </option>
                                <option lang="fr-LU" dir="ltr" value="fr_LU">
                                  français (Luxembourg)
                                </option>
                                <option lang="fr-CH" dir="ltr" value="fr_CH">
                                  français (Suisse)
                                </option>
                                <option lang="de" dir="ltr" value="de">
                                  Deutsch
                                </option>
                                <option lang="de-AT" dir="ltr" value="de_AT">
                                  Deutsch (Österreich)
                                </option>
                                <option lang="de-DE" dir="ltr" value="de_DE">
                                  Deutsch (Deutschland)
                                </option>
                                <option lang="de-GR" dir="ltr" value="de_GR">
                                  Deutsch (Griechenland)
                                </option>
                                <option lang="de-LU" dir="ltr" value="de_LU">
                                  Deutsch (Luxemburg)
                                </option>
                                <option lang="de-CH" dir="ltr" value="de_CH">
                                  Deutsch (Schweiz)
                                </option>
                                <option lang="el" dir="ltr" value="el">
                                  Ελληνικά
                                </option>
                                <option lang="el-CY" dir="ltr" value="el_CY">
                                  Ελληνικά (Κύπρος)
                                </option>
                                <option lang="el-GR" dir="ltr" value="el_GR">
                                  Ελληνικά (Ελλάδα)
                                </option>
                                <option lang="iw" dir="rtl" value="iw">
                                  עברית
                                </option>
                                <option lang="iw-IL" dir="rtl" value="iw_IL">
                                  עברית (ישראל)
                                </option>
                                <option lang="hi" dir="ltr" value="hi">
                                  हिंदी
                                </option>
                                <option lang="hi-IN" dir="ltr" value="hi_IN">
                                  हिंदी (भारत)
                                </option>
                                <option lang="hu" dir="ltr" value="hu">
                                  magyar
                                </option>
                                <option lang="hu-HU" dir="ltr" value="hu_HU">
                                  magyar (Magyarország)
                                </option>
                                <option lang="is" dir="ltr" value="is">
                                  íslenska
                                </option>
                                <option lang="is-IS" dir="ltr" value="is_IS">
                                  íslenska (Ísland)
                                </option>
                                <option lang="in" dir="ltr" value="in">
                                  Bahasa Indonesia
                                </option>
                                <option lang="in-ID" dir="ltr" value="in_ID">
                                  Bahasa Indonesia (Indonesia)
                                </option>
                                <option lang="ga" dir="ltr" value="ga">
                                  Gaeilge
                                </option>
                                <option lang="ga-IE" dir="ltr" value="ga_IE">
                                  Gaeilge (Éire)
                                </option>
                                <option lang="it" dir="ltr" value="it">
                                  italiano
                                </option>
                                <option lang="it-IT" dir="ltr" value="it_IT">
                                  italiano (Italia)
                                </option>
                                <option lang="it-CH" dir="ltr" value="it_CH">
                                  italiano (Svizzera)
                                </option>
                                <option lang="ja" dir="ltr" value="ja">
                                  日本語
                                </option>
                                <option lang="ja-JP" dir="ltr" value="ja_JP">
                                  日本語 (日本)
                                </option>
                                <option lang="ja-JP-JP-#u-ca-japanese" dir="ltr" value="ja_JP_JP_#u-ca-japanese">
                                  日本語 (日本,JP)
                                </option>
                                <option lang="ko" dir="ltr" value="ko">
                                  한국어
                                </option>
                                <option lang="ko-KR" dir="ltr" value="ko_KR">
                                  한국어 (대한민국)
                                </option>
                                <option lang="lv" dir="ltr" value="lv">
                                  Latviešu
                                </option>
                                <option lang="lv-LV" dir="ltr" value="lv_LV">
                                  Latviešu (Latvija)
                                </option>
                                <option lang="lt" dir="ltr" value="lt">
                                  Lietuvių
                                </option>
                                <option lang="lt-LT" dir="ltr" value="lt_LT">
                                  Lietuvių (Lietuva)
                                </option>
                                <option lang="mk" dir="ltr" value="mk">
                                  македонски
                                </option>
                                <option lang="mk-MK" dir="ltr" value="mk_MK">
                                  македонски (Македонија)
                                </option>
                                <option lang="ms" dir="ltr" value="ms">
                                  Bahasa Melayu
                                </option>
                                <option lang="ms-MY" dir="ltr" value="ms_MY">
                                  Bahasa Melayu (Malaysia)
                                </option>
                                <option lang="mt" dir="ltr" value="mt">
                                  Malti
                                </option>
                                <option lang="mt-MT" dir="ltr" value="mt_MT">
                                  Malti (Malta)
                                </option>
                                <option lang="no" dir="ltr" value="no">
                                  norsk
                                </option>
                                <option lang="no-NO" dir="ltr" value="no_NO">
                                  norsk (Norge)
                                </option>
                                <option lang="no-NO-NY" dir="ltr" value="no_NO_NY">
                                  norsk (Norge,nynorsk)
                                </option>
                                <option lang="pl" dir="ltr" value="pl">
                                  polski
                                </option>
                                <option lang="pl-PL" dir="ltr" value="pl_PL">
                                  polski (Polska)
                                </option>
                                <option lang="pt" dir="ltr" value="pt">
                                  português
                                </option>
                                <option lang="pt-BR" dir="ltr" value="pt_BR">
                                  português (Brasil)
                                </option>
                                <option lang="pt-PT" dir="ltr" value="pt_PT">
                                  português (Portugal)
                                </option>
                                <option lang="ro" dir="ltr" value="ro">
                                  română
                                </option>
                                <option lang="ro-RO" dir="ltr" value="ro_RO">
                                  română (România)
                                </option>
                                <option lang="ru" dir="ltr" value="ru">
                                  русский
                                </option>
                                <option lang="ru-RU" dir="ltr" value="ru_RU">
                                  русский (Россия)
                                </option>
                                <option lang="sr" dir="ltr" value="sr">
                                  Српски
                                </option>
                                <option lang="sr-BA" dir="ltr" value="sr_BA">
                                  Српски (Босна и Херцеговина)
                                </option>
                                <option lang="sr--#Latn" dir="ltr" value="sr__#Latn">
                                  Srpski (Latin)
                                </option>
                                <option lang="sr-BA-#Latn" dir="ltr" value="sr_BA_#Latn">
                                  Srpski (Latin,Bosna i Hercegovina)
                                </option>
                                <option lang="sr-ME-#Latn" dir="ltr" value="sr_ME_#Latn">
                                  Srpski (Latin,Crna Gora)
                                </option>
                                <option lang="sr-RS-#Latn" dir="ltr" value="sr_RS_#Latn">
                                  Srpski (Latin,Srbija)
                                </option>
                                <option lang="sr-ME" dir="ltr" value="sr_ME">
                                  Српски (Montenegro)
                                </option>
                                <option lang="sr-CS" dir="ltr" value="sr_CS">
                                  Српски (Србија и Црна Гора)
                                </option>
                                <option lang="sr-RS" dir="ltr" value="sr_RS">
                                  Српски (Serbia)
                                </option>
                                <option lang="sk" dir="ltr" value="sk">
                                  Slovenčina
                                </option>
                                <option lang="sk-SK" dir="ltr" value="sk_SK">
                                  Slovenčina (Slovenská republika)
                                </option>
                                <option lang="sl" dir="ltr" value="sl">
                                  Slovenščina
                                </option>
                                <option lang="sl-SI" dir="ltr" value="sl_SI">
                                  Slovenščina (Slovenija)
                                </option>
                                <option lang="es" dir="ltr" value="es">
                                  español
                                </option>
                                <option lang="es-AR" dir="ltr" value="es_AR">
                                  español (Argentina)
                                </option>
                                <option lang="es-BO" dir="ltr" value="es_BO">
                                  español (Bolivia)
                                </option>
                                <option lang="es-CL" dir="ltr" value="es_CL">
                                  español (Chile)
                                </option>
                                <option lang="es-CO" dir="ltr" value="es_CO">
                                  español (Colombia)
                                </option>
                                <option lang="es-CR" dir="ltr" value="es_CR">
                                  español (Costa Rica)
                                </option>
                                <option lang="es-CU" dir="ltr" value="es_CU">
                                  español (Cuba)
                                </option>
                                <option lang="es-DO" dir="ltr" value="es_DO">
                                  español (República Dominicana)
                                </option>
                                <option lang="es-EC" dir="ltr" value="es_EC">
                                  español (Ecuador)
                                </option>
                                <option lang="es-SV" dir="ltr" value="es_SV">
                                  español (El Salvador)
                                </option>
                                <option lang="es-GT" dir="ltr" value="es_GT">
                                  español (Guatemala)
                                </option>
                                <option lang="es-HN" dir="ltr" value="es_HN">
                                  español (Honduras)
                                </option>
                                <option lang="es-MX" dir="ltr" value="es_MX">
                                  español (México)
                                </option>
                                <option lang="es-NI" dir="ltr" value="es_NI">
                                  español (Nicaragua)
                                </option>
                                <option lang="es-PA" dir="ltr" value="es_PA">
                                  español (Panamá)
                                </option>
                                <option lang="es-PY" dir="ltr" value="es_PY">
                                  español (Paraguay)
                                </option>
                                <option lang="es-PE" dir="ltr" value="es_PE">
                                  español (Perú)
                                </option>
                                <option lang="es-PR" dir="ltr" value="es_PR">
                                  español (Puerto Rico)
                                </option>
                                <option lang="es-ES" dir="ltr" value="es_ES">
                                  español (España)
                                </option>
                                <option lang="es-US" dir="ltr" value="es_US">
                                  español (Estados Unidos)
                                </option>
                                <option lang="es-UY" dir="ltr" value="es_UY">
                                  español (Uruguay)
                                </option>
                                <option lang="es-VE" dir="ltr" value="es_VE">
                                  español (Venezuela)
                                </option>
                                <option lang="sv" dir="ltr" value="sv">
                                  svenska
                                </option>
                                <option lang="sv-SE" dir="ltr" value="sv_SE">
                                  svenska (Sverige)
                                </option>
                                <option lang="th" dir="ltr" value="th">
                                  ไทย
                                </option>
                                <option lang="th-TH" dir="ltr" value="th_TH">
                                  ไทย (ประเทศไทย)
                                </option>
                                <option lang="th-TH-TH-#u-nu-thai" dir="ltr" value="th_TH_TH_#u-nu-thai">
                                  ไทย (ประเทศไทย,TH)
                                </option>
                                <option lang="tr" dir="ltr" value="tr">
                                  Türkçe
                                </option>
                                <option lang="tr-TR" dir="ltr" value="tr_TR">
                                  Türkçe (Türkiye)
                                </option>
                                <option lang="uk" dir="ltr" value="uk">
                                  українська
                                </option>
                                <option lang="uk-UA" dir="ltr" value="uk_UA">
                                  українська (Україна)
                                </option>
                                <option lang="vi" dir="ltr" value="vi">
                                  Tiếng Việt
                                </option>
                                <option lang="vi-VN" dir="ltr" value="vi_VN">
                                  Tiếng Việt (Việt Nam)
                                </option>
                            </select>
                        </div>
                      </form>
                </div>
            </div>

            <div class="card mx-auto mt-2" style="width: 100%;">
              <div class="card-title">
                <p class="text-left my-3 mx-2">Cart Summary</p>
              </div>
              <div class="p-2">
                <form>
                  <div class="card-body">
                    <table class="table">
                      <thead>
                      <tr>
                        <th>Qty</th>
                        <th>Item </th>
                        <th>Subtotal</th>
                      </tr>
                      </thead>
                      <tfoot>
                      <tr>
                        <td colspan="3"  *ngIf="this.summary">
                        Total: ${{this.summary.cartTotal}}
                        </td>
                      </tr>
                      </tfoot>
                      <tbody>
                        <tr  *ngFor="let product of this.showCart;let indexOfelement=index;">
                          <td>{{indexOfelement+1}}</td>
                          <td>
                                <span class="span-link">{{product.productName}}</span>
                          </td>
                          <td>${{product.itemTotal}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <ul>
                      <li><span class="span-link" (click)="goToViewCart()">View Cart</span></li>
                      <li><span class="span-link" (click)="goToCheckOut()">Check out</span></li>
                      <li><span class="span-link" (click)="goToQuickCheckOut()">Quick Checkout</span></li>
                      <li><span class="span-link" (click)="goToOnePageCheckOut()">One Page Checkout</span></li>
                    </ul>
                     
                  </div>
                </form>
              </div>
            </div>

            <!-- <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Cart Summary</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group p-1">
                          <p>Shopping Cart Empty</p>
                        </div>
                      </form>
                </div>
            </div> -->

            <div class="card mx-auto mt-2" style="width: 100%;">
              <div class="card-title">
                <p class="text-left my-3 mx-2">Compare Products</p>
              </div>
              <div class="p-1">
                <form>
                  <div class="card-body" *ngFor="let compare of compareProductList;">
                    <label>{{compare.productData.productName}}</label>&nbsp;<span (click)="removeAddToCompare(compare.productData.productId)" class="span-link">Remove</span>
                    <div>
                      <a href="" class="btn btn-outline-secondary" (click)="clearCompare()">Clear All</a>
                    </div>
                    <div>
                      <!--Modal Button-->
                      <a class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal">Compare
                        Products</a>
                    </div>
                    <!--Modal Popup Dialog Box-->
                   
                  </div>
                 
                </form>
              </div>
              <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="card m-5">
                      <div class="card-header">
                        Compare Products
                      </div>
                      <div class="card-body">
                        <div class="row table-scroll">
                          <div class="col-6" *ngFor="let compareItem of compareProductList;">
                            <table class="table">
                              <tbody>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td style="width:100%;">
                                    <img [src]="compareItem.smallImageUrl" alt="Small Image"><br>
                                  <span > {{compareItem.productData.productName}}</span> <br>
          
                                    <span class="regularPrice">${{compareItem.productPrice.price}}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Brand Name</td>
                                  <td>{{compareItem.productData.brandName}}</td>
                                </tr>
                                <tr>
                                  <td>Product Description</td>
                                  <td>{{compareItem.productData.description}}</td>
                                </tr>
                                <tr>
                                  <td>Long Description</td>
                                  <td>{{compareItem.productData.longDescription}}</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td>
                                    <div class="productbuy">
                                      <form method="post" action="" name="compare2FormAdd0">
                                        <input type="hidden" name="add_product_id" value="10110">
                                        <input type="text" size="5" name="quantity" value="1">
                                        <input type="hidden" name="clearSearch" value="N">
                                      </form>
                                      <a class="buttontext">Add to Cart </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <!-- <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Compare Products</p>
                </div>
                <div class="p-1">
                    <form>
                        <div class="form-group p-1">
                          <p>You have no products to compare.</p>
                        </div>
                      </form>
                </div>
            </div> -->
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Special Offers</p>
                </div>
                <div class="p-1">
                    <form>
                        <div class="form-group">
                            <ul>
                                <li>
                                  <p>
                                    <a (click)="goToShowPromotionDetails()"  class="linktext">Details</a>
                                    Free Shipping in Utah
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    <a (click)="goToShowPromotionDetails()"  class="linktext">Details</a>
                                    10% off entire purchase
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    <a (click)="goToShowPromotionDetails()"  class="linktext">Details</a>
                                    Buy 3 Get 2 Free in the Widgets [200] or any sub-category (except the Small Widgets [201] category and sub-categories, but always including the Micro Widgets [20111] category), limit to two per order
                                  </p>
                                </li>
                            </ul>
                        </div>
                        <div class="form-group py-2 ps-4">
                            <input class="btn btn-outline-secondary" type="submit" value="View All Promotions" (click)="goToViewAllPromotions()">
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Did You Know?</p>
                </div>
                <div class="p-2">
                    <form>
                        <div class="form-group">
                            <ul>
                                <li>
                                  <p>
                                    The use of gizmos has been shown to have no negative effect on personal longetivity.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Did you know that widget users live twice as long as their non-widget counterparts?
                                  </p>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mx-auto mt-4" style="width: 100%;">
                <div class="card-title">
                    <p class="text-left my-3 mx-2">Tag Cloud</p>
                </div>
                <div class="p-2">
                    <a style="font-size: 8pt;">
                      big
                    </a>
                    <a style="font-size: 8pt;" >
                      black
                    </a>
                    <a style="font-size: 8pt;" >
                      chrome
                    </a>
                    <a style="font-size: 11pt;" >
                      demonstration
                    </a>
                    <a style="font-size: 14pt;" >
                      gizmos
                    </a>
                    <a style="font-size: 11pt;" >
                      red
                    </a>
                    <a style="font-size: 9.5pt;" >
                      service
                    </a>
                    <a style="font-size: 9.5pt;" >
                      small
                    </a>
                    <a style="font-size: 8pt;" >
                      standard
                    </a>
                    <a style="font-size: 8pt;" >
                      tiny
                    </a>
                    <a style="font-size: 9.5pt;" >
                      widget
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

