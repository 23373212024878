import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { SideBarComponent } from './side-bar/side-bar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RegisterComponent } from './register/register.component';
import { FeauredProductsComponent } from './feaured-products/feaured-products.component';
import { MessagesComponent } from './messages/messages.component';
import { QuotesComponent } from './quotes/quotes.component';
import { RequestsComponent } from './requests/requests.component';
import { ShoppingListComponent } from './shopping-list/shopping-list.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { QuickAddComponent } from './quick-add/quick-add.component';
import { ProfileComponent } from './profile/profile.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { ViewAllPromotionsComponent } from './view-all-promotions/view-all-promotions.component';
import { ShowPromotionDetailsComponent } from './show-promotion-details/show-promotion-details.component';
import { ViewCartComponent } from './view-cart/view-cart.component';
import { QuickCheckOutComponent } from './quick-check-out/quick-check-out.component';
import { OnePageCheckOutComponent } from './one-page-check-out/one-page-check-out.component';
import { ConfigureProductComponent } from './configure-product/configure-product.component';
import { ChooseProductAmountComponent } from './choose-product-amount/choose-product-amount.component';
import { ChooseVariationComponent } from './choose-variation/choose-variation.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { ReviewProductComponent } from './review-product/review-product.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { CompareProductsPopUpComponent } from './compare-products-pop-up/compare-products-pop-up.component';
import { CheckOutOption1Component } from './check-out-option1/check-out-option1.component';
import { CheckOutOption2Component } from './check-out-option2/check-out-option2.component';
import { CheckOutOption3Component } from './check-out-option3/check-out-option3.component';
import { FinalCheckOutReviewComponent } from './final-check-out-review/final-check-out-review.component';
import { AddCreditCardComponent } from './add-credit-card/add-credit-card.component';
import { AddEftAccountComponent } from './add-eft-account/add-eft-account.component';
import { HttpClientModule ,HTTP_INTERCEPTORS} from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppInterceptor } from './app.interceptor';
import { DropdownModule } from 'primeng/dropdown';
import { SplitPaymentComponent } from './split-payment/split-payment.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TreeModule } from 'primeng/tree';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoginGuard } from './guards/login.guard';
import { AddGiftCardComponent } from './add-gift-card/add-gift-card.component';
import { LeftSideBarComponent } from './left-side-bar/left-side-bar.component';
import { RightSideBarComponent } from './right-side-bar/right-side-bar.component';
import { FindCatalogComponent } from './find-catalog/find-catalog.component';
import { LoaderComponent } from './loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './loader.service';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { CreateContactComponent } from './create-contact/create-contact.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { UploadNewFileComponent } from './upload-new-file/upload-new-file.component';
import { CreateAddressComponent } from './create-address/create-address.component';
import { CreateEmailComponent } from './create-email/create-email.component';
import { ProductsComponent } from './products/products.component';
import { ShippingComponent } from './shipping/shipping.component';
import { OrderComponent } from './order/order.component';
import { HeaderMessagesComponent } from './header-messages/header-messages.component';
import { UpdatePersonalInformationComponent } from './update-personal-information/update-personal-information.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { ReadMessageSummaryComponent } from './messages/read-message-summary/read-message-summary.component';
import { AdminGuard } from './admin.guard';
import { ViewQuotesComponent } from './view-quotes/view-quotes.component';
import { RequestSummaryComponent } from './requests/request-summary/request-summary.component';
import { AllMyListComponent } from './my-account/All-my-List/all-my-list/all-my-list.component'
import { PrintStatementComponent } from './my-account/Print-Statement/print-statement/print-statement.component'
import { MyWishListComponent } from './my-account/My-wish-list/my-wish-list/my-wish-list.component'
import { PurchaseProductComponent } from './my-account/Purchase/purchase-product/purchase-product.component'
import { ReorderProductComponent } from './my-account/Reorder/reorder-product/reorder-product.component'
import { ReturnProductComponent } from './my-account/Return-Product/return-product/return-product.component'
import { TransictionHistoryComponent } from './my-account/Transiction-history/transiction-history/transiction-history.component'
import { DataFilterComponent } from './my-account/dateFilter/data-filter/data-filter.component'
import { AmountFilterComponent } from './my-account/amountFilter/amount-filter/amount-filter.component';
import { AboutUsComponent } from './about-us/about-us/about-us.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent},
  { path: 'sidebar', component: SideBarComponent},
  {path: '', component: FeauredProductsComponent},
  {path: 'messages', component: MessagesComponent,

},
  {path: 'quotes', component: QuotesComponent},
  {path: 'requests', component: RequestsComponent},
  {path: 'requests-summary', component: RequestSummaryComponent},
  {path: 'shoppingList', component: ShoppingListComponent},
  {path: 'quick-add', component: QuickAddComponent},
  {path: 'profile', component: ProfileComponent,
    children:[
      { path: '', redirectTo: 'order-history', pathMatch: 'full' }, //setting a default route among nested route
      { path: 'order-history', component: OrderHistoryComponent},
      { path: 'quotes', component: QuotesComponent},
      { path: 'payment-method', component: PaymentMethodComponent},
      { path: 'personal-information', component: PersonalInformationComponent},
      { path: 'contact-information', component: ContactInformationComponent},
      { path: 'file-manager', component: FileManagerComponent},
      { path: 'messages', component: MessagesComponent},
      {path: 'shipping', component: ShippingComponent},
      {path: 'order', component: OrderComponent},
      {path: 'all-my-list', component: AllMyListComponent},
      {path: 'print-statement', component: PrintStatementComponent},
      {path: 'my-wish-list', component: MyWishListComponent},
      {path: 'purchase-products', component: PurchaseProductComponent},
      {path: 'reorder-items', component: ReorderProductComponent},
      {path: 'return-products', component: ReturnProductComponent},
      {path: 'transiction-history', component: TransictionHistoryComponent},
      {path: 'date-filter', component: DataFilterComponent},
      {path: 'amount-filter', component: AmountFilterComponent},
]
  },
  {path: 'advanced-search', component: AdvancedSearchComponent},
  {path: 'view-all-promotions', component: ViewAllPromotionsComponent},
  {path: 'show-promotion-details', component: ShowPromotionDetailsComponent},
  {path: 'view-cart', component: ViewCartComponent},
  {path: 'quick-check-out', component: QuickCheckOutComponent},
  {path: 'one-page-check-out', component: OnePageCheckOutComponent},
  {path: 'configure-product', component: ConfigureProductComponent},
  {path: 'choose-product-amount', component: ChooseProductAmountComponent},
  {path: 'choose-variation', component: ChooseVariationComponent},
  {path: 'compare-products-pop-up', component: CompareProductsPopUpComponent},
  {path: 'check-out-option1', component: CheckOutOption1Component},
  {path: 'check-out-option2', component: CheckOutOption2Component},
  {path: 'check-out-option3', component: CheckOutOption3Component},
  {path: 'final-check-out-review', component: FinalCheckOutReviewComponent},
  {path: 'add-credit-card', component: AddCreditCardComponent},
  {path: 'add-eft-account', component: AddEftAccountComponent},
  {path: 'contact-us', component: ContactUsComponent},
  {path: 'add-gift-card', component: AddGiftCardComponent},
  {path: 'find-catalog', component: FindCatalogComponent},
  {path: 'create-contact', component: CreateContactComponent},
  {path: 'upload-new-file', component: UploadNewFileComponent},
  {path: 'create-address', component: CreateAddressComponent},
  {path: 'create-email', component: CreateEmailComponent},
  {path: 'products', component: ProductsComponent},
  {path: 'split-ship', component: SplitPaymentComponent},
  {path: 'about-us', component: AboutUsComponent},

  
  { 
    path: 'header-messages', children: [
      { path: '', component: HeaderMessagesComponent, canActivate: [AdminGuard] },
      { path: 'read-message-summary', component: ReadMessageSummaryComponent, canActivate: [AdminGuard] },
    ]
  },
  {path: 'view-quotes', component: ViewQuotesComponent},

 // {path: 'header-messages', component: HeaderMessagesComponent,},
  {path: 'view-order', component: ViewOrderComponent},
  {path: 'update-personal-information', component: UpdatePersonalInformationComponent},

]
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SideBarComponent,
    HeaderComponent,
    FooterComponent,
    RegisterComponent,
    FeauredProductsComponent,
    MessagesComponent,
    QuotesComponent,
    RequestsComponent,
    ShoppingListComponent,
    OrderHistoryComponent,
    QuickAddComponent,
    ProfileComponent,
    AdvancedSearchComponent,
    ViewAllPromotionsComponent,
    ShowPromotionDetailsComponent,
    ViewCartComponent,
    QuickCheckOutComponent,
    OnePageCheckOutComponent,
    ConfigureProductComponent,
    ChooseProductAmountComponent,
    ChooseVariationComponent,
    CheckOutComponent,
    ReviewProductComponent,
    CompareProductsPopUpComponent,
    CheckOutOption1Component,
    CheckOutOption2Component,
    CheckOutOption3Component,
    FinalCheckOutReviewComponent,
    AddCreditCardComponent,
    AddEftAccountComponent,
    SplitPaymentComponent,
    ContactUsComponent,
    PaymentMethodComponent,
    PersonalInformationComponent,
    AddGiftCardComponent,
    LeftSideBarComponent,
    RightSideBarComponent,
    FindCatalogComponent,
    LoaderComponent,
    ContactInformationComponent,
    CreateContactComponent,
    FileManagerComponent,
    UploadNewFileComponent,
    CreateAddressComponent,
    CreateEmailComponent,
    ProductsComponent,
    ShippingComponent,
    OrderComponent,
    HeaderMessagesComponent,
    UpdatePersonalInformationComponent,
    ViewOrderComponent,
    ReadMessageSummaryComponent,
    ViewQuotesComponent,
    RequestSummaryComponent,
    AllMyListComponent,
    PrintStatementComponent,
    MyWishListComponent,
    PurchaseProductComponent,
    ReorderProductComponent,
    ReturnProductComponent,
    TransictionHistoryComponent,
    DataFilterComponent,
    AmountFilterComponent,
    AboutUsComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TableModule,
    RouterModule.forRoot(appRoutes),
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    DropdownModule, TreeModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true
    // DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService
  },DatePipe,
   {provide: LocationStrategy, useClass: HashLocationStrategy}, LoaderService],
 
  
  bootstrap: [AppComponent]
})
export class AppModule { }
