<div class="container-fluid">

    <div class="row banner_div banner_section">
      <div class="col-md-12">
        <h2>Choose the wide range of best accessories.</h2>
      </div>
    </div>

    <div class="container mt-5">
      <div class="row">
        <div class="col-lg-3">
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div  class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box1" aria-expanded="false" aria-controls="box1">
              <p class="text-left my-3 mx-2"><strong>Catalog</strong></p>
            </div>
            <div id="box1" class="accordion-collapse collapse p-2" aria-labelledby="box1">
              <form [formGroup]="CatalogForm">
                <div class="form-group p-1">
                  <!-- <label class="mb-2" for="exampleInputEmail1">Choose Catalog</label> -->
                  <p-dropdown [options]="catalogArray" placeholder="Select Catalog" formControlName="catalogId"
                    (onChange)="getCategoryByCatalog($event)">
                  </p-dropdown>
                </div>
              </form>
            </div>
          </div>
          <div class="card mx-auto mt-2" style="width:100%">
            <div class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box2" aria-expanded="false" aria-controls="box2">
              <p class="text-left my-3 mx-2"><strong>Search Catalog</strong></p>
            </div>
            <div id="box2" class="accordion-collapse collapse p-2" aria-labelledby="box2">
              <form [formGroup]="findForm">
                <div class="form-group p-1">
                    <!-- <label class="mb-2" for="exampleInputEmail1">Search Catalog</label> -->
                    <input type="email" class="form-control mb-2" formControlName="findCatalog" id="username" aria-describedby="emailHelp"
                        placeholder="">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="OR" formControlName="radioAll" name="searchCatalogueRadio"
                            id="searchCatalogueRadio" checked>
                        <label class="form-check-label" for="searchCatalogueRadio">Any</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" value="AND" formControlName="radioAll" type="radio" name="searchCatalogueRadio"
                            id="searchCatalogueRadio">
                        <label class="form-check-label" for="searchCatalogueRadio">All</label>
                    </div>
                </div>
                <div class="form-group p-1">
                    <input class="btn btn-outline-secondary" type="submit" value="Find" (click)="goToFindCatalog()">
                </div>
                <div class="form-group p-1">
                    <input class="btn btn-outline-secondary" type="submit" value="Advacned Search"
                        (click)="goToAdvancedSearch()">
                </div>
            </form>
            </div>
          </div>
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box3" aria-expanded="false" aria-controls="box3">
              <p class="text-left my-3 mx-2"><strong>Categories</strong> </p>
            </div>
            <div id="box3" class="accordion-collapse collapse p-2" aria-labelledby="box3">
              <form>
                <div class="form-group p-1">
                  <p-tree [value]="category" selectionMode="single"></p-tree>
                </div>
              </form>
            </div>
          </div>
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box4" aria-expanded="false" aria-controls="box4">
              <p class="text-left my-3 mx-2"><strong>Quick Recorder</strong> </p>
            </div>
            <div id="box4" class="accordion-collapse collapse p-2" aria-labelledby="box4">
              <span class="span-link">Tiny Gizmo</span>
              <ul class="list-unstyled">
                <li>GZ-1000 <span>$12.00</span></li>
                <li><span class="span-link">Add To Cart</span></li>
              </ul>
            </div>
          </div>
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box5" aria-expanded="false" aria-controls="box5">
              <p class="text-left my-3 mx-2"><strong>Sign Up For Contact List</strong> </p>
            </div>
            <div id="box5" class="accordion-collapse collapse p-2" aria-labelledby="box5">
              <form>
                <div class="form-group p-1">
                  <p>Sign Up For Contact List LogIn</p>
                  <p><span class="span-link">Login </span>THE ADMINISTRATOR</p>
                </div>
              </form>
            </div>
          </div>
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box6" aria-expanded="false" aria-controls="box6">
              <p class="text-left my-3 mx-2"><strong>Mini Poll</strong> </p>
            </div>
            <div id="box6" class="accordion-collapse collapse p-2" aria-labelledby="box6">
              <form>
                <div class="form-group p-1">
                  <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
                  <select class="form-select form-select my-2" aria-label=".form-select example">
                    <option selected>Right Side</option>
                    <option value="1">Right Side</option>
                    <option value="2">Left Side/option>
                  </select>
                  <p>*</p>
                  <label class="mb-2" for="exampleInputEmail1">Which side should the mini-poll box show?</label>
                  <select class="form-select form-select my-2" aria-label=".form-select example">
                    <option selected>Right Side</option>
                    <option value="1">Right Side</option>
                    <option value="2">Left Side</option>
                  </select>
                  <p>*</p>
                  <input class="btn btn-outline-secondary" type="submit" value="Vote!">
                </div>
              </form>
            </div>
          </div>
         <!--  <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="card-title">
              <p class="text-left my-3 mx-2">Browse Forums</p>
            </div>
            <div class="p-2 pb-4">
              <form>
                <span class="span-link">Ask the Experts</span><br>
                <span class="span-link">Ask the Experts</span><br>
                <span class="span-link">Gizmos</span><br>
                <span class="span-link">Gizmos</span><br>
                <span class="span-link">Widgets</span><br>
                <span class="span-link">Widgets</span><br>
              </form>
            </div>
          </div>
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="card-title">
              <p class="text-left my-3 mx-2">Browse Content</p>
            </div>
            <div class="p-2 pb-4">
              <form>
                <span class="span-link">Widgets</span><br>
                <span class="span-link">Store Policies 2</span><br>
                <span class="span-link">Gizmos</span><br>
                <span class="span-link">Cookie Policy</span><br>
                <span class="span-link">Policies</span><br>
                <span class="span-link">Store Policies</span><br>
                <span class="span-link">Store Policies 2</span><br>
                <span class="span-link">Widgets</span><br>
                <span class="span-link">Policies</span><br>
                <span class="span-link">Gizmos</span><br>
                <span class="span-link">Cookie Policy</span><br>
                <span class="span-link">Store Policies</span><br>
              </form>
            </div>
          </div>
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="card-title">
              <p class="text-left my-3 mx-2">Browse Blogs</p>
            </div>
            <div class="p-2 pb-4">
              <form>
                <span class="span-link">Big AI</span><br>
                <span class="span-link">Big AI</span><br>
                <span class="span-link">Mad Max</span><br>
                <span class="span-link">Mad Max</span><br>
                <span class="span-link">xczx</span><br>
              </form>
            </div>
          </div> -->
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box7" aria-expanded="false" aria-controls="box7">
              <p class="text-left my-3 mx-2"><strong>Language</strong></p>
            </div>
            <div id="box7" class="accordion-collapse collapse p-2" aria-labelledby="box7">
              <form>
                <div class="form-group p-1">
                  <p-dropdown [options]="localeArray" (onChange)="getLanguage($event)" placeholder="Select Language">
                  </p-dropdown>
                </div>
              </form>
            </div>
          </div>
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box8" aria-expanded="false" aria-controls="box8">
              <p class="text-left my-3 mx-2"><strong>Cart Summary</strong> </p>
            </div>
            <div id="box8" class="accordion-collapse collapse p-2" aria-labelledby="box8">
              <form>
                <div class="card-body">
                  <table class="table">
                    <thead>
                    <tr>
                      <th>Qty</th>
                      <th>Item </th>
                      <th>Subtotal</th>
                    </tr>
                    </thead>
                    <tfoot>
                    <tr>
                      <td colspan="3"  *ngIf="this.summary">
                      Total: ${{this.summary.cartTotal}}
                      </td>
                    </tr>
                    </tfoot>
                    <tbody>
                      <tr  *ngFor="let product of this.showCart;let indexOfelement=index;">
                        <td>{{indexOfelement+1}}</td>
                        <td>
                              <span class="span-link">{{product.productName}}</span>
                        </td>
                        <td>${{product.itemTotal}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <ul>
                    <li><span class="span-link" (click)="goToViewCart()">View Cart</span></li>
                    <li><span class="span-link" (click)="goToCheckOut()">Check out</span></li>
                    <li><span class="span-link" (click)="goToQuickCheckOut()">Quick Checkout</span></li>
                    <li><span class="span-link" (click)="goToOnePageCheckOut()">One Page Checkout</span></li>
                  </ul>
                   
                </div>
              </form>
            </div>
          </div>
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box9" aria-expanded="false" aria-controls="box9">
              <p class="text-left my-3 mx-2"><strong>Compare Products</strong> </p>
            </div>
            <div id="box9" class="accordion-collapse collapse p-2" aria-labelledby="box9">
              <form>
                <div class="card-body" *ngFor="let compare of compareProductList;">
                  <label>{{compare.productData.productName}}</label>&nbsp;<span (click)="removeAddToCompare(compare.productData.productId)" class="span-link">Remove</span>
                  <div>
                    <a href="" class="btn btn-outline-secondary" (click)="clearCompare()">Clear All</a>
                  </div>
                  <div>
                    <!--Modal Button-->
                    <a class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal">Compare
                      Products</a>
                  </div>
                  <!--Modal Popup Dialog Box-->
                 
                </div>
               
              </form>
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="card m-5">
                    <div class="card-header">
                      Compare Products
                    </div>
                    <div class="card-body">
                      <div class="row table-scroll">
                        <div class="col-6" *ngFor="let compareItem of compareProductList;">
                          <table class="table">
                            <tbody>
                              <tr>
                                <td>&nbsp;</td>
                                <td style="width:100%;">
                                  <img [src]="compareItem.smallImageUrl" alt="Small Image"><br>
                                <span > {{compareItem.productData.productName}}</span> <br>
        
                                  <span class="regularPrice">${{compareItem.productPrice.price}}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>Brand Name</td>
                                <td>{{compareItem.productData.brandName}}</td>
                              </tr>
                              <tr>
                                <td>Product Description</td>
                                <td>{{compareItem.productData.description}}</td>
                              </tr>
                              <tr>
                                <td>Long Description</td>
                                <td>{{compareItem.productData.longDescription}}</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>
                                  <div class="productbuy">
                                    <form method="post" action="" name="compare2FormAdd0">
                                      <input type="hidden" name="add_product_id" value="10110">
                                      <input type="text" size="5" name="quantity" value="1">
                                      <input type="hidden" name="clearSearch" value="N">
                                    </form>
                                    <a class="buttontext">Add to Cart </a>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box10" aria-expanded="false" aria-controls="box10">
              <p class="text-left my-3 mx-2"><strong>Special Offers</strong> </p>
            </div>
            <div id="box10" class="accordion-collapse collapse p-2" aria-labelledby="box10">
              <form>
                <div class="form-group">
                  <ul>
                    <li>
                      <p>
                        <span class="span-link" (click)="goToShowPromotionDetails()">Details</span>
                        Free Shipping in Utah
                      </p>
                    </li>
                    <li>
                      <p>
                        <span class="span-link" (click)="goToShowPromotionDetails()">Details</span>
                        10% off entire purchase
                      </p>
                    </li>
                    <li>
                      <p>
                        <span class="span-link" (click)="goToShowPromotionDetails()">Details</span>
                        Buy 3 Get 2 Free in the Widgets [200] or any sub-category (except the Small Widgets [201] category
                        and sub-categories, but always including the Micro Widgets [20111] category), limit to two per order
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="form-group py-2 ps-4">
                  <input class="btn btn-outline-secondary" type="submit" value="View All Promotions"
                    (click)="goToViewAllPromotions()">
                </div>
              </form>
            </div>
          </div>
          <div class="card mx-auto mt-2" style="width: 100%;">
            <div class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box11" aria-expanded="false" aria-controls="box11">
              <p class="text-left my-3 mx-2"><strong>Did You Know?</strong> </p>
            </div>
            <div id="box11" class="accordion-collapse collapse p-2" aria-labelledby="box11">
              <form>
                <div class="form-group">
                  <ul>
                    <li>
                      <p>
                        The use of gizmos has been shown to have no negative effect on personal longetivity.
                      </p>
                    </li>
                    <li>
                      <p>
                        Did you know that widget users live twice as long as their non-widget counterparts?
                      </p>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
          <div class="card mx-auto mt-2" style="width: 100%;" >
            <div class="accordion-button collapsed card-title" type="button" data-bs-toggle="collapse" data-bs-target="#box12" aria-expanded="false" aria-controls="box12">
              <p class="text-left my-3 mx-2"><strong>Tag Cloud</strong> </p>
            </div>
            <div id="box12" class="accordion-collapse collapse p-2" aria-labelledby="box12">
              <a  *ngFor="let cloud of tagCloudList"  [style.font-size.pt]="cloud.fontSize"
              href="https://34.196.47.126:8443/ecommerce/control/tagsearch?SEARCH_STRING=big&amp;keywordTypeId=KWT_TAG&amp;statusId=KW_APPROVED">
              {{cloud.tag}}
            </a>
             
            
            </div>
          </div>

        </div>
        <div class="col-lg-9">
            <h5 class="mt-3"> <strong>Sort By</strong> </h5>
            <div>
              <div class="col-lg-6 order-lg-2 order-1 px-4" *ngIf="MainFeaturedProduct?.length == 0" >
                <h2 class="text-center my-4 mx-1">No PROMOTION Category</h2>
              </div>
              <div class="mx-auto mt-2" style="width: 100%;" *ngIf="MainFeaturedProduct?.length > 0" >
                <!-- <div class="card-title">
                  <h3 class="text-left my-3 mx-4">Featured Products</h3>
                </div> -->
                <div class="row">
                  <div class="col-lg-12">
                    <div class="pt-3 ps-2">
                      <!-- <span class="span-link">Add Products in this Category to the Cart using Default Quantities</span> -->
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-3">
                    <div class="p-2">
                      <form>
                        <div class="form-group p-1">
                          <select class="form-select form-select my-2" aria-label=".form-select example" style="background-color: #EFEFEF;">
                            <option selected>Featured</option>
                            <option value="1">Price asc.</option>
                            <option value="2">Price desc.</option>
                          </select>
                          <!-- <span>1 - 9 of 64 | <span class="span-link">Next</span></span> -->
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="p-2">
                      <form>
                        <div class="form-group p-1">
                          <select class="form-select form-select my-2" aria-label=".form-select example" style="background-color: #EFEFEF;">
                            <option selected>Page 1 of 4</option>
                            <option value="1">Go to Page 1</option>
                            <option value="2">Go to Page 2</option>
                            <option value="3">Go to Page 3</option>
                            <option value="4">Go to Page 4</option>
                          </select>
                          <!-- <span>1 - 9 of 64 | <span class="span-link">Next</span></span> -->
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="row" >
                  <div class="col-lg-4 px-4" style="padding-right: 1px !important;padding-left: 13px !important;" *ngFor="let data of MainFeaturedProduct">
                    <div class="card mx-auto mt-2 mb-2">
                      <div class="d-flex justify-content-center p-2">
                        <img class="img-fluid item-preview" [src]="data.images[0].data[0]?data.images[0].data[0].objectInfo:img_missing" alt="Item Preview">
                      </div>
    
                      <div class="d-flex justify-content-center p-2">
                        <span class="span-link" (click)="addPromoTag(data.product.productId)">{{data.product.productName?data.product.productName:'N/A'}}</span>
                      </div>
                      <div class="d-flex justify-content-center p-2">
                        <p class="h6">{{data.product.description?data.product.description:'N/A'}}</p>
                      </div>
                      <div class="d-flex justify-content-center p-2">
                        <p class="h6">{{data.product.productId?data.product.productId:'N/A'}}</p>
                      </div>
                      <div class="" style="border-bottom: 1px solid rgba(0,0,0,.125);margin-top: 14px;">

                      </div>
                      <div class="shop_cart_div">
                        
                        <p class="shop_cart_p" (click)="addPromoTag(data.product.productId)">
                          <span class="shop_cart_span" >                         
                             <i class="fa fa-shopping-cart shop_cart_img" style="font-size:23px;color:#FFFFFF;"></i>
                          </span>
                        </p>
                      </div>
                      <div class="d-flex justify-content-center p-2" style="margin-top: 15%;">
                        <span>Product Aggregated Price :${{data.price.defaultPrice}}</span>
                      </div>
                      <!-- <div class="d-flex justify-content-center p-2" *ngIf="data.showForm=='Y'">
                        <input type="email" class="form-control" id="username" aria-describedby="emailHelp"
                          placeholder="Enter Number Of Items">
                        <input class="btn btn-outline-secondary btn-sm" type="submit" value="Add To Cart">
                      </div> -->
                      <!-- <div class="d-flex justify-content-center p-2" *ngIf="data.showOrderConfigureButton=='Y'">
                        <a class="btn btn-outline-secondary btn-sm" (click)="goToConfigureProduct(data.product.productId)">Configure...</a>
                      </div>
                      <div class="d-flex justify-content-center p-2" *ngIf="data.orderChooseVariations=='Y'">
                        <a class="btn btn-outline-secondary btn-sm" (click)="goToConfigureProduct(data.product.productId)">Choose
                          Variations...</a>
                      </div>
                      <div *ngIf="data.orderChooseAmount=='Y'" class="d-flex justify-content-center p-2">
                        <a class="btn btn-outline-secondary btn-sm" (click)="goToConfigureProduct(data.product.productId)">Choose Amount...</a>
                      </div> -->
                      <!-- <div class="d-flex justify-content-center p-2 mb-3" *ngIf="data.showForm=='Y'">
                        <span (click)="addToCompare(data.product.productId);" class="span-link">Add To Compare</span>
                      </div> -->
                      <!-- <div class="d-flex justify-content-center p-2" *ngIf="data.showForm=='Y'">
    
                        <input *ngIf="data.showAddtoCart1=='Y'" class="btn btn-outline-secondary btn-sm" type="submit"
                          value="Add Defualt To Cart">
    
                      </div>
                      <div class="d-flex justify-content-center p-2" *ngIf="data.showForm=='Y'">
    
                        <input *ngIf="data.showAddtoCart2=='Y'" class="btn btn-outline-secondary btn-sm" type="submit"
                          value="Add Default To Cart">
                      </div> -->
    
                    </div>
                  </div>
    
                </div>
                <!-- <div class="row">
                  <div class="col-sm-4 offset-sm-8">
                    <div class="p-2">
                      <form>
                        <div class="form-group p-1">
                          <select class="form-select form-select my-2" aria-label=".form-select example">
                            <option selected>Page 1 of 4</option>
                            <option value="1">Go to Page 1</option>
                            <option value="2">Go to Page 2</option>
                            <option value="3">Go to Page 3</option>
                            <option value="4">Go to Page 4</option>
                          </select>
                          <span>1 - 9 of 64 | <span class="span-link">Next</span></span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
        </div>
      </div>

    </div>
  
 
 
</div>