import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-update-personal-information',
  templateUrl: './update-personal-information.component.html',
  styleUrls: ['./update-personal-information.component.css']
})
export class UpdatePersonalInformationComponent implements OnInit {
  Editform:FormGroup;
  birthDate: any;
  passportExpireDate: any;
  partyId: string | null;
  SpassportExpireDate:any;
  SbirthDate:any;
  constructor(readonly router:Router ,private sidebarService: SideBarService,
    private toastr: ToastrService, private _ActivatedRoute: ActivatedRoute,
    private form: FormBuilder,   readonly datePipe: DatePipe,) { 
      this.partyId = localStorage.getItem("partyId");
    this.Editform = this.form.group({
      birthDate: [''],
      comments:  [''],
      firstName:  [''],
      gender:  [''],
      height:  [''],
      lastName:  [''],
      maritalStatusEnumId:  [''],
      middleName: [''],
      mothersMaidenName:  [''],
      nickname:  [''],
    
      passportExpireDate:  [''],
      passportNumber:  [''],
      personalTitle:  [''],
      suffix:  [''],
      totalYearsWorkExperience: [''],
      weight:  [''],
    });
  }

  ngOnInit(): void {
    this.customerProfile();

  }
  customerProfile() {
    this.sidebarService.customerProfile().subscribe((res: any) => {
      setTimeout(() => {
       
      
        const formValue = this.Editform;
        const birthDate=res.data.personalInfo.person.birthDate
        this.birthDate=this.datePipe.transform(birthDate,"yyyy-MM-dd");
        const passportExpireDate=res.data.personalInfo.person.passportExpireDate
        this.passportExpireDate=this.datePipe.transform(passportExpireDate,"yyyy-MM-dd");
        formValue.patchValue({
          birthDate: this.birthDate,
          comments:  res.data.personalInfo.person.comments,
          firstName:  res.data.personalInfo.person.firstName,
          gender: res.data.personalInfo.person.gender,
          height:  res.data.personalInfo.person.height,
          lastName: res.data.personalInfo.person.lastName,
          maritalStatusEnumId: res.data.personalInfo.person.maritalStatusEnumId,
          middleName:res.data.personalInfo.person.middleName,
          mothersMaidenName: res.data.personalInfo.person.mothersMaidenName,
          nickname: res.data.personalInfo.person.nickname,
        
          passportExpireDate:  this.passportExpireDate,
          passportNumber: res.data.personalInfo.person.passportNumber,
          personalTitle: res.data.personalInfo.person.personalTitle,
          suffix: res.data.personalInfo.person.suffix,
          totalYearsWorkExperience:res.data.personalInfo.person.totalYearsWorkExperience,
          weight: res.data.personalInfo.person.weight,
        })
      
      }, 3000);

    })
  }
  
  onSubmit() {
    const birthDate=this.Editform.value.birthDate;
    this.SbirthDate=this.datePipe.transform(birthDate,"yyyy-MM-dd hh:mm:ss");
    const passportExpireDate=this.Editform.value.passportExpireDate;
    this.SpassportExpireDate=this.datePipe.transform(passportExpireDate,"yyyy-MM-dd hh:mm:ss");
    const req = {
      "birthDate":  this.SbirthDate,
      "comments": this.Editform.value.comments,
      "firstName": this.Editform.value.firstName,
      "gender": this.Editform.value.gender,
      "height": this.Editform.value.height,
      "lastName": this.Editform.value.lastName,
      "maritalStatusEnumId": this.Editform.value.maritalStatusEnumId,
      "middleName":this.Editform.value.middleName,
      "mothersMaidenName":this.Editform.value.mothersMaidenName,
      "nickname": this.Editform.value.nickname,
      "partyId":this.partyId,
      "passportExpireDate": this.SpassportExpireDate,
      "passportNumber": this.Editform.value.passportNumber,
      "personalTitle": this.Editform.value.personalTitle,
      "suffix":this.Editform.value.suffix,
      "totalYearsWorkExperience": this.Editform.value.totalYearsWorkExperience,
      "weight": this.Editform.value.weight,
    }
    this.sidebarService.updateCustomerProfile(req).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.router.navigate(['/profile']);
      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })
  }
  goToProfile(){
    this.router.navigate(['/profile']);
  }
}
