import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ServicesService } from '../services/services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from '../register/register.service';
import { MyAccountsService } from '../my-account/my-accounts.service';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-create-address',
  templateUrl: './create-address.component.html',
  styleUrls: ['./create-address.component.css']
})
export class CreateAddressComponent implements OnInit {

  address1:any;
  address2:any;
  city:any;
  postalCode:any;
  show!: boolean;
  partyId: any;
  addressForm: FormGroup;
  solicitationArray = [
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }
  ];
  contactMechTypeId: any[];
  countryArray: any[];
  stateArray: any[];
  accountInfo: any;
  contactMechId: string;
  id:any;
  address: any;
  partyTaxAuthInfoAndDetailList: any;
  partyTaxIdentification: any;
  partyTaxIdentificationArray: any[]=[];
  partyTaxIdentificationData: any;
  constructor(
    readonly _GlobalResourceService: RegisterService,
    readonly _CRMAccountService: ServicesService,
    readonly _FormBuilder: FormBuilder,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _ToastrService: ToastrService,
    private myAccountsService : MyAccountsService,
    private sidebarService: SideBarService

  ) {

    this.addressForm = this._FormBuilder.group({
      address1: [''],
      address2: [''],
      allowSolicitation: [''],
      attnName: [''],
      city: [''],
      contactMechId: [''],
      contactMechPurposeTypeId: [''],
      contactMechTypeId: [''],
      countryGeoId: [''],
      directions: [''],
      partyId: [''],
      postalCode: [''],
      preContactMechTypeId: ['POSTAL_ADDRESS'],
      stateProvinceGeoId: [''],
      toName: ['']
    });
    this.show = false;
    this.partyId = localStorage.getItem('partyId');
    this.countryArray = [];
    this.stateArray = [];
    this.contactMechTypeId = [];
    this.contactMechId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.contactMechId = this._ActivatedRoute.snapshot.queryParams.contactMechId;
    if (this.contactMechId) {
      this.getAccountDetails();
      this.show = true;
    }
    this.addressForm.controls.countryGeoId.valueChanges.subscribe(data => {
      if (data) {
        this.getStates(data);
      }
    });
    this.getCountries();
    this.getPreContactMechIds();
    this.checkoutOptions();
  }
  getAccountDetails(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.accountInfo = data.data.contactInformation.filter((value: any) => {
            if (value.contactMech && (value.contactMech.contactMechId === this.contactMechId)) {
              this.addressForm.patchValue({
                ...value.postalAddress, ...{
                  contactMechPurposeTypeId: value.partyContactMechPurposes[0].contactMechPurposeTypeId
                }
              })
              return value.postalAddress;
            }
          });
          this.addressForm.controls.contactMechPurposeTypeId.disable();
          this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();

        }
      });

  }
  getPreContactMechIds(): void {
    this.spinner.show();
    this._CRMAccountService.getContactPurposes(this.partyId, 'POSTAL_ADDRESS')
      .subscribe(data => {
        if (data.success) {
          this.contactMechTypeId = data.data.purposeTypes.map((value: any) => {
            return {
              label: value.description,
              value: value.contactMechPurposeTypeId
            };
          });
        }
      });
    this.spinner.hide();

  }
  getCountries(): void {
    this.spinner.show();
    this._GlobalResourceService.getCountry()
      .subscribe(data => {
        if (data.success) {
          this.spinner.hide();
          this.countryArray = data.data.countryList.map((value: any) => {
            return {
              label: value.split(':')[0].trim(),
              value: value.split(':')[1].trim()
            };
          });
        }
      });

  }
  getStates(countryId: string): void {
    this.spinner.show();
    this._GlobalResourceService.getState(countryId)
      .subscribe(data => {
        if (data.success) {
          this.spinner.hide();
          this.stateArray = data.data.stateList.map((value: any) => {
            return {
              label: value.split(':')[0].trim(),
              value: value.split(':')[1].trim()
            };
          });
        }
      });

  }


  updateShippingAddress(): void {
    this.spinner.show();
    this.addressForm.patchValue({
      partyId:[this.partyId]
    });
    const form = {
      "address1": this.addressForm.value.address1,
      "address2": this.addressForm.value.address2,
      "allowSolicitation": this.addressForm.value.allowSolicitation,
      "attnName": this.addressForm.value.attnName,
      "city": this.addressForm.value.city,
      "contactMechPurposeTypeId": this.addressForm.value.contactMechPurposeTypeId,
      "contactMechTypeId": this.addressForm.value.contactMechTypeId,
      "countryGeoId": this.addressForm.value.countryGeoId,
      "partyId": this.partyId,
      "postalCode":this.addressForm.value.postalCode,
      "preContactMechTypeId": this.addressForm.value.preContactMechTypeId,
      "stateProvinceGeoId": this.addressForm.value.stateProvinceGeoId,
      "toName": this.addressForm.value.toName,
    }

    this.myAccountsService.updateShippingAddress(form).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.addressForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }

  createShippingAddress(): void {
    this.spinner.show();
    this.addressForm.patchValue({
      partyId:[this.partyId]
    });
    const form = {
      "address1": this.addressForm.value.address1,
      "address2": this.addressForm.value.address2,
      "allowSolicitation": this.addressForm.value.allowSolicitation,
      "attnName": this.addressForm.value.attnName,
      "city": this.addressForm.value.city,
      "contactMechPurposeTypeId": this.addressForm.value.contactMechPurposeTypeId,
      "contactMechTypeId": this.addressForm.value.contactMechTypeId,
      "countryGeoId": this.addressForm.value.countryGeoId,
      "partyId": this.partyId,
      "postalCode":this.addressForm.value.postalCode,
      "preContactMechTypeId": this.addressForm.value.preContactMechTypeId,
      "stateProvinceGeoId": this.addressForm.value.stateProvinceGeoId,
      "toName": this.addressForm.value.toName,
    }

    this.myAccountsService.createShippingAddress(form).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.addressForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }

  checkoutOptions() {
    this.id="";
    let req={

    }
    this.sidebarService.checkoutOptions(this.id,req).subscribe((res: any) => {
      this.address = res.data.address;
      this.partyTaxAuthInfoAndDetailList=res.data.partyTaxIdentification.partyTaxAuthInfoAndDetailList
      this.partyTaxIdentification = res.data.partyTaxIdentification.taxAuthPartyGeoIds;
      for (const value of this.partyTaxIdentification) {
        this.partyTaxIdentificationArray.push({
          label: value.geoName,
          value: value.partyId + '::' +value.taxAuthGeoId
        })
      }
      this.partyTaxIdentificationData = res.data.partyTaxIdentification;
      

   

    })
  }

 
  onSubmit(): void {
    this.addressForm.patchValue({
      partyId:[this.partyId]
    });
    this.spinner.show();
    if (this.addressForm.valid) {
      this._CRMAccountService.createPostalAddress({
        ...this.addressForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.cancelSubmit();
          }
        });
    }
    else
    {
      this._ToastrService.success('Something Went Wrong');

    }
   
  }



  updateAddress(): void {
    this.spinner.show();
    if (this.addressForm.valid) {
      this._CRMAccountService.updatePostalAddress({
        ...this.accountInfo[0], ...this.addressForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.addressForm.controls.contactMechPurposeTypeId.enable();
            this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
            
            this.addressForm.reset();

          }
        });
    }

  }

  onUpdate(): void {
    console.log(this.contactMechId);
    this.addressForm.patchValue({
      contactMechId:this.contactMechId,
      partyId:[this.partyId]

    });
    this.spinner.show();
    console.log(this.partyId)
    if (this.addressForm.valid) {
      this._CRMAccountService.updatePostalAddress({
         ...this.addressForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.addressForm.controls.contactMechPurposeTypeId.enable();
            this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
            this.cancelSubmit();
          }
        });
    }
    else{
      console.log("invalid");
    }

  }

  cancelSubmit(): void {
    this.addressForm.reset();
    this._Router.navigate(['/profile/contact-information'], { queryParams: { partyId: this.partyId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  goToProfile(){
    this._Router.navigate(['/profile/contact-information']);
  }
}
