<div class="container-fluid">
    <div class="row">
        <div class="col-lg-9">
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <div class="float-start">
                        <h6 class="text-left my-3 mx-4"><strong>Quote History</strong></h6>
                    </div>
                    
                </div>
                <div class="p-2">
                  <div class="form-group p-2 table-scroll">
                    <p-table [value]="ListQuotesList" [paginator]="true" [rows]="rows"
                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100]"
                        [totalRecords]="total" [scrollable]="true" [style]="{'width':'100%','min-width':'600px'}">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Quote Nbr</th>
                               
                                <th>Name</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Quote Issue Date</th>
                                <th>Valid From Date</th>
                                <th>Valid Through Date</th>
                                <th>Action</th>
                               
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-product>
                            <tr>
                                <td>{{product.ListQuotes.quoteId}}</td>
                                <td>{{product.ListQuotes.quoteName}}</td>
                                <td>{{product.ListQuotes.description}}</td>
                                <td>{{product.ListQuotes.statusId}}</td>
                                <td>{{product.issueDate}}</td>
                                <td>{{product.validFromDate}}</td>
                                <td>{{product.validThruDate}}</td>
                                <td><span class="span-link" (click)="goToViewQuotes(product.ListQuotes.quoteId)">View</span></td>
                            </tr>
                        </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td>
                      <span class="text-center">
                          <h6 class="ml-2">No Record Found
                          </h6>
                      </span>
                  </td>
              </tr>
          </ng-template>
          </p-table>
                    
                </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
          <app-right-side-bar></app-right-side-bar>
        </div>
    </div>
</div>

