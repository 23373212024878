import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  constructor(private activatedRoute:ActivatedRoute,private router:Router) { }

  ngOnInit(): void {
  }

  toDataFilter()
  {
    this.router.navigate(['/profile/date-filter'])
  }
  goToMessages() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/header-messages"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  goToQuotes() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/quotes"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  goToRequests() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/requests"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  goToShoppingList() {
    const token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/shoppingList"]);
    }else{
      this.router.navigate(["/login"]);
    }
   
  }
  goToQuickAdd() {
    this.router.navigate(["/quick-add"]);
  }



}
