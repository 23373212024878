import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-view-all-promotions',
  templateUrl: './view-all-promotions.component.html',
  styleUrls: ['./view-all-promotions.component.css']
})
export class ViewAllPromotionsComponent implements OnInit {

  constructor(readonly router:Router) { }

  ngOnInit(): void {
  }

  goToFeaturedProducts(){
    this.router.navigate(["/"]);
  }
  goToAdvancedSearch(){
    this.router.navigate(["/advanced-search"]);
  }
  goToShowPromotionDetails(){
    this.router.navigate(["/show-promotion-details"]);
  }
}
