<div class="container-fluid">
  <div id="ecom-mainarea" class="row mx-2 mt-2">
    <div id="div3" class="col-lg-12 order-lg-2 order-1">
      <ng-container *ngIf="show">
        <h2>Update Credit Card</h2>
      </ng-container>
      <ng-container *ngIf="!show">
        <h2>Add New Credit Card</h2>
      </ng-container>
      <form [formGroup]="creditCardForm" name="addcreditcardform">
        <a class="btn btn-outline-secondary me-2" (click)="goToQuickCheckOut()">Go Back</a>
        <ng-container *ngIf="show">
          <a class="btn btn-outline-secondary" (click)="update()">Update</a>
        </ng-container>
        <ng-container *ngIf="!show">
          <a class="btn btn-outline-secondary" (click)="submit()">Save</a>
        </ng-container>
        <div>
          <label class="mt-2 d-block">Company Name Card</label>
          <div class="row my-2">
            <div class="col-sm-6">
              <input type="text" class="form-control" maxlength="60" placeholder="Enter company name on card"
                name="companyNameOnCard" value="">
            </div>
          </div>
          <label>Prefix Card</label>
          <div class="row">
            <div class="col-sm-6">
              <p-dropdown [options]="prefixArray">
              </p-dropdown>
            </div>
          </div>
          <label class="required">First Name On Card<span style="color:red!important">*</span></label>
          <div class="row">
            <div class="col-sm-6">
              <input type="text" class="form-control mb-2" maxlength="60" name="firstNameOnCard"
                [(ngModel)]="firstNameOnCard" placeholder="Enter first name on card" formControlName="firstNameOnCard"
                value="">
            </div>
          </div>
          <label>Middle Name On Card<span style="color:red!important">*</span></label>
          <div class="row">
            <div class="col-sm-6">
              <input type="text" class="form-control mb-2" maxlength="60" name="middleNameOnCard"
                formControlName="middleNameOnCard" placeholder="Enter middle name on card" value="">
            </div>
          </div>
          <label class="required">Last Name On Card<span style="color:red!important">*</span></label>
          <div class="row">
            <div class="col-sm-6">
              <input type="text" class="form-control mb-2" placeholder="Enter last name on card" maxlength="60"
                [(ngModel)]="lastNameOnCard" formControlName="lastNameOnCard" name="lastNameOnCard" value="">
            </div>
          </div>
          <label>Suffix on Card</label>
          <div class="row">
            <div class="col-sm-6">
              <p-dropdown [options]="suffixArray" formControlName="suffixOnCard">
              </p-dropdown>
            </div>
          </div>
          <label class="required">Card Type<span style="color:red!important">*</span></label>
          <div class="row">
            <div class="col-sm-6">
              <p-dropdown [options]="cardTypes" [(ngModel)]="cardType" formControlName="cardType">
              </p-dropdown>
            </div>
          </div>
          <label class="required">Card Number<span style="color:red!important">*</span></label>
          <div class="row">
            <div class="col-sm-6">
              <input type="text" class="required form-control mb-2" [(ngModel)]="cardNumber"
                formControlName="cardNumber" placeholder="Enter card number" maxlength="30" name="cardNumber">

            </div>
          </div>
          <label class="required">Expiration Date<span style="color:red!important">*</span></label>
          <div class="row mb-2">
            <div class="col-sm-3">
              <p-dropdown [options]="expirationMonth" formControlName="expMonth">
              </p-dropdown>
            </div>
            <div class="col-sm-3">
              <p-dropdown [options]="expirationYear" formControlName="expYear">
              </p-dropdown>
            </div>
          </div>
          <label>Description</label>
          <div class="row mb-2">
            <div class="col-sm-6">
              <input type="text" size="20" class="form-control" placeholder="Enter description" name="description"
                value="">
            </div>
          </div>
          <!-- End Template component://ecommerce/template/customer/CreditCardFields.ftl -->
          <!-- End Screen component://ecommerce/widget/CustomerScreens.xml#creditCardFields -->

          <label class="mb-2">Billing Address</label>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-check">
                <input type="radio" class="form-check-input" name="contactMechId" value="11660"
                  fromControlName="contactMechId">
                <label>
                  Shipping Destination Address
                  General Correspondence Address
                  To: David J. Warn

                  address
                  eed
                  CA
                  ,&nbsp;AA
                  12121
                  <br>USA
                  (Updated:&nbsp;2021-05-19 05:07:47.726)
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-check">
                <input type="radio" class="form-check-input" name="contactMechId" value="11671">
                <label>
                  Billing (AP) Address


                  address

                  CA
                  ,&nbsp;AA
                  11223
                  <br>USA
                  (Updated:&nbsp;2021-05-19 07:42:11.944)
                </label>
              </div>
            </div>
          </div>
          <div class="form-check">
            <input type="radio" class="form-check-input mr-2" name="contactMechId" value="_NEW_" checked="checked">
            <label>Create a new billing address for this credit card.</label>
          </div>
        </div>
      </form>
      <a class="btn btn-outline-secondary me-2" (click)="goToQuickCheckOut()">Go Back</a>
      <ng-container *ngIf="show">
        <a class="btn btn-outline-secondary" (click)="update()">Update</a>
      </ng-container>
      <ng-container *ngIf="!show">
        <a class="btn btn-outline-secondary" (click)="submit()">Save</a>
      </ng-container>
    </div>
    <div id="div4" class="endcolumns">
    </div>
  </div>
</div>