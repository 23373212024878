<div class="container-fluid">
    <div class="row mx-2 mt-2">
        <!-- Begin Section Widget  -->
        <!-- End Section Widget  -->

        <div id="div3" class="col-lg-12 order-lg-2 order-1">
            <!-- Begin Template component://common-theme/template/includes/Messages.ftl -->
            <!-- End Template component://common-theme/template/includes/Messages.ftl -->
            <!-- Begin Template component://ecommerce/template/order/CheckoutReview.ftl -->
            <h2>Final Checkout Review</h2>
            <!-- Begin Screen component://ecommerce/widget/OrderScreens.xml#orderheader -->
            <!-- Begin Template component://ecommerce/template/order/OrderHeader.ftl -->
            <div class="card mt-2">
                <div class="card-title  p-3">
                    <h6> Order Information </h6>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li> Status : {{this.orderView.status}} </li>
                    </ul>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-title  p-3">
                    <h6>Payment Information</h6>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li> Payment Via Credit Card
                            {{paymentInformation.paymentVia}} 
                        </li>
                        <li>
                            <ul class="list-unstyled">
                                <li> Credit Card david J </li>
                                <li>CCT_VISA 1111 02/2022</li>
                            </ul>
                        </li>
                        <li>
                            <ul class="list-unstyled">
                                <li> </li>
                                <li> </li>
                                <li> address </li>
                                <li> </li>
                                <li> CA , Armed Forces Americas 11223 United States </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-title  p-3">
                    <h6>Shipping Information</h6>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>
                            <ul class="list-unstyled">
                                <li> Destination [1] To:{{ this.shippingInformation.shippingAddress.toName}} </li>
                                <li>Attn:{{ this.shippingInformation.shippingAddress.attnName}} </li>
                                <li> {{ this.shippingInformation.shippingAddress.address1}} </li>
                                <li> {{ this.shippingInformation.shippingAddress.address2}} </li>
                                <li>{{ this.shippingInformation.shippingAddress.city}}</li>
                                <li> {{ this.shippingInformation.shippingAddress.countryGeoId}} </li>
                            </ul>
                        </li>
                        <li>
                            <ul class="list-unstyled">
                                <li> Method:{{ this.shippingInformation.carrierPartyId}}{{ this.shippingInformation.shipmentMethodType.description}} </li>
                                <li> </li>
                            </ul>
                        </li>
                        <li> Splitting Preference: Please wait until the entire order is ready before shipping. </li>
                        <li> Gift? This order is not a gift. </li>
                    </ul>
                </div>
            </div> <!-- End Template component://ecommerce/template/order/OrderHeader.ftl -->
            <!-- End Screen component://ecommerce/widget/OrderScreens.xml#orderheader -->
            <br> <!-- Begin Screen component://ecommerce/widget/OrderScreens.xml#orderitems -->
            <!-- Begin Template component://ecommerce/template/order/OrderItems.ftl -->
            <div class="card mt-2">
                <div class="card-title  p-3">
                    <h6> Order Items </h6>
                </div>
                <div class="card-body">
                    <div class="table-scroll">
                        <table class="table table-responsive-sm">
                            <thead class="thead-light">
                                <tr>
                                    <th>Product</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Qty Ordered</th>
                                    <th class="amount">Unit Price</th>
                                    <th class="amount">Adjustments</th>
                                    <th class="amount">Subtotal</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th colspan="7" style="background-color: white !important;">Subtotal</th>
                                    <td class="amount" style="border-top: none!important;">${{this.otherdetails.orderSubTotal}}</td>
                                </tr>
                                <tr>
                                    <th colspan="7" style="background-color: white !important;">Promotion</th>
                                    <td class="amount"></td>
                                </tr>
                                <tr>
                                    <th colspan="7" style="background-color: white !important;">Promotion</th>
                                    <td class="amount"></td>
                                </tr>
                                <tr>
                                    <th colspan="7" style="background-color: white !important;">Shipping and Handling
                                    </th>
                                    <td class="amount">${{this.otherdetails.orderShippingTotal}}</td>
                                </tr>
                                <tr>
                                    <th colspan="7" style="background-color: white !important;">Sales Tax</th>
                                    <td class="amount">${{this.otherdetails.orderTaxTotal}}</td>
                                </tr>
                                <tr>
                                    <td colspan="3"></td>
                                    <td colspan="5"></td>
                                </tr>
                                <tr>
                                    <th colspan="7" style="background-color: white !important;">Grand Total</th>
                                    <td class="amount"
                                        style="border-top: none!important;border-bottom: 2px solid #dee2e6 !important;">
                                        ${{this.otherdetails.orderGrandTotal}} </td>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr>
                                    <td colspan="8"></td>
                                </tr>
                                <tr *ngFor="let productItem of this.orderItemList">
                                    <td> <a class="linktext">{{productItem.orderItem.productId}}- {{productItem.orderItem.itemDescription}}</a>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{{productItem.orderItem.quantity}}</td>
                                    <td class="amount"> ${{productItem.orderItem.unitPrice}} </td>
                                    <td class="amount"> ${{productItem.itemAdjustment}} </td>
                                    <td class="amount"> ${{productItem.itemTotal}} </td>
                                </tr>
                                <tr>
                                    <td colspan="8"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div><!-- End Template component://ecommerce/template/order/OrderItems.ftl -->
            <!-- End Screen component://ecommerce/widget/OrderScreens.xml#orderitems -->
            <label ></label>
            <div class="row">
                <div class="col-auto ml-auto mt-2">
                    <form method="post" action="" name="orderreview">
                        <input type="hidden" name="checkoutpage" value="payment">
                        <input type="button" name="processButton" (click)="processOrder();" class="btn btn-primary" value="Submit Order">
                    </form>
                </div>
            </div> <!-- End Template component://ecommerce/template/order/CheckoutReview.ftl -->
        </div><!-- Begin Section Widget  -->
        <!-- End Section Widget  -->

        <div id="div4" class="endcolumns">
        </div>
    </div>
</div>