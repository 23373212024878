import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, RouterModule,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RandomService } from '../random.service';
import { SideBarService } from '../side-bar/side-bar.service';
import { TreeNode } from 'primeng/api';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css']
})
export class ViewOrderComponent implements OnInit {
  catalogId: string | null;
  orderID: any;
  orderInfo:any;
  id:any;
  orderId: any;
  OrderID: any;
  ShippingInfo: any;
  productInfo: any;
  allData: any;
  paymentInfo: any;

  constructor(
    readonly router: Router, 
    private sidebarService: SideBarService,
    private toastr: ToastrService,
    readonly Form:FormBuilder,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
  ) {
    this.catalogId = localStorage.getItem("catalog");
   }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.OrderID = params["OrderID"];
      this.orderSummary();
    });
    

  }

  orderSummary() {
    this.sidebarService.orderSummary(this.OrderID).subscribe((res: any) => {
      this.allData=res.data;
      this.orderInfo = res.data.orderItems;
      this.ShippingInfo=res.data.shippingInformation
      this.productInfo=res.data
      this.paymentInfo=res.data.paymentList[1]
  });
  }

  processOrder() {
    this.id = "payment";
   
    this.sidebarService.processOrder(this.id).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");

      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);

      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {

          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {

          this.toastr.error(err.error.message);
        }
      }
    })

  
  }

}
