<div class="container-fluid">
    <div class="row banner_div banner_section">
        <div class="col-md-12">
          <h1>Contact-Us</h1>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-lg-9 px-4">
            <div class="card mx-auto mt-2" style="width: 100%;">
                <div class="card-title">
                    <div class="float-start">
                        <h6 class="text-left my-3 mx-4">Contact Us</h6>
                    </div>

                </div>
                <div class="card-body ps-4">

                    <div class="my-4" *ngIf="this.show">
                        <div class="d-flex row border-bottom">
                            <div class="col-lg-3">
                                <label for="exampletext">
                                    <h6 class="me-4">From:</h6>
                                </label>
                            </div>
                            <div class="col-lg-9">
                                <p>{{this.username}} {{this.lastname}} [{{this.partyId}}] (Not You? <span class="span-link" (click)="toMainPage()">Click Here)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <form [formGroup]="contactForm">
                        <div class="my-4">
                            <div class="d-flex row border-bottom py-4">
                                <div class="col-lg-3">
                                    <label for="exampletext">
                                        <h6 class="me-4">Subject:</h6>
                                    </label>
                                </div>
                                <div class="col-lg-9">
                                    <input type="text" formControlName="subject"
                                        class="form-control form-control-sm mb-2">
                                </div>
                            </div>
                            <div>
                                <div class="d-flex row my-4">
                                    <div class="col-lg-3">
                                        <label for="exampletext">
                                            <h6 class="me-4">Messsage:</h6>
                                        </label>
                                    </div>
                                    <div class="col-lg-9">
                                        <textarea type="text-area" formControlName="message"
                                            class="form-control form-control-sm mb-2"></textarea>
                                    
                                        </div>
                                </div>
                            </div>
                            <div class="d-flex row border-top py-4" *ngIf="!this.show">
                                <div class="col-lg-3">
                                    <label for="exampletext">
                                        <h6 class="me-4">Email Address:</h6>
                                    </label>
                                </div>
                                <div class="col-lg-9">
                                    <input type="text" formControlName="email"
                                        class="form-control form-control-sm mb-2">
                                </div>
                            </div>
                            <div class="d-flex row border-top py-4" *ngIf="!this.show">
                                <div class="col-lg-3">
                                    <label for="exampletext">
                                        <h6 class="me-4">First name:</h6>
                                    </label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text" formControlName="firstName"
                                        class="form-control form-control-sm mb-2">
                                </div>
                                <div class="col-lg-3">
                                    <label for="exampletext">
                                        <h6 class="ms-lg-5">Last name:</h6>
                                    </label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text" formControlName="lastName"
                                        class="form-control form-control-sm mb-2">
                                </div>
                            </div>
                            <div class="d-flex row border-top py-4" *ngIf="!this.show">
                                <div class="col-lg-3">
                                    <label for="exampletext">
                                        <h6 class="me-4">Captcha Code:</h6>
                                    </label>
                                </div>
                                <div class="col-lg-9">
                                    
                                </div>
                            </div>
                            <div class="d-flex row border-top py-4" *ngIf="!this.show">
                                <div class="col-lg-3">
                                    <label for="exampletext">
                                        <h6 class="me-4">Verify Captcha Code:</h6>
                                    </label>
                                </div>
                                <div class="col-lg-9">
                                    <input type="text" formControlName="verifyCaptcha"
                                        class="form-control form-control-sm mb-2">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="d-flex justify-content-center">
                    <button class="btn btn-outline-secondary btn-sm m-1" (click)="cancel()">Cancel</button>
                    <button class="btn btn-outline-secondary btn-sm m-1" (click)="sendMessage()">Save</button>
                </div>
            </div>
        </div>
        <div class="col-lg-3 px-4">
            <app-right-side-bar></app-right-side-bar>
        </div>
    </div>
</div>