import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-check-out-option2',
  templateUrl: './check-out-option2.component.html',
  styleUrls: ['./check-out-option2.component.css']
})
export class CheckOutOption2Component implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  locale: any;
  localeArray: any[] = [];
  partyId: any;
  mechId: any;
  exempt: any;
  selecttaxAuthPartyGeoIds: any;
  id: any;
  shipmentMethods: any;
  otherdata: any;
  radioValueGift: any;
  splitValue: any;
  emailId: string | null;
  showCart: any;
  specialOffers: any;
  summary: any;
  compareProductList: any;
  catalogId: string | null;
  shipmentId: any;
  dataPartyId:any;
  constructor(readonly router:Router,private sidebarService: SideBarService,
    private activeRoute:ActivatedRoute,
    private toastr: ToastrService) {
      this.emailId = localStorage.getItem("email");
      this.catalogId = localStorage.getItem("catalog");
     }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.mechId = params["mechId"];
      this.exempt = params["exempt"];
      this.selecttaxAuthPartyGeoIds=params["selecttaxAuthPartyGeoIds"];
    });
    this.getLocale();
    this.checkoutOptions();
    this.getshowCart();
    this.getCompareProducts();
  }
  removeAddToCompare(id:any): void {

    this.sidebarService.removeAddToCompare(id)
      .then(data => {
        if (data.success) {
          this.toastr.success(data.data.successMessage);
        this.getCompareProducts();
        }
      });
  
  }
  getCompareProducts() {
    this.sidebarService.getCompareProducts(this.catalogId).subscribe((res: any) => {
      this.compareProductList = res.data.productList;

    })
  } 
 
  clearCompare() {
     
  
    this.sidebarService.clearCompare().subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.getCompareProducts();
  
      }
    }, (err) => {
   
      this.toastr.error(err.message);
  
    });
   
  }
  goToCheckOut(){
    this.router.navigate(["/check-out-option1"]);
  }
  getshowCart() {
    this.sidebarService.getshowCart().subscribe((res: any) => {
      this.showCart = res.data.productsList;
      this.specialOffers = res.data.specialOffers;
      this.summary = res.data.summary;

    })
  }
  radioShip(id:any){
    this.shipmentId=id;
  }
  checkoutOptions() {
    this.dataPartyId=localStorage.getItem("partyId");
    this.id="shippingaddress";
    let req={
      "shipping_contact_mech_id": this.mechId,
      "taxAuthPartyGeoIds":this.selecttaxAuthPartyGeoIds,
      "partyTaxId": this.dataPartyId,
      "isExempt":  this.exempt
    }
    this.sidebarService.checkoutOptions(this.id,req).subscribe((res: any) => {
     this.shipmentMethods = res.data.shipmentMethods;
     this.otherdata=res.data;
    })
  }
  split(value:any){
    this.splitValue=value;
  }
  radioGift(value:any){
    this.radioValueGift=value;
  }
  goToViewAllPromotions(){
    this.router.navigate(["/view-all-promotions"]);
  }
  goToShowPromotionDetails(){
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewCart(){
    this.router.navigate(["/view-cart"]);
  }
  goToQuickCheckOut(){
    this.router.navigate(["/quick-check-out"]);
  }
  goToOnePageCheckOut(){
    this.router.navigate(["/one-page-check-out"]);
  }

  goToCheckOutOption1(){
    this.router.navigate(["/check-out-option1"]);
  }
  goToCheckOutOption3(product:any){
    this.router.navigate(["/check-out-option3"],{ queryParams: {shipmentId:this.shipmentId, shipping_instructions:product.shipping_instructions,order_additional_emails:product.order_additional_emails,gift_message: product.gift_message,splitValue:this.splitValue,correspondingPoId:product.correspondingPoId,radioValueGift:this.radioValueGift}});
  }
  getLocale() {
    //this.spinner.show();
    this.sidebarService.getLocale().subscribe((res: any) => {
      this.locale = res.data;
      //this.spinner.hide();
      for (const value of this.locale) {
        this.localeArray.push({
          label: value.value,
          value: value.key
        })
      }
    })

  }
}
