<div class="card">
    <div class="card-header">
      <a href="javascript:document.setdefaultshipmeth.submit();" class="submenutextright">Set Default</a>
      &nbsp;<strong>Default Shipment Method</strong>
    </div>
    <div class="card-body">
      <table class="table table-responsive-sm">
            <tbody>
                <tr *ngFor="let item of carrierShipMethods">
              <td></td>
              <td>
                {{item.partyId}}  {{item.description}}
              </td>
            </tr>
            
           
          
      </tbody></table>
    </div>
  </div>