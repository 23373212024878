import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';

@Component({
  selector: 'app-header-messages',
  templateUrl: './header-messages.component.html',
  styleUrls: ['./header-messages.component.css']
})
export class HeaderMessagesComponent implements OnInit {

  total=0;
  pageNo = 1;
  rows = 50;
  pageSize= 100;
  rowNumber=0;
  search="";
  messages:any[]=[];
  getCustomerProfile: any;
  getMessageList: any;
  username: any;
  lastname: any;
  partyId: any ;
  messageListFirstList: any;
  messageListSecondList: any;
  show: boolean;
  id:any;
  constructor(readonly router:Router,private sidebarService:SideBarService,
    private _ActivatedRoute: ActivatedRoute,private form: FormBuilder,
    readonly spinner: NgxSpinnerService,

    private toastr: ToastrService,) {
      this.show = true;
      this.username = localStorage.getItem("firstName");
      this.lastname = localStorage.getItem("lastName");
      this.partyId = localStorage.getItem("partyId");

     }

  ngOnInit(): void {
    this.username = localStorage.getItem("firstName");
    this.lastname = localStorage.getItem("lastName");
    this.partyId = localStorage.getItem("partyId");

    this.messageListFirst();
    this.messageListSecond();
  }
  sendMailPage(id: any){
    this.router.navigate(['/contact-us'], { queryParams: { communicationEventId: id } });
  }
  summaryPage(id: any){
    this.router.navigate(['/header-messages/read-message-summary'], { queryParams: { communicationEventId: id } });
  }
  viewRecieved(){
    this.show = false;
  }
  viewRead(){
    this.show = true;
  }
  messageListSecond() {
    this.spinner.show;
    this.sidebarService.messageListSecond(this.partyId).subscribe((res: any) => {
      this.messageListSecondList = res.data;
    })
    this.spinner.hide;

  }

  messageListFirst() {
    this.spinner.show;
    this.sidebarService.messageListFirst(this.partyId).subscribe((res: any) => {
      this.messageListFirstList = res.data;
    })
    this.spinner.hide;
  }
}
