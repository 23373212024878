import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SideBarService } from '../side-bar/side-bar.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent implements OnInit {

  files:any[]=[];
  total = 0;
  pageNo = 1;
  rows = 50;
  pageSize = 100;
  rowNumber = 0;
  search = "";
  contentList: any;
  partyId: string | null;
  constructor(readonly router: Router, private sidebarService: SideBarService,
    private toastr: ToastrService, private _ActivatedRoute: ActivatedRoute) { 
      this.partyId = localStorage.getItem('partyId');
    }

  ngOnInit(): void {
    this.getPartyContentsList();
  }
  
  onDelete(contentId:any, fromDate:any, partyContent:any, partyId:any): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.sidebarService.deleteContent(contentId, fromDate, partyContent, partyId).subscribe(res => {
         
          this.getPartyContentsList();
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
   
  }
  getPartyContentsList() {
    
    this.sidebarService.getPartyContentList(this.partyId).subscribe(res => {
      this.contentList = res.data;
      
    })
   
  }
  export(id: any, contentName: any) {

    this.sidebarService.downloadView(id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
   
      link.download = contentName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
   
  }
  goToUploadNewFile(){
    this.router.navigate(["/upload-new-file"]);
  }
  delete(){}
  download(){}
}
