import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { SideBarService } from '../side-bar/side-bar.service';
import { TreeNode } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-find-catalog',
  templateUrl: './find-catalog.component.html',
  styleUrls: ['./find-catalog.component.css']
})
export class FindCatalogComponent implements OnInit {
  catalog: any;
  catalogArray: any[] = [];
  locale: any;
  localeArray: any[] = [];
  id: any;
  selectedFiles: any;
  category: TreeNode[] = [];
  language: any;
  findCatalog: any;
  total = 0;
  pageNo = 0;
  rows = 50;
  pageSize = 100;
  rowNumber = 0;
  search = "";
  messages: any[] = [];
  
  categoryId: any;
  productMain: any;
  
  private finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  findSearch: any;
  keywordSearch: any;
  type: any;
  constructor(readonly router: Router, private sidebarService: SideBarService,
    private toastr: ToastrService, private _ActivatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.getCatalogList();
    this.getLocale();
    this._ActivatedRoute.queryParams.subscribe(params=>{
      this.findCatalog=params["findCatalog"];
      this.type=params["type"];
    })
    this.keywordSearchCatalog();

  }
  keywordSearchCatalog() {
    
    const req = {
      "clearSearch": "",
      "color": "",
      "license": "",
      "noContains": "",
      "operator": this.type,
      "searchCatalogId": "",
      "searchCategoryId": "CATALOG1_SEARCH",
      "searchString":this.findCatalog,
      "searchsubCategories": "",
      "size": "",
      "sortAscending": "",
      "sortOrder": ""

    }
    this.sidebarService.keywordSearchCatalog(req,this.finSize).subscribe((res: any) => {
      this.keywordSearch = res.data;
      

    })
   
  }
  

  goToFeaturedProducts() {
    this.router.navigate(["/"]);
  }
  goToAdvancedSearch() {
    this.router.navigate(["/advanced-search"]);
  }
  goToViewAllPromotions() {
    this.router.navigate(["/view-all-promotions"]);
  }
  goToPromotionDetails() {
    this.router.navigate(["/show-promotion-details"]);
  }
  goToLogin() {
    this.router.navigate([""]);
  }
  getCatalogList() {
    //this.spinner.show();
    this.sidebarService.getCatalogList().subscribe((res: any) => {
      this.catalog = res.data;
      //this.spinner.hide();
      for (const value of this.catalog) {
        this.catalogArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })

  }

  getLocale() {
    //this.spinner.show();
    this.sidebarService.getLocale().subscribe((res: any) => {
      this.locale = res.data;
      //this.spinner.hide();
      for (const value of this.locale) {
        this.localeArray.push({
          label: value.value,
          value: value.key
        })
      }
    })

  }
 
  goToConfigureProduct() {
    this.router.navigate(["/configure-product"]);
  }
  goToChooseProductAmount() {
    this.router.navigate(["/choose-product-amount"]);
  }
  goToChooseVariation() {
    this.router.navigate(["/choose-variation"]);
  }

  goToShowPromotionDetails() {
    this.router.navigate(["/show-promotion-details"]);
  }
  goToViewCart() {
    this.router.navigate(["/view-cart"]);
  }
  goToQuickCheckOut() {
    this.router.navigate(["/quick-check-out"]);
  }
  goToOnePageCheckOut() {
    this.router.navigate(["/one-page-check-out"]);
  }
  getLanguage(event:any) {
    this.language=event.value;
    this.sidebarService.postLocale(this.language).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
       
      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);
      
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
       
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
        
          this.toastr.error(err.error.message);
        }
      }
    })
  
 
}
  
  getCategoryByCatalog(event:any){
    this.id=event.value;
    this.sidebarService.getCategoryByCatalog(this.id).subscribe((res: any) => {
      this.category = res.data;
      this.router.navigate(["/"],{ queryParams: { categoryId: this.id } });
    })
  }
 /*  addToCompare(id:any) {
   
    this.sidebarService.addToComparePost(id).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
       
      } else if (res.success == false) {
        this.toastr.error(res.data.responseMessage);
      
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
       
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
        
          this.toastr.error(err.error.message);
        }
      }
    })
  
 
} */
}
