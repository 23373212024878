
 <div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 px-4" style="display:flex !important;">
          <div class="col-lg-3" style="padding-right:1% !important ;">
            <div class="card mx-auto mt-4" style="width: 100%;">
              <div class="card-title">
                <p class="text-left my-3 mx-2">Group 1:</p>
              </div>
              <div class="p-2">
                <form>
                  <div class="card-body">
                    <table class="table">
                      <thead>
                      <tr>
                        <th>Item </th>
                        <th>Qty</th>
                      </tr>
                      </thead>
                      
                      <tbody>
                        <tr  *ngFor="let product of this.showCart;let indexOfelement=index;">
                          <!-- <td>{{indexOfelement+1}}</td> -->
                          <td>
                                <span class="span-link">{{product.productName}}</span>
                          </td>
                          <td>${{product.itemTotal}}</td>
                        </tr>
                      </tbody>
                    </table>
                    
                     
                  </div>
                </form>
              </div>
            </div>
          </div>

          
          <div class="col-lg-9">
            <div class="card mx-auto mt-4" style="width: 100%;">
              <div class="card-title">
                  <p class="text-left my-3 mx-2"><strong>Split Shipment</strong></p>
              </div>
              <form [formGroup]="splitShipmentForm" >

                <div class="row p-3">
                  <div class="form-group col-lg-6">
                      <div>
                        <label class="mb-2" for="exampleInputEmail1" style="float:left">Address: <span class="span-link ml-3" (click)="toNewAddress()">New Address</span></label>
                      </div>
                  </div>

                </div>
                <div class="row p-3">
                  <div class="form-group col-lg-6">
                      <div>
                        <label class="mb-2" for="exampleInputEmail1" style="float:left">Shipping Address:</label>
                        <p-dropdown placeholder="Select Shipping Address" formControlName="shippingAddress">
                        </p-dropdown>


                      
                      </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <label class="mb-2" for="exampleInputEmail1" style="float:left">Shipping :</label>
                    <p-dropdown placeholder="Ship All Items Togather" formControlName="shippingQuantity">
                  </p-dropdown>
                  </div>
                  
                </div>

                <div class="row p-3">
                  <div class="form-group col-lg-6">
                      <div>
                        <label class="mb-2" for="exampleInputEmail1" style="float:left">Carrier Party ID:</label>
                        <p-dropdown placeholder="Select Carrier" formControlName="carrierPartyId">
                        </p-dropdown>


                      
                      </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <label class="mb-2" for="exampleInputEmail1" style="float:left">Is GIft ?:</label>
                    <p-dropdown placeholder="Select Is GIft ?" formControlName="isGift">
                    </p-dropdown>
                  </div>
                  
                </div>

                <div class="row p-3">
                  <div class="form-group col-lg-6">
                      <div>
                        <label class="mb-2" for="exampleInputEmail1" style="float:left">Special Instruction:</label>
                        <textarea type="text-area" formControlName="specialInstruction"class="form-control form-control-sm mb-2">
                        </textarea>
                                    
                        <!-- <input type="email" class="form-control mb-2"  formControlName="specialInstruction"  
                        id="username" aria-describedby="emailHelp" placeholder="Enter Special Instruction"> -->


                      
                      </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <label class="mb-2" for="exampleInputEmail1" style="float:left">Gift Message:</label>
                    <textarea type="text-area" formControlName="giftMessage"class="form-control form-control-sm mb-2">
                    </textarea>
                    <!-- <input type="email" class="form-control mb-2"  formControlName="giftMessage"  
                    id="username" aria-describedby="emailHelp" placeholder="Enter Gift Message">
                   -->
                  </div>
                  
                </div>

              <div class="form-group pt-4 col-lg-3"  style="margin-left: 46% !important;">
                <div class="pt-1 text-left my-3 mx-2">
                  <input class="btn btn-outline-secondary"   
                  type="submit" value="Save" >
                </div>
              </div>
            </form>
            </div>
          </div>
        </div>
        
            <div class="col-lg-12 px-4" style="display:flex !important;">
              <div class="card mx-auto mt-4" style="width: 100%;">
                  <div class="card-title">
                      <div class="float-start">
                        <p class="my-3 mx-2"><strong>Assign Items</strong></p>
                      </div>
                      
                  </div>
                  <div class="p-2">
                    
                    <div class="form-group p-2 table-scroll">
                      <p-table  [paginator]="true" [rows]="rows"
                          scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100]"
                          [totalRecords]="total" [scrollable]="true" [style]="{'width':'100%','min-width':'600px'}">
                          <ng-template pTemplate="header">
                              <tr>
                                  <th style="background-color: #343a40!important;color:white!important">Product</th>
                                  <th style="background-color: #343a40!important;color:white!important">Total Qty</th>
                                  <th style="background-color: #343a40!important;color:white!important">Move Qty</th>
                                  <th style="background-color: #343a40!important;color:white!important">From</th>
                                  <th style="background-color: #343a40!important;color:white!important">To</th>
                                  <th style="background-color: #343a40!important;color:white!important">Action</th>

                              </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-product>
                              <tr>
                                  <td>{{[product.productId]}} {{product.productName}} {{product.description}}</td>
                                  <td>{{product.quantity}}</td>
                                  <td>{{product.unitPrice}}</td>
                                  <td>{{product.subTotal}}</td>
                                  <td><input class="btn btn-outline-secondary" type="submit" data-toggle="modal" data-target="#updateListItemPopup"  value="Update">
                                    <input class="btn btn-outline-secondary" type="submit" value="Remove">
                                    <input class="btn btn-primary" type="submit" value="Add 1 To Cart">
                                  </td>
                              </tr>
                          </ng-template>
                      <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td>
                        <span class="text-center">
                            <h6 class="ml-2">No Record Found
                            </h6>
                        </span>
                    </td>
                </tr>
            </ng-template>
            </p-table>
                      
                  </div>
                  </div>
              </div>
            </div>
            
          
        
    </div>
  </div>
